import { FC } from 'react';
import { useModalUtilities } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components';
import CampaignTypeModal from '../CampaignTypeModal';

import * as Styled from './EmptyCampaignsPlaceholder.styles';

const EmptyCampaignsPlaceholder: FC = () => {
  const { t } = useTranslation();

  const {
    open: selectCampaignTypeModal,
    triggerRef: selectCampaignTypeBtnRef,
    closeModal: closeSelectCampaignTypeModal,
    ModalButton: SelectCampaignTypeButton,
  } = useModalUtilities();

  return (
    <Styled.Container className="empty-campaigns">
      <Icon name="megaphone" className="empty-campaigns__icon" />

      <p className="empty-campaigns__title">
        {t('gamification-campaigns_empty_state_title_no_campaigns')}
      </p>
      <p className="empty-campaigns__content">
        {t('gamification-campaigns_empty_state_subtitle_give_people_option')}
      </p>

      <SelectCampaignTypeButton
        icon={<Icon name="plus" />}
        iconPosition="right"
      >
        {t('gamification-campaigns_title_create_a_campaign')}
      </SelectCampaignTypeButton>

      {selectCampaignTypeModal && (
        <CampaignTypeModal
          onClose={closeSelectCampaignTypeModal}
          triggerRef={selectCampaignTypeBtnRef.current as HTMLButtonElement}
        />
      )}
    </Styled.Container>
  );
};

export default EmptyCampaignsPlaceholder;
