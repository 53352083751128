import { flex } from '@faxi/web-css-utilities';

import styled from 'styled-components';
import { theme } from '@faxi/web-component-library';

export const MessageSenderStyled = styled.div`
  padding: ${theme.sizes.SIZE_20};
  z-index: 1;
  background-color: var(--BACKGROUND_2_1);

  form {
    ${flex('row', 'center', 'center')};
  }

  .message-sender {
    &__textarea {
      width: 100%;

      > .textarea__container {
        width: 100%;
      }
    }

    &__button {
      height: 100%;
      margin-left: ${theme.sizes.SIZE_24};
    }
  }
`;
