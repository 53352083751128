import dayjs from 'dayjs';
import { Message } from '../../../models';

function markParentSingleMessage(msgToMark: Message, previousMsg: Message) {
  if (previousMsg.from.id !== msgToMark.from.id) return;

  const firstTimestamp = dayjs(previousMsg.ts);
  const secondTimeStamp = dayjs(msgToMark.ts);
  const diffInMinutes = Math.abs(
    firstTimestamp.diff(secondTimeStamp, 'minutes')
  );

  msgToMark.hasParent = diffInMinutes < 5;
}

function markParentMessages(messages: Message[]) {
  const markedMessages: Message[] = [];

  for (const [ind, msg] of messages.entries()) {
    markedMessages[ind] = msg;

    // we are using `ind + 1` instead of `ind - 1` because the first
    // message in the array is the latest
    const previousMsg = messages[ind + 1];

    if (!previousMsg) continue;

    markParentSingleMessage(markedMessages[ind], previousMsg);
  }

  return markedMessages;
}

export { markParentSingleMessage, markParentMessages };
