import {
  useCallback,
  useContext,
  useState,
  useEffect,
  FC,
  PropsWithChildren,
} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUtilities } from '@faxi/web-component-library';

import CarSpaceContext from './CarSpace.context';
import { UserContext } from 'store';
import { CarSpaceProps } from 'pages/Settings/CarParkSettings/CarParkSettings.component';
import { apiCommunity } from 'modules';

const CarSpaceProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const { carParkId } = useParams() as {
    carParkId: string;
  };

  const { communityId, isValidCommunity } = useContext(UserContext);

  const [carParkSpaces, setCarParkSpaces] = useState<CarSpaceProps[]>([]);

  const { t } = useTranslation();

  const getCarParkSpaces = useCallback(async () => {
    if (!isValidCommunity || !communityId) return;

    try {
      showOverlay('.kinto-page');

      const { rc, carparkspace } = await apiCommunity.getCarParkSpaces(
        communityId,
        carParkId
      );
      if (rc === 'ok') {
        setCarParkSpaces(carparkspace);
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.kinto-page');
    }
  }, [carParkId, communityId, hideOverlay, isValidCommunity, showOverlay]);

  const addNewCarSpace = useCallback(
    async (carSpaceName: string) => {
      if (!communityId) {
        return;
      }

      try {
        showOverlay('.kinto-page');

        const { rc } = await apiCommunity.addCarSpace(
          communityId,
          carParkId,
          carSpaceName
        );
        if (rc === 'ok') {
          getCarParkSpaces();
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.kinto-page');
      }
    },
    [carParkId, communityId, getCarParkSpaces, hideOverlay, showOverlay]
  );

  const updateCarSpace = useCallback(
    async (name: string, carSpaceId: string) => {
      if (!communityId) {
        return;
      }

      try {
        showOverlay('.kinto-page');
        const { rc } = await apiCommunity.updateCarSpace(
          communityId,
          carParkId,
          carSpaceId,
          name
        );
        if (rc === 'ok') {
          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t('changes_saved'),
            variant: 'success',
          });

          setCarParkSpaces((old) =>
            old.map((carSpace) => {
              if (carSpace.id === carSpaceId) {
                carSpace.name = name;
              }
              return carSpace;
            })
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.kinto-page');
      }
    },
    [carParkId, communityId, hideOverlay, showOverlay, showSnackBar, t]
  );

  const removeCarSpace = useCallback(
    async (carspaceId: string) => {
      if (!communityId) {
        return;
      }

      try {
        showOverlay('.kinto-page');

        const { rc } = await apiCommunity.removeCarSpace(
          communityId,
          carParkId,
          carspaceId
        );
        if (rc === 'ok') {
          setCarParkSpaces((old) =>
            old.filter((carSpace: CarSpaceProps) => {
              return carSpace.id !== carspaceId;
            })
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.kinto-page');
      }
    },
    [carParkId, communityId, hideOverlay, showOverlay]
  );

  useEffect(() => {
    getCarParkSpaces();
  }, [getCarParkSpaces]);

  return (
    <CarSpaceContext.Provider
      value={{
        carParkSpaces,
        addNewCarSpace,
        updateCarSpace,
        removeCarSpace,
      }}
    >
      {children}
    </CarSpaceContext.Provider>
  );
};

export default CarSpaceProvider;
