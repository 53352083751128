import {
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  useCallbackRef,
  useEffectSkipFirst,
} from '@faxi/web-component-library';
import classNames from 'classnames';

import Icon from 'components/Icon';
import ImageCropModal from './components/ImageCropModal';
import ImagePreview from './components/ImagePreview';
import useDropzoneField, {
  DropzoneFieldProps,
  DropzoneFileType,
} from './hooks/useDropzoneField';

import * as Styled from './ImageDropzone.styles';
import { useTranslation } from 'react-i18next';

export const getPreviewImageURL = (file?: File) => {
  if (!file) return '';
  return typeof file === 'string' ? file : URL.createObjectURL(file as File);
};

export type ImageDropzoneProps = {
  className?: string;
  title?: string;
  alt?: string;
  fallbackUrl?: string;
  circularCrop?: boolean;
  placeholder?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onFileDelete?: (name: string) => void;
  onChange: (file: File | null) => void;
  onCropToggle?: (value: boolean) => void;
} & DropzoneFieldProps;

const ImageDropzone: FC<ImageDropzoneProps> = (props) => {
  const {
    className,
    value,
    title,
    alt,
    placeholder: pPlaceholder,
    disabled,
    fallbackUrl = '/assets/svg/user_circle_placeholder.svg',
    deleteCondition,
    circularCrop = false,
    accept,
    loading,
    uploadRequest,
    deleteRequest,
    onFileDelete,
    onChange,
    ...rest
  } = props;

  const { t } = useTranslation();

  const componentIsMounted = useRef<boolean>(true);

  const [addBtnElement, addBtnRef] = useCallbackRef<HTMLButtonElement>();
  const [editBtnElement, editBtnRef] = useCallbackRef<HTMLButtonElement>();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const {
    uploadedFile,
    setUploadedFile,
    deleteFile,
    getRootProps,
    getInputProps,
  } = useDropzoneField({
    value,
    accept,
    deleteCondition,
    onFileDelete,
    uploadRequest,
    deleteRequest,
    ...rest,
  });

  const [lastUploadedFile, setLastUploadedFile] =
    useState<DropzoneFileType | null>();

  const previewImageUrl = useMemo(
    () => getPreviewImageURL(lastUploadedFile as File),
    [lastUploadedFile]
  );

  const modalTriggerElement = useMemo(
    () => (previewImageUrl ? editBtnElement : addBtnElement) as HTMLElement,
    [addBtnElement, editBtnElement, previewImageUrl]
  );

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  //When user clicks on delete button then delete file
  const handleDeleteFile = useCallback(
    (file: File) => {
      deleteFile(file, file.name);
      setLastUploadedFile('');
      onChange(null);
    },
    [deleteFile, onChange]
  );

  //When crop modal is active and user cancel current cropping then
  //set current uploaded file if shouldSaveFile is set on true else delete it.
  const handleCloseCrop = useCallback(
    (shouldSaveFile: boolean, lastValidFile: DropzoneFileType | null) => {
      setUploadedFile(() => {
        if (!lastUploadedFile) return null;
        return shouldSaveFile ? lastUploadedFile : null;
      });
    },
    [setUploadedFile, lastUploadedFile]
  );

  //When user save changes in cropping modal then set new uploaded file
  //and call callback function
  const handleSaveChanges = useCallback(
    async (file: File) => {
      let imageURL = null;

      if (uploadRequest) {
        imageURL = await uploadRequest(file, file.name);
      }

      const newFile = imageURL || file;

      setUploadedFile(newFile);
      setLastUploadedFile(newFile);
      onChange?.(newFile);
    },
    [onChange, setUploadedFile, uploadRequest]
  );

  //When delete condition is true and uploaded file doesn't exists
  //then you can send delete request
  useEffectSkipFirst(() => {
    if (!deleteCondition || uploadedFile) return;

    deleteFile();
  }, [deleteCondition]);

  useEffect(() => {
    const isFile = uploadedFile instanceof File;
    if (isFile) return;

    setLastUploadedFile(uploadedFile as string);

    return () => {
      componentIsMounted.current = false;
    };
  }, [uploadedFile]);

  return (
    <Styled.DropzoneContainer
      className={classNames('kinto-image-dropzone', className)}
    >
      {!lastUploadedFile && (
        <div className="kinto-image-dropzone__drop-trigger">
          <div className="kinto-image-dropzone__placeholder">
            <ImagePreview
              url=""
              alt={alt}
              hideActions
              loading={loading}
              fallbackUrl={fallbackUrl}
              circularPreview={circularCrop}
            />
            <Button
              ref={addBtnRef}
              variant="ghost"
              iconPosition="right"
              className="kinto-image-dropzone__edit-btn"
              aria-label={t('upload_from_device')}
              icon={<Icon name={'plus'} />}
              onClick={() => setOpenModal(true)}
              disabled={disabled}
            />
          </div>
        </div>
      )}

      {previewImageUrl && (
        <div
          className="kinto-image-dropzone__wrapper-img"
          onClick={() => {
            if (disabled) return;
            setOpenModal(true);
            setLastUploadedFile(uploadedFile);
          }}
        >
          <ImagePreview
            hideModal
            hideActions
            alt={alt}
            loading={loading}
            className="kinto-image-dropzone__img"
            url={previewImageUrl}
            fallbackUrl={fallbackUrl}
            circularPreview={circularCrop}
          />
          <Button
            ref={editBtnRef}
            variant="ghost"
            className="kinto-image-dropzone__edit-btn"
            aria-label={t('upload_from_device')}
            icon={<Icon name="pen" />}
            disabled={disabled}
          />
        </div>
      )}

      {openModal && (
        <ImageCropModal
          alt={alt}
          title={title}
          accept={accept}
          triggerRef={modalTriggerElement}
          file={uploadedFile as File}
          circularCrop={circularCrop}
          dropzoneProps={getRootProps()}
          dropzoneInputProps={getInputProps()}
          onClose={closeModal}
          onSave={handleSaveChanges}
          onDelete={handleDeleteFile}
          onCloseCrop={handleCloseCrop}
        />
      )}
    </Styled.DropzoneContainer>
  );
};

export default memo(ImageDropzone);
