import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { validationRegexes, validators } from '@faxi/web-form';
import {
  Button,
  PageSelector,
  Divider,
  Icon,
  useKeyboardListener,
  useUtilities,
  useStateQueries,
} from '@faxi/web-component-library';
import { debounce } from 'lodash';

import { CommunitySettingsContext, UserContext } from 'store';
import TagsField from 'components/_molecules/TagsField';
import { apiCommunity } from 'modules';
import InputField from 'components/_molecules/InputField/InputField.component';

import * as ModalsStyled from '../../_modals/EmailListModal/EmailListModal.styles';
import * as Styled from './PreapprovedSettings.styles';
import regex from 'validation/regex';

type FormValues = {
  domain: string[];
  preapproved_emails: string[];
};

const PREAPPROVED_EMAILS_PAGE_SIZE = 10;

const PreapprovedSettings: FC<{ openForm: () => void }> = (props: {
  openForm: () => void;
}) => {
  const { openForm } = props;

  const { communityId, updateCommunity } = useContext(UserContext);
  const { community } = useContext(CommunitySettingsContext);

  const { t } = useTranslation();

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const [preapprovedEmails, setPreapprovedEmails] = useState<string[]>();
  const [totalPreapprovedEmails, setTotalPreapprovedEmails] = useState(0);
  const [seeAllEmails, setSeeAllEmails] = useState<boolean>();
  const [firstPagePreapprovedEmails, setFirstPagePreapprovedEmails] =
    useState<string[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const {
    params: { search },
    setQuery,
  } = useStateQueries<{ search: string }>(
    [{ query: 'search', defaultValue: '' }],
    false
  );

  const preapprovedDomains = useMemo<string[]>(
    () =>
      community?.domain === ''
        ? []
        : community?.domain?.split(', ').sort((a, b) => (a < b ? -1 : 1)) || [],
    [community?.domain]
  );

  const tagValidations = useMemo(
    () => ({
      preapproved_emails: [
        validators.general.regex(
          validationRegexes.workEmail,
          t('validation-field_valid_email', {
            fieldname: t('register_email_hint').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          80,
          t('validation-field_validation_max_length', {
            fieldname: t('register_email_hint').toLowerCase(),
            number: '80',
          })
        ),
      ],
      domain: [
        validators.general.regex(
          regex.domainRegex,
          t('validation-field_domain')
        ),
      ],
    }),
    [t]
  );

  const initFormData = useMemo<FormValues>(
    () => ({
      preapproved_emails: firstPagePreapprovedEmails || [],
      domain: preapprovedDomains || [],
    }),
    [firstPagePreapprovedEmails, preapprovedDomains]
  );

  const totalPages = useMemo(
    () => Math.ceil(totalPreapprovedEmails / PREAPPROVED_EMAILS_PAGE_SIZE),
    [totalPreapprovedEmails]
  );

  const fetchPreapprovedEmails = useCallback(async () => {
    if (!communityId) {
      return;
    }

    const { preapproved_emails, total_count } =
      await apiCommunity.getPreapprovedEmails(
        communityId,
        PREAPPROVED_EMAILS_PAGE_SIZE,
        (currentPage - 1) * PREAPPROVED_EMAILS_PAGE_SIZE,
        debouncedSearch
      );

    setPreapprovedEmails(preapproved_emails);
    setTotalPreapprovedEmails(total_count);

    if (currentPage === 1) {
      setFirstPagePreapprovedEmails(preapproved_emails);
    }
  }, [communityId, currentPage, debouncedSearch]);

  const handleUpdateDomains = useCallback(
    async (domains: string[]) => {
      showOverlay('.kinto-page');

      try {
        const preapprovedData = {
          domain: domains.join(', '),
        };

        await updateCommunity(communityId!, preapprovedData, 'PUT');

        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t(
            'preapproved_settings-notification_successfully_updated_the_domain_list'
          ),
          variant: 'success',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.kinto-page');
      }
    },
    [communityId, hideOverlay, showOverlay, showSnackBar, t, updateCommunity]
  );

  const onSearchChange = useRef(
    debounce((text: string) => {
      setDebouncedSearch(text);
      setCurrentPage(1);
    }, 300)
  );

  const handleRemoveEmail = useCallback(
    async (email: string) => {
      showOverlay(!seeAllEmails ? '.kinto-page' : '.wcl-modal');

      try {
        await apiCommunity.deletePreapprovedEmails(communityId!, [email]);
        await fetchPreapprovedEmails();

        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t(
            'preapproved_settings-notification_successfully_removed_email'
          ),
          variant: 'success',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay(!seeAllEmails ? '.kinto-page' : '.wcl-modal');
      }
    },
    [
      communityId,
      fetchPreapprovedEmails,
      hideOverlay,
      seeAllEmails,
      showOverlay,
      showSnackBar,
      t,
    ]
  );

  const handleUpdateEmails = useCallback(
    async (emails: string[]) => {
      showOverlay('.kinto-page');

      try {
        const res = await apiCommunity.addPreapprovedEmails(
          communityId!,
          emails
        );

        if (res.rc === 'error') {
          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t('preapproved_invalid_email'),
            variant: 'error',
          });
        } else {
          await fetchPreapprovedEmails();

          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t(
              'preapproved_settings-notification_successfully_added_email'
            ),
            variant: 'success',
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.kinto-page');
      }
    },
    [
      communityId,
      fetchPreapprovedEmails,
      hideOverlay,
      showOverlay,
      showSnackBar,
      t,
    ]
  );

  const handleCloseEmailModal = useCallback(async () => {
    setSeeAllEmails(false);

    if (search) setQuery('search', search);
  }, [search, setQuery]);

  const handleChangeEmails = useCallback(
    (emails: string[]) => {
      if (firstPagePreapprovedEmails) {
        // REMOVE
        if (emails.length < firstPagePreapprovedEmails?.length) {
          const emailToRemove = firstPagePreapprovedEmails.find(
            (email) => !emails.includes(email)
          );

          if (emailToRemove) {
            handleRemoveEmail(emailToRemove);
          }
        }
        // ADD
        else if (
          emails.length > 0 &&
          emails.length !== firstPagePreapprovedEmails.length
        ) {
          const emailsToAdd = emails.filter(
            (el) => !firstPagePreapprovedEmails.includes(el)
          );

          let hasValidationError = false;

          emailsToAdd.forEach((email) => {
            tagValidations.preapproved_emails.forEach((validation) => {
              if (validation(email)) {
                hasValidationError = true;
                return;
              }
            });
          });

          if (emailsToAdd && !hasValidationError) {
            handleUpdateEmails(emailsToAdd);
          }
        }
      }
    },
    [
      firstPagePreapprovedEmails,
      handleRemoveEmail,
      handleUpdateEmails,
      tagValidations.preapproved_emails,
    ]
  );

  const handleChangeDomains = useCallback(
    (domains: string[]) => {
      if (domains.length !== preapprovedDomains?.length) {
        let hasValidationError = false;

        domains.forEach((domain) => {
          tagValidations.domain.forEach((validation) => {
            if (validation(domain)) {
              hasValidationError = true;
              return;
            }
          });
        });

        if (!hasValidationError) {
          handleUpdateDomains(domains);
        }
      }
    },
    [handleUpdateDomains, preapprovedDomains, tagValidations.domain]
  );

  useKeyboardListener(
    [
      {
        keyCodes: ['Escape'],
        callbackFn: () => {
          setSeeAllEmails(false);
        },
      },
    ],
    window
  );

  useEffect(() => {
    onSearchChange.current(search);
  }, [onSearchChange, search]);

  useEffect(() => {
    const handleFetchPreapprovedEmails = async () => {
      showOverlay(!seeAllEmails ? '.kinto-page' : '.wcl-modal');
      await fetchPreapprovedEmails();
      hideOverlay(!seeAllEmails ? '.kinto-page' : '.wcl-modal');
    };

    handleFetchPreapprovedEmails();
  }, [
    currentPage,
    debouncedSearch,
    fetchPreapprovedEmails,
    hideOverlay,
    seeAllEmails,
    showOverlay,
  ]);

  useEffect(() => {
    if (
      currentPage > 1 &&
      totalPreapprovedEmails ===
        (currentPage - 1) * PREAPPROVED_EMAILS_PAGE_SIZE
    ) {
      setCurrentPage((old) => old - 1);
    }
  }, [currentPage, totalPreapprovedEmails]);

  return (
    <Fragment>
      <Styled.PreapprovedSettingsContainer className="kinto-preapproved-seettings-container">
        <TagsField
          id="preapproved_settings_emails"
          name="preapproved_emails"
          placeholder={t('preapproved_add_email')}
          label={t('preapproved_emails')}
          value={initFormData.preapproved_emails}
          validateTag={tagValidations.preapproved_emails as any}
          onChange={(emails: string[]) => handleChangeEmails(emails)}
        />

        <Styled.ButtonContainer>
          <Button variant="outline" onClick={openForm}>
            {t('preapproved_settings-button_upload_csv')}
          </Button>
          {totalPreapprovedEmails > PREAPPROVED_EMAILS_PAGE_SIZE && (
            <Button
              variant="ghost"
              iconPosition="right"
              icon={<Icon name="chevron-right" />}
              onClick={() => setSeeAllEmails(true)}
            >
              {t('preapproved_settings-button_see_all_emails')}
            </Button>
          )}
        </Styled.ButtonContainer>

        <TagsField
          id="preapproved_settings_domains"
          name="domain"
          value={initFormData.domain}
          placeholder={t('preapproved_add_domain')}
          label={t('preapproved_domains')}
          validateTag={tagValidations.domain as any}
          onChange={(domains: string[]) => handleChangeDomains(domains)}
        />
      </Styled.PreapprovedSettingsContainer>

      {seeAllEmails && (
        <ModalsStyled.EmailListModal
          onClose={handleCloseEmailModal}
          className="email-list-modal"
          title={t('preapproved_emails')}
          ariaCloseModal={t('accessibility-button_close_modal', {
            name: t('preapproved_emails'),
          })}
        >
          <InputField
            className="email-list-modal__body__search"
            value={search}
            suffixIcon={<Icon name="magnifying-glass" />}
            placeholder={t('selgroup_search')}
            onChange={(value) => setQuery('search', value)}
          />
          {preapprovedEmails?.length === 0 ? (
            <span className="email-list-modal__body__no-results">
              {t('search_no_results_found')}
            </span>
          ) : (
            preapprovedEmails?.map((email, index) => (
              <Fragment key={`email_${index}`}>
                <div className="email-list-modal__body__row">
                  <div className="email-list-modal__body__row__email">
                    {email}
                  </div>
                  <Button
                    className="email-list-modal__body__delete-btn"
                    icon={<Icon name="xmark" />}
                    variant="ghost"
                    aria-label={t('accessibility-button_delete', {
                      name: email,
                    })}
                    onClick={() => handleRemoveEmail(email)}
                  />
                </div>
                {index < preapprovedEmails.length - 1 && <Divider />}
              </Fragment>
            ))
          )}

          <PageSelector
            className="email-list-modal__page-selector"
            selected={currentPage}
            numberOfPages={totalPages}
            onPageChange={setCurrentPage}
            ariaRightLabel={t('accessibility-button_next_page')}
            ariaLeftLabel={t('accessibility-button_previous_page')}
            goToPageInputProps={{ placeholder: t('global-go_to_page') }}
          />
        </ModalsStyled.EmailListModal>
      )}
    </Fragment>
  );
};

export default PreapprovedSettings;
