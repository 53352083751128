import { Button, elevate_xl, theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const PickShiftButton = styled(Button)`
  ${flex('row', 'space-between', 'center')};

  width: 100%;
  height: unset;
  min-height: unset;
  padding: ${theme.sizes.SIZE_20};

  &.button--outline::after {
    ${elevate_xl};
  }

  &.pick-shift-button--active::after {
    border: 2px solid var(--ACCENT_1_1);
  }
`;

export const IconTitle = styled.div`
  ${flex('row', 'center', 'center')};

  .icon-title {
    ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)}

    color: var(--SHADE_1_1);
    margin: 0 0 0 ${theme.sizes.SIZE_8};
  }
`;
