import styled, { css } from 'styled-components';
import { Modal, theme } from '@faxi/web-component-library';
import { phablet, pxToRem } from '@faxi/web-css-utilities';

export const ModalContainer = styled((props) => <Modal {...props} />)`
  .modal-earned-rewards-today {
    &__description {
      font-size: ${theme.fontSizes.FONT_14};
      color: var(--SHADE_1_2);
    }

    &__search {
      margin: ${theme.sizes.SIZE_32} 0;
      width: ${pxToRem(theme.sizes.SIZE_240)};

      ${phablet(css`
        width: 100%;
      `)}
    }

    &__table {
      width: ${pxToRem(theme.sizes.SIZE_632)};

      ${phablet(css`
        width: 100%;
      `)}
    }
  }
`;
