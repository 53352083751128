import { FC } from 'react';
import { RadioGroup, RadioGroupProps } from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';

export type RadioGroupFieldProps = FieldProps<string> & RadioGroupProps;

const RadioGroupField: FC<RadioGroupFieldProps> = (
  props: RadioGroupFieldProps
): JSX.Element => {
  const { error, dirty, touched, ...rest } = props;

  return <RadioGroup {...rest} />;
};

export default RadioGroupField;
