import { ReactNode } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useEffectOnceWhen } from '@faxi/web-component-library';

import * as Styled from './SkipLink.styles';

export const focusElement = (selector: string, scroll = true) => {
  const element = document.querySelector(selector);
  if (!element) return;

  (document.activeElement as HTMLElement).blur();
  (element as HTMLElement).focus();
  if (scroll) element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

type SkipLinkProps = {
  to: string; //hash
  className?: string;
  children?: ReactNode;
};

const SkipLink: React.FC<SkipLinkProps> = (props) => {
  const { className, to, children, ...rest } = props;

  const { pathname, search, hash } = useLocation();

  useEffectOnceWhen(() => {
    focusElement(to, false);
  }, !!hash);

  return (
    <Styled.Link
      to={`${pathname}${search}${to}`}
      className={classNames('skip-link', className)}
      onClick={(e) => {
        e.preventDefault();
        focusElement(to);
      }}
      {...rest}
    >
      {children}
    </Styled.Link>
  );
};

export default SkipLink;
