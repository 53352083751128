import { FC, memo, useCallback, useContext, useMemo, useRef } from 'react';
import {
  Button,
  ModalProps,
  ModalRef,
  useCallbackRef,
  useFormButtons,
  useUtilities,
} from '@faxi/web-component-library';
import {
  Form,
  FormField,
  FormRef,
  useFormRefValues,
  validators,
  FormProviderProps,
} from '@faxi/web-form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useCallbackAsync } from 'hooks';
import {
  GoogleAutocompleteField,
  FormActions,
  InputField,
  TextareaField,
  CalendarField,
} from 'components';

import { Event } from 'models';
import { getWinterUtcOffset } from 'utils';
import { UserContext } from 'store';
import config from 'config';
import { apiEvents, CreateEventData, UpdateEventData } from 'modules';
import Icon from 'components/Icon';
import specific from 'validation/validators/specific';

import * as Styles from './EventModal.styles';

type Props = {
  eventId?: number;
  onEventSubmitted: (event?: Event) => void;
  onClickDeleteEvent: (element: HTMLElement) => void;
} & ModalProps &
  FormProviderProps;

const EventModal: FC<Props> = (props: Props): JSX.Element => {
  const {
    eventId,
    loading: pLoading,
    initialData,
    onEventSubmitted,
    onClickDeleteEvent,
    ...rest
  } = props;

  const {
    community,
    user,
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  const { showSnackBar } = useUtilities();

  const { t } = useTranslation();

  const modalRef = useRef<ModalRef>(null);
  const deleteBtnRef = useRef<HTMLButtonElement>(null);

  const [form, formRef] = useCallbackRef<FormRef>();

  const isEditing = useMemo(() => initialData !== undefined, [initialData]);

  const [FormButtons] = useFormButtons({
    submitLabel: t(isEditing ? 'Save' : 'global_button_create'),
    cancelLabel: t('cancel'),
  });

  const start_date = useFormRefValues(form, 'start_date')?.start_date;
  const end_date = useFormRefValues(form, 'end_date')?.end_date;

  const start_time = useFormRefValues(form, 'start_time')?.start_time;

  const isCurrentStartDate = useMemo(
    () => dayjs(start_date).isSame(dayjs(), 'day'),
    [start_date]
  );

  const eventModalTitle = useMemo(
    () =>
      t(
        `${
          !isEditing ? 'events-button_create_event' : 'events-title_edit_event'
        }`
      ),
    [isEditing, t]
  );

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', { fieldname: t('name') })
        ),
        validators.general.maxLength(
          150,
          t('validation-field_validation_max_length', {
            fieldname: t('name').toLowerCase(),
            number: '150',
          })
        ),
      ],
      description: [
        validators.general.maxLength(
          500,
          t('validation-field_validation_max_length', {
            fieldname: t('dw_description').toLowerCase(),
            number: '500',
          })
        ),
      ],
      address: [
        specific.googleAutocompleteAddress(
          t('validation-field_is_required', { fieldname: t('address_header') })
        ),
      ],
      start_date: [
        validators.general.required(
          t('required_start_date', { fieldname: t('parkNRide_date') })
        ),
      ],
      end_date: [
        validators.general.required(
          t('required_end_date', { fieldname: t('parkNRide_date') })
        ),
      ],
      start_time: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('predefined_shifts-start_time'),
          })
        ),
        specific.startTimeValidation(
          t('global-validation_start_time_can_not_be_in_the_past'),
          start_date,
          dayjs().format(config.timeFormat)
        ),
      ],
      end_time: [
        specific.endTimeValidation(
          t('validation-alert_error_end_time_greater')
        ),
      ],
    }),
    [start_date, t]
  );

  const [handleSubmitEvent, loading] = useCallbackAsync({
    showSpinner: false,
    condition: () =>
      isCurrentStartDate
        ? start_time >= dayjs().format(config.timeFormat)
        : true,
    callback: async (data: any) => {
      if (!user || !community) {
        return;
      }

      const dateStartTime = dayjs(
        `${dayjs(data['start_date'], 'DD.MM.YYYY').format(
          config.apiDateFormat
        )}T${data['start_time']}:00Z`
      )
        .utcOffset(getWinterUtcOffset())
        .format('YYYY-MM-DD HH:mm');

      let dateEndTime = dayjs(data['end_date']).format(config.apiDateFormat);

      const commonEventData: UpdateEventData = {
        start_date: dateStartTime.split(' ')[0],
        start_time: dateStartTime.split(' ')[1],
        description: data['description'],
        type: 'event-appointment',
        data: {
          title: data['name'],
          organisation_id: +community.id,
        },
      };

      if (data['end_time']) {
        dateEndTime = dayjs(`${dateEndTime}T${data['end_time']}:00Z`)
          .utcOffset(getWinterUtcOffset())
          .format('YYYY-MM-DD HH:mm');
        commonEventData.end_date = dateEndTime.split(' ')[0];
        commonEventData.end_time = dateEndTime.split(' ')[1];
      } else {
        commonEventData.end_date = dateEndTime.split(' ')[0];
        commonEventData.end_time = '';
      }

      commonEventData.data = {
        ...commonEventData.data,
        ...data.address,
        address: data.address.formatted_address,
      };

      if (!isEditing) {
        const newEvent: CreateEventData = {
          creator_id: user.id,
          ...(commonEventData as Omit<CreateEventData, 'creator_id'>),
        };

        await apiEvents.create(newEvent);

        onEventSubmitted();

        showSnackBar({
          variant: 'success',
          text: t('events-toast_event_successfully_created', {
            title: newEvent.data.title,
          }),
          actionButtonText: t('dismiss'),
        });
      } else {
        const { appointment } = await apiEvents.update(
          eventId!,
          commonEventData
        );

        showSnackBar({
          variant: 'success',
          text: t('events-toast_event_successfully_updated', {
            title: appointment.title,
          }),
          actionButtonText: t('dismiss'),
        });

        onEventSubmitted(appointment);
      }

      modalRef.current?.close();
    },
  });

  const modalForm = useCallback(
    ({ children, className }: any) => (
      <Form
        ref={formRef}
        children={children}
        className={className}
        initialData={isEditing ? initialData : undefined}
        onSubmit={handleSubmitEvent}
      />
    ),
    [formRef, handleSubmitEvent, initialData, isEditing]
  );

  return (
    <Styles.EventModal
      position="center"
      loading={loading || pLoading}
      renderAsPortal
      className="event-modal-form"
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t(
          `${
            !isEditing
              ? 'events-button_create_event'
              : 'events-title_edit_event'
          }`
        ),
      })}
      titleId="event-modal-title"
      title={eventModalTitle}
      subtitle={t('events-subtitle_create_event')}
      childrenWrapper={modalForm}
      footer={
        <FormActions className="kinto-modal__actions">
          <Button
            type="submit"
            disabled={
              !(
                form?.isFormChanged() &&
                form?.asyncFormValid &&
                form?.syncFormValid
              )
            }
          >
            {t(isEditing ? 'Save' : 'global_button_create')}
          </Button>
          <FormButtons.Cancel onClick={() => modalRef.current?.close()} />

          {isEditing && (
            <Button
              ref={deleteBtnRef}
              variant="delete-ghost"
              icon={<Icon name="trash-can" />}
              onClick={(e) => onClickDeleteEvent(e.target as HTMLButtonElement)}
            >
              {t('Discovery_map_delete')}
            </Button>
          )}
        </FormActions>
      }
      ref={modalRef}
      {...rest}
    >
      <fieldset className="kinto-modal__fields">
        <legend data-hidden hidden>
          {eventModalTitle}
        </legend>
        <FormField
          name="name"
          component={InputField}
          autoComplete="on"
          placeholder={t('name')}
          required
          requiredLabel={t('global-input_field_required_label')}
          validate={validations.name}
        />
        <FormField
          name="description"
          component={TextareaField}
          placeholder={t('dw_description')}
          validate={validations.description}
          verticalResize
        />
        <FormField
          name="address"
          autoComplete="off"
          component={GoogleAutocompleteField}
          placeholder={t('address_header')}
          required
          requiredLabel={t('global-input_field_required_label')}
          validate={validations.address}
        />
      </fieldset>

      <fieldset className="kinto-event-modal__date-fieldset">
        <legend className="kinto-modal__fields__row-title">{t('start')}</legend>
        <div className="kinto-modal__fields__fields-row">
          <FormField
            required
            name="start_date"
            renderAsPortal
            component={CalendarField}
            placeholder={t('campaign-start_date')}
            leftLimitDate={dayjs()}
            rightLimitDate={end_date || dayjs().add(6, 'months')}
            dateFormat={dateFormat}
            requiredLabel={t('global-input_field_required_label')}
            validate={validations.start_date}
          />
          <FormField
            name="start_time"
            component={InputField}
            type="time"
            placeholder={t('predefined_shifts-start_time')}
            required
            requiredLabel={t('global-input_field_required_label')}
            validate={validations.start_time}
          />
        </div>
      </fieldset>

      <fieldset className="kinto-event-modal__date-fieldset">
        <legend className="kinto-modal__fields__row-title">{t('end')}</legend>
        <div className="kinto-modal__fields__fields-row">
          <FormField
            required
            name="end_date"
            renderAsPortal
            component={CalendarField}
            placeholder={t('campaign-end_date')}
            leftLimitDate={start_date || dayjs()}
            rightLimitDate={dayjs().add(6, 'months')}
            dateFormat={dateFormat}
            requiredLabel={t('global-input_field_required_label')}
            validate={validations.end_date}
          />
          <FormField
            name="end_time"
            component={InputField}
            type="time"
            placeholder={t('predefined_shifts-end_time')}
            validate={validations.end_time}
          />
        </div>
      </fieldset>
    </Styles.EventModal>
  );
};

export default memo(EventModal);
