import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  getColor,
  useCallbackRef,
  useGlowScroll,
  useKeyboardListener,
} from '@faxi/web-component-library';

import { Icon } from 'components';
import { CarPark } from 'models';

import * as Styled from './InTheCarPark.styles';

type CarParkProps = CarPark;

const CarParkOccupancy = (props: CarParkProps): JSX.Element => {
  const { carParkName, totalPlacesCount, takenPlacesCount } = props;

  const barProgress = useMemo(
    () =>
      totalPlacesCount > 0 ? (100 / totalPlacesCount) * takenPlacesCount : 0,
    [takenPlacesCount, totalPlacesCount]
  );

  return (
    <Styled.CarParkOccupancy
      activePercentage={barProgress}
      className="kinto-car-park-occupancy"
    >
      <div className="kinto-car-park-occupancy__name">{carParkName}</div>
      <div className="kinto-car-park-occupancy__bar-chart">
        <div className="kinto-car-park-occupancy__numbers">
          <Icon name="car" color={getColor('--PRIMARY_1_1')} />
          <div>{`${takenPlacesCount}/${totalPlacesCount}`}</div>
        </div>
        <div className="kinto-car-park-occupancy__bar">
          <div className="kinto-car-park-occupancy__bar-progress" />
        </div>
      </div>
    </Styled.CarParkOccupancy>
  );
};

const CarParkLegend = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Styled.CarParkLegend className="kinto-car-park-legend">
      <div className="kinto-car-park-legend__occupied">{t('occupied')}</div>
      <div className="kinto-car-park-legend__available">{t('available')}</div>
    </Styled.CarParkLegend>
  );
};

type OpacityIndicatorProps = {
  inModal: boolean;
  carParks: CarPark[];
};

const CarParks = (props: OpacityIndicatorProps): JSX.Element => {
  const { inModal, carParks } = props;

  const glowScrollContainerRef = useRef<HTMLDivElement>(null);
  const [carParksContainer, carParksContainerRef] =
    useCallbackRef<HTMLDivElement>();

  useGlowScroll(
    glowScrollContainerRef.current,
    carParksContainer,
    1,
    0,
    'gray',
    'vertical'
  );

  return (
    <Styled.CarParks className="kinto-car-parks" ref={glowScrollContainerRef}>
      <div className="kinto-car-parks__container" ref={carParksContainerRef}>
        {(!inModal ? carParks.slice(0, 10) : carParks).map(
          ({
            carParkName,
            totalPlacesCount,
            takenPlacesCount,
          }: CarParkProps) => (
            <CarParkOccupancy
              key={carParkName}
              carParkName={carParkName}
              totalPlacesCount={totalPlacesCount}
              takenPlacesCount={takenPlacesCount}
            />
          )
        )}
      </div>
    </Styled.CarParks>
  );
};

type InTheCarParkProps = {
  carParks?: CarPark[];
};

export const InTheCarPark = (props: InTheCarParkProps): JSX.Element => {
  const { carParks } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showWholeListModal, setShowWholeListModal] = useState(false);

  const { t } = useTranslation();

  useKeyboardListener(
    [
      {
        keyCodes: ['Escape'],
        callbackFn: () => setShowWholeListModal(false),
      },
    ],
    window
  );

  return (
    <Styled.InTheCarPark className="in-the-car-park" id="in-the-car-park">
      {showWholeListModal && (
        <Modal
          triggerRef={buttonRef.current as HTMLElement}
          position="center"
          title={t('in_the_car_park')}
          ariaCloseModal={t('accessibility-button_close_modal', {
            name: t('in_the_car_park'),
          })}
          onClose={() => setShowWholeListModal(false)}
        >
          {carParks && <CarParks inModal carParks={carParks} />}
          <CarParkLegend />
        </Modal>
      )}

      {!carParks?.length ? (
        <div className="in-the-car-park__placeholder">
          {t('parking_spot_allocation_placeholder')}
        </div>
      ) : (
        <CarParks inModal={false} carParks={carParks} />
      )}

      <div className="in-the-car-park__footer">
        <Button
          ref={buttonRef}
          type="button"
          variant="ghost"
          onClick={() => {
            setShowWholeListModal(true);
          }}
          icon={<Icon name="chevron-right" />}
          iconPosition="right"
        >
          {t('view_whole_list')}
        </Button>

        <CarParkLegend />
      </div>
    </Styled.InTheCarPark>
  );
};

export default InTheCarPark;
