import {
  useContext,
  useImperativeHandle,
  useMemo,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Chart } from '@faxi/web-component-library';

import { ReportsContext, UserContext } from 'store';
import { ChartData, ChartDataTotals, ChartDataWithTest } from 'models';
import { useChartDate } from '../hooks';

export type ActiveUsersChartRef = {
  activeUsers: ChartDataWithTest;
  activeUsersTotal: ChartDataTotals;
};

export type ActiveUsersChartProps = {
  className?: string;
};

const ActiveUsersChart: ForwardRefRenderFunction<
  ActiveUsersChartRef,
  ActiveUsersChartProps
> = (_, ref): JSX.Element => {
  const { includeTestUsers } = useContext(UserContext);
  const { kpiData, activeUsersTotal } = useContext(ReportsContext);

  const { t } = useTranslation();

  const activeUsersChartData = useMemo(() => {
    if (!kpiData || (includeTestUsers && !kpiData.activeUsers.testChartData)) {
      return [];
    }

    return [
      {
        name: t('active_users'),
        data: kpiData.activeUsers.chartData,
      },
      ...(includeTestUsers
        ? [
            {
              name: `${t('active_users')} ${t('reports_export_test_users')}`,
              data: kpiData.activeUsers.testChartData as ChartData,
            },
          ]
        : []),
    ];
  }, [kpiData, includeTestUsers, t]);

  useImperativeHandle(
    ref,
    () =>
      ({
        activeUsers: kpiData?.activeUsers,
        activeUsersTotal,
      } as ActiveUsersChartRef),
    [kpiData, activeUsersTotal]
  );

  const dateObject = useChartDate(activeUsersChartData);

  return (
    <Chart
      id="active-users-chart"
      series={activeUsersChartData}
      yAxisLabel={t('active_users')}
      ariaLabel={t('accessibility_chart_label', {
        name: t('active_users'),
        endDate: dateObject?.end,
        startDate: dateObject?.start,
      })}
    />
  );
};

export default forwardRef(ActiveUsersChart);
