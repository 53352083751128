import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { CarParkProvider, CarSpaceProvider } from 'store';
import { ApiCarpark } from 'models';
import { CarParkInfo } from './CarParkInfo/CarParkInfo.component';
import { Heading, getColor } from '@faxi/web-component-library';
import { PageLayout } from 'components/_layouts';
import { useTranslation } from 'react-i18next';

export type CarParkProps = ApiCarpark & {
  id: string;
  name: string;
};

export type CarSpaceProps = {
  id: string;
  name: string;
};

export const CarParkSettings: FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <CarParkProvider>
      <PageLayout className="kinto-page">
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__heading"
        >
          {t('car_park_settings')}
        </Heading>

        <Outlet />
      </PageLayout>
    </CarParkProvider>
  );
};

export const CarSpaceSettings: FC = (): JSX.Element => {
  return (
    <CarSpaceProvider>
      <CarParkInfo />
    </CarSpaceProvider>
  );
};
