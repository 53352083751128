import { Modal, theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const EventModal = styled(Modal)`
  .kinto-event-modal {
    &__date-fieldset {
      margin-top: ${theme.sizes.SIZE_24};
    }
  }
`;
