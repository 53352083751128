import { theme } from '@faxi/web-component-library';
import { flex, fontSize, pxToRem, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Container = styled.div`
  ${flex('column', 'center', 'center')}

  width: 100%;
  padding: ${theme.sizes.SIZE_10} 0;
  margin-top: ${theme.sizes.SIZE_96};

  .empty-campaigns {
    margin-top: ${theme.sizes.SIZE_96};

    &__icon {
      &,
      svg {
        ${size(theme.sizes.SIZE_64)};
      }

      margin: 0 0 ${theme.sizes.SIZE_16};
      color: var(--ACCENT_1_1);
    }
    &__title,
    &__content {
      margin: 0;
      text-align: center;
    }

    &__title {
      ${fontSize(theme.fontSizes.FONT_30, theme.sizes.SIZE_40)};
      color: var(--SECONDARY_1_1);
      margin: 0 0 ${theme.sizes.SIZE_16};
      font-weight: 600;
    }
    &__content {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};
      max-width: ${pxToRem(theme.sizes.SIZE_374)};
      color: var(--SHADE_1_2);
      margin: 0 0 ${theme.sizes.SIZE_16};
      font-weight: 400;
    }
  }
`;
