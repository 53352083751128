import { FC, memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Shift } from 'models';
import { formattedTime } from 'utils';
import Icon, { INameExtended } from 'components/Icon';

import * as Styled from './PickShift.styles';
import PickShiftButton from '../../components/PickShiftButton';
import { ShiftSettingsContext } from '../../providers/ShiftSettings/ShiftSettings.context';
import { useView } from 'providers/Views';
import { ShiftSettingsViews } from '../..';

export const getShiftIconName = (shift: Shift): INameExtended => {
  const startTime = parseInt(shift?.start_time || '');

  return startTime >= 6 && startTime < 15
    ? 'sun'
    : startTime >= 15 && startTime < 20
    ? 'sunset'
    : 'moon';
};

export const IconTitle = (props: {
  title: string;
  iconName: INameExtended;
}) => {
  const { title, iconName } = props;

  return (
    <div className="title-icon">
      <Icon name={iconName} />
      <p>{title}</p>
    </div>
  );
};

type PickShiftProps = {
  className?: string;
  onPickShift: (shift: Shift) => void;
};

const PickShift: FC<PickShiftProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const { pushView } = useView<ShiftSettingsViews>();

  const { shifts, assignedShifts, setSelectedShift } =
    useContext(ShiftSettingsContext);

  const handlePickShift = useCallback(
    (shift: Shift) => {
      setSelectedShift(shift);
      pushView('select-schedule');
    },
    [pushView, setSelectedShift]
  );

  const finalShifts = useMemo(
    () =>
      shifts?.filter(
        (shift) =>
          !assignedShifts.find((assigned) => assigned.shift_id === shift.id)
      ),
    [assignedShifts, shifts]
  );

  if (!finalShifts?.length)
    return (
      <Styled.PickShiftNoData>
        {t('shifts_settings-empty_state_no_available_shifts')}
      </Styled.PickShiftNoData>
    );

  return (
    <Styled.PickShift className={className}>
      <p className="pick-shift__title">
        {t('shift_settings-title_pick_a_shift')}
      </p>
      <div className="pick-shift__shifts">
        {finalShifts.map((shift) => (
          <PickShiftButton
            key={shift.id}
            onClick={() => handlePickShift(shift)}
            shiftTitles={[
              { icon: getShiftIconName(shift), title: shift.name },
              {
                icon: 'clock-nine',
                title: formattedTime(shift.start_time, shift.end_time),
              },
            ]}
          />
        ))}
      </div>
    </Styled.PickShift>
  );
};

export default memo(PickShift);
