import { FC, memo, useEffect } from 'react';
import QRCode from 'qrcode';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Image } from '@faxi/web-component-library';

import * as Styled from './InvitePeopleCode.styles';

type InvitePeopleCodeProps = {
  className?: string;
  link: string;
  qrCodeUrl: string;
  onGenerateCode: (value: string) => void;
};

const InvitePeopleCode: FC<InvitePeopleCodeProps> = (props) => {
  const { className, link, qrCodeUrl, onGenerateCode } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (!link) return;

    const generateQrCode = async () => {
      const qrCode = await QRCode.toDataURL(link, {
        margin: 0,
        //set quality of generated image
        width: 350,
        type: 'image/jpeg',
      });

      onGenerateCode(qrCode);
    };

    generateQrCode();
  }, [link, onGenerateCode]);

  return (
    <Styled.InvitePeopleCode
      className={classNames('invite-people-code', className)}
    >
      <div className="invite-people-code__content">
        <p className="invite-people-code__content__title">
          {t('invite-people_title_qr_code')}
        </p>
        <p className="invite-people-code__content__description">
          {t('invite-people_body_qr_code')}
        </p>
      </div>

      <Image
        className="invite-people-code__qr-code"
        src={qrCodeUrl || ''}
        alt="qr code"
        fallbackUrl="/assets/svg/kinto_join_logo_stacked.svg"
      />
    </Styled.InvitePeopleCode>
  );
};

export default memo(InvitePeopleCode);
