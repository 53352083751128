import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function useHeadTitle(title: string) {
  const { t } = useTranslation();

  useEffect(() => {
    const newTitle = `${t('app_name')} - ${title}`;
    const prevTitle = document.title;

    //when previous route is sub route of new route then do not update
    //document title with new route
    if (prevTitle.split('-').length > newTitle.split('-').length) {
      return;
    }

    document.title = newTitle;

    return () => {
      document.title = prevTitle;
    };
  }, [t, title]);
}
