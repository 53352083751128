import { renderToString } from 'react-dom/server';

import UserPin from '../pins/UserPin.icon';
import imgToBase64 from './imgToBase64';

const createUserMarker = async (
  location: google.maps.LatLngLiteral,
  map: google.maps.Map,
  title: string,
  url?: string
) => {
  if (url) {
    const base64img = await imgToBase64(url);

    return new google.maps.Marker({
      position: location,
      map,
      title,
      icon: `data:image/svg+xml;utf-8, ${renderToString(
        <UserPin src={base64img} />
      )}`,
    });
  }

  return new google.maps.Marker({
    position: location,
    map,
    title,
    icon: '/assets/svg/user_pin.svg',
  });
};

export default createUserMarker;
