import {
  Children,
  Fragment,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
} from 'react';

/**
 * Use this when you want to map through array of different types of objects
 * and Children.toArray will automatically add key index on each rendered element.
 */

type EachReturnedType =
  | string
  | number
  | ReactElement<any, string | JSXElementConstructor<any>>
  | Iterable<ReactNode>
  | ReactPortal
  | JSX.Element;

type EachTypeProps<T> = {
  of: T[];
  className?: string;
  containerAs?: keyof JSX.IntrinsicElements;
  render: (item: T, index: number) => EachReturnedType;
};

function Each<T = any>({
  render,
  of,
  containerAs,
  className,
  ...rest
}: EachTypeProps<T>) {
  const HtmlEl = containerAs || Fragment;
  const htmlProps = containerAs ? { className, ...rest } : {};

  return (
    <HtmlEl {...htmlProps}>
      {Children.toArray(of.map((item, index) => render(item, index)))}
    </HtmlEl>
  );
}

export default Each as <T = any>({ render, of }: EachTypeProps<T>) => any;
