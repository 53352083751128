import { FC, memo, useCallback, useContext, useRef } from 'react';
import { Form, FormField, FormRef } from '@faxi/web-form';
import classNames from 'classnames';
import {
  useCallbackRef,
  ModalProps,
  useFormButtons,
  Modal,
  Icon,
  ModalRef,
  useUtilities,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { useCallbackAsync, useSendMessage } from 'hooks';
import { MessagesContext, UserContext } from 'store';

import { Home } from 'models';
import FormActions from '../../_layouts/FormActions';
import TextareaField from '../../_molecules/TextareaField';

export type InvitePeopleModalProps = {
  organisationId: number;
  receiver?: Home;
  textareaPlaceholder: string;
  onMessageSent?: (message?: string, messageId?: number) => void;
} & ModalProps;

const SendMessageModal: FC<InvitePeopleModalProps> = (
  props: InvitePeopleModalProps
): JSX.Element => {
  const {
    organisationId,
    title,
    textareaPlaceholder,
    triggerRef,
    receiver,
    onMessageSent,
    ...rest
  } = props;

  const { t } = useTranslation();

  const { showSnackBar } = useUtilities();

  const [FormButtons] = useFormButtons({
    submitLabel: t(receiver ? 'ga_group_msg_send' : 'send_to_all'),
  });

  const { sendMessage } = useContext(MessagesContext);
  const { user } = useContext(UserContext);

  const modalRef = useRef<ModalRef>(null);
  const [form, formRef] = useCallbackRef<FormRef>();

  const [handleFormSubmit, loading] = useCallbackAsync({
    showSpinner: false,
    callback: async (data: { message: string }) => {
      if (!user) return;

      const res = await sendMessage(
        user.id,
        receiver ? `${receiver.id}` : 'ORGANISATION_USERS_AND_ADMINS',
        data.message.trim(),
        organisationId,
        receiver ? 'U' : 'A'
      );

      if (res.rc === 'error') {
        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t('unable_to_send_message'),
          variant: 'error',
        });
      } else {
        showSnackBar({
          actionButtonText: t('dismiss'),
          text: receiver
            ? t('message_sent_to_person', {
                name:
                  [receiver.first_name, receiver.last_name].join(' ').trim() ||
                  receiver.email,
              })
            : t('message_sent_all_community_members'),
          variant: 'success',
        });

        modalRef.current?.close();

        onMessageSent?.(data.message, res.id);
        triggerRef?.focus();
      }
    },
  });

  const { validations } = useSendMessage({
    onEnter: () => {
      if (form?.fields['message']?.value) {
        handleFormSubmit({ message: form?.fields['message']?.value.trim() });
      }
    },
    onClose: onMessageSent,
  });

  const modalForm = useCallback(
    ({ children, className }: any) => (
      <Form
        onSubmit={handleFormSubmit}
        ref={formRef}
        children={children}
        className={className}
      />
    ),
    [formRef, handleFormSubmit]
  );

  return (
    <Modal
      {...rest}
      ref={modalRef}
      title={title}
      loading={loading}
      triggerRef={triggerRef}
      className="send-message-modal"
      focusableElements="textarea, button:not([disabled])"
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: title,
      })}
      childrenWrapper={modalForm}
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit
            disabled={
              !form?.syncFormValid ||
              form?.fields['message'].value.trim() === ''
            }
            icon={<Icon name="paper-plane-top" />}
            iconPosition="right"
          />
        </FormActions>
      }
    >
      <div className={classNames('kinto-modal__fields', 'send-message-modal')}>
        <FormField
          component={TextareaField}
          name="message"
          noresize
          id="whole_community_message"
          prefixIcon={<Icon name="message-lines" />}
          placeholder={textareaPlaceholder}
          validate={validations.message}
        />
      </div>
    </Modal>
  );
};

export default memo(SendMessageModal);
