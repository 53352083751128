import { elevate_s, Modal, theme } from '@faxi/web-component-library';
import {
  abovePhablet,
  flex,
  flexGap,
  fontSize,
  phablet,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const InvitePeopleModal = styled(Modal)`
  .wcl-modal {
    &__header {
      margin: 0 0 ${theme.sizes.SIZE_16};
    }

    &__footer {
      ${flex('row')};

      gap: ${theme.sizes.SIZE_10};

      padding-top: ${theme.sizes.SIZE_16};

      .button {
        &:first-of-type {
          flex: 1;
        }
      }

      ${phablet(css`
        flex-direction: column;

        .button {
          width: 100%;
        }
      `)}
    }

    ${abovePhablet(css`
      max-width: ${pxToRem(theme.sizes.SIZE_520)};
    `)}
  }

  .download-btn {
    ${flex('row', 'center', 'center')};

    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    height: ${pxToRem(theme.sizes.SIZE_48)};
    min-height: ${pxToRem(theme.sizes.SIZE_48)};
    color: var(--PRIMARY_1_1);
    border-radius: ${theme.sizes.SIZE_8};
    border: 1px solid var(--PRIMARY_1_1);
    padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_24};

    &:hover {
      background-color: var(--ACCENT_1_6);
    }
  }

  .invite-people-modal {
    &__content {
      ${flex('row')};

      width: 100%;
      margin-top: ${theme.sizes.SIZE_24};
    }

    &__box {
      gap: ${theme.sizes.SIZE_8};
      margin-top: ${theme.sizes.SIZE_8};

      .wcl-checkbox__label {
        ${fontSize(theme.fontSizes.FONT_12, theme.sizes.SIZE_20)};

        font-weight: 400;
        color: var(--SHADE_1_1);
      }
    }

    &__title {
      ${flex('column')};
      ${flexGap(theme.sizes.SIZE_8, 'column')};
    }

    &__share-title {
      ${fontSize('14px', theme.sizes.SIZE_20)};

      font-weight: 400;
      color: var(--SHADE_1_1);
      margin: 0 0 ${theme.sizes.SIZE_16};
    }

    &__qr-code {
      ${size(theme.sizes.SIZE_176)};
      ${elevate_s};

      margin-left: auto;
      padding: ${theme.sizes.SIZE_8};
      border-radius: ${theme.sizes.SIZE_8};
      border: 1px solid var(--SHADE_1_7);
    }
  }
`;
