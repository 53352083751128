import { theme } from '@faxi/web-component-library';
import { flex, phablet, pxToRem, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${size('100%')};

  display: grid;
  overflow: auto;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;

  ${phablet(css`
    grid-template-rows: auto 1fr auto;
  `)}

  .template {
    &__header {
      ${flex('row', 'space-between', 'center')};

      padding: 0 ${theme.sizes.SIZE_32};
      height: ${pxToRem(theme.sizes.SIZE_80)};

      img {
        height: ${pxToRem(theme.sizes.SIZE_40)};
      }

      ${phablet(css`
        .wcl-select {
          max-width: ${theme.sizes.SIZE_192};
        }
      `)}
    }

    &__main {
      ${size('100%', 'auto')};

      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;

      &__image {
        max-height: 520px;
        margin-top: 104px;

        object-fit: contain;

        ${phablet(css`
          display: none;
        `)};
      }

      ${phablet(css`
        grid-template-columns: 1fr;
      `)}
    }

    &__footer {
      background-color: var(--PRIMARY_1_1);
      padding: ${`${theme.sizes.SIZE_72}
        ${theme.sizes.SIZE_144}`};

      a {
        color: var(--BACKGROUND_1_1);
      }

      ${phablet(css`
        padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_16};
      `)};
    }
  }
`;
