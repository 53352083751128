import { useMemo } from 'react';
import {
  Heading,
  getColor,
  useElementClass,
} from '@faxi/web-component-library';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { PageLayout } from 'components/_layouts';

const GAMIFICATION_PAGES_WITH_BLUE_BACKGROUND = ['campaigns', 'points-system'];

const GamificationRouter = () => {
  const location = useLocation();

  const addBlueBackground = useMemo(
    () =>
      GAMIFICATION_PAGES_WITH_BLUE_BACKGROUND.some((page) =>
        location.pathname.includes(page)
      ),
    [location]
  );

  useElementClass('.kinto-page', 'gamification--blue', addBlueBackground);

  return (
    <PageLayout className={classNames('kinto-page', 'gamification')}>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('global-gamification')}
      </Heading>

      <Outlet />
    </PageLayout>
  );
};

export default GamificationRouter;
