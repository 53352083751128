import { FC } from 'react';
import { ModalProps } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import * as Styled from './CalculatedModal.styles';

type CalculatedModalProps = ModalProps;

const CalculatedModal: FC<CalculatedModalProps> = (props) => {
  const { ...rest } = props;
  const { t } = useTranslation();

  return (
    <Styled.CalculatedModal
      title={t('global-CO2_emissions')}
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t(`global-CO2_emissions`),
      })}
      {...rest}
    >
      <p className="calculated-body-emission">
        {t('survey_sustainability-pdf-body_emissions')}
      </p>

      <p className="calculated-estimation">
        {t('report-emissions_estimation')}
      </p>
    </Styled.CalculatedModal>
  );
};

export default CalculatedModal;
