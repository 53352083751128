import styled, { css } from 'styled-components';
import {
  flex,
  fontSize,
  marginChildren,
  mobile,
  mobileLandscape,
} from '@faxi/web-css-utilities';

import { focusStyles, theme } from '@faxi/web-component-library';

export const Container = styled.div`
  ${flex('column', 'center', 'center')};
  background-color: var(--PRIMARY_1_1);
  color: var(--BACKGROUND_1_1);
  padding: ${theme.sizes.SIZE_24};
  border-radius: 8px;

  ${mobile(
    css`
      background-color: var(--BACKGROUND_1_1);
      color: var(--PRIMARY_1_1);
      padding: 0;
    `
  )};

  ${mobileLandscape(
    css`
      background-color: var(--BACKGROUND_1_1);
      color: var(--PRIMARY_1_1);
      padding: 0;
    `
  )};

  .card {
    &__title {
      ${flex('row', 'center', 'center')};
      text-align: center;
      ${fontSize(theme.fontSizes.FONT_30)};
      font-weight: 500;
      margin-bottom: ${theme.sizes.SIZE_32};

      ${mobile(css`
        color: var(--PRIMARY_1_1);
        ${fontSize(theme.fontSizes.FONT_28)};
        margin: auto auto ${theme.sizes.SIZE_32};
      `)};

      ${mobileLandscape(css`
        color: var(--PRIMARY_1_1);
        ${fontSize(theme.fontSizes.FONT_28)};
        margin: auto auto ${theme.sizes.SIZE_32};
      `)};
    }

    &__content {
      ${flex('row', 'center', 'center')};
      color: var(--BACKGROUND_1_1);
      text-align: center;
      margin-bottom: auto;
      ${fontSize(theme.fontSizes.FONT_18)};

      ${mobile(css`
        font-weight: 500;
        color: var(--SHADE_1_2);
        ${fontSize(theme.fontSizes.FONT_16)};
      `)};

      ${mobileLandscape(
        css`
          font-weight: 500;
          color: var(--SHADE_1_2);
          ${fontSize(theme.fontSizes.FONT_16)};
        `
      )};
    }

    &__button {
      margin-top: ${theme.sizes.SIZE_32};
      width: 100%;
    }

    &__custom-action {
      margin-top: ${theme.sizes.SIZE_32};
      width: 100%;

      .fast-code {
        &__edit {
          padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_24};
          ${flex('row', 'center', 'center')};
          background-color: var(--BACKGROUND_1_1);
          color: var(--PRIMARY_1_1);
          outline: 1px solid var(--PRIMARY_1_1);
          font-weight: 500;
          word-break: normal;
          overflow-wrap: anywhere;
          border-radius: ${theme.sizes.SIZE_8};
          ${marginChildren(`0 ${theme.sizes.SIZE_8} 0 0`)};
          width: 100%;

          .input {
            flex: 0 0 70%;
          }

          &__form {
            ${flex('row', 'center', 'center')};
            ${marginChildren(`0 ${theme.sizes.SIZE_8} 0 0`)};
          }

          button {
            padding: ${theme.sizes.SIZE_8};
          }
        }
      }
    }

    &__send-link {
      ${focusStyles};

      margin-top: ${theme.sizes.SIZE_16};
      text-align: center;
      border-radius: ${theme.sizes.SIZE_4};
      ${fontSize(theme.fontSizes.FONT_14)};
      color: var(--BACKGROUND_1_1);

      ${mobile(css`
        color: var(--PRIMARY_1_1);
      `)};

      ${mobileLandscape(css`
        color: var(--PRIMARY_1_1);
      `)};
    }
  }
`;
