import { useEffect } from 'react';
import env from 'env';

const SuperadminRedirection = () => {
  useEffect(() => {
    //bacause we dont have .env.staging setup we must detect it manually
    const redirectUrl = window.location.href.includes('staging')
      ? 'https://superadmin.kinto-join-io.staging.kjforge.xyz/'
      : env.VITE_SUPERADMIN_URL;

    window.location.href = redirectUrl;
  }, []);

  return <></>;
};

export default SuperadminRedirection;
