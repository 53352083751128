import { createContext } from 'react';
import { Message, MessageUser } from 'models';

interface MessagesContext {
  groupMessage?: Message;
  receiver?: MessageUser;

  sendMessage: (
    from: string,
    to: string,
    text: string,
    oid: number,
    type: 'A' | 'U'
  ) => Promise<any>;
  setGroupMessage: (message: Message) => void;
  setReceiver: (user: MessageUser) => void;
  markMessageAsRead: (from: string, to: string) => Promise<void>;
}

export const messagesDefaultValue: MessagesContext = {
  sendMessage: () => new Promise(() => {}),
  setGroupMessage: () => new Promise(() => {}),
  markMessageAsRead: () => new Promise(() => {}),
  setReceiver: () => {},
};

const MessagesContext = createContext<MessagesContext>(messagesDefaultValue);

export default MessagesContext;
