import { useQueryParams } from '@faxi/web-component-library';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { JOURNEY_DIRECTIONS } from 'utils';
import UserContext from '../UserProvider/User.context';

const useXlsxContants = () => {
  const { t } = useTranslation();

  const {
    userPreferences: { unit },
  } = useContext(UserContext);

  const { params } = useQueryParams<{
    directions: string;
  }>();

  const activeDirectionFilters = useMemo(
    () =>
      params.directions
        ?.split(',')
        .filter(Boolean)
        .map((d) =>
          t(
            JOURNEY_DIRECTIONS[d as keyof typeof JOURNEY_DIRECTIONS]
          ).toLowerCase()
        )
        .join(', ') || t('joinGroup_search_filter_all').toLowerCase(),
    [params, t]
  );

  const xlsxConstants = useMemo(() => {
    return [
      [
        {
          dataKey: 'activeUsers',
          title: [t('active_users')],
          columns: [t('parkNRide_date'), t('active_users')],
          testTitle: [`${t('active_users')} ${t('reports_export_test_users')}`],
          testColumns: [
            t('parkNRide_date'),
            `${t('active_users')} ${t('reports_export_test_users')}`,
          ],
        },
        {
          dataKey: 'registeredUsers',
          title: [t('registrations')],
          columns: [t('parkNRide_date'), t('registrations')],
          testTitle: [
            `${t('registrations')} ${t('reports_export_test_users')}`,
          ],
          testColumns: [
            t('parkNRide_date'),
            `${t('registrations')} ${t('reports_export_test_users')}`,
          ],
        },
        {
          dataKey: 'messagesCount',
          title: [t('number_of_messages')],
          columns: [t('parkNRide_date'), t('mMessages')],
          testTitle: [
            `${t('number_of_messages')} ${t('reports_export_test_users')}`,
          ],
          testColumns: [t('parkNRide_date'), t('mMessages')],
        },
      ],
      [
        {
          dataKey: 'walkingDistances',
          title: [t('cp_map_walking')],
          columns: [t('parkNRide_date'), t('distance_walked') + ` (${unit})`],
          testTitle: [
            `${t('cp_map_walking')} ${t(
              'reports_export_test_users'
            )} (${unit})`,
          ],
          testColumns: [
            t('parkNRide_date'),
            t('distance_walked') + ` (${unit})`,
          ],
        },
        {
          dataKey: 'cyclingDistances',
          title: [t('cp_map_cycling')],
          columns: [t('parkNRide_date'), t('distance_cycled') + ` (${unit})`],
          testTitle: [
            `${t('cp_map_cycling')} ${t(
              'reports_export_test_users'
            )} (${unit})`,
          ],
          testColumns: [
            t('parkNRide_date'),
            t('distance_cycled') + ` (${unit})`,
          ],
        },
        {
          dataKey: 'verifications',
          title: [t('reports-chart_title_verifications')],
          columns: [
            `${t('total_for_chosen_directions')} (${activeDirectionFilters})`,
          ],
          testTitle: [
            `${t('reports-chart_title_verifications')} ${t(
              'reports_export_test_users'
            )}`,
          ],
          testColumns: [
            `${t('total_for_chosen_directions')} (${activeDirectionFilters})`,
          ],
        },
        {
          dataKey: 'verifiedJourneys',
          title: [t('verified_journeys')],
          columns: [
            `${t('total_for_chosen_directions')} (${activeDirectionFilters})`,
          ],
          testTitle: [
            `${t('verified_journeys')} ${t('reports_export_test_users')}`,
          ],
          testColumns: [
            `${t('total_for_chosen_directions')} (${activeDirectionFilters})`,
          ],
        },
        {
          dataKey: 'passengerDistances',
          title: [t('passenger_distances')],
          columns: [
            `${t('total_for_chosen_directions')} (${activeDirectionFilters})`,
          ],
          testTitle: [t('reports_export_passenger_distance_test')],
          testColumns: [
            `${t('total_for_chosen_directions')} (${activeDirectionFilters})`,
          ],
        },
        {
          dataKey: 'co2',
          title: ['CO₂'],
          columns: [t('parkNRide_date'), t('daily_co2_saving_kg')],
          testTitle: [`${t('co2')} ${t('reports_export_test_users')}`],
          testColumns: [t('parkNRide_date'), t('daily_co2_saving_kg')],
        },
        {
          dataKey: 'nox',
          title: [t('nox')],
          columns: [t('parkNRide_date'), t('daily_nox_saving_g')],
          testTitle: [`${t('nox')} ${t('reports_export_test_users')}`],
          testColumns: [t('parkNRide_date'), t('daily_nox_saving_g')],
        },
      ],
    ];
  }, [activeDirectionFilters, t, unit]);

  return xlsxConstants;
};

export default useXlsxContants;
