import { FC } from 'react';
import { t } from 'i18next';
import { Button } from '@faxi/web-component-library';

import { Shift, ScheduleTime } from 'models';
import { formattedTime } from 'utils';

import Icon from 'components/Icon';
import { SCHEDULE_TIME_TRANSLATIONS } from '../../views/SelectSchedule/SelectSchedule.component';

import * as Styled from './AssignedShift.styles';

export const SELECTED_DAYS_MAP = {
  monday_to_friday: ['Mo', 'Tu', 'We', 'Th', 'Fr'],
  monday_to_sunday: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
} as Record<ScheduleTime, string[]>;

type AssignedShiftProps = {
  className?: string;
  shift: Shift;
  onEdit: (shift: Shift) => void;
};

const AssignedShift: FC<AssignedShiftProps> = (props) => {
  const { className, shift, onEdit } = props;

  return (
    <Styled.AssignedShift className={className}>
      <div className="assigned-shift__content">
        <p className="assigned-shift__title">
          <span>{shift.name}</span>
          <span>{formattedTime(shift.start_time, shift.end_time)}</span>
        </p>

        {shift.pattern !== 'custom' ? (
          <>
            <p className="assigned-shift__data">
              {t('shift_settings-selected_date_range')}:
              <span>{t(SCHEDULE_TIME_TRANSLATIONS[shift.pattern!])}</span>
            </p>

            <p className="assigned-shift__data">
              {t('shift_settings-selected_days')}:
              <span>
                {SELECTED_DAYS_MAP[shift.pattern!].map((day) => `${t(day)} `)}
              </span>
            </p>
          </>
        ) : (
          <p className="assigned-shift__custom-data">
            {t('shifts-pattern_custom_multiple_days_selected')}
          </p>
        )}
      </div>

      <Button
        className="assigned-shift__edit"
        variant="ghost"
        icon={<Icon name="pen" />}
        onClick={() => onEdit(shift)}
      />
    </Styled.AssignedShift>
  );
};

export default AssignedShift;
