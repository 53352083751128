import httpClient from '../httpClient';
import { AxiosRequestConfig, Method } from 'axios';
import { NotificationPreferences, User } from 'models';

// POST

const updateUser = async (userId: string, data: object, method?: Method) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]: [string, any]) => {
    formData.append(key, typeof value === 'number' ? value.toString() : value);
  });

  return httpClient
    .post(`user/${userId}`, formData, method && { params: { _method: method } })
    .then((res) => res.data);
};

const changePassword = async (resetCode: string, newPassword: string) => {
  const formData = new FormData();
  formData.append('token', resetCode);
  formData.append('password', newPassword);

  return httpClient.post(`user/new-password`, formData).then((res) => res.data);
};

const changeEmail = (userId: string, data: object) =>
  httpClient.post(`user/${userId}/email`, data, { params: { _method: 'PUT' } });

// GET

const getUser = async (userId?: string, key?: string) =>
  httpClient
    .get<User & { value: string }>(userId ? `user/${userId}` : 'user', {
      params: { key, ca: 1 },
    })
    .then((res) => res.data);

const verifyUser = (code: string) =>
  httpClient
    .get(`user`, { params: { activate: code } })
    .then((res) => res.data);

const getUserNotificationPreferences = (userId: string) =>
  httpClient
    .get<{
      notification_preferences: NotificationPreferences;
    }>(`user/${userId}/notification-preferences`)
    .then((res) => res.data);

const setUserNotificationPreferences = (
  userId: string,
  data: FormData,
  config?: Partial<AxiosRequestConfig>
) => {
  return httpClient
    .post(`user/${userId}/notification-preferences`, data, {
      params: { _method: 'PUT' },
      ...config,
    })
    .then((res) => res.data);
};

export default {
  getUser,
  updateUser,
  verifyUser,
  changePassword,
  changeEmail,
  setUserNotificationPreferences,
  getUserNotificationPreferences,
};
