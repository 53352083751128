import { FC, useCallback, useContext } from 'react';
import { Button, Image, Spinner, getColor } from '@faxi/web-component-library';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { CachedCommunityMessages } from '../MessageSender/MessageSender.component';
import { MessagesContext } from 'store';
import { Icon } from 'components';
import storageService, { STORAGE_KEYS } from 'services/storageService';

import * as Styled from './UserMessage.styles';
import { Message } from 'models';

export type UserMessageProps = {
  message: Message;
  userAvatarUrl?: string;
  className?: string;
  variant?: 'left' | 'right';
  organisationId: number;
  loading?: boolean;
  hasParent: boolean;
  resendMessageCallback: (message: Message) => void;
};

const UserMessage: FC<UserMessageProps> = (
  props: UserMessageProps
): JSX.Element => {
  const {
    message,
    userAvatarUrl = '/assets/svg/user_circle_placeholder.svg',
    className,
    variant = 'right',
    resendMessageCallback,
    organisationId,
    hasParent,
    loading: pLoading,
  } = props;

  const { t } = useTranslation();

  const { sendMessage } = useContext(MessagesContext);

  const resendMessage = useCallback(async () => {
    if (message) {
      try {
        const res = await sendMessage(
          message.from.id,
          message.to[0].id,
          message.text,
          organisationId,
          'U'
        );
        if (res.rc !== 'error') {
          resendMessageCallback(message);

          const failedMessages = storageService.getItem(
            STORAGE_KEYS.FAILED_MESSAGES
          ) as CachedCommunityMessages[];

          const failedMessagesCommunityIndex = failedMessages.findIndex(
            (messages) => messages.communityId === organisationId
          );

          const failedMessagesUserIndex = failedMessages[
            failedMessagesCommunityIndex
          ].users.findIndex((elem) => elem.userId === message.to[0].id);

          failedMessages[failedMessagesCommunityIndex].users[
            failedMessagesUserIndex
          ].messages = failedMessages[failedMessagesCommunityIndex].users[
            failedMessagesUserIndex
          ].messages.filter((item) => item.id !== message.id);

          storageService.setItem(STORAGE_KEYS.FAILED_MESSAGES, failedMessages);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [message, organisationId, resendMessageCallback, sendMessage]);

  const senderName = message.from.name;

  const msgTime = dayjs(`${message.ts.split(' ').join('T')}Z`).format('HH:mm');

  return (
    <Styled.UserMessageStyled
      className={classNames(
        'kinto-user-message',
        {
          'kinto-user-message--left': variant === 'left',
          'kinto-user-message--child': hasParent,
          'kinto-user-message--parent': !hasParent,
        },
        className
      )}
    >
      {!hasParent && (
        <div className="kinto-user-message__sender">
          <p className="kinto-user-message__sender__name">
            <span>{senderName}</span>
            <span className="kinto-user-message__sender__time">{msgTime}</span>
          </p>
          {pLoading ? (
            <div
              className={classNames(
                'kinto-user-message__avatar',
                'profile-img'
              )}
            >
              <Spinner size={18} color={getColor('--PRIMARY_1_1')} />
            </div>
          ) : (
            <Image
              className={classNames(
                'kinto-user-message__avatar',
                'profile-img'
              )}
              src={userAvatarUrl || ''}
              fallbackUrl="/assets/svg/user_circle_placeholder.svg"
              alt={t('user_profile_picture', {
                user: senderName,
              })}
            />
          )}
        </div>
      )}

      <div className="kinto-user-message__content-wrapper">
        <div className="kinto-user-message__content">
          <div
            className={classNames('kinto-user-message__content__message', {
              'kinto-user-message__content__message--failed': message.failed,
            })}
          >
            <div>{message.text.trim()}</div>
          </div>

          {message.failed && (
            <div className="kinto-user-message__content__failed">
              <div>{t('unable_to_send_message')}</div>
              <Button
                className="kinto-user-message__content__failed__button"
                onClick={resendMessage}
                variant="ghost"
                icon={<Icon name="rotate-right" />}
                iconPosition="right"
              >
                {t('resend')}
              </Button>
            </div>
          )}
        </div>
        <div className="kinto-user-message__time">{msgTime}</div>
      </div>
    </Styled.UserMessageStyled>
  );
};

export default UserMessage;
