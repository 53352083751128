import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const AccountDeletionContainer = styled.div`
  padding: ${theme.sizes.SIZE_56} ${theme.fontSizes.FONT_30};

  .kinto-account-deletion {
    &__heading {
      font-size: ${theme.fontSizes.FONT_28};
      line-height: ${theme.sizes.SIZE_40};
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__title {
      font-size: ${theme.fontSizes.FONT_18};
      font-weight: 600;
      color: var(--SHADE_1_1);
      margin-bottom: ${theme.sizes.SIZE_16};
    }
  }

  b {
    font-weight: 600;
    color: var(--SHADE_1_1);
  }

  ul {
    color: var(--SHADE_1_2);
    list-style-type: decimal;
    padding-left: ${theme.sizes.SIZE_24};
    margin: ${theme.sizes.SIZE_16} 0 ${theme.sizes.SIZE_56};

    li {
      &::marker {
        font-weight: 600;
        color: var(--SHADE_1_1);
      }
    }
  }

  p {
    margin: 0;
  }

  a {
    color: var(--PRIMARY_1_1);
    text-decoration: underline;
  }
`;
