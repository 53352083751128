import { Navigate } from 'react-router-dom';
import { ExtendRouteObject } from 'router/routes';

import Pages from './pages';
import { appUri } from '../../../config';

export default [
  {
    path: appUri.HOME,
    element: <Pages.Home />,
  },
  {
    index: true,
    element: <Navigate to={appUri.HOME} replace />,
  },
  {
    path: '*',
    element: <Navigate to={appUri.HOME} replace />,
  },
] as ExtendRouteObject[];
