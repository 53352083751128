import { Modal, theme } from '@faxi/web-component-library';
import { flex, position } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Container = styled(Modal)`
  .wcl-modal {
    overflow: auto;
    background-color: var(--BACKGROUND_2_1);

    &__footer {
      padding-top: ${theme.sizes.SIZE_12};
      padding-bottom: ${theme.sizes.SIZE_20};
    }
  }

  .kinto-pdf {
    &__page {
      page-break-after: always;
      width: 595px;
      height: 842px;
      flex: 0 0 842px;
      background-color: var(--BACKGROUND_1_1);
      outline: 1px solid lightgray;

      &__header {
        background: linear-gradient(
          to right,
          var(--PRIMARY_1_1),
          var(--PRIMARY_2_1)
        );
        height: ${theme.sizes.SIZE_64};

        &__logo {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          margin-left: ${theme.sizes.SIZE_24};
          width: ${theme.sizes.SIZE_80};
        }
      }

      &__main {
        padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_32};
        position: relative;

        h1 {
          color: var(--SHADE_1_1);
          font-weight: 600;
          margin: 0;
          font-size: ${theme.fontSizes.FONT_26};
        }

        &__first-paragraph,
        &__second-paragraph {
          color: var(--SHADE_1_2);
          font-size: ${theme.fontSizes.FONT_14};
        }

        &__first-paragraph {
          margin: ${theme.sizes.SIZE_12} 0 ${theme.sizes.SIZE_12};
          max-width: ${theme.sizes.SIZE_320};
        }

        &__illustration {
          ${position('absolute', 'top 36px right 0')};
          height: 320px;
          width: 299px;
        }

        &__second-paragraph {
          margin: 0;
        }
      }
    }

    &__footer {
      width: 100%;
      ${flex('column', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_24};

      &__pagination {
        font-size: ${theme.fontSizes.FONT_14};
        color: var(--SHADE_1_1);
      }
    }
  }

  .kinto-pdf-card {
    background: var(--BACKGROUND_2_1);
    opacity: 0.99;
    padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_20};
    width: 100%;
    border-radius: ${theme.sizes.SIZE_8};

    h2 {
      color: var(--SHADE_1_1);
      font-size: ${theme.fontSizes.FONT_14};
      font-weight: 600;
      margin: 0;
    }

    p {
      color: var(--SHADE_1_2);
      font-size: ${theme.fontSizes.FONT_14};
      margin: 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      color: var(--SHADE_1_2);
      font-size: ${theme.fontSizes.FONT_14};
      margin: ${theme.sizes.SIZE_12} 0 0;

      li {
        ${flex('row', 'space-between')};
        margin-bottom: 8px;

        &:last-of-type {
          margin-bottom: unset;
        }

        > div {
          ${flex('row')};

          > span {
            text-align: right;
            margin-right: ${theme.sizes.SIZE_12};

            &:last-of-type {
              width: ${theme.sizes.SIZE_64};
              margin-right: unset;
            }
          }
        }

        > span {
          &:last-of-type {
            color: var(--PRIMARY_1_1);
            font-weight: 600;
          }
        }
      }
    }
  }

  .kinto-pdf-card-row {
    ${flex('row')};
    gap: ${theme.sizes.SIZE_12};
    margin-bottom: ${theme.sizes.SIZE_12};
  }

  h2 {
    color: var(--SHADE_1_1);
    font-size: ${theme.fontSizes.FONT_16};
    font-weight: 400;
    margin: ${theme.sizes.SIZE_16} 0 ${theme.sizes.SIZE_10};
  }

  .community-details {
    margin-bottom: ${theme.sizes.SIZE_12};
    /* subtract half of the gap (12px) from 50%  */
    max-width: calc(50% - 6px);
    min-height: ${theme.sizes.SIZE_152};

    > div {
      color: var(--SHADE_1_1);
      font-size: ${theme.fontSizes.FONT_16};
      margin-top: ${theme.sizes.SIZE_12};
    }

    > p {
      margin-top: ${theme.sizes.SIZE_8};
      white-space: pre-wrap;
      line-height: 22px;
    }
  }

  .co2-emissions {
    margin-bottom: ${theme.sizes.SIZE_12};
    ${flex('row')};

    &__stats {
      ${flex('column')};
      margin-right: ${theme.sizes.SIZE_32};
      flex: 0 0 ${theme.sizes.SIZE_164};

      > div {
        ${flex('column', 'center', 'stretch')};
        flex: 0 0 50%;
      }

      &__value,
      &__unit {
        color: var(--PRIMARY_1_1);
        font-weight: 600;
        text-align: center;
      }

      &__value {
        font-size: 40px;
        padding: ${theme.sizes.SIZE_10} ${theme.sizes.SIZE_10} 0;
      }

      &__unit {
        font-size: ${theme.fontSizes.FONT_18};
        padding: 0 ${theme.sizes.SIZE_10} ${theme.sizes.SIZE_10};
      }

      .kinto-progress-bar__container__bar {
        height: ${theme.sizes.SIZE_8};
      }

      .kinto-progress-bar__container__percentage {
        flex: 0 0 ${theme.sizes.SIZE_48};
        font-size: ${theme.fontSizes.FONT_14};
      }

      .kinto-progress-bar__container__bar--rich {
        margin-right: ${theme.sizes.SIZE_8};
      }
    }

    &__description {
      h2 {
        font-size: ${theme.fontSizes.FONT_20};
        font-weight: 600;
        color: var(--SHADE_1_2);
      }

      p {
        vertical-align: top;
        margin-top: ${theme.sizes.SIZE_10};
      }
    }
  }

  .averages {
    ${flex('column', 'space-between')};

    > div {
      font-weight: 600;
      color: var(--PRIMARY_1_1);
      font-size: ${theme.fontSizes.FONT_14};

      &:first-of-type {
        margin-bottom: ${theme.sizes.SIZE_12};
      }
    }
  }

  .mode-of-transport {
    h2 {
      color: var(--SHADE_1_1);
      font-size: ${theme.fontSizes.FONT_16};
      font-weight: 600;
      margin-bottom: ${theme.sizes.SIZE_12};
    }

    .label {
      font-weight: 600;
    }

    li {
      margin-bottom: ${theme.sizes.SIZE_20};

      &:first-of-type {
        > * {
          font-weight: 600 !important;
          color: var(--SHADE_1_2) !important;
        }
      }

      &:not(:first-of-type) {
        > span {
          font-weight: 400 !important;
          color: var(--SHADE_1_2) !important;
        }

        > div {
          > * {
            font-weight: 600 !important;
            color: var(--PRIMARY_1_1) !important;
          }
        }
      }
    }
  }
`;
