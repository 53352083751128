import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@faxi/web-component-library';

import Each from 'helpers/Each';
import { Icon } from 'components';
import SurveyCard from '../SurveyCard';
import SustainabilityContext from '../../providers/Sustainability/Sustainability.context';

import * as Styles from './SurveysBoard.styles';

const SurveysBoard: FC = () => {
  const { t } = useTranslation();

  const {
    surveys,
    openModal: openSurveyModal,
    setActiveSurveyId,
  } = useContext(SustainabilityContext);

  return (
    <Styles.SurveysContainer>
      <Button
        iconPosition="left"
        variant="outline"
        icon={<Icon name="plus" />}
        onClick={(e) => {
          setActiveSurveyId('');
          openSurveyModal(e);
        }}
      >
        {t('global-new_run_survey')}
      </Button>

      <Each
        containerAs="div"
        className="kinto-survey-board__surveys"
        of={surveys}
        render={(item) => (
          <SurveyCard
            {...item}
            onSurveyClick={() => {
              setActiveSurveyId(`${item.id}`);
            }}
          />
        )}
      />
    </Styles.SurveysContainer>
  );
};

export default memo(SurveysBoard);
