import { useCallback, useContext } from 'react';

import { useMsal } from '@azure/msal-react';

import { SocialButton } from 'components';
import { loginRequest } from 'config/microsoftAuthConfig';
import { AppContext } from 'store';

const Microsoft = (props: { signup?: boolean; disabled?: boolean }) => {
  const { signup, disabled } = props;

  const { instance } = useMsal();
  const { platform } = useContext(AppContext);

  const handleLoginPopup = useCallback(() => {
    if (signup) {
      localStorage.setItem('signup', 'true');
      localStorage.setItem('platformId', `${platform?.id}`);
    }

    instance
      .loginRedirect({
        ...loginRequest,
        redirectUri: `${window.location.origin}/login-redirection`,
        prompt: 'select_account',
      })
      .catch((error) => console.error(error));
  }, [instance, platform?.id, signup]);

  return (
    <SocialButton
      provider="microsoft"
      onClick={handleLoginPopup}
      disabled={disabled}
    />
  );
};

export default Microsoft;
