import { focusStyles, theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const FiltersButton = styled.button`
  ${flex('row', 'center', 'center')};
  ${focusStyles};
  ${size('fit-content')};
  gap: ${theme.sizes.SIZE_8};
  margin-top: auto;
  background-color: var(--BACKGROUND_1_1);
  color: var(--SHADE_1_2);
  font-weight: 400;
  padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_32};
  border: none;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &:hover {
    background-color: var(--BACKGROUND_2_1);
  }

  > svg {
    color: var(--PRIMARY_1_1);
  }

  &.kinto-filter-button--active {
    background-color: var(--PRIMARY_1_1);
    color: var(--BACKGROUND_1_1);

    &:hover {
      background-color: var(--PRIMARY_2_1);
    }

    > svg {
      color: var(--BACKGROUND_1_1);
    }
  }
`;
