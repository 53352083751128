import { useCallback, useMemo, useRef, useState } from 'react';
import {
  ButtonProps,
  Button,
  ColumnsModalLabels,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';

export default function useColumnSettings() {
  const { t } = useTranslation();

  const columnBtnRef = useRef<HTMLButtonElement>(null);
  const [columnSettingsOpen, setColumnSettingsOpen] = useState<boolean>(false);

  const openColumnSettings = useCallback(() => {
    setColumnSettingsOpen(true);
  }, []);

  const closeColumnSettings = useCallback(() => {
    setColumnSettingsOpen(false);
  }, []);

  const columnTranslations = useMemo(
    () =>
      ({
        cancel: t('Cancel'),
        save: t('CommuteDetails_saving_message'),
        title: t('global-column_settings'),
        unselected: t('global-unselected'),
        subtitle: t('column_settings-subtitle'),
        warning: t('column_settings-alert_you_must_select'),
      } as ColumnsModalLabels),
    [t]
  );

  const columnSettingsBtn = useMemo(
    () => (props: ButtonProps) =>
      (
        <Button
          variant="ghost"
          iconPosition="left"
          ref={columnBtnRef}
          className="gray-ghost"
          icon={<Icon name="sliders-up" />}
          onClick={openColumnSettings}
          {...props}
        >
          {t('global-column_settings')}
        </Button>
      ),
    [openColumnSettings, t]
  );

  return {
    columnSettingsOpen,
    columnBtnRef,
    columnTranslations,
    openColumnSettings,
    closeColumnSettings,
    ColumnSettingsButton: columnSettingsBtn,
  };
}
