import { FC, useContext } from 'react';
import CustomInformation from '../CustomInformation';
import CustomInputForm from '../CustomInputForm';
import CustomFieldsContext from '../../../store/CustomFieldsProvider/CustomFields.context';

const CustomFields: FC = () => {
  const { status, managingInput } = useContext(CustomFieldsContext);
  return status === 'LIST_VIEW' ? (
    <CustomInformation />
  ) : managingInput?.type === 'text_field' ? (
    <CustomInputForm />
  ) : (
    // this is just an example of where to start putting new components when
    // its needed to do so
    <div>custom dropdown</div>
  );
};

export default CustomFields;
