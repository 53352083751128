import { FC, MouseEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { UserContext } from 'store';
import { SeparatedText } from 'components';
import { LeaderBoardFilterType } from 'models';

import * as Styles from './LeaderBoardRangeCard.styles';

type LeaderBoardRangeCardProps = {
  className?: string;
  onClick: (event: MouseEvent) => void;
} & LeaderBoardFilterType;

const LeaderBoardRangeCard: FC<LeaderBoardRangeCardProps> = (props) => {
  const { className, name, startDate, endDate, onClick } = props;

  const { t } = useTranslation();
  const {
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  return (
    <Styles.LeaderBoardCardContainer
      tabIndex={0}
      className={classNames('kinto-leader-board-range-card', className)}
      onClick={onClick}
    >
      <div className="kinto-leader-board-range-card__title" aria-label={name}>
        {name}
      </div>

      <SeparatedText
        className="kinto-leader-board-range-card__separated-text"
        name={t('campaign-start_date')}
        value={dayjs(startDate).format(dateFormat)}
      />
      <SeparatedText
        className="kinto-leader-board-range-card__separated-text"
        name={t('campaign-end_date')}
        value={dayjs(endDate).format(dateFormat)}
      />
    </Styles.LeaderBoardCardContainer>
  );
};

export default LeaderBoardRangeCard;
