import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';

import styled from 'styled-components';

export const Container = styled.div`
  ${flex('row', 'flex-start', 'center')};
  margin-bottom: ${theme.sizes.SIZE_48};
  .error-message {
    &__text {
      color: var(--ALERT_ERROR_1_1);
    }
    &__icon {
      margin-right: ${theme.sizes.SIZE_24};
      color: var(--ALERT_ERROR_1_1);
    }
  }
`;
