import { useEffectOnceWhen, useLatestRef } from '@faxi/web-component-library';
import React, { useCallback } from 'react';

export type AuthOptions = {
  clientId: string;
  scope: string;
  redirectURI: string;
  state?: string;
  nonce?: string;
  usePopup?: boolean;
};

export type AppleAuthResponse = {
  authorization: {
    id_token: string;
    code: string;
    state?: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
};

export type AppleLoginProps = {
  scriptLoaded: boolean;
  authOptions: AuthOptions;
  onError?: (error: any) => void;
  onSuccess: (data: AppleAuthResponse) => void;
};

export default function useAppleLogin(props: AppleLoginProps) {
  const { scriptLoaded, authOptions = {}, onSuccess, onError } = props;

  const onErrorRef = useLatestRef(onError);
  const onSuccessRef = useLatestRef(onSuccess);

  const authenticate = useCallback(
    async (event: React.MouseEvent) => {
      try {
        event.preventDefault();

        const response = await window.AppleID?.auth.signIn();
        onSuccessRef.current(response);
      } catch (error) {
        onErrorRef.current?.(error);
      }
    },
    [onErrorRef, onSuccessRef]
  );

  useEffectOnceWhen(() => {
    if (!scriptLoaded) {
      console.error(new Error('Error while loading apple script'));
      return;
    }

    window.AppleID?.auth.init(authOptions);
  }, scriptLoaded);

  return { authenticate };
}
