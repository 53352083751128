import { FC, useCallback, useContext } from 'react';
import { FieldProps, FormContext } from '@faxi/web-form';
import ImageDropzone from '../ImageDropzone';
import { ImageDropzoneProps } from '../ImageDropzone/ImageDropzone.component';
import { DropzoneFileType } from '../ImageDropzone/hooks/useDropzoneField';

type ImageDropzoneFieldProps = {
  className?: string;
  onCropToggle?: (value: boolean) => void;
} & Partial<ImageDropzoneProps> &
  FieldProps<DropzoneFileType, (event: string) => void>;

const ImageDropzoneField: FC<ImageDropzoneFieldProps> = (props) => {
  const { name, value, error, touched, onChange, ...rest } = props;
  const { clearFieldValidationProps } = useContext(FormContext);

  const handleOnChange = useCallback(
    (file: File | null) => {
      if (!file) onChange?.('');
      else onChange?.(file);

      clearFieldValidationProps(name as string);
    },
    [name, onChange, clearFieldValidationProps]
  );

  return (
    <ImageDropzone
      value={value as DropzoneFileType}
      onChange={handleOnChange}
      {...rest}
    />
  );
};

export default ImageDropzoneField;
