import { FC, memo, useCallback, useContext, useMemo } from 'react';
import { Button } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { Shift } from 'models';
import Icon from 'components/Icon';
import AssignedShift from '../../components/AssignedShift';
import { ShiftSettingsContext } from '../../providers/ShiftSettings/ShiftSettings.context';
import { useView } from 'providers/Views';
import { ShiftSettingsViews } from '../../index';

type AssignedShiftsProps = {
  onComplete?: () => void;
  onClearAll?: () => void;
};

const AssignedShifts: FC<AssignedShiftsProps> = (props) => {
  const { onComplete, onClearAll } = props;
  const { t } = useTranslation();

  const { pushView } = useView<ShiftSettingsViews>();

  const {
    shifts,
    assignedShifts,
    hasAssignedShifts,
    shouldRevalidate,
    assignedShiftsAreSame,
    setSelectedShift,
  } = useContext(ShiftSettingsContext);

  const handleEditShift = useCallback(
    (shift: Shift) => {
      setSelectedShift(shift);
      pushView('select-schedule');
    },
    [pushView, setSelectedShift]
  );

  const canAddShift = useMemo(
    () =>
      !shifts?.every((shift) =>
        assignedShifts.find((assigned) => assigned.shift_id === shift.id)
      ),
    [assignedShifts, shifts]
  );

  return (
    <>
      <div className="shift-settings-modal__assigned-shifts">
        {!assignedShiftsAreSame && hasAssignedShifts ? (
          <div className="shift-settings-modal__multiple-assigned">
            {t('shift_settings-multiple_schedules_assigned')}
          </div>
        ) : (
          assignedShifts?.map((assignedShift) => (
            <AssignedShift
              key={assignedShift.shift_id}
              shift={assignedShift}
              onEdit={handleEditShift}
            />
          ))
        )}
      </div>

      <div className="shift-settings-modal__actions">
        {canAddShift && (
          <Button
            variant="ghost"
            iconPosition="left"
            icon={<Icon name="plus" />}
            onClick={() => pushView('pick-shift')}
          >
            {t('predefined_shifts-button_add_shift')}
          </Button>
        )}

        {hasAssignedShifts ? (
          <Button
            className="clear-all-assign"
            variant="outline"
            onClick={() => {
              shouldRevalidate.current = true;
              onClearAll?.();
            }}
          >
            {t('filters_clear_all')}
          </Button>
        ) : (
          <Button
            className="assign-complete"
            variant="primary"
            onClick={() => {
              shouldRevalidate.current = true;
              onComplete?.();
            }}
          >
            {t('register_complete')}
          </Button>
        )}
      </div>
    </>
  );
};

export default memo(AssignedShifts);
