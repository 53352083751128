import styled, { css } from 'styled-components';
import SurveyPageWrapper from '../../components/SurveyPageWrapper';
import { flex, fontSize, phablet } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const YourEmissionContainer = styled(SurveyPageWrapper)`
  padding-top: ${theme.sizes.SIZE_40};

  p {
    max-width: ${theme.sizes.SIZE_520};
    color: var(--SHADE_1_1);
  }

  ${phablet(css`
    padding-top: 0px;
  `)}

  .kinto-survey-main {
    &__left-section {
      &__mobile-image {
        display: none;
      }
    }
  }

  .kinto-survey-your-emission {
    &__title {
      ${fontSize(theme.fontSizes.FONT_30, theme.sizes.SIZE_40)};

      margin: 0;
      font-weight: 400;

      ${phablet(css`
        ${fontSize(theme.fontSizes.FONT_20, theme.sizes.SIZE_24)};
        margin: 0 auto;
      `)}
    }

    &__result {
      ${flex('row')};

      background-color: var(--ACCENT_1_6);
      border-radius: ${theme.sizes.SIZE_8};
      margin-top: ${theme.sizes.SIZE_40};
      margin-bottom: ${theme.sizes.SIZE_160};
      padding: ${theme.sizes.SIZE_40} ${theme.sizes.SIZE_32};

      ${phablet(css`
        flex-direction: column-reverse;
        padding: ${theme.sizes.SIZE_20};
        margin-top: ${theme.sizes.SIZE_20};
        margin-bottom: ${theme.sizes.SIZE_48};
      `)};

      &__left {
        flex: 0 1 60%;
        ${fontSize(theme.fontSizes.FONT_20)};
        color: var(--SHADE_1_1);
        margin: auto;

        ${phablet(css`
          ${fontSize(theme.fontSizes.FONT_16)};
        `)};
      }

      &__right {
        ${flex('column', 'center', 'center')};
        flex: 0 1 40%;
        ${fontSize(theme.fontSizes.FONT_44)};
        color: var(--PRIMARY_1_1);

        ${phablet(css`
          ${flex('column', 'center', 'center')};
        `)};

        span {
          ${fontSize(theme.fontSizes.FONT_16)};
        }
      }
    }

    &__bottom-title {
      ${fontSize(theme.fontSizes.FONT_24, theme.sizes.SIZE_32)};

      color: red;
      font-weight: 600;
      margin: 0 0 ${theme.sizes.SIZE_24};
      color: var(--SHADE_1_1);

      ${phablet(css`
        ${fontSize(theme.fontSizes.FONT_20, theme.sizes.SIZE_24)};
      `)}
    }
  }
`;
