import { useContext, useMemo } from 'react';
import { Footer as WCLFooter, NavLink } from '@faxi/web-component-library';
import { AppContext } from 'store';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { platform, defaultCountry } = useContext(AppContext);

  const { t } = useTranslation();

  const secondaryLinks: NavLink[] = useMemo(
    () =>
      defaultCountry && platform
        ? [
            {
              name: t('register_privacy_and_policy'),
              route: `/corp/privacy-policy/?country=${defaultCountry.name.parametrify()}&language=${platform.default_language.parametrify()}`,
              id: 'privacy_policy',
            },
            {
              name: t('TermsCondition'),
              route: `/corp/terms-and-conditions/?country=${defaultCountry.name.parametrify()}&language=${platform.default_language.parametrify()}`,
              id: 'terms_conditions',
            },
            {
              name: t('CookiePolicy'),
              route: `/corp/cookie-policy/?country=${defaultCountry.name.parametrify()}&language=${platform.default_language.parametrify()}`,
              id: 'cookie_policy',
            },
          ]
        : [],
    [defaultCountry, platform, t]
  );

  return (
    <WCLFooter
      copyrights={<>{t('kintofooter_all_rights_reserved')}</>}
      logoSrc="/assets/svg/kinto_join.svg"
      secondaryLinks={secondaryLinks}
    />
  );
};

export default Footer;
