import { FormState, ValidationFn } from '@faxi/web-form';
import { AddressPlace, Shift } from 'models';
import dayjs from 'dayjs';
import config from 'config';
import i18next from 'i18next';

const POSITIVE_INTEGERS_ONLY = /^([1-9]+\d*)*$/;
const PHONE_NUMBER = /^([+]?[0-9]{5,15})?$/;

const shiftNameDuplicate =
  (msg: string, ind: number) => (value: string, fields: any) => {
    const shiftNames: string[] = fields.shifts.value.map(
      ({ name }: Shift) => name
    );

    const indexOfFirstValue = shiftNames.indexOf(value);

    const indexOfSecondValue = shiftNames.indexOf(value, indexOfFirstValue + 1);

    if (
      indexOfFirstValue !== -1 &&
      indexOfSecondValue !== -1 &&
      indexOfFirstValue !== indexOfSecondValue &&
      fields.shifts.fields[ind].name.dirty
    ) {
      return msg;
    }

    return undefined;
  };

const carSpaceNameDuplicate =
  (msg: string, index: number, carSpaces: string[]) => (value: string) =>
    value !== carSpaces[index] && carSpaces.includes(value) ? msg : undefined;

const positiveNumbersOnly =
  (errorMessage: string): ValidationFn<any> =>
  (value: string) => {
    if (!value) {
      return undefined;
    }

    return POSITIVE_INTEGERS_ONLY.test(value) ? undefined : errorMessage;
  };

const requiredArray = (errorMessage: string) => (value: string[]) => {
  if (value?.length === 0) {
    return errorMessage;
  }
  return undefined;
};

const dateAfter = (errorMessage: string, after?: Date) => (value: Date) => {
  if (after && value > after) {
    return errorMessage;
  }
  return undefined;
};

const startTimeValidation =
  (msg: string, start_date: string, minValue: string) => (value: string) => {
    if (
      dayjs().isSame(dayjs(start_date, config.dateFormat), 'day') &&
      value < minValue
    ) {
      return msg;
    }
    return '';
  };

const endTimeValidation =
  (errorMessage: string) => (value: string, allValues: any) => {
    const getFormValue = (key: string) => allValues[key]?.value ?? {};

    const start_date = getFormValue('start_date').format?.(config.dateFormat);
    const start_time = getFormValue('start_time');

    const end_date = getFormValue('end_date').format?.(config.dateFormat);
    const end_time = value;

    const startDate = dayjs(
      `${start_date} ${start_time}`,
      config.dateTimeFormat
    );
    const endDate = dayjs(`${end_date} ${end_time}`, config.dateTimeFormat);

    if (startDate.isAfter(endDate)) return errorMessage;
    else return undefined;
  };

const googleAutocompleteAddress =
  (errorMessage: string) => (value: AddressPlace) =>
    !value?.formatted_address ? errorMessage : undefined;

const rewardCountAccumulationCampaigns = (
  value: string,
  fields: FormState,
  rewardId: string,
  maxRewardCount: number
) => {
  // this is important to pass for validation when select Unlimited number of rewards
  if (fields?.[`reward_count_type_${rewardId}`]?.value !== 'unlimitedRewards') {
    if (!value) {
      return i18next.t('validation-fields_are_required', {
        fieldname: i18next.t('global-points'),
      });
    } else {
      return POSITIVE_INTEGERS_ONLY.test(value)
        ? +value > maxRewardCount
          ? i18next.t('validation-field_validation_max_value', {
              fieldname: i18next.t('global-points').toLowerCase(),
              number: maxRewardCount,
            })
          : undefined
        : i18next.t('validation-field_positive_round_number_only', {
            fieldname: i18next.t('global-points').toLowerCase(),
          });
    }
  } else return undefined;
};

const endDateCampaignValidation = (value: string, fields: FormState) =>
  //validate only if radio button End date is selected
  fields?.end_date_type?.value === 'date' && !value
    ? i18next.t('validation-field_is_required', {
        fieldname: i18next.t('campaign-end_date'),
      })
    : undefined;

const checkEqualitySum =
  (
    message: string,
    sumFields: Array<string>,
    equality: (sum: number) => boolean
  ) =>
  (_: any, fields: Record<string, any>) => {
    const calculatedSum = Object.values(fields).reduce(
      (value, field) =>
        sumFields.includes(field.name) && (value += +field.value),
      0
    );

    return equality(+calculatedSum) ? undefined : message;
  };

export default {
  shiftNameDuplicate,
  carSpaceNameDuplicate,
  requiredArray,
  dateAfter,
  positiveNumbersOnly,
  startTimeValidation,
  endTimeValidation,
  googleAutocompleteAddress,
  rewardCountAccumulationCampaigns,
  endDateCampaignValidation,
  checkEqualitySum,
  PHONE_NUMBER,
};
