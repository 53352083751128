import httpClient from '../httpClient';
import { Language, Platform } from 'models';
import { AxiosRequestConfig } from 'axios';

const getPlatforms = async (domain?: string, config?: AxiosRequestConfig) =>
  httpClient
    .get<{ platforms: Platform[] }>('platform/details', {
      ...config,
      params: {
        domain: domain || null,
      },
    })
    .then((res) => res.data.platforms);

const register = (email: string, pwd: string, id: string) => {
  const formdata = new FormData();
  formdata.append('email', email);
  formdata.append('pwd', pwd);
  formdata.append('idpltf', id);
  formdata.append('orig', 'web');
  formdata.append(
    'consent',
    JSON.stringify([
      { key: 't&c', val: 'Y' },
      { key: 'prp', val: 'Y' },
    ])
  );
  formdata.append('type', 'A');
  formdata.append('lang', 'en');
  formdata.append('app', 'A');

  return httpClient.post(`user`, formdata, {
    params: {
      _method: 'POST',
    },
  });
};

const getLanguages = async () =>
  httpClient
    .get<{ languages: Language[] }>('platform/available-languages')
    .then((res) => res.data);

const resendEmaiLVerification = (email: string) => {
  const formdata = new FormData();
  formdata.append('email', email);

  return httpClient.post('user/resendemail', formdata);
};

export default {
  getPlatforms,
  getLanguages,
  register,
  resendEmaiLVerification,
};
