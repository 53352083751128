import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { useQueryParams } from '@faxi/web-component-library';

import { Icon } from '../../../../components';
import { appUri } from '../../../../config';
import { BlockUI } from '../../../../helpers';
import { convertKgToTonsIfNeeded } from '../../../../utils';
import SurveyContext from '../../providers/Survey/Survey.context';

import * as Styles from './YourEmission.styles';

const YourEmissions: FC = () => {
  const { t } = useTranslation();

  const { yourEmission } = useContext(SurveyContext);
  const { surveySlug } = useParams<{ surveySlug: string }>();

  const { params } = useQueryParams<{
    co2: string;
  }>();

  const finalEmission = useMemo(
    () =>
      yourEmission !== undefined && (
        <>
          {convertKgToTonsIfNeeded(yourEmission).toFixed(2)}
          <span> {yourEmission > 1000 ? 't' : 'kg'}CO2e</span>
        </>
      ),
    [yourEmission]
  );

  return (
    <BlockUI
      condition={!params.co2 || yourEmission === undefined}
      fallback={<Navigate to={`/${appUri.SURVEY}/${surveySlug}`} />}
    >
      <Styles.YourEmissionContainer
        className="kinto-survey-your-emission"
        imageUrl="/assets/svg/survey_results.svg"
      >
        <div className="kinto-survey-your-emission__title">
          {t('sustainability-page_title_your_emissions')}
        </div>

        <div className="kinto-survey-your-emission__result" aria-live="polite">
          <div className="kinto-survey-your-emission__result__left">
            {t('sustainability-completed_page_info')}
          </div>

          <div className="kinto-survey-your-emission__result__right">
            <Icon name="cloud-fog" />
            <div>{finalEmission}</div>
          </div>
        </div>

        <p className="kinto-survey-your-emission__bottom-title">
          {t('sustainability-completed_page_title')}
        </p>

        <p className="kinto-survey-your-emission__bottom-subtitle">
          {t('sustainability-completed_page_subtitle')}
        </p>
      </Styles.YourEmissionContainer>
    </BlockUI>
  );
};

export default YourEmissions;
