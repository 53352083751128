import { Modal, theme } from '@faxi/web-component-library';
import { flex, fontSize, phablet, pxToRem } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const CreateNewCampaignModal = styled(Modal)`
  .create-new-campaign {
    &__modal {
      background-color: var(--BACKGROUND_2_1);

      &.wcl-modal {
        width: ${pxToRem(theme.sizes.SIZE_800)};
      }

      &__notif {
        ${flex('row', undefined, 'baseline')};
        ${fontSize(theme.fontSizes.FONT_18)};

        font-weight: 600;
        background-color: var(--PRIMARY_1_7);
        border-radius: ${theme.sizes.SIZE_8};
        padding: ${theme.sizes.SIZE_20};
        margin: ${theme.sizes.SIZE_24} 0 ${theme.sizes.SIZE_8};
        color: var(--SHADE_1_1);

        .wcl-icon {
          margin-right: ${theme.sizes.SIZE_12};
        }

        &__future-date {
          color: var(--PRIMARY_1_1);
          background-color: var(--PRIMARY_1_7);
          border-radius: ${theme.sizes.SIZE_8};
          padding: 0 ${theme.sizes.SIZE_4};
        }
      }

      &__details {
        ${flex('column')};

        background-color: var(--BACKGROUND_2_1);
        border-radius: ${theme.sizes.SIZE_8};
        padding: ${theme.sizes.SIZE_16};

        ${phablet(css`
          grid-template-columns: auto;
          grid-template-rows: auto auto auto;
        `)}

        h2 {
          grid-column: 1 / span 2;
          ${fontSize(theme.fontSizes.FONT_18)};
          font-weight: 400;
          color: var(--SECONDARY_1_1);
          margin: 0 0 ${theme.sizes.SIZE_24};

          ${phablet(css`
            grid-column: auto;
          `)}
        }

        .campaign-details__detail {
          margin-bottom: ${theme.sizes.SIZE_24};

          &__values {
            display: flex;
            flex-wrap: wrap;
            gap: ${theme.sizes.SIZE_12};
          }

          &.rewards {
            .campaign-details__detail__values {
              display: grid;
              gap: ${theme.sizes.SIZE_24};
              grid-template-columns: 1fr 1fr;

              ${phablet(css`
                ${flex('column')};
              `)}
            }
          }
        }

        &__dates {
          ${flex('row')};
        }
      }
    }
  }
`;
