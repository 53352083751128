import { getColor } from '@faxi/web-component-library';
import { datafyColor } from './datafyColor';

const updateMarkerIcon = async (
  marker: google.maps.Marker,
  becomeActive: boolean
) => {
  const icon = marker.getIcon() as string;

  if (icon.includes('/assets/svg/user_pin')) {
    marker.setIcon(`/assets/svg/user_pin${becomeActive ? '_active' : ''}.svg`);
  } else {
    marker.setIcon(
      icon.replace(
        datafyColor(getColor(becomeActive ? '--PRIMARY_1_1' : '--ACCENT_1_1')),
        datafyColor(getColor(becomeActive ? '--ACCENT_1_1' : '--PRIMARY_1_1'))
      )
    );
  }
};

export default updateMarkerIcon;
