import { useCallbackAsync } from 'hooks';
import { Shift } from 'models';
import { apiPredefinedShifts } from 'modules';
import { FC, PropsWithChildren, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ShiftSettingsModalProps } from '../../ShiftSettingsModal.component';
import { ShiftSettingsContext } from './ShiftSettings.context';

/* This should map all assigned shifts in map form {[shift_id]: user_shift_id[]}
 * to make easier to access for deletion, when user wants to delete single assigned shift
 * for multiple assign users then we get all them from object[shift_id], if user wants to clear all
 * assigned shifts then we map in uniq array of user_shift_id to delete assign for every assigned shift
 */
const getUserAssignedShifts = (shifts: Shift[]) => {
  return shifts.reduce(
    (currentValue, value, _, shifts) => ({
      ...currentValue,
      [value.shift_id]: shifts
        .filter((shift) => shift.shift_id === value.shift_id)
        .map((shift) => shift.user_shift_id) as number[],
    }),
    {} as Record<string, number[]>
  );
};

const checkShiftsIsSame = (shifts?: Shift[][], compareShift?: Shift[]) => {
  if (!shifts || !compareShift) return false;

  return shifts?.every(
    (value) =>
      compareShift?.length === value?.length &&
      compareShift.every((shift) =>
        value.find((v) => v.shift_id === shift.shift_id)
      )
  );
};

const initialAssignedShifts = (shifts?: Record<string, Shift[]>): Shift[] => {
  if (!shifts) return [];
  const checkedShifts = Object.values(shifts);
  const firstValidShifts = checkedShifts?.find((v) => v !== null);

  if (!firstValidShifts) return [];
  else
    return checkShiftsIsSame(checkedShifts, firstValidShifts)
      ? firstValidShifts
      : [];
};

export type ShiftSettingsProviderProps = ShiftSettingsModalProps &
  PropsWithChildren<{
    shifts: Shift[];
    selectedShift?: Shift;
    assignedShifts: Record<string, Shift[]>;
  }>;

const ShiftSettingsProvider: FC<ShiftSettingsProviderProps> = (props) => {
  const {
    children,
    shifts,
    selectedShift: pSelected = {} as Shift,
    assignedShifts: pAssigned,
  } = props;

  const params = useParams() as {
    organisationId: string;
  };

  const shouldRevalidate = useRef<boolean>(false);
  const initiallyAssigned = useRef<Shift[]>(initialAssignedShifts(pAssigned));

  const initialShiftsArray = useMemo(
    () =>
      Object.values(pAssigned)
        .reduce((acc, array) => {
          return acc.concat(array);
        }, [])
        .filter((s) => s !== null),
    [pAssigned]
  );

  const multipleAssignedUserIds = useRef<Record<string, number[]>>(
    getUserAssignedShifts(initialShiftsArray) || {}
  );

  const [selectedShift, setSelectedShift] = useState<Shift>(pSelected);
  const [assignedShifts, setAssignedShifts] = useState<Shift[]>(
    initialAssignedShifts(pAssigned) || []
  );

  const hasAssignedShifts = useMemo(
    () => Object.values(pAssigned).filter((s) => Boolean(s)).length > 0,
    [pAssigned]
  );

  const assignedUsers = useMemo(
    () => pAssigned && Object.keys(pAssigned).map((uid) => Number(uid)),
    [pAssigned]
  );

  const assignedShiftsAreSame = useMemo(() => {
    const checkedShifts = Object.values(pAssigned);
    const firstValidShifts = checkedShifts?.find((v) => v !== null);

    return checkShiftsIsSame(checkedShifts, firstValidShifts);
  }, [pAssigned]);

  const [assignShifts, loadingAssign] = useCallbackAsync({
    showSpinner: false,
    callback: async (shift?: Shift) => {
      const finalShifts = shift ? [shift] : assignedShifts;
      const singleShiftAssign = Boolean(shift);
      const initialData = initiallyAssigned.current.find(
        (s) => s.shift_id === shift?.shift_id
      );

      //Dates which are not in current shift dates
      const remove_dates = initialData?.dates.filter(
        (date) => !shift?.dates.includes(date) && !!date
      );

      const {
        data: { data: newShifts },
      } = await apiPredefinedShifts.assignShiftToUsers(
        params.organisationId,
        assignedUsers,
        finalShifts.map((shift) => ({
          remove_dates,
          add_dates: shift.dates,
          shift_id: shift.shift_id,
          pattern: shift.pattern || 'monday_to_friday',
        }))
      );

      multipleAssignedUserIds.current = {
        ...multipleAssignedUserIds.current,
        ...getUserAssignedShifts(newShifts),
      };

      const getShiftData = (shift_id: number, shifts: Shift[]) => {
        return shifts.find((fShift) => fShift.shift_id === shift_id);
      };

      const newAssignedShifts = finalShifts.map((shift) => ({
        ...shift,
        ...getShiftData(shift.shift_id, newShifts),
      }));

      //1. Initially assigned multiple shifts
      //2. Check if exists in array and update with new data

      setAssignedShifts((old) => {
        if (!singleShiftAssign) return newAssignedShifts;

        const shiftAlreadyExists = old.find(
          (s) => s.shift_id === shift?.shift_id
        );

        return shiftAlreadyExists
          ? old.map((s) =>
              s.shift_id === shift?.shift_id
                ? { ...s, ...newAssignedShifts[0] }
                : s
            )
          : [...old, ...newAssignedShifts];
      });
    },
  });

  const [deleteAssignedShifts, loadingDelete] = useCallbackAsync({
    showSpinner: false,
    callback: async (shift_id: number) => {
      const allAssignedUserIds = Object.values(
        multipleAssignedUserIds.current
      ).reduce((acc, array) => acc.concat(array), []);

      //If there are no target shift id then delete all assigned user shift ids
      //else delete only assigned ones by target shift id
      const finalUserShiftIds = !shift_id
        ? allAssignedUserIds
        : multipleAssignedUserIds.current[shift_id];

      //Delete only if there are initially applied shifts
      await apiPredefinedShifts.deleteAssignedShifts(
        params.organisationId,
        finalUserShiftIds
      );

      setAssignedShifts((shifts) =>
        shifts.filter((shift) => {
          return !finalUserShiftIds.includes(shift.user_shift_id as number);
        })
      );
    },
  });

  const loading = useMemo(
    () => loadingAssign || loadingDelete,
    [loadingAssign, loadingDelete]
  );

  return (
    <ShiftSettingsContext.Provider
      value={{
        shifts,
        loading,
        selectedShift,
        assignedShifts,
        hasAssignedShifts,
        initiallyAssigned,
        assignedShiftsAreSame,
        shouldRevalidate,
        setSelectedShift,
        setAssignedShifts,
        assignShifts,
        deleteAssignedShifts,
      }}
    >
      {children}
    </ShiftSettingsContext.Provider>
  );
};

export default ShiftSettingsProvider;
