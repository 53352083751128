import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, getColor, useUtilities } from '@faxi/web-component-library';

import CustomFields from 'components/_molecules/CustomFields/CustomFields.component';
import { apiCustomFields } from 'modules';
import { CustomFieldsContext, AppContext, UserContext } from 'store';
import { CustomFieldsStatus } from './CustomFields.context';
import { CustomField, CustomFieldTypes } from 'models';
import { PageLayout } from 'components/_layouts';

const CustomFieldsProvider: FC = () => {
  const { t } = useTranslation();
  const { platform } = useContext(AppContext);
  const { communityId } = useContext(UserContext);

  const { showOverlay, hideOverlay } = useUtilities();

  const [fields, setFields] = useState<CustomField[]>([]);
  const [managingInput, setManagingInput] = useState<CustomField | null>(null);
  const [status, setStatus] = useState<CustomFieldsStatus>('LIST_VIEW');

  const lastFocusEditId = useRef<string>('');

  const fetchCustomFields = useCallback(async () => {
    if (!communityId) {
      return;
    }

    try {
      showOverlay('.kinto-page');
      const data = await apiCustomFields.getAllCustomFields(communityId);

      setFields(data);
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.kinto-page');
    }
  }, [communityId, hideOverlay, showOverlay]);

  const addNewField = useCallback((field: CustomField) => {
    setFields((old) => [...old, field]);
  }, []);

  const startCreateNewField = useCallback(
    (fieldType: CustomFieldTypes) => {
      const newInput: CustomField = {
        type: fieldType,
        mandatory: 'N',
        error_key: '',
        label: '',
        name: '',
        platform_id: platform?.id,
      };

      lastFocusEditId.current = '#custom_info_add_input';

      setManagingInput(newInput);
      setStatus('ADD_FIELD');
    },
    [platform]
  );

  const startEditField = useCallback(
    (fieldId: string) => {
      lastFocusEditId.current = `#edit_custom_info_input_${fieldId}`;

      const foundInput = fields.find((field) => +field.id! === +fieldId);
      if (foundInput) {
        setManagingInput(foundInput);
        setStatus('EDIT_FIELD');
      }
    },
    [fields]
  );

  const editField = useCallback((field: CustomField) => {
    setFields((old) => old.map((f) => (field.id === f.id ? field : f)));
  }, []);

  const cancelEdit = useCallback(() => {
    setManagingInput(null);
    setStatus('LIST_VIEW');
  }, []);

  useEffect(() => {
    fetchCustomFields();

    return () => {
      hideOverlay('.kinto-page');
    };
  }, [fetchCustomFields, communityId, hideOverlay]);

  return (
    <CustomFieldsContext.Provider
      value={{
        fields,
        managingInput,
        status,
        lastFocusEditId,
        addNewField,
        fetchCustomFields,
        startCreateNewField,
        startEditField,
        editField,
        cancelEdit,
      }}
    >
      <PageLayout className="kinto-custom-information">
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__heading"
        >
          {t('custom_information')}
        </Heading>
        <CustomFields />
      </PageLayout>
    </CustomFieldsContext.Provider>
  );
};

export default CustomFieldsProvider;
