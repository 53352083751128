import { useTranslation } from 'react-i18next';
import { FormField, validators } from '@faxi/web-form';
import { RadioGroupField } from '../../../../../components';
import { RadioGroupOption } from '@faxi/web-component-library';
import { useMemo } from 'react';
import SurveyFormWrapper from '../../../components/SurveyFormWrapper';

const CommutingPattern = () => {
  const { t } = useTranslation();

  const commutingPatternOptions = useMemo<RadioGroupOption[]>(
    () => [
      {
        label: t('sustainability-working_pattern_working_from_home'),
        description: t('sustainability-working_pattern_description_remote'),
        value: 'remote',
        optionProps: {
          'aria-labelledby': 'kinto-radio-option-extra--0',
        },
      },
      {
        label: t('fuel_type-label_hybrid'),
        description: t('sustainability-working_pattern_description_hybrid'),
        value: 'hybrid',
        optionProps: {
          'aria-labelledby': 'kinto-radio-option-extra--1',
        },
      },
      {
        label: t('sustainability-working_pattern_going_to_the_office'),
        description: t('sustainability-working_pattern_description_on_site'),
        value: 'on_site',
        optionProps: {
          'aria-labelledby': 'kinto-radio-option-extra--2',
        },
      },
    ],
    [t]
  );

  return (
    <SurveyFormWrapper className="kinto-survey-form-container">
      <FormField
        className="kinto-survey-form-container__radio-group"
        component={RadioGroupField}
        name="commuting-pattern"
        options={commutingPatternOptions}
        orientation="column"
        validate={validators.general.required(
          t('validation-field_is_required')
        )}
      />
    </SurveyFormWrapper>
  );
};

export default CommutingPattern;
