import SurveyPage from './Survey.page';
import SurveyProvider from './providers/Survey';

export { default as surveyRoutes } from './routes';

const SurveyWrapper = () => {
  return (
    <SurveyProvider>
      <SurveyPage />
    </SurveyProvider>
  );
};

export default SurveyWrapper;
