import styled from 'styled-components';

import {
  elevate_l,
  Button,
  shadow_xl,
  theme,
} from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';

export const ShiftPickerStyled = styled(Button)`
  ${size('100%', 'auto')};
  ${flex('row', 'space-between', 'center')};

  color: var(--SHADE_1_1);
  padding: ${theme.sizes.SIZE_20};
  margin-bottom: ${theme.sizes.SIZE_16};

  &.button--outline {
    font-weight: 400;
    color: var(--SHADE_1_1);

    &:after {
      border-color: var(--SHADE_1_8);
      ${shadow_xl};
    }
  }

  &.selected-shift {
    font-weight: 600;
    margin-bottom: ${theme.sizes.SIZE_32};
    border: 2px solid var(--ACCENT_1_1);
  }

  .shift-picker {
    &__name {
      display: flex;
      align-items: center;
      margin-right: ${theme.sizes.SIZE_12};

      span {
        word-break: normal;
        overflow-wrap: anywhere;
      }
    }

    &__time {
      display: flex;
      align-items: center;
      margin-left: auto;

      span {
        margin-left: ${theme.sizes.SIZE_4};
      }
    }

    &__img {
      margin-right: ${theme.sizes.SIZE_10};
    }
  }
`;

export const SavedShiftPatternStyled = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--BACKGROUND_1_1);
  ${elevate_l};
  padding: ${theme.sizes.SIZE_16};
  align-items: center;
  border-radius: ${theme.sizes.SIZE_8};
  margin-bottom: ${theme.sizes.SIZE_16};

  .saved-shift-pattern {
    margin-right: ${theme.sizes.SIZE_72};

    &__shift-info {
      font-weight: 600;
      ${fontSize(theme.fontSizes.FONT_14)};

      > span:first-of-type {
        margin-right: ${theme.sizes.SIZE_10};
        word-break: normal;
        overflow-wrap: anywhere;
      }
    }

    &__edit-btn {
      ${size(theme.sizes.SIZE_48)};
      min-height: unset;
    }

    &__selected-date {
      &__label {
        ${fontSize(theme.fontSizes.FONT_12)};
        color: var(--SHADE_1_3);
      }

      &__value {
        ${fontSize(theme.fontSizes.FONT_12)};
        color: var(--SHADE_1_2);
      }
    }
  }
`;
