import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './Messages.styles';

const NoConversationSelected: FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.NoConversationSelectedStyled className="kinto-no-conversation-selected">
      <div className="kinto-no-conversation-selected__container">
        <img
          src="/assets/svg/kinto_no_conversation_selected_placeholder.svg"
          alt={t('search_no_results_found')}
        />
        <div className="kinto-no-conversation-selected__container__title">
          {t('messages-title_no_conversation_selected')}
        </div>
        <div className="kinto-no-conversation-selected__container__content">
          {t('messages-subtitle_no_conversation_selected')}
        </div>
      </div>
    </Styled.NoConversationSelectedStyled>
  );
};

export default NoConversationSelected;
