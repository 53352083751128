import { Navigate, RouteObject } from 'react-router-dom';

import {
  ErrorFallback,
  LoginTemplate,
  MainTemplate,
  MicrosoftRedirection,
} from 'components';
import { AdminPermissions } from 'helpers';
import { Redirection } from '../modules';
import { Role } from '../models/User';
import { authRoutes } from './subrouters/Auth';
import { adminRoutes } from './subrouters/Admin';
import { accountRoutes } from './subrouters/AccountSettings';
import { userRoutes } from './subrouters/User';
import UserLayout from './components/UserLayout';
import SuperadminRedirection from './components/SuperadminRedirection';
import Root from './Root.component';
import Pages from 'pages';
import { appUri } from '../config';
import { surveyRoutes } from '../pages/Survey';
import SignUpForm from '../components/_forms/SignUpForm/SignUpForm.component';

export type ExtendRouteObject = Omit<RouteObject, 'children'> & {
  authorized?: boolean;
  onlyPublic?: boolean;
  roles: Role[];
  fallbackUrl?: string;
  title?: string;
  children?: ExtendRouteObject[];
};

export default [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: appUri.LOGIN,
        onlyPublic: true,
        element: <LoginTemplate />,
        children: authRoutes,
      },
      {
        path: appUri.SIGN_UP,
        onlyPublic: true,
        element: (
          <LoginTemplate renderOutlet={false}>
            <SignUpForm />
          </LoginTemplate>
        ),
      },
      {
        path: appUri.RESEND_EMAIL,
        onlyPublic: true,
        element: (
          <LoginTemplate renderOutlet={false} onlyHeader>
            <Pages.ResendEmailVerification />
          </LoginTemplate>
          // <LoginTemplate renderOutlet={false}>
          //   <SignUpForm />
          // </LoginTemplate>
        ),
      },
      {
        path: appUri.REDIRECTION,
        onlyPublic: true,
        element: <Redirection />,
      },
      {
        path: 'admin/*',
        element: <Navigate to="/" />,
      },
      {
        path: 'superadmin/*',
        element: <SuperadminRedirection />,
      },
      {
        path: appUri.SURVEY,
        element: <Pages.Survey />,
        errorElement: <ErrorFallback />,
        children: surveyRoutes,
      },
      {
        path: '',
        authorized: true,
        element: <MainTemplate />,
        children: [
          {
            path: appUri.COMMUNITY_SINGLE,
            errorElement: <ErrorFallback />,
            children: [
              {
                //regular user routes
                path: '',
                roles: ['user', 'admin'],
                element: <UserLayout />,
                children: userRoutes,
              },
              {
                //admin user routes
                path: appUri.ADMIN,
                roles: ['admin'],
                element: <AdminPermissions />,
                children: adminRoutes,
              },
              {
                index: true,
                element: <Navigate to="" replace />,
              },
            ],
          },
          {
            path: appUri.ACCOUNT_SETTINGS,
            roles: ['user', 'admin'],
            authorized: true,
            errorElement: <ErrorFallback />,
            children: accountRoutes,
          },
          {
            path: '',
            roles: ['user'],
            authorized: true,
            errorElement: <ErrorFallback />,
            children: userRoutes,
          },
        ],
      },
      {
        path: appUri.INTERSTITIAL,
        onlyPublic: true,
        element: <Pages.Interstitial />,
      },
      {
        path: appUri.ACTIVATE,
        onlyPublic: true,
        element: <Pages.Activate />,
      },
      {
        path: appUri.MICROSOFT_REDIRECTION,
        onlyPublic: true,
        element: <MicrosoftRedirection />,
      },
      {
        path: appUri.ACCOUNT_DELETION,
        onlyPublic: true,
        element: <Pages.AccountDeletion />,
      },
      {
        path: '*',
        element: <Pages.NotFound />,
      },
    ],
  },
] as ExtendRouteObject[];
