import { theme } from '@faxi/web-component-library';
import {
  aboveTablet,
  flex,
  fontSize,
  pxToRem,
  size,
  tablet,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const SurveyReport = styled.div`
  ${flex('column')};
  ${size('100%')};

  max-width: ${pxToRem(theme.sizes.SIZE_1200)};

  @media (max-width: ${pxToRem('1640px')}) {
    max-width: ${pxToRem(theme.sizes.SIZE_976)};
  }

  @media (max-width: ${pxToRem(theme.sizes.SIZE_1200)}) {
    max-width: ${pxToRem(theme.sizes.SIZE_600)};
  }

  .kinto-survey-report {
    &__header {
      ${flex('row')};
      gap: ${theme.sizes.SIZE_12};
      margin-top: ${theme.sizes.SIZE_40};

      &__tooltip {
        margin: auto 0;
      }

      &__actions {
        ${flex('row', 'flex-start', 'center')};

        gap: ${theme.sizes.SIZE_24};

        ${tablet(css`
          flex-direction: column;
          gap: ${theme.sizes.SIZE_12};
          align-items: flex-end;
        `)};
      }

      @media (max-width: ${pxToRem(theme.sizes.SIZE_1200)}) {
        flex-direction: column;
      }
    }

    &__title {
      ${fontSize(theme.fontSizes.FONT_26, theme.sizes.SIZE_40)};

      margin: 0;
      font-weight: 600;
      margin-right: auto;
      color: var(--SHADE_1_2);
    }

    &__copy-link {
      width: fit-content;
      margin-right: auto;

      .kinto-copy-link__content {
        background-color: var(--ACCENT_1_6);
      }
    }

    &__content {
      ${size('fit-content', '100%')};

      gap: ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_32};

      .travel-distance {
        &--not-finished {
          grid-area: 6 / 1;

          @media (max-width: ${pxToRem('1640px')}) {
            grid-area: 7 / 2;
          }
        }
      }

      ${aboveTablet(css`
        display: grid;
        grid-template-rows: repeat(6, auto);
        grid-template-columns: repeat(
          3,
          minmax(${pxToRem(theme.sizes.SIZE_376)}, auto)
        );

        .days-on-site {
          grid-area: 5 / 1;
        }

        .reduction-analysis {
          grid-area: 7 / 1;
          grid-column-end: span 2;
        }

        .status,
        .audience,
        .commuting-pattern {
          grid-row-end: span 2;
        }

        .mode-of-transport {
          grid-row-end: span 4;
        }

        .co2-emission {
          grid-row-end: span 5;
          height: fit-content;

          &--not-finished {
            grid-row-end: span 2;
          }
        }
      `)};

      @media (max-width: ${pxToRem('1640px')}) {
        display: grid;
        grid-template-rows: repeat(6, ${pxToRem(theme.sizes.SIZE_112)});
        grid-template-columns: repeat(2, auto);

        .days-on-site {
          grid-area: 7 / 1;
        }

        .reduction-analysis {
          grid-area: 10 / 1;
          grid-column-end: span 2;
        }

        .mode-of-transport {
          grid-area: 3 / 1;
          grid-row-end: span 4;
        }

        .co2-emission {
          height: 100%;
        }
      }

      @media (max-width: ${pxToRem(theme.sizes.SIZE_1200)}) {
        ${flex('column')};

        width: 100%;

        .kinto-survey-report__header {
          flex-direction: column;
        }
      }

      .commuting-pattern {
        .kinto-report-card__labels {
          margin: auto 0;
        }
      }

      .travel-distance,
      .days-on-site {
        > .kinto-report-card {
          &__header {
            margin: 0 0 ${theme.sizes.SIZE_10};
          }
        }
      }
    }

    &__how-is-calculated {
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

      height: unset;
      min-height: unset;
      margin-left: auto;
      color: var(--SHADE_1_2);
      text-decoration: underline;
      margin-top: ${theme.sizes.SIZE_24};
    }

    &__stats-container {
      ${size('100%')};

      display: grid;
      gap: ${theme.sizes.SIZE_40};
      grid-template-columns: 1fr 1fr;
    }

    &__stats {
      ${flex('column')};

      gap: ${theme.sizes.SIZE_16};

      &__units {
        ${flex('row', 'flex-end', 'center')};
        width: 100%;

        div {
          ${fontSize(theme.fontSizes.FONT_16)};

          text-align: end;
          font-weight: 600;
          color: var(--SHADE_1_2);
          margin: 0 0 ${theme.sizes.SIZE_12};
          flex: 0 1 ${pxToRem(theme.sizes.SIZE_96)};
        }
      }

      &__item {
        ${flex('row', 'space-between', 'center')};
        ${fontSize(theme.fontSizes.FONT_16)};

        margin: 0;
        width: 100%;
        font-weight: 400;
        color: var(--SHADE_1_2);

        div:first-of-type {
          margin-right: auto;
          flex: 0 0 ${pxToRem(theme.sizes.SIZE_128)};
        }

        div:not(:first-of-type) {
          text-align: end;
          font-weight: 600;
          color: var(--PRIMARY_1_1);
          flex: 0 1 ${pxToRem(theme.sizes.SIZE_96)};
        }
      }
    }

    &__emissions {
      ${flex('column', 'center', 'center')};

      width: 100%;
      margin-top: ${theme.sizes.SIZE_24};

      .emission,
      .co2 {
        margin: 0;
        font-weight: 600;
        color: var(--PRIMARY_1_1);
      }

      .cloud-icon,
      svg {
        ${size(theme.sizes.SIZE_64)};
        color: var(--PRIMARY_1_1);
      }

      .emission {
        ${fontSize('56px')};
      }

      .co2 {
        ${fontSize(theme.fontSizes.FONT_24, theme.sizes.SIZE_32)};
      }

      .title {
        ${fontSize(theme.fontSizes.FONT_20, theme.sizes.SIZE_32)};

        font-weight: 600;
        color: var(--SHADE_1_2);
        margin: 0 0 ${theme.sizes.SIZE_12};
      }

      &__desc,
      &__desc-second {
        ${fontSize(theme.fontSizes.FONT_16)};
        margin-top: ${theme.sizes.SIZE_16};
        color: var(--SHADE_1_2);
      }

      &__desc-second {
        ${fontSize(theme.fontSizes.FONT_16)};
        margin-top: ${theme.sizes.SIZE_48};
      }

      &__stats {
        margin-top: ${theme.sizes.SIZE_24};
        ${flex('column')};
        gap: ${theme.sizes.SIZE_10};
      }
    }

    &__emissions-not-finished {
      ${flex('column', 'flex-start', 'flex-start')};
      gap: ${theme.sizes.SIZE_10};

      &__icon {
        svg {
          font-size: ${theme.fontSizes.FONT_20};
        }
      }
    }
  }
`;
