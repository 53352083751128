import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalProps,
  StatusElement,
  getColor,
  useFormButtons,
  useUtilities,
} from '@faxi/web-component-library';
import { FormActions, Icon } from 'components';
import { useCallbackAsync } from 'hooks';
import { apiGamification } from 'modules';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { UserContext } from 'store';
import {
  getCampaignStatusElementStatus,
  getCampaignStatusElementTranslation,
} from 'utils';
import { RewardType, DepotState } from 'models';
import CampaignDetails from '../CampaignDetails';

import * as Styled from './CreateNewCampaignModal.styles';

type CreateNewCampaignModalProps = {
  campaignValues: any;
} & ModalProps;

const CreateNewCampaignModal = (props: CreateNewCampaignModalProps) => {
  const { campaignValues, onClose, ...rest } = props;

  const { showSnackBar } = useUtilities();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  const { organisationId, campaignId } = useParams() as {
    organisationId: string;
    campaignId: string;
  };

  const [FormButtons] = useFormButtons({
    submitLabel: t('global_button_create'),
    cancelLabel: t('cancel'),
  });

  const [handleSaveCampaign] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.create-new-campaign__modal',
    callback: async () => {
      const { excluded, ...rest } = campaignValues;

      const params = {
        ...rest,
        end_date: rest?.end_date_type === 'never' ? '' : rest?.end_date,
      };

      const newState: DepotState | undefined =
        params.state && ['draft', 'finished', 'canceled'].includes(params.state)
          ? new Date(params.start_date!) <= new Date()
            ? 'active'
            : 'pending'
          : undefined;

      const { data } = newState
        ? await apiGamification.updateDepot(campaignId, {
            ...params,
            state: newState,
          })
        : await apiGamification.createDepot(params);

      if (data) {
        navigate('..');

        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t('gamification-campaigns_notification_campaign_created', {
            campaign: campaignValues?.name,
          }),
          variant: 'success',
        });
      }
    },
    deps: [campaignValues, organisationId, campaignId, navigate],
  });

  return (
    <Styled.CreateNewCampaignModal
      onClose={onClose}
      title={t('gamification-campaigns_modal_title_are_you_sure')}
      modalClassName="create-new-campaign__modal"
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit onClick={handleSaveCampaign} />
          <FormButtons.Cancel onClick={onClose} />
        </FormActions>
      }
      {...rest}
    >
      <CampaignDetails
        campaign={campaignValues}
        campaignState={
          <StatusElement
            status={getCampaignStatusElementStatus(campaignValues.state!)}
          >
            {getCampaignStatusElementTranslation(
              {
                state: campaignValues.state,
                start_date: campaignValues.start_date,
              },
              campaignValues.data?.rewards as RewardType[],
              dateFormat
            )}
          </StatusElement>
        }
        className="create-new-campaign__modal__details"
      />

      <div className="create-new-campaign__modal__notif">
        <Icon name="circle-info" color={getColor('--PRIMARY_1_1')} />
        {campaignValues?.start_date &&
        dayjs(campaignValues?.start_date).diff(dayjs(), 'days', true) > 0 ? (
          <span>
            {`${t('gamification-campaigns_alert_campaign_will_be_active_on')}`}{' '}
            <span className="create-new-campaign__modal__notif__future-date">{`${dayjs(
              campaignValues?.start_date
            ).format(dateFormat)}`}</span>
          </span>
        ) : (
          t('gamification-campaigns_alert_campaign_will_also_be_activated')
        )}
      </div>
    </Styled.CreateNewCampaignModal>
  );
};

export default CreateNewCampaignModal;
