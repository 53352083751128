import { FC, useCallback, useContext, useMemo } from 'react';
import Styled from 'components/_layouts/Containers';
import {
  Button,
  useCallbackRef,
  Heading,
  useUtilities,
  getColor,
} from '@faxi/web-component-library';
import {
  FormField,
  Form,
  useFormRefValues,
  validators,
  FormRef,
  DataState,
} from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from 'store';
import { useHeadTitle } from 'hooks';
import { PasswordField, Icon } from 'components';
import regex from 'validation/regex';
import { appUri } from '../../../config';

const CreateNewPasswordForm: FC = (): JSX.Element => {
  const { changePassword } = useContext(AuthContext);
  const { prompts } = useUtilities();

  const location = useLocation();

  const [form, formRef] = useCallbackRef<FormRef>();

  const password = useFormRefValues(form, 'newPassword')?.newPassword;

  const navigate = useNavigate();
  const { t } = useTranslation();
  useHeadTitle(t('global_form_create_new_password'));

  const validations = useMemo(
    () => ({
      newPassword: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('new_password'),
          })
        ),
        validators.general.regex(
          regex.passwordValidator,
          t('validation-field_new_password')
        ),
        validators.general.regex(
          regex.trimWhiteSpaces,
          t('validation-field_new_password')
        ),
      ],
      reEnterPassword: [
        validators.general.required(
          t('validation-field_new_confirm_new_password')
        ),
        validators.general.reEnterFieldDoesntMatch(
          password,
          t('passwords_match_validation')
        ),
      ],
    }),
    [password, t]
  );

  const resetCode = useMemo(
    () => new URLSearchParams(location.search).get('resetpwd')!,
    [location.search]
  );

  const handleChangePassword = useCallback(
    async (event: DataState) => {
      try {
        const { errc } = await changePassword(resetCode, event.reEnterPassword);
        if (errc !== 0) {
          prompts.infoError({
            title: t('reset_password-expired_message'),
            submitBtnText: t('ok'),
            onSubmit: () => navigate(appUri.LOGIN_RESET_PASSWORD),
            onClose: () => navigate(appUri.LOGIN_RESET_PASSWORD),
          });
        } else {
          prompts.infoSuccess({
            title: t('Password_reset_success'),
            submitBtnText: t('ok'),
            onSubmit: () => navigate(appUri.LOGIN),
            onClose: () => navigate(appUri.LOGIN),
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [changePassword, resetCode, prompts, t, navigate]
  );

  return (
    <Styled.AuthGrid>
      <Form ref={formRef} className="form" onSubmit={handleChangePassword}>
        <Heading level="1" color={getColor('--PRIMARY_1_1')}>
          {t('global_form_create_new_password')}
        </Heading>
        <div className="form__fields">
          <FormField
            component={PasswordField}
            name="newPassword"
            prefixIcon={<Icon name="lock-keyhole" />}
            label={t('new_password')}
            className="form__fields__field"
            placeholder={t('enter_new_password')}
            validate={validations.newPassword}
            required
            requiredLabel={t('global-input_field_required_label')}
            hasRules
          />
          <FormField
            component={PasswordField}
            name="reEnterPassword"
            prefixIcon={<Icon name="lock-keyhole" />}
            label={t('confirm_new_password')}
            className="form__fields__field"
            placeholder={t('confirm_new_password')}
            validate={validations.reEnterPassword}
            required
            requiredLabel={t('global-input_field_required_label')}
          />

          <div className="form__fields__commands">
            <Button
              className="form__fields__commands__command--full-width-mobile"
              type="submit"
              disabled={!form?.isFormChanged() || !form?.syncFormValid}
            >
              {t('global_button_create')}
            </Button>
          </div>
        </div>
      </Form>
    </Styled.AuthGrid>
  );
};

export default CreateNewPasswordForm;
