import { useCallback, useContext } from 'react';
import { Button, Icon } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'store';
import { apiPlatform } from 'modules';

import * as Styled from './EmailRequestSent.styles';

const EmailRequestSent = () => {
  const { user } = useContext(UserContext);

  const { t } = useTranslation();

  const handleResendEmailVerification = useCallback(async () => {
    try {
      if (user?.registeredEmail) {
        await apiPlatform.resendEmaiLVerification(user?.registeredEmail);
      }
    } catch (e) {
      console.error(e);
    }
  }, [user?.registeredEmail]);

  return (
    <Styled.EmailRequestSentStyled>
      <Icon name="check-circle" className="resend-email__check-icon" />

      <h1>{t('onboarding-title_request_sent')}</h1>
      <p className="resend-email__explanation">
        {t('change_email-body_email_changed')}
      </p>

      {user?.registeredEmail && (
        <>
          <div className="resend-email__email">
            {user?.registeredEmail} <Icon name="check-circle-solid" />
          </div>
          <Button variant="outline" onClick={handleResendEmailVerification}>
            {t('resend')}
          </Button>
        </>
      )}
    </Styled.EmailRequestSentStyled>
  );
};

export default EmailRequestSent;
