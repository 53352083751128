import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '@faxi/web-component-library';
import { UserContext } from 'store';
import { StoreButtons } from 'components';

import * as Styles from './Home.styles';
import classNames from 'classnames';

const Home: FC = () => {
  const { t } = useTranslation();

  const { communities } = useContext(UserContext);

  return (
    <Styles.Home
      className={classNames([
        'kinto-user-home',
        { 'kinto-user-home__download-page': communities.length === 0 },
      ])}
    >
      {communities.length !== 0 ? (
        <>
          <h2 className="kinto-user-home__title">
            {t('navigation_home-empty_state_title_coming_soon')}
          </h2>

          <p className="kinto-user-home__description">
            {t('navigation_home-empty_state_subtitle_coming_soon')}
          </p>

          <Image
            alt=""
            className="kinto-user-home__placeholder"
            src="/assets/images/user-home-placeholder.webp"
          />
        </>
      ) : (
        <>
          <h2 className="kinto-user-home__title">
            {t('onboarding_download-title_download_the_app')}
          </h2>

          <p className="kinto-user-home__description">
            {t('onboarding_download-subtitle_download_the_app')}
          </p>

          <Image
            alt=""
            className="kinto-user-home__placeholder"
            src="/assets/svg/mobile-login.svg"
          />

          <StoreButtons />
        </>
      )}
    </Styles.Home>
  );
};

export default Home;
