import { useContext, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { appUri } from 'config';
import { UserContext } from 'store';
import { ObjectValues, PREMIUM_FEATURES } from 'models';

const GuardedPremiumRoute = ({
  element: Element,
  permission,
}: {
  element: React.ReactElement;
  permission: ObjectValues<typeof PREMIUM_FEATURES>;
}) => {
  const { community } = useContext(UserContext);

  const premiumFeatureAvailable = useMemo(
    () =>
      !!community?.premium_features?.find(({ name }) => name === permission),
    [community, permission]
  );

  return premiumFeatureAvailable ? (
    Element
  ) : (
    <Navigate to={appUri.COMMUNITY_DASHBOARD} />
  );
};

export default GuardedPremiumRoute;
