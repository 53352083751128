import { AxiosRequestConfig } from 'axios';
import { Conversation, Message, PaginatedResponse } from 'models';
import httpClient from '../httpClient';

const getConversations = ({
  uid,
  oid,
  offset,
  count,
  search,
  config,
}: {
  uid: string;
  oid: number;
  offset?: number;
  count?: number;
  search?: string;
  config?: Partial<AxiosRequestConfig>;
}): Promise<PaginatedResponse<Conversation, 'conversations'>> =>
  httpClient
    .get('message/conversations', {
      params: {
        uid,
        oid,
        offset,
        count,
        search,
        client: 'web',
      },
      ...config,
    })
    .then((res) => res.data);

const getMessagesBetweenUsers = ({
  from,
  to,
  offset,
  count,
  config,
}: {
  from: string;
  to: string;
  offset?: number;
  count?: number;
  config?: Partial<AxiosRequestConfig>;
}): Promise<PaginatedResponse<Message, 'messages'>> =>
  httpClient
    .get('message', {
      params: {
        from,
        to,
        offset,
        count,
      },
      ...config,
    })
    .then((res) => res.data);

const sendMessage = (formData: FormData) =>
  httpClient.post('message', formData).then((res) => res.data);

const markMessageAsRead = (formData: FormData) =>
  httpClient
    .post('message', formData, {
      params: { _method: 'PUT' },
    })
    .then((res) => res.data);

export default {
  getConversations,
  getMessagesBetweenUsers,
  sendMessage,
  markMessageAsRead,
};
