import {
  Button,
  ButtonProps,
  useEffectOnceWhen,
  useLatestRef,
} from '@faxi/web-component-library';
import { Icon } from 'components';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ViewsContext, { View, ViewsContextProps } from './Views.context';

export type ViewsProviderProps<T> = {
  children?: ReactNode;
} & Pick<ViewsContextProps<T>, 'views' | 'initialView'>;

function ViewsProvider<T>(props: ViewsProviderProps<T>) {
  const { children, views: pViews, initialView } = props;

  const { t } = useTranslation();
  const viewsRef = useLatestRef<View<T>[]>(pViews as View<T>[]);
  const [views, setViews] = useState<View<T>[]>([]);

  const View = useMemo(
    () => (props: any) => {
      if (!views.length) return null;

      const View = views[views.length - 1].component as FC;
      return <View key={props.key} {...props} />;
    },
    [views]
  );

  const clearStack = useCallback(() => setViews([]), []);

  const findValidView = useCallback(
    () =>
      viewsRef.current.find((view) => view.validate && view.validate())?.key,
    [viewsRef]
  );

  const pushView = useCallback(
    (key: T, replace = false) => {
      const view = viewsRef.current.find((view) => view.key === key);

      if (!view || (view?.validate && !view.validate())) {
        const validView = findValidView();
        if (validView) pushView(validView);
        return;
      }

      setViews((old) => {
        return [
          ...(replace ? old.slice(0, old.length - 1) : old),
          view,
        ] as View<T>[];
      });
    },
    [findValidView, viewsRef]
  );

  const goBack = useCallback(() => {
    setViews((old) => {
      if (old.length > 1) return old.slice(0, old.length - 1);
      else return old;
    });
  }, []);

  const BackButton = useMemo(
    () =>
      ({ onClick, ...restProps }: ButtonProps) =>
        views?.length > 1 ? (
          <Button
            variant="ghost"
            iconPosition="left"
            icon={<Icon name="arrow-left" />}
            onClick={(e) => {
              onClick?.(e);
              goBack();
            }}
            {...restProps}
          >
            {t('Back')}
          </Button>
        ) : null,
    [goBack, t, views?.length]
  );

  useEffectOnceWhen(() => {
    if (initialView) {
      pushView(initialView as T);
    }
  });

  const viewProviderValues = useMemo(
    () =>
      ({
        views,
        initialView,
        activeView: View,
        BackButton,
        pushView,
        goBack,
        clearStack,
      } as ViewsContextProps<T>),
    [BackButton, View, goBack, clearStack, pushView, initialView, views]
  );

  return (
    <ViewsContext.Provider value={viewProviderValues}>
      {children}
    </ViewsContext.Provider>
  );
}

export default ViewsProvider;
