import { AxiosRequestConfig } from 'axios';
import { APIResponse, Survey, SurveyAnalysis } from 'models';
import httpClient from '../httpClient';
import { SurveyAnswers } from '../../../models/Survey';

const createSurvey = (data: Survey, config?: Partial<AxiosRequestConfig>) =>
  httpClient
    .post<APIResponse<any>>('/surveys/run', data, {
      ...config,
    })
    .then((res) => res.data);

const editSurvey = (
  surveyId: string,
  data: Partial<Survey>,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient
    .post(`/surveys/${surveyId}/edit`, data, {
      params: { _method: 'PUT' },
      ...config,
    })
    .then((res) => res.data);

const stopSurvey = (
  surveyId: string,
  organisation_id: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient.post<APIResponse<Survey>>(
    `/surveys/${surveyId}/stop`,
    {},
    { params: { organisation_id, _method: 'PUT' }, ...config }
  );

const getSurveys = (
  organisation_id: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient.get<APIResponse<Survey[]>>('/surveys/all', {
    params: { organisation_id },
    ...config,
  });

const getSurvey = (
  surveyId: string,
  organisation_id: string,
  unit: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient.get<APIResponse<Survey>>(`/surveys/${surveyId}/get`, {
    params: { organisation_id, unit },
    ...config,
  });

const getSurveyReport = (
  surveyId: string,
  organisation_id: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient
    .get<Blob>(`/surveys/${surveyId}/report`, {
      params: { organisation_id },
      ...config,
      responseType: 'blob',
    })
    .then((res) => res.data);

const getSurveyAnalysis = (surveyId: string, organisation_id: string) =>
  httpClient.get<APIResponse<SurveyAnalysis[]>>(
    `/surveys/${surveyId}/analysis`,
    { params: { organisation_id } }
  );

const startSurveyAnalysis = (surveyId: string, organisation_id: string) =>
  httpClient.post(
    `/surveys/${surveyId}/analyse?organisation_id=${organisation_id}`
  );

const answerSurvey = (
  survey: SurveyAnswers,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient.post<APIResponse<{ co2: number }>>(
    `/surveys/answers/standalone`,
    { ...survey, origin: 'web' },
    { ...config }
  );

const getSurveyBySlug = (slug: string, config?: Partial<AxiosRequestConfig>) =>
  httpClient.get<APIResponse<{ status: string }>>(`/surveys/slug/${slug}`, {
    ...config,
  });

const checkSurveyEmail = (
  email: string,
  slug: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient.post<APIResponse<{ status: string }>>(
    `/surveys/standalone/check-email`,
    { email, 'survey-slug': slug },
    {
      ...config,
    }
  );

export default {
  getSurveys,
  getSurvey,
  stopSurvey,
  createSurvey,
  editSurvey,
  getSurveyReport,
  getSurveyAnalysis,
  startSurveyAnalysis,
  answerSurvey,
  getSurveyBySlug,
  checkSurveyEmail,
};
