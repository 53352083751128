import { AxiosRequestConfig } from 'axios';
import { APIResponseCustom, Shift, ScheduleTime } from 'models';
import httpClient from '../httpClient';

const getShifts = (communityId: number, config?: Partial<AxiosRequestConfig>) =>
  httpClient
    .get<{ shifts: Shift[]; errc: number }>(
      `organisation/${communityId}/shifts`,
      {
        ...config,
      }
    )
    .then((res) => res.data);

const addShift = (communityId: number, data: FormData) =>
  httpClient.post(`organisation/${communityId}/shifts`, data);

//modal
const deleteShift = (communityId: number, shiftId: number) =>
  httpClient.post(
    `organisation/${communityId}/shifts/${shiftId}`,
    {},
    { params: { _method: 'DELETE' } }
  );

const deleteMultipleShits = (communityId: number, shiftIds: number[]) => {
  const data = new FormData();
  data.append('ids', JSON.stringify(shiftIds));
  return httpClient.post(`organisation/${communityId}/shifts`, data, {
    params: { _method: 'DELETE' },
  });
};

const updateShift = (communityId: number, shiftId: number, data: FormData) =>
  httpClient.post(`organisation/${communityId}/shifts/${shiftId}`, data, {
    params: { _method: 'PUT' },
  });

const updateBatchShifts = (communityId: number, data: FormData) =>
  httpClient.post(`organisation/${communityId}/shifts/batch`, data);

//modal
const assignShiftToUsers = (
  communityId: string,
  users: number[],
  shifts: {
    shift_id?: number | undefined;
    pattern: ScheduleTime;
    add_dates?: string[];
    remove_dates?: string[];
  }[]
) => {
  const formdata = new FormData();
  formdata.append('users', JSON.stringify(users));
  formdata.append('shifts', JSON.stringify(shifts));

  return httpClient.post<APIResponseCustom<{ data: Shift[] }>>(
    `organisation/${communityId}/shift-assign`,
    formdata
  );
};

//modal
const deleteAssignedShifts = (communityId: string, userShiftIds: number[]) => {
  const formdata = new FormData();
  formdata.append('ids', JSON.stringify(userShiftIds));
  return httpClient.post(`organisation/${communityId}/shift-assign`, formdata, {
    params: { _method: 'DELETE' },
  });
};

export default {
  getShifts,
  addShift,
  deleteShift,
  updateShift,
  updateBatchShifts,
  deleteMultipleShits,
  assignShiftToUsers,
  deleteAssignedShifts,
};
