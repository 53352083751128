import {
  flex,
  fontSize,
  position,
  size,
  phablet,
  pxToRem,
} from '@faxi/web-css-utilities';

import styled, { css } from 'styled-components';
import { elevate_xxl, focusStyles, theme } from '@faxi/web-component-library';

export const MapUtilityButton = styled.button`
  ${fontSize(`${theme.fontSizes.FONT_20}`, `${theme.fontSizes.FONT_28}`)};
  ${focusStyles};

  min-width: ${pxToRem(theme.sizes.SIZE_56)};
  min-height: ${pxToRem(theme.sizes.SIZE_56)};
  padding: 0;
  font-family: inherit;
  color: var(--SECONDARY_1_1);
  background: var(--BACKGROUND_1_1);
  border: none;
  cursor: pointer;

  &:focus {
    z-index: 1;
  }

  ${phablet(css`
    ${size(theme.sizes.SIZE_40)};

    min-width: ${pxToRem(theme.sizes.SIZE_40)};
    min-height: ${pxToRem(theme.sizes.SIZE_40)};

    svg[data-icon] {
      ${size(theme.sizes.SIZE_16)};
    }
  `)}
`;

export const MapMode = styled.div`
  z-index: 1;
  display: flex;
  width: ${pxToRem(theme.sizes.SIZE_256)};
  position: absolute;
  bottom: ${theme.sizes.SIZE_32};
  left: 50%;
  transform: translateX(-50%);
  ${elevate_xxl};

  button {
    flex: 1 1 100%;
    margin: 0;

    ${phablet(css`
      ${fontSize(theme.fontSizes.FONT_14)};
    `)}

    &:first-of-type {
      border-radius: ${theme.sizes.SIZE_8} 0 0 ${theme.sizes.SIZE_8};
    }

    &:last-of-type {
      border-radius: 0 ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_8} 0;
    }

    &.active {
      font-weight: 600;
    }

    + button {
      border-left: ${theme.sizes.SIZE_1} solid var(--SHADE_1_6);
    }
  }

  ${phablet(css`
    width: ${pxToRem(theme.sizes.SIZE_160)};
  `)}
`;

export const MapZoom = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  top: ${theme.sizes.SIZE_48};
  right: ${theme.sizes.SIZE_32};
  height: fit-content;
  ${elevate_xxl};
  border-radius: ${theme.sizes.SIZE_8};

  ${phablet(css`
    right: ${theme.sizes.SIZE_16};
    top: ${theme.sizes.SIZE_64};
  `)}

  button {
    ${flex('row', 'center', 'center')};

    svg[data-icon] {
      ${size(theme.sizes.SIZE_24)};

      ${phablet(css`
        ${size(theme.sizes.SIZE_16)};
      `)}
    }

    + button {
      position: relative;

      &:before {
        ${position(
          'absolute',
          'top -0.5px right -0.5px bottom -0.5px left -0.5px'
        )};
        content: '';
        background-color: var(--SHADE_1_6);
        display: block;
        height: ${pxToRem(theme.sizes.SIZE_1)};
        width: 80%;
        margin: 0 auto;
      }
    }

    &:first-of-type {
      border-radius: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_8} 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_8};
    }
  }
`;
