import styled, { css } from 'styled-components';
import {
  fontSize,
  phablet,
  tablet,
  flex,
  pxToRem,
} from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const Container = styled.div`
  ${flex('column')};
  height: 100%;

  ${tablet(css`
    height: 100%;
    display: flex;
    flex-direction: column;
  `)};

  .base-template {
    &__header {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  .consents {
    &__links {
      max-width: ${pxToRem(theme.sizes.SIZE_1200)};
      display: flex;
      justify-content: space-evenly;
      margin: 0 10% ${`${theme.sizes.SIZE_48}
        ${theme.sizes.SIZE_72}`};

      ${phablet(css`
        margin: 0 ${theme.sizes.SIZE_40} ${theme.sizes.SIZE_48};
        text-align: center;
      `)};

      &__navs {
        padding: 0 ${theme.sizes.SIZE_4};
        ${fontSize(theme.fontSizes.FONT_16)};
        color: var(--SHADE_1_2);
        text-decoration: none;
      }
    }
  }
`;
