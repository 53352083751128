import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContext, StepsContext } from '@faxi/web-form';
import { useFormButtons } from '@faxi/web-component-library';

import { FormActions } from '../../../../components';

const SurveyFormFooter: FC = () => {
  const { t } = useTranslation();

  const { syncFormValid, asyncFormValid } = useContext(FormContext);

  const { currentStep, previousPage } = useContext(StepsContext);

  const [FormButtons] = useFormButtons({
    submitLabel: t('next'),
    cancelLabel: t('Back'),
  });

  return (
    <FormActions className="form__actions">
      {currentStep > 1 && (
        <FormButtons.Cancel variant="outline" onClick={previousPage} />
      )}

      <FormButtons.Submit
        type="submit"
        disabled={!syncFormValid || !asyncFormValid}
      />
    </FormActions>
  );
};

export default SurveyFormFooter;
