import { convertObjToFormData } from '@faxi/web-form';
import { CustomField } from 'models';
import httpClient from '../httpClient';

const getAllCustomFields = (oid: number) => {
  return httpClient
    .get<{ custom_fields: CustomField[] }>(`admin/${oid}/custom_fields`)
    .then(({ data: { custom_fields } }) => custom_fields);
};

const createNewField = (oid: number, field: CustomField) => {
  const formData = convertObjToFormData(field);
  return httpClient.post<{ rc: 'ok' | 'error'; errc: number }>(
    `admin/${oid}/custom_field`,
    formData
  );
};

const editField = (oid: number, field: CustomField, oldFieldName: string) => {
  const formData = convertObjToFormData(field);
  return httpClient.post<{ rc: 'ok' | 'error'; errc: number }>(
    `admin/${oid}/custom_field/${oldFieldName}?_method=PUT`,
    formData
  );
};

const deleteField = (oid: number, fieldName: string) => {
  return httpClient.post<{ rc: 'ok' | 'error'; errc: number }>(
    `admin/${oid}/custom_fields/${fieldName}`,
    null,
    {
      params: { _method: 'DELETE' },
    }
  );
};

export default { getAllCustomFields, createNewField, editField, deleteField };
