import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize, phablet } from '@faxi/web-css-utilities';
import { Form } from '@faxi/web-form';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${flex('column')};

  gap: ${theme.sizes.SIZE_32};
  background-color: var(--BACKGROUND_2_1);

  .kinto-survey {
    &__header {
      ${flex('column', 'space-between', 'flex-start')};
      padding: ${`${theme.sizes.SIZE_32} ${theme.sizes.SIZE_80} ${theme.sizes.SIZE_32}`};
      background-color: var(--BACKGROUND_2_1);

      &__content {
        ${flex('row', 'space-between', 'center')};
        width: 100%;
      }

      &__title {
        ${fontSize(theme.fontSizes.FONT_22, theme.sizes.SIZE_32)};

        font-weight: 600;
        color: var(--SECONDARY_1_1);
        margin: ${theme.sizes.SIZE_20} 0 0 0;

        ${phablet(css`
          ${fontSize(theme.fontSizes.FONT_20, theme.sizes.SIZE_24)};

          margin-top: ${theme.sizes.SIZE_10};
        `)}
      }

      ${phablet(css`
        padding: ${`${theme.sizes.SIZE_24} ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_24}`};
      `)};

      img {
        width: ${theme.sizes.SIZE_152};
        cursor: pointer;
      }

      .wcl-dropdown {
        ${phablet(css`
          max-width: ${theme.sizes.SIZE_120};
        `)};
      }
    }
  }

  &.kinto-survey {
    &--started-or-ended {
      background-color: var(--BACKGROUND_1_1);
    }
  }
`;

export const FormContainer = styled(Form)`
  .input {
    max-width: ${theme.sizes.SIZE_528};
  }

  .kinto-survey-form-container {
    &__title {
      color: var(--PRIMARY_1_1);
      margin-top: ${theme.sizes.SIZE_10};
      ${fontSize(theme.fontSizes.FONT_18)};
    }

    &__question {
      ${fontSize(theme.fontSizes.FONT_30, theme.sizes.SIZE_40)};

      font-weight: 400;
      color: var(--SHADE_1_1);
      margin: ${theme.sizes.SIZE_12} 0 ${theme.sizes.SIZE_48};
    }

    &__radio-group {
      gap: ${theme.sizes.SIZE_56};

      .wcl-radio-button__label {
        ${fontSize(theme.fontSizes.FONT_20, theme.sizes.SIZE_32)};
      }
    }

    &__transport-mode {
      ${flex('row', 'space-between', 'center')};

      width: 100%;

      .input__container {
        max-height: ${theme.sizes.SIZE_48};

        input {
          text-align: center;
        }
      }
    }

    &__container {
      ${flex('column')};

      gap: ${theme.sizes.SIZE_32};

      .wcl-radio-group {
        .kinto-radio-option-extra {
          color: var(--SHADE_1_1);
          padding-left: ${theme.sizes.SIZE_24};
          ${fontSize(theme.fontSizes.FONT_14)};
          max-width: ${theme.sizes.SIZE_184};
          margin-bottom: ${theme.sizes.SIZE_4};
        }
      }
    }

    &--mode-of-transport {
      .kinto-survey-form-container {
        &__container {
          gap: ${theme.sizes.SIZE_8};
        }
      }
    }
  }

  &.kinto-survey-mode-of-transport {
    .kinto-survey-form-container {
      &__header {
        ${flex('row', 'space-between', 'center')};
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_48)};

        font-weight: 400;
        color: var(--SHADE_1_2);

        p {
          margin: 0;
        }
      }

      &__wrapper {
        ${flexGap(theme.sizes.SIZE_12, 'column')};
      }

      &__mode-warning {
        width: 100%;
        animation: unset;
        max-width: unset;
        margin-top: ${theme.sizes.SIZE_24};
      }

      &__total-days {
        height: ${theme.sizes.SIZE_56};
        margin-top: ${theme.sizes.SIZE_24};
      }

      &__container {
        gap: ${theme.sizes.SIZE_12};
      }
    }
  }
`;
