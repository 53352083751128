import { apiAuth } from 'modules';
import authBus, { AUTH_BUS_EVENTS } from '../modules/authBus';
import storageService, { STORAGE_KEYS } from './storageService';

export type RefreshTokenService = {
  refreshingAtm: boolean;
  triedRefresh: boolean;
  checkRefreshTreshold: number;
  refresh: () => Promise<void>;
  reinit: () => void;
  tried: () => void;
};

export default {
  refreshingAtm: false,
  triedRefresh: false,
  checkRefreshTreshold: 5000,
  async refresh() {
    this.refreshingAtm = true;

    const refreshToken = storageService.getItem<string>(
      STORAGE_KEYS.REFRESH_TOKEN
    );

    const {
      data: { refresh_token: newRefreshToken, sid: newSid },
    } = await apiAuth.refreshToken(refreshToken);

    storageService.setItem(STORAGE_KEYS.SID, newSid);
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, newRefreshToken);
    authBus.broadcastEvent(AUTH_BUS_EVENTS.REFRESH_TOKEN_SUCCESS);

    this.tried();
  },
  reinit() {
    this.refreshingAtm = false;
    this.triedRefresh = false;
  },
  tried() {
    this.refreshingAtm = false;
    this.triedRefresh = true;
  },
} as RefreshTokenService;
