import { FC, MouseEvent } from 'react';
import { Event } from 'models';
import { Icon } from 'components';
import { dateTimeString } from 'utils';
import config from 'config';

import * as Styled from './EventCard.styles';

type Props = {
  event: Event;
  dateFormat?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

const EventCard: FC<Props> = (props: Props): JSX.Element => {
  const {
    event: {
      title,
      address,
      city,
      country,
      start_date,
      start_time,
      end_date,
      end_time,
    },
    onClick,
    dateFormat = config.dateFormatSimple,
  } = props;

  return (
    <Styled.EventCardContainer
      tabIndex={0}
      className="kinto-event-card"
      onClick={onClick}
    >
      <div className="kinto-event-card__data">
        <div className="kinto-event-card__data__time">
          {dateTimeString(
            start_date,
            start_time,
            dateFormat,
            config.timeFormat,
            (value) => `at ${value} to`
          )}
          <br />
          {dateTimeString(
            end_date,
            end_time,
            dateFormat,
            config.timeFormat,
            (value) => `at ${value}`
          )}
        </div>
        <div className="kinto-event-card__data__name">{title}</div>
        <div className="kinto-event-card__data__location">
          {[address, city, country].filter(Boolean).join(', ')}
        </div>
      </div>
      <div className="kinto-event-card__arrow">
        <Icon name="chevron-right" />
      </div>
    </Styled.EventCardContainer>
  );
};

export default EventCard;
