import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  Fragment,
  useRef,
  KeyboardEvent,
} from 'react';
import { responsiveSm } from '@faxi/web-css-utilities';
import classNames from 'classnames';
import {
  useCallbackRef,
  useEffectSkipFirst,
  useResize,
  ExpanderRef,
  Button,
  Heading,
  getColor,
  useStateQueries,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  InputField,
  InvitePeopleModal,
  CommunityElement,
} from 'components';
import { MapDataContext } from 'pages/Map/providers/MapData';
import { MarkerClusterContext } from 'pages/Map/providers/MarkerCluster';
import { UserContext } from 'store';
import UserItem from '../UserItem';

import { NoData, SearchableUsersSection } from 'Global.styles';
import * as Styled from './MapUserList.styled';

const KEYBOARD_KEYS = [
  'ArrowDown',
  'ArrowUp',
  'ArrowLeft',
  'ArrowRight',
] as const;
type KeyboardKeysType = (typeof KEYBOARD_KEYS)[number];

type MapUserListProps = {
  className?: string;
};

const MapUserList: FC<MapUserListProps> = (props) => {
  const { className } = props;

  const {
    params: { search },
    setQuery,
  } = useStateQueries<{ search: string }>(
    [{ query: 'search', defaultValue: null }],
    true
  );

  const { user, communityId } = useContext(UserContext);
  const { locations, setRouteStart } = useContext(MapDataContext);
  const { activePin } = useContext(MarkerClusterContext);

  const { t } = useTranslation();

  const [filteredPeople, setFilteredPeople] = useState(locations.peopleHomes);

  const [invitePeopleModalActive, setInvitePeopleModalActive] = useState(false);

  const [memberListExpander, memberListExpanderRef] =
    useCallbackRef<ExpanderRef | null>(null);

  const isBelowPhablet = useResize(responsiveSm);

  const onMarkerSelected = useCallback(() => {
    if (isBelowPhablet) {
      memberListExpander?.setOpen(!memberListExpander.open);
    }
  }, [isBelowPhablet, memberListExpander]);

  const clearSearch = useCallback(() => {
    if (isBelowPhablet && memberListExpander?.open) {
      memberListExpander.setOpen(!memberListExpander.open);
    }

    setQuery('search', '');
  }, [isBelowPhablet, setQuery, memberListExpander]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLUListElement>) => {
      if (!KEYBOARD_KEYS.includes(event.key as KeyboardKeysType)) return;
      event.preventDefault();

      const { key } = event;

      if (!filteredPeople) return;
      const currentIndex = filteredPeople?.findIndex(
        (p) => p.id === activeFocusTabId.current
      );

      const nextFocusableId = filteredPeople[currentIndex + 1]?.id;
      const prevFocusableId = filteredPeople[currentIndex - 1]?.id;

      const nextElement = document.querySelector(
        `#map_select_user_${nextFocusableId}`
      ) as HTMLElement;

      const prevElement = document.querySelector(
        `#map_select_user_${prevFocusableId}`
      ) as HTMLElement;

      if (['ArrowDown', 'ArrowRight'].includes(key) && nextElement) {
        activeFocusTabId.current = nextFocusableId;
        nextElement.focus();
      } else if (['ArrowUp', 'ArrowLeft'].includes(key) && prevElement) {
        activeFocusTabId.current = prevFocusableId;
        prevElement.focus();
      }
    },
    [filteredPeople]
  );

  // LOCAL SEARCH WITH REGEX
  useEffect(() => {
    const regexString = `${(search || '').trim().replace(/[^a-z0-9]/gi, '')}`
      .split('')
      .join('.*')
      .trim();

    if (!regexString && search) {
      setFilteredPeople([]);
      return;
    }

    const peopleFiltered = locations.peopleHomes?.filter(
      ({ first_name, last_name, email }) =>
        RegExp(regexString, 'gi').test(`${first_name} ${last_name} ${email}`)
    );

    setFilteredPeople(peopleFiltered);
  }, [isBelowPhablet, locations, search]);

  // HANDLE EXPANDER
  useEffect(() => {
    if (
      isBelowPhablet &&
      memberListExpander &&
      !memberListExpander.open &&
      search
    ) {
      memberListExpander.setOpen(!memberListExpander.open);
    }
    // we don't want changes of memberListExpander to trigger
    // toggling it over and over again
    // ---------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isBelowPhablet]);

  useEffectSkipFirst(() => {
    if (!memberListExpander) return;

    if (isBelowPhablet === memberListExpander.open) {
      memberListExpander.setOpen(!memberListExpander.open);
    }
  }, [isBelowPhablet]);

  useEffect(() => {
    if (activePin) {
      setRouteStart({ lat: activePin.lat, lng: activePin.lng });
    } else {
      setRouteStart(undefined);
    }
  }, [activePin, communityId, setRouteStart, user]);

  //When closing info window on mobile then return focus on
  //expander trigger to collapse users list again
  useEffectSkipFirst(() => {
    if (!isBelowPhablet || activePin) return;
    const trigger = document.querySelector(`.expander__trigger`);

    if (!trigger) return;
    (trigger as HTMLElement).focus();
  }, [activePin, isBelowPhablet, memberListExpander]);

  const activeFocusTabId = useRef<number>();

  const usersExpanderBody = useMemo(
    () => (
      <Styled.UserList className="kinto-user-list">
        {filteredPeople?.map((pHome, index) => (
          <UserItem
            tabIndex={
              isBelowPhablet ||
              activeFocusTabId.current === pHome.id ||
              (!activeFocusTabId.current && index === 0)
                ? 0
                : -1
            }
            className={pHome.id === activePin?.id ? 'active' : undefined}
            key={pHome.id}
            {...pHome}
            onClick={onMarkerSelected}
            onFocus={() => (activeFocusTabId.current = pHome.id)}
          />
        ))}

        {/* NO MEMBERS IN COMMUNITY */}
        {locations.peopleHomes?.length === 0 && (
          <Fragment>
            <NoData className="kinto-no-data">{t('no_members_yet')}</NoData>
            <Button
              icon={<Icon name="plus" />}
              variant="outline"
              className="kinto-user-list__invite-button"
              onClick={() => setInvitePeopleModalActive(true)}
            >
              {t('invite_people')}
            </Button>
          </Fragment>
        )}

        {/* NO SEARCH RESULTS */}
        {filteredPeople?.length === 0 && !!search && (
          <NoData className="kinto-no-data">
            {t('search_no_results_found')}
          </NoData>
        )}
      </Styled.UserList>
    ),
    [
      filteredPeople,
      locations,
      t,
      search,
      isBelowPhablet,
      activePin,
      onMarkerSelected,
    ]
  );

  return (
    <SearchableUsersSection
      className={classNames(
        'kinto-searchable-users-section',
        'kinto-map-user-list',
        {
          'list-expanded': memberListExpander?.open,
        },
        className
      )}
      onKeyDown={handleKeyDown}
    >
      <div className="kinto-searchable-users-section__top">
        <CommunityElement />

        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__heading"
        >
          {t('mMap')}
        </Heading>
      </div>

      <InputField
        className="kinto-searchable-users-section__search"
        value={search}
        prefixIcon={<Icon name="magnifying-glass" />}
        placeholder={t('selgroup_search')}
        onChange={(value) => setQuery('search', value)}
        {...(search && {
          suffixIcon: (
            <Button
              variant="ghost"
              aria-label={t('delete_input')}
              icon={<Icon name="xmark" />}
              onClick={clearSearch}
            />
          ),
        })}
      />

      <Styled.ExpanderContainer>
        {isBelowPhablet ? (
          <Styled.UserListExpander
            bodyAs="div"
            title={t('member_list')}
            ref={memberListExpanderRef}
            icon={<Icon name="chevron-down" />}
            body={usersExpanderBody}
          />
        ) : (
          usersExpanderBody
        )}
      </Styled.ExpanderContainer>

      {/* INVITE PEOPLE MODAL */}
      {invitePeopleModalActive && communityId && (
        <InvitePeopleModal
          organisationId={communityId}
          onClose={() => {
            setInvitePeopleModalActive(false);
          }}
        />
      )}
    </SearchableUsersSection>
  );
};

export default MapUserList;
