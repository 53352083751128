import { useEffectOnceWhen } from '@faxi/web-component-library';
import classNames from 'classnames';
import { ButtonCard } from 'components';
import { INameExtended } from 'components/Icon';
import Each from 'helpers/Each';
import { RewardTypes, REWARD_TYPES } from 'models';
import { useView } from 'providers/Views';
import { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RewardViewsType } from '../..';

import * as Styled from './RewardType.styles';

type RewardTypeProps = {
  className?: string;
  onRewardType?: (value?: RewardTypes) => void;
};

const RewardType: FC<RewardTypeProps> = (props) => {
  const { className, onRewardType } = props;

  const { t } = useTranslation();
  const { pushView } = useView<RewardViewsType>();

  const updateRewardType = useCallback(
    (type: RewardTypes) => {
      onRewardType?.(type);
      pushView('reward-settings');
    },
    [pushView, onRewardType]
  );

  const rewardTypes = useMemo(
    () => [
      {
        icon: 'cup-togo' as INameExtended,
        title: t('rewards-title_regular_reward'),
        description: t('rewards-subtitle_regular_reward'),
        onClick: () => updateRewardType(REWARD_TYPES.MESSAGE_REWARD),
      },
      {
        icon: 'square-parking' as INameExtended,
        title: t('rewards-title_parking_reward'),
        description: t('rewards-subtitle_parking_reward'),
        onClick: () => updateRewardType(REWARD_TYPES.PARKING_REWARD),
      },
    ],
    [t, updateRewardType]
  );

  //Reset active reward type while choosing reward type
  useEffectOnceWhen(() => onRewardType?.(undefined));

  return (
    <Styled.RewardType className={classNames('kinto-reward-type', className)}>
      <p className="kinto-reward-type__title">
        {t('rewards-choose_reward_type')}
      </p>

      <Each of={rewardTypes} render={(type) => <ButtonCard {...type} />} />
    </Styled.RewardType>
  );
};

export default memo(RewardType);
