import {
  useState,
  useEffect,
  useCallback,
  useContext,
  FC,
  useRef,
  PropsWithChildren,
} from 'react';
import { FormRef } from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUtilities } from '@faxi/web-component-library';

import { AppContext } from 'store';
import { apiAuth, apiUser, apiMailer, authBus, AUTH_BUS_EVENTS } from 'modules';
import { snackBarErrorMessage } from 'utils';
import { useEffectOnceWhen } from 'hooks';

import storageService, { STORAGE_KEYS } from 'services/storageService';
import refreshTokenService from 'services/refreshTokenService';
import AuthContext from './Auth.context';
import env from 'env';

const AuthProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const { platform, allPlatforms } = useContext(AppContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { prompts, showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const [errorMessage, setErrorMessage] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState('');
  const [placeholderValue, setPlaceholderValue] = useState('');
  const [sessionId, setSessionId] = useState(
    storageService?.getItem<string>(STORAGE_KEYS.SID) || ''
  );
  const [registeredUserPlatforms, setRegisteredUserPlatforms] =
    useState<[{ idpltf: number }]>();
  const [uris, setUris] = useState<string[]>([]);

  const renderSnackBarSessionExpired = useRef(false);

  const applyCredentials = useCallback(
    (authData: any) => {
      let pltfExists = false;

      const isRestricted = authData.errc === 2001;
      if (isRestricted) {
        snackBarErrorMessage(t('login-toast_login_failed_admin_required'));
        return;
      }

      if (authData.platforms_registered) {
        const regUserPlatforms = JSON.parse(authData.platforms_registered) as [
          { idpltf: number }
        ];

        storageService.setItem(
          STORAGE_KEYS.REGISTERED_USER_PLATFORMS,
          authData.platforms_registered
        );

        setRegisteredUserPlatforms(regUserPlatforms);

        const platformFilter = regUserPlatforms.filter(
          (platformIds: any) =>
            platformIds?.idpltf === `${platform?.id}` ||
            platformIds?.idpltf === localStorage.getItem('platformId')
        );

        if (platformFilter.length !== 0) {
          pltfExists = true;
        }
      }

      if (!JSON.parse(env.VITE_APP_SKIP_LOGIN_PLATFORM_AUTH) && !pltfExists) {
        authBus.broadcastEvent('redirect_interstitial');
        return;
      }

      setSessionId(authData.sid);

      storageService.setItem(STORAGE_KEYS.SID, authData.sid);
      storageService.setItem(
        STORAGE_KEYS.REFRESH_TOKEN,
        authData.refresh_token
      );

      navigate('/');

      setFailedAttempts('');
      setPlaceholderValue('');
      setErrorMessage(false);
    },
    [platform, navigate, t]
  );

  const handleLogin = useCallback(
    async (formData: FormData, form: FormRef) => {
      storageService.removeItem(STORAGE_KEYS.REGISTERED_USER_PLATFORMS);
      document.body.style.overflow = 'hidden';
      showOverlay('body', 'fixed');

      const res = await apiAuth.login(formData);
      refreshTokenService.reinit();

      renderSnackBarSessionExpired.current = false;
      if (res.errc === 0) applyCredentials(res);

      if (res.errc >= 1038 && res.errc <= 1040) {
        setFailedAttempts(res.errc.toString());
        setErrorMessage(false);
        if (res.errc !== '1039') {
          setPlaceholderValue(res.placeholder_value);
        }
      } else if (res.errc === 1005) {
        // account is not verified
        setFailedAttempts('');
        setPlaceholderValue('');
        setErrorMessage(true);
      }

      if (res.errc !== 1005 && res.errc !== 1040 && res.errc !== 0) {
        setSessionId('');
        setErrorMessage(false);
        snackBarErrorMessage(t('invalid_email_or_password'));
      }

      hideOverlay('body');
      document.body.style.overflow = 'initial';
    },
    [hideOverlay, showOverlay, t, applyCredentials]
  );

  const { fetchLanguages, fetchPlatforms } = useContext(AppContext);

  const handleLogout = useCallback(
    async (sessionExpired?: boolean) => {
      if (sessionId === '') return;

      if (!sessionExpired) await apiAuth.invalidateSession(sessionId);

      localStorage.clear();
      setSessionId('');
      navigate('/login');

      if (sessionExpired && !renderSnackBarSessionExpired.current) {
        renderSnackBarSessionExpired.current = true;

        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t('your_session_expired'),
          variant: 'error',
        });

        fetchLanguages();
        fetchPlatforms();
      }
    },
    [fetchLanguages, fetchPlatforms, navigate, sessionId, showSnackBar, t]
  );

  const resetPassword = useCallback(
    async (email: string) => {
      const res = await apiMailer.sendEmail(email);

      if (res.errc === 0) {
        prompts.infoSuccess({
          title: t('email_is_successfully_sent'),
          submitBtnText: t('ok'),
          cancelBtnText: t('cancel'),
        });
      } else {
        prompts.infoError({
          title: t(res.errc === 9025 ? 'error_9025' : 'invalid_email_mssg'),
          submitBtnText: t('ok'),
          cancelBtnText: t('cancel'),
        });
      }
    },
    [t, prompts]
  );

  const changePassword = useCallback(
    async (resetCode: string, newPassword: string) =>
      apiUser.changePassword(resetCode, newPassword),
    []
  );

  useEffectOnceWhen(() => {
    const logoutExpired = async () => await handleLogout(true);

    authBus.addEventListener(AUTH_BUS_EVENTS.SESSION_EXPIRED, logoutExpired);

    return () => {
      authBus.removeEventListener(
        AUTH_BUS_EVENTS.SESSION_EXPIRED,
        logoutExpired
      );
    };
  });

  useEffect(() => {
    const regUserPlatforms = storageService.getItem<string>(
      STORAGE_KEYS.REGISTERED_USER_PLATFORMS
    );

    if (regUserPlatforms) {
      setRegisteredUserPlatforms(JSON.parse(regUserPlatforms));
    }
  }, []);

  useEffect(() => {
    if (registeredUserPlatforms && allPlatforms) {
      const platformUrls: string[] = [];
      registeredUserPlatforms.forEach(({ idpltf }) => {
        const filteredPlatforms = allPlatforms.filter(
          (uri) => uri.id === Number(idpltf)
        );
        filteredPlatforms.forEach((item) => {
          if (item.id !== 1) {
            platformUrls.push(item.uri);
          }
        });
      });
      setUris(platformUrls);
    }
  }, [allPlatforms, registeredUserPlatforms]);

  return (
    <AuthContext.Provider
      value={{
        errorMessage,
        failedAttempts,
        placeholderValue,
        sessionId,
        uris,
        applyCredentials,
        changePassword,
        handleLogin,
        handleLogout,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
