import { FC, useContext, useMemo } from 'react';
import {
  SelectOption,
  Tabs,
  TabOption,
  useQueryParams,
  useUtilities,
  Heading,
  getColor,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { Form, FormField } from '@faxi/web-form';

import { useCallbackAsync } from 'hooks';
import { INameExtended } from 'components/Icon';
import { AppContext, UserContext } from 'store';
import { SelectField, FormFooter } from 'components';
import { UserPreferences } from 'models';
import { getLanguageCode } from 'store/AppProvider/App.provider';

import * as Styled from 'components/_layouts/Containers';
import { PageLayout } from 'components/_layouts';

const DATE_FORMAT_OPTIONS = [
  { label: 'mm/dd/yyyy', value: 'MM/DD/YYYY', id: 'MM/DD/YYYY' },
  { label: 'dd/mm/yyyy', value: 'DD/MM/YYYY', id: 'DD/MM/YYYY' },
  { label: 'yyyy/mm/dd', value: 'YYYY/MM/DD', id: 'YYYY/MM/DD' },
  { label: 'yyyy/dd/mm', value: 'YYYY/DD/MM', id: 'YYYY/DD/MM' },
  { label: 'mm.dd.yyyy', value: 'MM.DD.YYYY', id: 'MM.DD.YYYY' },
  { label: 'dd.mm.yyyy', value: 'DD.MM.YYYY', id: 'DD.MM.YYYY' },
  { label: 'yyyy.mm.dd', value: 'YYYY.MM.DD', id: 'YYYY.MM.DD' },
  { label: 'yyyy.dd.mm', value: 'YYYY.DD.MM', id: 'YYYY.DD.MM' },
  { label: 'mm-dd-yyyy', value: 'MM-DD-YYYY', id: 'MM-DD-YYYY' },
  { label: 'dd-mm-yyyy', value: 'DD-MM-YYYY', id: 'DD-MM-YYYY' },
  { label: 'yyyy-mm-dd', value: 'YYYY-MM-DD', id: 'YYYY-MM-DD' },
  { label: 'yyyy-dd-mm', value: 'YYYY-DD-MM', id: 'YYYY-DD-MM' },
] as SelectOption[];

type PreferencesFormType = UserPreferences & { lang: string };

const UserPreferencesForm: FC = () => {
  const { languageOptions } = useContext(AppContext);
  const { user, userPreferences, updateUserPreferences, updateUser } =
    useContext(UserContext);

  const { showSnackBar } = useUtilities();
  const { t, i18n } = useTranslation();

  const {
    params: { language },
    setQueryParam,
  } = useQueryParams<{ language: string }>();

  const initialData = useMemo(
    () => ({
      ...userPreferences,
      lang: user?.lang || 'en',
      dateFormat: DATE_FORMAT_OPTIONS.find(
        (option) => option.value === userPreferences.dateFormat
      )?.value,
    }),
    [userPreferences, user]
  );

  const unitsButtons = useMemo<TabOption<string, INameExtended>[]>(
    () => [
      { label: t('km'), value: 'km', id: 'user_preferences_km' },
      { label: t('miles'), value: 'mi', id: 'user_preferences_miles' },
    ],
    [t]
  );

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: 'form',
    callback: async (preferences: PreferencesFormType) => {
      if (!user) return;

      const { unit, dateFormat, lang } = preferences;
      let languageUpdated = null;

      if (userPreferences.dateFormat !== dateFormat) {
        await updateUserPreferences('date_format', dateFormat);
      }

      if (userPreferences.unit !== unit) {
        await updateUserPreferences('units', unit);
      }

      if (user.lang !== lang) {
        await updateUser(user.id, { lang: lang }, 'PUT');

        user.lang = lang;

        if (language) {
          setQueryParam('language', lang);
        } else {
          i18n.changeLanguage(lang);
        }

        languageUpdated = lang;
      }

      if (language && language !== lang) {
        setQueryParam('language', lang);
        languageUpdated = lang;
      }

      if (languageUpdated) {
        await i18n.loadLanguages([languageUpdated]);
        i18n.changeLanguage(languageUpdated);
        document.documentElement.lang = getLanguageCode(languageUpdated);
      }

      showSnackBar({
        actionButtonText: t('dismiss'),
        text: t('user_preferences_successfully_updated'),
        variant: 'success',
      });
    },
  });

  return (
    <PageLayout className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('user_preferences')}
      </Heading>

      <Styled.SettingsForm>
        <Form
          initialData={initialData}
          onSubmit={handleSubmit}
          id="user_preferences_form"
        >
          <fieldset className="form__fields">
            <legend data-hidden hidden>
              {t('user_preferences')}
            </legend>
            <FormField
              id="user_preferences_unit"
              name="unit"
              component={Tabs}
              label={t('units')}
              tabs={unitsButtons}
            />
            <FormField
              id="user_preferences_date-foramt"
              name="dateFormat"
              renderAsPortal
              component={SelectField}
              placeholder={t('pick_format')}
              options={DATE_FORMAT_OPTIONS}
            />

            <FormField
              id="user_preferences_lang"
              name="lang"
              renderAsPortal
              component={SelectField}
              placeholder={t('language')}
              options={languageOptions}
            />
          </fieldset>
          <FormFooter />
        </Form>
      </Styled.SettingsForm>
    </PageLayout>
  );
};
export default UserPreferencesForm;
