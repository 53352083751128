import styled, { css } from 'styled-components';
import { flex, mobile, phablet, size } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const ActionsContainer = styled.div`
  ${flex('row', 'flex-start', 'center')};
  ${size('100%', theme.sizes.SIZE_48)}

  .user-actions-wrapper {
    &__approve-user,
    &__reject-user,
    &__deactivate-user,
    &__view-user-details {
      display: inline-flex;
      margin-right: ${theme.sizes.SIZE_40};
    }

    &__reject-user {
      color: var(--ALERT_ERROR_1_1);
      &:hover {
        color: var(--ALERT_ERROR_1_2);
      }
    }
  }
`;

export const HeaderBar = styled.div`
  ${flex('column', 'center', 'stretch')};

  .people-header-bar {
    &__bottom {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_16};

      &__col-settings {
        margin-left: auto;
        color: var(--SHADE_1_2);

        &:hover {
          opacity: 0.9;
          color: var(--SHADE_1_3);
        }
      }

      &__input {
        max-width: ${theme.sizes.SIZE_256};

        ${phablet(css`
          margin-left: auto;
        `)};

        ${mobile(css`
          margin-left: unset;
        `)};

        button {
          height: fit-content;
          min-height: unset;
        }
      }

      ${phablet(css`
        display: grid;
        grid-template-columns: 2fr 1fr;
        row-gap: ${theme.sizes.SIZE_20};

        ${phablet(css`
          grid-template-columns: 1fr 1fr;
        `)};

        ${mobile(css`
          grid-template-columns: 1fr;
        `)};

        &__col-settings {
          margin-left: unset;
          grid-column: span 2;

          ${phablet(css`
            margin-left: auto;
            grid-column: unset;
          `)};
        }
      `)}
    }
  }
`;
