import { theme } from '@faxi/web-component-library';
import { flex, flexGap, position, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const DropzoneContainer = styled.div`
  width: fit-content;

  .kinto-image-dropzone {
    &__drop-trigger {
      ${flex('row', 'flex-start', 'center')};
      ${flexGap(theme.sizes.SIZE_32, 'row')};

      width: 100%;
    }

    &__placeholder {
      ${flex('row', 'center', 'center')};
      ${size(theme.sizes.SIZE_136)};

      position: relative;
      border-radius: ${theme.sizes.SIZE_24};
    }

    &__preview-actions {
      ${flex('row')};
      ${flexGap(theme.sizes.SIZE_56, 'row')};

      margin-top: ${theme.sizes.SIZE_64};
    }

    &__wrapper-img {
      ${size(theme.sizes.SIZE_136)};

      cursor: pointer;
      position: relative;
    }

    &__edit-btn {
      ${position('absolute', 'bottom 0 right 0')};
      ${size(theme.sizes.SIZE_32)}
      z-index: 1;
      min-height: unset;
      border-radius: 50%;
      transform: translate3d(6px, 6px, 0);
      border: 2px solid var(--SHADE_1_6);
      background-color: var(--BACKGROUND_1_1);

      &.button--ghost--disabled {
        color: var(--SHADE_1_3);
      }
    }

    &__img {
      ${size('100%')};
      object-fit: cover;
      pointer-events: all;
      border-radius: ${theme.sizes.SIZE_20};
    }
  }
`;
