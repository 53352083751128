import { focusStyles, theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const RewardSettings = styled.div`
  .kinto-reward-settings {
    &__description {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: ${theme.sizes.SIZE_20};

      .textarea__container {
        width: 100%;
      }
    }

    &__details,
    &__instances {
      ${flexGap(theme.sizes.SIZE_12, 'column')};

      margin-top: ${theme.sizes.SIZE_24};
    }

    &__tooltip {
      ${focusStyles};
    }

    &__tooltip-title {
      ${flex('row', 'flex-start', 'center')};
      ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};

      font-weight: 400;
      gap: ${theme.sizes.SIZE_8};
      margin: 0 0 ${theme.sizes.SIZE_16};
      color: var(--SECONDARY_1_1);
    }

    &__voucher-reward {
      ${flex('row', 'space-between', 'center')};

      background-color: var(--PRIMARY_1_7);
      padding: ${theme.sizes.SIZE_12};
      font-weight: 600;
      color: var(--SHADE_1_1);
      border-radius: ${theme.sizes.SIZE_8};
    }
  }
`;
