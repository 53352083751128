import { FC, Fragment, PropsWithChildren, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { UserContext } from '../store';
import { BlockUI } from 'helpers';

const AdminPermissions: FC<PropsWithChildren> = () => {
  const { userReady, communityId, adminForCommunity } = useContext(UserContext);

  const fallbackUrl = `/community/${communityId}`;

  //This will check admin permission in case of async update
  //of role in local storage (e.g. SuperAdmin updates user role
  //and user tries to access admin pages)
  if (userReady && !adminForCommunity) {
    return <Navigate to={fallbackUrl} replace />;
  }

  return (
    <BlockUI condition={!userReady} fallback={<Fragment />}>
      <Outlet />
    </BlockUI>
  );
};

export default AdminPermissions;
