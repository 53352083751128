import { useContext } from 'react';

import { apiAuth } from 'modules';
import { AppContext, AuthContext } from 'store';
import { SocialButton } from 'components';
import { APPLE_SCRIPT_SRC } from './utils';
import { useCallbackAsync, useScript } from 'hooks';
import useAppleLogin, { AppleAuthResponse } from './hooks/useAppleLogin';
import env from 'env';

const Apple = (props: { signup?: boolean; disabled?: boolean }) => {
  const { signup, disabled } = props;

  const { applyCredentials } = useContext(AuthContext);
  const { scriptLoaded } = useScript(APPLE_SCRIPT_SRC);

  const { platform } = useContext(AppContext);

  const [successAppleAuth] = useCallbackAsync({
    showSpinner: true,
    catchAsyncErrors: false,
    callback: async (response: AppleAuthResponse) => {
      const { authorization, user } = response;
      if (!authorization) return;

      if (signup) {
        const authData = await apiAuth.socialRegister(
          'apple',
          authorization.id_token,
          user?.name?.firstName || '',
          user?.name?.lastName || '',
          `${platform?.id}`
        );

        applyCredentials(authData);
      } else {
        const authData = await apiAuth.socialLogin(
          'apple',
          authorization.id_token
        );

        applyCredentials(authData);
      }
    },
  });

  const { authenticate } = useAppleLogin({
    onSuccess: successAppleAuth,
    scriptLoaded,
    authOptions: {
      clientId: env.VITE_APP_APPLE_CLIENT_ID,
      redirectURI: `${window.location.origin}/login`,
      usePopup: true,
      scope: 'name email',
    },
  });

  return (
    <SocialButton provider="apple" onClick={authenticate} disabled={disabled} />
  );
};

export default Apple;
