import { FC, PropsWithChildren } from 'react';
import GoogleContext from './Google.context';
import { useScript } from 'hooks';

type GoogleProviderProps = PropsWithChildren<{
  clientId: string;
}>;

const GoogleProvider: FC<GoogleProviderProps> = (props) => {
  const { clientId, children } = props;
  const { scriptLoaded } = useScript('https://accounts.google.com/gsi/client');

  return (
    <GoogleContext.Provider value={{ clientId, scriptLoaded }}>
      {children}
    </GoogleContext.Provider>
  );
};

export default GoogleProvider;
