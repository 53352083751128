import { focusStyles, theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize, size } from '@faxi/web-css-utilities';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SurveyCard = styled(Link)`
  ${flex('column')};
  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_6);
  background-color: var(--BACKGROUND_1_1);
  ${focusStyles};
  text-decoration: none;

  .separated-text {
    margin: 0 0 ${theme.sizes.SIZE_4};
  }

  &:hover {
    border-color: var(--PRIMARY_1_1);
  }

  .kinto-survey-card {
    &__title {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_20)};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 600;
      color: var(--SHADE_1_1);
      margin: 0 0 ${theme.sizes.SIZE_20};
    }

    &__status {
      ${flex('row', 'space-between', 'center')};
      margin-top: ${theme.sizes.SIZE_12};

      &__element {
        ${size('auto', theme.sizes.SIZE_32)};
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

        > div {
          ${flex('row')};
          ${flexGap(theme.sizes.SIZE_10, 'row')};
        }
      }
    }

    &__arrow {
      ${flex('row', 'center', 'center')};
      ${size(theme.sizes.SIZE_32)};
      color: var(--PRIMARY_1_1);
    }
  }
`;
