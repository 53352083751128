import { Modal as ModalComponent, theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .wcl-modal {
    background-color: var(--BACKGROUND_2_1);

    .wcl-modal__main {
      gap: ${theme.sizes.SIZE_16};
    }
  }

  .campaign-type-modal {
    &__subtitle {
      color: var(--SECONDARY_1_1);
      font-size: ${theme.fontSizes.FONT_18};
    }
  }
`;
