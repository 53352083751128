import {
  FC,
  MutableRefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import {
  ModalProps,
  ModalRef,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Form, FormField, FormRef, validators } from '@faxi/web-form';

import { UserContext } from 'store';
import { useCallbackAsync } from 'hooks';
import { apiPredefinedAddresses } from 'modules/api';
import { AddressPlace, PredefinedAddress } from 'models';
import { FormFooter, GoogleAutocompleteField, InputField } from 'components';
import specific from 'validation/validators/specific';

import * as Styled from './PredefinedAddressModal.styles';

type PredefinedAddressFormType = {
  id: number;
  name: string;
  address: AddressPlace;
};

type PredefinedAddressModalProps = ModalProps & {
  addresses: PredefinedAddress[];
  initialData: MutableRefObject<PredefinedAddress | undefined>;
  onDelete?: (id: number) => void;
  onSave?: (address: PredefinedAddress) => void;
};

const PredefinedAddressModal: FC<PredefinedAddressModalProps> = (props) => {
  const { addresses, initialData, onSave, onDelete, onClose, ...rest } = props;

  const {
    communityId,
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  const { prompts, showSnackBar } = useUtilities();

  const modalRef = useRef<ModalRef>(null);
  const [form, formRef] = useCallbackRef<FormRef>();

  const { t } = useTranslation();

  const initialFormValues = useMemo(() => {
    if (initialData?.current) {
      return {
        name: initialData.current.name,
        address: {
          formatted_address: initialData?.current.address,
          lat: initialData?.current.lat,
          lng: initialData?.current.lng,
        },
      };
    }
  }, [initialData]);

  const isEditing = !!initialData.current;

  const [handleSubmitAddress, loading] = useCallbackAsync({
    showSpinner: false,
    condition: async (data: PredefinedAddressFormType) => {
      if (isEditing) return true;
      const valid = await handleValidateAddressName(data.name);

      if (!valid) {
        form.setFieldError(
          'name',
          t('validation-name_already_in_use', {
            fieldname: t(
              'predefined-addresses-placeholder_predefined_address_name'
            ),
          })
        );
      }

      return valid;
    },
    callback: async (data: PredefinedAddressFormType) => {
      const { name, address } = data;

      const params = new FormData();
      params.append('name', name);

      params.append('address', address.formatted_address || '');
      params.append('lat', `${address.lat}`);
      params.append('lng', `${address.lng}`);
      params.append('country', address.country || '');
      params.append(
        'city',
        address.locality || address.administrative_area_level_1 || ''
      );
      params.append('postcode', address.postal_code || '');

      //update predefined addresss
      if (initialData.current?.id) {
        await apiPredefinedAddresses.updatePlace(
          communityId!,
          initialData.current.id,
          params
        );

        onSave?.({
          name,
          id: initialData.current?.id,
          ts: initialData.current?.ts,
          lat: params.get('lat') as string,
          lng: params.get('lng') as string,
          address: params.get('address') as string,
        });
      } //create new one
      else {
        const { id } = await apiPredefinedAddresses.createPlace(
          communityId!,
          params
        );

        if (id)
          onSave?.({
            id,
            name: name,
            address: params.get('address') as string,
            lat: params.get('lat') as string,
            lng: params.get('lng') as string,
            ts: dayjs().format(dateFormat),
          });

        showSnackBar({
          actionButtonText: t('dismiss'),
          text: `${t('predefined-addresses-toast_predefined_address_added')}`,
          variant: 'success',
        });
      }

      onClose?.();
    },
  });

  const validations = useMemo(
    () => ({
      addressName: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t(
              'predefined-addresses-placeholder_predefined_address_name'
            ),
          })
        ),
        validators.general.maxLength(
          30,
          t('validation-field_validation_max_length', {
            fieldname: t(
              'predefined-addresses-placeholder_predefined_address_name'
            ).toLowerCase(),
            number: 30,
          })
        ),
      ],
      address: [
        specific.googleAutocompleteAddress(
          t('validation-field_is_required', {
            fieldname: t('address_header'),
          })
        ),
      ],
    }),
    [t]
  );

  const modalForm = useCallback(
    ({ children, className }: any) => (
      <Form
        onSubmit={handleSubmitAddress}
        ref={formRef}
        children={children}
        className={className}
        initialData={initialFormValues}
      />
    ),
    [formRef, handleSubmitAddress, initialFormValues]
  );

  const [handleValidateAddressName, loadingValidate] = useCallbackAsync({
    showSpinner: false,
    callback: async (name: string) => {
      if (!communityId) return false;

      const { data } = await apiPredefinedAddresses.checkPlaceName(
        communityId,
        name
      );

      return !data.name_taken;
    },
  });

  const [handleDeleteAddress, loadingDelete] = useCallbackAsync({
    showSpinner: false,
    condition: async (event) =>
      await prompts.deactivate({
        title: t('predefined-addresses-modal_delete_body', {
          address: initialData.current?.address,
        }),
        triggerRef: event.target,
        submitBtnText: t('Discovery_map_delete'),
        cancelBtnText: t('cancel'),
        className: 'predefined-address-modal__delete-prompt',
      }),
    callback: async () => {
      await apiPredefinedAddresses.deletePlace(
        communityId!,
        initialData.current?.id!
      );

      onDelete?.(initialData.current?.id!);
      onClose?.();

      showSnackBar({
        actionButtonText: t('dismiss'),
        text: `${t('predefined-addresses-toast_predefined_address_deleted')}`,
        variant: 'success',
      });
    },
  });

  return (
    <Styled.AddressModal
      {...rest}
      title={
        initialData.current?.address
          ? t('predefined-addresses-modal_title_edit_predefined_address')
          : t('predefined-addresses-button_add_an_address')
      }
      ref={modalRef}
      loading={loading || loadingDelete || loadingValidate}
      modalClassName="predefined-address-modal"
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: initialData.current?.address
          ? t('predefined-addresses-modal_title_edit_predefined_address')
          : t('predefined-addresses-button_add_an_address'),
      })}
      onClose={onClose}
      childrenWrapper={modalForm}
      footer={
        <FormFooter
          className={classNames('form-actions', {
            'form-actions--non-edit': !initialData.current,
          })}
          modalRef={modalRef}
          cancelLabel={t('cancel')}
          submitLabel={t(
            initialData.current ? 'CommuteDetails_saving_message' : 'add'
          )}
          {...(initialFormValues && { onDelete: handleDeleteAddress })}
        />
      }
    >
      <fieldset>
        <legend data-hidden hidden>
          {initialData.current?.address
            ? t('predefined-addresses-modal_title_edit_predefined_address')
            : t('predefined-addresses-button_add_an_address')}
        </legend>
        <FormField
          name="name"
          component={InputField}
          autoComplete="off"
          className="predefined-address-modal__address-name"
          placeholder={t(
            'predefined-addresses-placeholder_predefined_address_name'
          )}
          required
          requiredLabel={t('global-input_field_required_label')}
          validate={validations.addressName}
        />

        <FormField
          name="address"
          autoComplete="off"
          component={GoogleAutocompleteField}
          placeholder={t('address_header')}
          required
          requiredLabel={t('global-input_field_required_label')}
          validate={validations.address}
        />
      </fieldset>
    </Styled.AddressModal>
  );
};

export default PredefinedAddressModal;
