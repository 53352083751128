import { MouseEvent } from 'react';
import { Button, Heading } from '@faxi/web-component-library';
import { Icon } from 'components';
import { INameExtended } from 'components/Icon';
import { SettingsTabEmptyScreenContainer } from './SettingsTabEmptyScreen.styles';

type Props = {
  title: string;
  contentText: string;
  buttonLabel: string;
  iconName: INameExtended;
  buttonCallback: (event?: MouseEvent<HTMLButtonElement>) => void;
};

const SettingsTabEmptyScreen = (props: Props): JSX.Element => {
  const { iconName, title, contentText, buttonLabel, buttonCallback } = props;

  return (
    <SettingsTabEmptyScreenContainer className="settings-tab-empty-screen">
      <Icon name={iconName} className="settings-tab-empty-screen__icon" />
      <Heading level="1" className="settings-tab-empty-screen__title">
        {title}
      </Heading>
      <div className="settings-tab-empty-screen__content-text">
        {contentText}
      </div>
      <Button
        className="primary settings-tab-empty-screen__button"
        onClick={buttonCallback}
        icon={<Icon name="plus" />}
      >
        {buttonLabel}
      </Button>
    </SettingsTabEmptyScreenContainer>
  );
};

export default SettingsTabEmptyScreen;
