import { FC, memo } from 'react';
import classNames from 'classnames';
import { ButtonProps } from '@faxi/web-component-library';

import Icon, { INameExtended } from 'components/Icon';

import * as Styled from './PickShiftButton.styles';

export type IconTitleProps = {
  title: string;
  icon: INameExtended;
};

export const IconTitle = memo((props: IconTitleProps) => {
  const { title, icon } = props;

  return (
    <Styled.IconTitle>
      <Icon name={icon} />
      <p className="icon-title">{title}</p>
    </Styled.IconTitle>
  );
});

type PickShiftButtonProps = ButtonProps & {
  active?: boolean;
  shiftTitles: [IconTitleProps, IconTitleProps];
};

export const PickShiftButton: FC<PickShiftButtonProps> = (props) => {
  const {
    active,
    shiftTitles: [leftTitle, rightTitle],
    className,
    ...rest
  } = props;

  return (
    <Styled.PickShiftButton
      variant="outline"
      className={classNames('pick-shift-button', className, {
        'pick-shift-button--active': active,
      })}
      {...rest}
    >
      <IconTitle title={leftTitle.title} icon={leftTitle.icon} />
      <IconTitle title={rightTitle.title} icon={rightTitle.icon} />
    </Styled.PickShiftButton>
  );
};

export default memo(PickShiftButton);
