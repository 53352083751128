import { FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import {
  useEffectOnceWhen,
  useEffectSkipFirst,
} from '@faxi/web-component-library';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UserContext } from 'store';
import storageService, { STORAGE_KEYS } from 'services/storageService';

const CommunityHandler: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { organisationId } = useParams() as { organisationId: string };
  const { communities, setCommunityId } = useContext(UserContext);

  const fallbackCommunityId = useMemo(
    () =>
      storageService.getItem<string>(STORAGE_KEYS.COMMUNITY_ID) ||
      `${communities[0]?.id}`,
    [communities]
  );

  const isValidCommunity = useCallback(
    (cid?: string) => cid && !!communities.find((comm) => `${comm.id}` === cid),
    [communities]
  );

  const saveCommunityData = useCallback(
    (cid: number, shouldNavigate = true) => {
      if (!cid) return;

      setCommunityId(cid);
      storageService.setItem(STORAGE_KEYS.COMMUNITY_ID, `${cid}`);
      if (shouldNavigate) navigate(`community/${cid}`);
    },
    [setCommunityId, navigate]
  );

  useEffectOnceWhen(() => {
    //If not valid community or there are no oid then
    //set cached community or first valid community id

    if (!organisationId || !isValidCommunity(organisationId)) {
      saveCommunityData(
        +fallbackCommunityId,
        !location.pathname.includes('account-settings')
      );
      return;
    }

    // saveCommunityData(+organisationId);
  }, communities.length > 0);

  useEffectSkipFirst(() => {
    if (!organisationId) return;

    saveCommunityData(+organisationId!, false);
  }, [organisationId, saveCommunityData]);

  return <>{children}</>;
};

export default CommunityHandler;
