import {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Heading,
  Image,
  Tab,
  TabNavigation,
  getColor,
  useResize,
} from '@faxi/web-component-library';
import { responsiveSm } from '@faxi/web-css-utilities';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import classNames from 'classnames';

import { UserContext, MessagesContext } from 'store';
import {
  CommunityElement,
  Icon,
  InputField,
  SendMessageModal,
} from 'components';
import { useHeadTitle, useInfinitePagination } from 'hooks';

import { apiMessages, authBus } from 'modules';
import { Conversation, Message } from 'models';

import * as Styled from './Messages.styles';
import { NoData, SearchableUsersSection } from 'Global.styles';

dayjs.extend(utc);
dayjs.extend(timezone);

const CONVERSATIONS_PER_PAGE = 20;

const Messages: FC = (): JSX.Element => {
  const location = useLocation();

  const isBelowTablet = useResize(responsiveSm, true);
  const activeUserId = useRef<any>();

  const { t } = useTranslation();
  useHeadTitle(t('mMessages'));

  const { receiver, setGroupMessage, markMessageAsRead, setReceiver } =
    useContext(MessagesContext);
  const { user, userReady, communityId, community, includeTestUsers } =
    useContext(UserContext);

  const [showWholeCommunityMessageModal, setShowWholeCommunityMessageModal] =
    useState(false);

  const usersWithNotification = useRef<number[]>([]);
  const communityMessageBtnRef = useRef<HTMLButtonElement>(null);

  const cutUserIdFromUrl = useMemo(() => {
    const urlEnd = location.pathname.split('/').slice(-1)[0];
    return !isNaN(Number.parseInt(urlEnd)) ? urlEnd : null;
  }, [location.pathname]);

  const {
    items,
    loading,
    search,
    reset,
    updateItem,
    onSearchChange,
    handleContainerScroll,
    addItemRemoveOld,
  } = useInfinitePagination<Conversation, Tab<string, ReactNode>>({
    perPage: CONVERSATIONS_PER_PAGE,
    resetDeps: [communityId],
    condition: userReady && !!user && !!communityId,
    spinnerParent: '.kinto-searchable-users-section',
    mappingFunction: async (items) =>
      items.map(
        ({
          message_unread_count,
          user_id,
          user_firstname,
          user_lastname,
          user_email,
          image_url,
        }: Conversation) => {
          if (message_unread_count) {
            usersWithNotification.current.push(user_id);
          }

          return {
            path: `/community/${communityId}/admin/messages/${user_id}`,
            id: `message_recipient_${user_id}`,
            prefixElement: (
              <div className="conversation-item">
                <Image
                  src={image_url || ''}
                  alt={t('user_profile_picture', { user: user_firstname })}
                  className="profile-img conversation-item__user-image"
                  fallbackUrl="/assets/svg/user_circle_placeholder.svg"
                />
                {message_unread_count > 0 && (
                  <div className="conversation-item__unread-count">
                    {message_unread_count}
                  </div>
                )}
              </div>
            ),
            title:
              [user_firstname, user_lastname].join(' ').trim() ||
              user_email ||
              '-',
            image_url,
          } as Tab<string, ReactNode> & { image_url: string };
        }
      ),
    apiRequest: async (offset: string, search: string) => {
      const { conversations, total_count } = await apiMessages.getConversations(
        {
          uid: `${user?.id}`,
          oid: communityId!,
          search,
          offset: +offset,
          count: CONVERSATIONS_PER_PAGE,
        }
      );

      return {
        data: conversations,
        total: total_count,
      };
    },
  });

  const currentTab = useMemo(() => {
    if (!cutUserIdFromUrl) return;

    return (
      items.find((tab) => tab.path === cutUserIdFromUrl) ||
      (receiver &&
        receiver.id !== '' && { path: receiver.id, title: receiver.name })
    );
  }, [cutUserIdFromUrl, receiver, items]);

  const usersExist = useMemo(
    () =>
      community
        ? Number(community.approved_users) +
            (includeTestUsers ? Number(community['approved_users-test']) : 0) >
          1
        : false,
    [community, includeTestUsers]
  );

  const handleMarkMessageAsRead = useCallback(async () => {
    if (!user || !receiver) return;

    const receiverHasUnreadMessages = usersWithNotification.current.find(
      (u) => `message_recipient_${u}` === receiver.id
    );

    if (receiverHasUnreadMessages) {
      await markMessageAsRead(receiver.id.split('_').at(-1)!, user.id);
      usersWithNotification.current = usersWithNotification.current.filter(
        (u) => `message_recipient_${u}` !== receiver.id
      );

      const item = items.find(({ id }) => id === receiver.id);

      updateItem({
        ...item,
        prefixElement: (
          <div className="conversation-item">
            <Image
              src={(item as any).prefixElement.props.children[0].props.src}
              alt=""
              className="profile-img conversation-item__user-image"
              fallbackUrl="/assets/svg/user_circle_placeholder.svg"
            />
          </div>
        ),
      } as any);
    }
  }, [items, markMessageAsRead, receiver, updateItem, user]);

  const bumpConversationToTop = useCallback(
    (message: Message) => {
      const to = message.to[0].id;

      const item = items.find((i) => i.id === to);

      if (item && item.id !== items[0].id) {
        addItemRemoveOld(item, 'start');
      }
    },
    [addItemRemoveOld, items]
  );

  // MARK AS READ OF CURRENTLY OPEN MESSAGES WITH A USER
  useEffect(() => {
    if (!user || !receiver) return;

    handleMarkMessageAsRead();
  }, [handleMarkMessageAsRead, receiver, user]);

  useEffect(() => {
    if (!receiver && cutUserIdFromUrl && items.length > 0) {
      const { id, title, image_url } = items.find(
        (el) => el.id?.split('_').at(-1) === cutUserIdFromUrl
      ) as any;

      setReceiver({ id, name: title, image_url });
    }
  }, [cutUserIdFromUrl, items, receiver, setReceiver]);

  useEffect(() => {
    authBus.addEventListener('message_sent', bumpConversationToTop);

    return () => {
      authBus.removeEventListener('message_sent', bumpConversationToTop);
    };
  }, [bumpConversationToTop]);

  return (
    <Fragment>
      <Styled.MessagesStyled
        className={classNames('messages', {
          'on-tab': currentTab !== undefined,
        })}
      >
        <SearchableUsersSection className="kinto-searchable-users-section">
          <div className="kinto-searchable-users-section__top">
            <CommunityElement />

            <Heading level="1" color={getColor('--PRIMARY_1_1')}>
              {t('mMessages')}
            </Heading>
          </div>

          <InputField
            className="kinto-searchable-users-section__search"
            prefixIcon={<Icon name="magnifying-glass" />}
            placeholder={t('search_for_people_or_start_new_chat')}
            value={search}
            onChange={onSearchChange}
            {...(search && {
              suffixIcon: (
                <Button
                  variant="ghost"
                  aria-label={t('delete_input')}
                  icon={<Icon name="xmark" />}
                  onClick={() => {
                    onSearchChange('');
                  }}
                />
              ),
            })}
          />

          <div
            className={classNames(
              'kinto-searchable-users-section__send-to-all',
              {
                'kinto-searchable-users-section__send-to-all--disabled':
                  !usersExist,
              }
            )}
          >
            <Button
              ref={communityMessageBtnRef}
              className="kinto-searchable-users-section__send-to-all__icon-container"
              onClick={() => setShowWholeCommunityMessageModal(true)}
              id="send_whole_community_message"
              aria-label={t('send_message_all_community_members')}
              variant="primary-invert"
              icon={<Icon name="pen" />}
            />
            {t('send_message_all_community_members')}
          </div>
          <Fragment>
            {search && items.length === 0 && (
              <NoData className="kinto-no-data">
                {t('search_no_results_found')}
              </NoData>
            )}
            {items.length > 0 && (
              <TabNavigation<string>
                className="kinto-searchable-users-section__navigation"
                t={t}
                tabs={items}
                renderAs="div"
                onTabClick={(index) => {
                  const { id, title, image_url } = items[index] as Tab<
                    string,
                    ReactNode
                  > & {
                    image_url: string;
                  };
                  activeUserId.current = id;

                  setReceiver({ id: id!, name: title, image_url });
                }}
                // TODO: check props...
                paginationProps={{
                  hasMore: true,
                  loading,
                  paginationCallback: handleContainerScroll,
                }}
              />
            )}
          </Fragment>
        </SearchableUsersSection>
        <section className="kinto-tabs__panel">
          <div className="kinto-tabs__panel__navigation">
            <NavLink
              to="/"
              className="back"
              onClick={() => {
                if (!isBelowTablet) return;

                const prevActiveTab = document.querySelector(
                  `#${activeUserId.current}`
                );

                if (prevActiveTab) {
                  setTimeout(() => {
                    (prevActiveTab as HTMLElement).focus();
                  }, 0);
                }

                reset();
              }}
            >
              <Icon
                name="light-chevron-left"
                color={getColor('--PRIMARY_1_1')}
              />
              <span>{t('conversations')}</span>
            </NavLink>
          </div>

          <Outlet />
        </section>
      </Styled.MessagesStyled>

      {showWholeCommunityMessageModal && user && communityId && (
        <SendMessageModal
          triggerRef={communityMessageBtnRef.current as HTMLElement}
          title={t('this_message_sent_to_all_community_members')}
          textareaPlaceholder={t('write_message_to_community_here')}
          organisationId={communityId}
          onMessageSent={(message?: string, messageId?: number) => {
            if (message && messageId && receiver) {
              setGroupMessage({
                id: messageId as any,
                from: user,
                text: message,
                to: [receiver],
                ts: dayjs().utc().format('YYYY-MM-DD HH:mm:ss'),
                failed: false,
              });
            }
            reset();
          }}
          onClose={() => setShowWholeCommunityMessageModal(false)}
        />
      )}
    </Fragment>
  );
};

export default Messages;
