import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';
import { Button, ButtonProps } from '@faxi/web-component-library';
import classNames from 'classnames';

import Icon, { INameExtended } from 'components/Icon';

import * as Styles from './EmptyTab.styles';

type EmptyTabProps = {
  className?: string;
  title: ReactNode;
  icon: INameExtended;
  description: string;
  actionTitle: string;
  buttonProps?: Partial<ButtonProps>;
  onAction?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const EmptyTab: ForwardRefRenderFunction<HTMLButtonElement, EmptyTabProps> = (
  props,
  ref
) => {
  const {
    className,
    icon,
    title,
    description,
    actionTitle,
    buttonProps,
    onAction,
  } = props;

  return (
    <Styles.EmptyTab className={classNames('empty-tab', className)}>
      <Icon name={icon} className="empty-tab__icon" />

      <div className="empty-tab__title">{title}</div>
      <div className="empty-tab__description">{description}</div>

      <Button
        ref={ref}
        className="empty-tab__button"
        icon={<Icon name="plus" />}
        iconPosition="left"
        variant="primary"
        {...buttonProps}
        onClick={onAction}
      >
        {actionTitle}
      </Button>
    </Styles.EmptyTab>
  );
};

export default forwardRef(EmptyTab);
