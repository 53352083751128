import { useMemo, useCallback, useContext, FC, useState } from 'react';
import {
  Form,
  FormField,
  validators,
  DataState,
  FormRef,
  validationRegexes,
} from '@faxi/web-form';
import {
  Heading,
  getColor,
  useCallbackRef,
  useFormButtons,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import {
  InputField,
  FormActions,
  PasswordField,
  Icon,
  EmailRequestSent,
} from 'components';
import { UserContext } from 'store';
import { PageLayout } from 'components/_layouts';
import { apiUser } from 'modules';
import { User } from 'models';

import * as Styled from 'components/_layouts/Containers';

const ChangeEmailForm: FC = (): JSX.Element => {
  const { user, setUser } = useContext(UserContext);

  const { t } = useTranslation();

  const [emailSend, setEmailSend] = useState(false);

  const [FormButtons, setLoading] = useFormButtons({
    submitLabel: t('Save'),
    cancelLabel: t('cancel'),
    loadingOverlaySelector: '.kinto-page',
  });

  const [form, formRef] = useCallbackRef<FormRef>();

  const handleSubmit = useCallback(
    async (formValues: DataState) => {
      if (!user) {
        return;
      }

      const { email, password } = formValues;

      const data = {
        email,
        password,
      };

      try {
        setLoading(true);

        const { data: responseData } = await apiUser.changeEmail(user.id, data);

        if (responseData.rc === 'ok') {
          setEmailSend(true);
          setUser((old) => ({ ...old, registeredEmail: email } as User));
        } else if (responseData.errc === 9023) {
          form.setFieldError('email', t('verify_your_email'));
        } else if (responseData.errc === 1016) {
          form.setFieldError('password', t('error_1016'));
        }
      } catch (err) {
        if (err === 1033) {
          /** email already exists */
          form.setFieldError('email', t('email_address_taken'));
        }
      } finally {
        setLoading(false);
      }
    },
    [user, setLoading, t, setUser, form]
  );

  const validations = useMemo(
    () => ({
      email: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_email_hint'),
          })
        ),
        validators.general.regex(
          validationRegexes.workEmail,
          t('validation-field_valid_email', {
            fieldname: t('register_email_hint').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          80,
          t('validation-field_validation_max_length', {
            fieldname: t('register_email_hint').toLowerCase(),
            number: 80,
          })
        ),
        (value: string) => {
          if (value === user?.email) {
            return t('validation-current_email_address_can_t_use');
          }
        },
      ],
      password: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_password_hint'),
          })
        ),
      ],
    }),
    [t, user?.email]
  );

  return (
    <PageLayout className="kinto-page">
      {!emailSend ? (
        <>
          <Heading
            level="1"
            color={getColor('--PRIMARY_1_1')}
            className="kinto-page__heading"
          >
            {t('account-personal_details_change_email')}
          </Heading>

          <Styled.SettingsForm>
            <Form
              id="contact_info_form"
              ref={formRef}
              className="contact-details-form"
              onSubmit={handleSubmit}
            >
              <fieldset className="form__fields">
                <legend data-hidden hidden>
                  {t('account-personal_details_change_email')}
                </legend>

                <p className="contact-details-form__desc">
                  {t('change_email-info_text_confirm_your_address')}
                </p>

                <div className="contact-details-form__curr-email">
                  <div>{t('account-personal_details_current_email')}</div>
                  <div>{user?.registeredEmail || user?.email}</div>
                </div>

                <FormField
                  id="person_work_email"
                  name="email"
                  component={InputField}
                  autoComplete="off"
                  placeholder={t(
                    'account-personal_details_change_email_new_email'
                  )}
                  validate={validations.email}
                  required
                />

                <FormField
                  className="form__fields__field"
                  component={PasswordField}
                  prefixIcon={<Icon name="lock-keyhole" />}
                  name="password"
                  id="login_password"
                  placeholder={t('current_password')}
                  validate={validations.password}
                  required
                  requiredLabel={t('global-input_field_required_label')}
                  autoComplete="new-password"
                />
              </fieldset>

              <FormActions className="form__actions">
                <FormButtons.Submit
                  id="submit_contact_info"
                  disabled={!form?.syncFormValid || !form?.asyncFormValid}
                />
              </FormActions>
            </Form>
          </Styled.SettingsForm>
        </>
      ) : (
        <EmailRequestSent />
      )}
    </PageLayout>
  );
};

export default ChangeEmailForm;
