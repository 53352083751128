import styled, { css } from 'styled-components';
import {
  marginChildren,
  flex,
  phablet,
  pxToRem,
} from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

const StoreButtonsStyled = styled.div`
  ${flex('row', 'flex-start', 'center')};
  ${marginChildren(`0 ${theme.sizes.SIZE_48} 0 0`)};

  ${phablet(css`
    ${flex('column', 'center', 'flex-start')};
    ${marginChildren(`0 0 ${theme.sizes.SIZE_32} 0`)};
  `)};

  .store-buttons {
    &__badge {
      &__image {
        height: ${pxToRem(theme.sizes.SIZE_40)};
      }
    }
  }
`;

export default StoreButtonsStyled;
