import { createContext, Dispatch, SetStateAction } from 'react';
import { PeoplePageUser } from 'models';

interface UserProfileContext {
  userProfile: PeoplePageUser;
  setUserProfile: Dispatch<SetStateAction<PeoplePageUser>>;
  loadingUserProfile: boolean;
}

export const userProfileDefaultValue: UserProfileContext = {
  userProfile: {
    id: 0,
    first_name: '',
    last_name: '',
    name: '',
    image_url: '',
    email: '',
    lastactive_ts: '',
    messages_count: 0,
    joined_ts: '',
    shifts: [],
    status: '',
    profilepic_ts: 0,
    authorised: '' as PeoplePageUser['authorised'],
    is_admin: '' as PeoplePageUser['is_admin'],
    shiftLabel: '' as PeoplePageUser['shiftLabel'],
  },
  setUserProfile: () => {},
  loadingUserProfile: true,
};

const UserProfileContext = createContext<UserProfileContext>(
  userProfileDefaultValue
);

export default UserProfileContext;
