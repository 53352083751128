import { Modal, theme } from '@faxi/web-component-library';
import { flex, fontSize, phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const RewardDetailsModal = styled(Modal)`
  ${phablet(css`
    .wcl-modal {
      width: 100%;
    }
  `)}

  .kinto-reward-details-modal {
    &__title {
      ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};

      font-weight: 400;
      margin: 0 0 ${theme.sizes.SIZE_24};
      color: var(--SECONDARY_1_1);
    }

    &__instances {
      ${flex('column')};

      width: 100%;
      gap: ${theme.sizes.SIZE_8};
    }
  }

  .reward-instance {
    ${flex('row')}

    gap: ${theme.sizes.SIZE_8};
    color: var(--PRIMARY_1_1);
    padding-bottom: ${theme.sizes.SIZE_8};
    border-bottom: 2px solid var(--SHADE_1_7);

    > .title {
      ${flex('row')};
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

      margin: 0;
      font-weight: 400;
      color: var(--SHADE_1_1);

      > span {
        font-weight: 600;
        margin-left: ${theme.sizes.SIZE_8};
      }
    }
  }
`;
