import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
} from 'react';
import { CarParkProps } from 'pages/Settings/CarParkSettings/CarParkSettings.component';

type CarParkContext = {
  carParks?: CarParkProps[];
  lastEditCarParkId: MutableRefObject<string>;
  updateCarParkName: (data: FormData, carParkId: string) => void;
  savingField: boolean;
  editCarParkName: boolean;
  setEditCarParkName: Dispatch<SetStateAction<boolean>>;
};

export default createContext<CarParkContext>({
  updateCarParkName: () => {},
  lastEditCarParkId: {} as MutableRefObject<string>,
  savingField: false,
  editCarParkName: false,
  setEditCarParkName: () => {},
});
