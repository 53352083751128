import styled, { css } from 'styled-components';
import { theme } from '@faxi/web-component-library';
import {
  flex,
  position,
  size,
  phablet,
  pxToRem,
} from '@faxi/web-css-utilities';

export const IphoneFrameContainer = styled.div`
  position: relative;
  align-self: center;
  /* important for safari */
  height: ${pxToRem(theme.sizes.SIZE_224)};

  ${phablet(css`
    height: ${pxToRem(theme.sizes.SIZE_248)};
  `)}

  > .iphone-frame {
    ${position('absolute', 'top 0 right 50%')};

    &,
    > svg {
      ${size('auto', '100%')};
    }

    transform: translateX(50%);

    .input-preview {
      ${position('absolute', 'top 30% right 0 bottom auto left 0')};
      ${flex('column', 'flex-start')};
      padding: 5% 10%;
      min-height: 5%;
      max-height: 68%;
      overflow: auto;
      word-break: normal;
      overflow-wrap: anywhere;

      > div:first-child {
        margin-bottom: ${theme.sizes.SIZE_10};
        display: flex;
        font-size: 50%;
        flex: 0 0 35%;
        margin-right: 5%;
        color: var(--PRIMARY_1_1);

        > span {
          margin-left: ${theme.sizes.SIZE_2};
        }
      }

      .line {
        flex: 0 0 60%;
        border-bottom: ${theme.sizes.SIZE_1} solid var(--SHADE_1_5);
      }
    }
  }

  ${phablet(css`
    width: 100%;

    > .iphone-frame {
      height: ${pxToRem(theme.sizes.SIZE_248)};
    }
  `)}
`;
