import dayjs from 'dayjs';
import config from 'config';

//Function is created to get UTC offset without DTS (Daylight saving time)
//in order to send information to the server only in winter time mode
const getWinterUtcOffset = () => {
  const winterDate = new Date(Date.UTC(new Date().getFullYear(), 0, 1));
  const utcOffsetMinutes = winterDate.getTimezoneOffset(); // Get the UTC offset in minutes
  return utcOffsetMinutes / 60; // Convert to hours
};

const oppositeNumber = (value: number) =>
  value < 0 ? Math.abs(value) : value > 0 ? -value : 0;

const formattedTime = (start_time: string, end_time: string) => {
  return `${dayjs(start_time, 'hh-mm').format(config.timeFormat)}  -  ${dayjs(
    end_time,
    'hh-mm'
  ).format(config.timeFormat)}`;
};

type DateStringArgsType = Partial<{
  date: string;
  time: string;
  format: string;
  isUTC: boolean;
  winter: boolean;
}>;

const dateString = (args: DateStringArgsType) => {
  const {
    date,
    time,
    format = config.dateFormatSimple,
    isUTC = true,
    winter = true,
  } = args;

  return dayjs(
    `${date ? date : dayjs().format('YYYY-MM-DD')}T${time ? time : '00:00'}${
      isUTC ? 'Z' : ''
    }`
  )
    .utcOffset(winter ? oppositeNumber(getWinterUtcOffset()) : '')
    .format(format);
};

const dateStringLocale = (
  date?: string,
  time?: string,
  format: string = config.dateFormatSimple
) => dateString({ date, time, format, isUTC: false, winter: false });

const dateTimeString = (
  date?: string,
  time?: string,
  dateFormat?: string,
  timeFormat?: string,
  timeWrapper?: (value: string) => string
) => {
  return `${dateString({ date, time, format: dateFormat })} ${
    !time
      ? ''
      : timeWrapper
      ? timeWrapper?.(dateString({ date, time, format: timeFormat }))
      : dateString({ date, time, format: timeFormat })
  }`;
};

export {
  getWinterUtcOffset,
  dateString,
  dateStringLocale,
  dateTimeString,
  formattedTime,
};
