import { Expander, elevate_l, theme } from '@faxi/web-component-library';
import {
  flex,
  position,
  size,
  aboveTablet,
  phablet,
  fontSize,
  pxToRem,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const UserListExpander = styled(Expander)`
  background-color: var(--BACKGROUND_1_1);

  &[open] {
    ${aboveTablet(css`
      summary {
        display: none;
      }
    `)}
  }

  summary {
    ${fontSize(theme.fontSizes.FONT_14)};
    font-weight: 500;
    color: var(--SECONDARY_1_1);

    > div {
      ${flex('row', 'space-between', 'center')};
      padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_16};
    }

    ${phablet(css`
      + div {
        flex: 1 1 100%;
        overflow: auto;
        height: 100%;
      }
    `)}
  }
`;

export const ExpanderContainer = styled.div`
  flex: 1 1 100%;

  ${UserListExpander} {
    summary {
      + div {
        z-index: 1;

        ul {
          background-color: var(--BACKGROUND_1_1);
          z-index: 1;

          ${aboveTablet(css`
            ${position('absolute', '0')};
          `)}
        }

        ${phablet(css`
          ${position('absolute', `top ${pxToRem(theme.sizes.SIZE_128)}`)};

          &,
          ul {
            height: auto;
          }
        `)}
      }
    }
  }

  .expander {
    z-index: 2;
    position: absolute;
    height: fit-content;
    ${elevate_l};

    > main {
      width: 100%;
    }

    &__container {
      width: 100%;
    }

    &__trigger {
      width: 100%;
      margin: 0;
      padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_16};

      &__title {
        ${flex('row', 'space-between', 'center')};
        ${fontSize(theme.fontSizes.FONT_14)};
        font-weight: 500;
        color: var(--SECONDARY_1_1);
      }
    }
  }

  ${aboveTablet(css`
    position: relative;
  `)}
`;

export const UserList = styled.ul`
  ${position('absolute', '0')};
  ${size('100%')};
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: auto;

  ${phablet(css`
    position: initial;
  `)};

  .kinto-user-list {
    &__invite-button {
      margin: 0 ${theme.sizes.SIZE_20};
    }
  }

  > .kinto-no-data {
    margin-top: unset;
  }
`;
