import { forwardRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMapsProvider } from '@faxi/web-component-library';

import { useHeadTitle } from 'hooks';
import MapDataProvider from './providers/MapData';
import MapUserPins from './components/MapUserPins';
import MapUtilities from './components/MapUtilities';
import MarkerClusterProvider from './providers/MarkerCluster';
import MapUserList from './components/MapUserList';

import * as Styled from './Map.styles';

const MapCanvas = forwardRef<any, any>(({ children }, ref) => (
  <Styled.Map>
    <div className="map" ref={ref} />
    {children}
  </Styled.Map>
));

const Map = () => {
  const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null);

  const { t } = useTranslation();
  useHeadTitle(t('header_map'));

  const mapRef = useCallback((node: any) => {
    node && setMapContainer(node);
  }, []);

  return (
    <Styled.MapPageContainer className="kinto-map-page-container">
      <GoogleMapsProvider
        mapContainer={mapContainer}
        mapOptions={{ zoom: 17, disableDefaultUI: true }}
      >
        <MapDataProvider>
          <Styled.MapPage className="kinto-map-page">
            <MarkerClusterProvider>
              <MapUserList />
              <MapUserPins />
            </MarkerClusterProvider>
            <MapCanvas ref={mapRef}>
              <MapUtilities />
            </MapCanvas>
          </Styled.MapPage>
        </MapDataProvider>
      </GoogleMapsProvider>
    </Styled.MapPageContainer>
  );
};

export default Map;
