import { theme } from '@faxi/web-component-library';
import {
  flex,
  fontSize,
  phablet,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const ErrorContainer = styled.div`
  ${flex('column')};

  margin: 0 auto;
  padding: ${theme.sizes.SIZE_120} 0;
  width: clamp(
    ${pxToRem(theme.sizes.SIZE_120)},
    100%,
    ${pxToRem(theme.sizes.SIZE_568)}
  );

  .error-fallback {
    &__logo {
      ${flex('row', 'center', 'center')};
      margin: 0 0 ${theme.sizes.SIZE_64};

      img {
        ${size('auto', theme.sizes.SIZE_224)}
      }
    }

    &__ops {
      ${fontSize(theme.sizes.SIZE_40, theme.sizes.SIZE_48)};

      font-weight: 500;
      text-align: center;
      margin: 0 0 ${theme.sizes.SIZE_32};
      color: var(--SECONDARY_1_2);
    }

    &__desc {
      ${fontSize(theme.sizes.SIZE_24, theme.sizes.SIZE_32)};

      margin: 0;
      font-weight: 500;
      text-align: center;
      color: var(--SECONDARY_1_1);
    }

    &__refresh {
      margin: 0 auto;
      margin-top: ${theme.sizes.SIZE_40};
    }
  }
  ${phablet(css`
    padding: ${theme.sizes.SIZE_80} ${theme.sizes.SIZE_16};

    .error-fallback {
      &__logo > img {
        height: ${pxToRem(theme.sizes.SIZE_160)};
      }

      &__ops {
        ${fontSize(theme.fontSizes.FONT_34, theme.sizes.SIZE_40)};
      }

      &__desc {
        ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};
        margin: 0 0 ${theme.sizes.SIZE_4};
      }
    }
  `)}
`;
