import { useContext, useEffect, useState } from 'react';
import { GoogleMapsContext } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Icon } from 'components';

import * as Styled from './MapUtilities.styles';

const MapUtilities = () => {
  const { t } = useTranslation();
  const [activeMode, setActiveMode] = useState<'roadmap' | 'satellite'>(
    'roadmap'
  );
  const { map } = useContext(GoogleMapsContext);

  useEffect(() => {
    map?.setMapTypeId(activeMode);
  }, [activeMode, map]);

  return (
    <>
      <Styled.MapMode>
        <Styled.MapUtilityButton
          id="map_view"
          onClick={() => {
            setActiveMode('roadmap');
          }}
          className={classNames({ active: activeMode === 'roadmap' })}
        >
          {t('mMap')}
        </Styled.MapUtilityButton>
        <Styled.MapUtilityButton
          id="satellite_view"
          onClick={() => setActiveMode('satellite')}
          className={classNames({ active: activeMode === 'satellite' })}
        >
          {t('mSatellite')}
        </Styled.MapUtilityButton>
      </Styled.MapMode>

      <Styled.MapZoom>
        <Styled.MapUtilityButton
          id="map_zoom_in"
          aria-label={t('accessibility-zoom_in_map')}
          onClick={() => {
            map?.setZoom(map.getZoom() + 1);
          }}
        >
          <Icon name="plus" />
        </Styled.MapUtilityButton>
        <Styled.MapUtilityButton
          id="map_zoom_out"
          aria-label={t('accessibility-zoom_out_map')}
          onClick={() => {
            map?.setZoom(map.getZoom() - 1);
          }}
        >
          <Icon name="minus" />
        </Styled.MapUtilityButton>
      </Styled.MapZoom>
    </>
  );
};

export default MapUtilities;
