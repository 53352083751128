import { FC, memo, useCallback, useContext, useMemo, useState } from 'react';
import {
  Button,
  ButtonProps,
  Calendar,
  CalendarValue,
  config,
  Menu,
} from '@faxi/web-component-library';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import { Shift } from 'models';
import { formattedTime } from 'utils';
import { ScheduleTime, SCHEDULE_TIME } from 'models';
import { ShiftSettingsContext } from '../../providers/ShiftSettings/ShiftSettings.context';
import PickShiftButton from '../../components/PickShiftButton';

import * as Styled from './SelectSchedule.styles';
import { IconTitleProps } from '../../components/PickShiftButton/PickShiftButton.component';
import { getShiftIconName } from '../PickShift/PickShift.component';

export const currentDate = dayjs();

export const SCHEDULE_TIME_TRANSLATIONS = {
  monday_to_friday: 'shift_settings-every_weekday_monday_to_friday',
  monday_to_sunday: 'shift_settings-everyday',
  custom: 'shift_settings-custom',
} as const;

type SelectScheduleProps = {
  className?: string;
  shift: Shift;
  onSaveSchedule: () => void;
  onDeleteSchedule: () => void;
};

const SelectSchedule: FC<SelectScheduleProps> = (props) => {
  const { className, onSaveSchedule, onDeleteSchedule } = props;

  const {
    assignedShifts,
    selectedShift: shift,
    hasAssignedShifts,
    initiallyAssigned,
    shouldRevalidate,
    assignShifts,
    setAssignedShifts,
    deleteAssignedShifts,
  } = useContext(ShiftSettingsContext);

  const { t } = useTranslation();

  const [customDates, setCustomDates] = useState<CalendarValue>(
    shift.dates
      ?.filter((date) => date !== null)
      .map((date) => dayjs(date)) as CalendarValue
  );

  const [timeSchedule, setTimeSchedule] = useState<ScheduleTime>(
    shift.pattern || SCHEDULE_TIME.MONDAY_TO_FRIDAY
  );

  const calendarValue = useMemo(
    () =>
      shift.dates
        ?.filter((date) => date !== null)
        .map((date) => dayjs(date)) as CalendarValue,
    [shift.dates]
  );

  const editingShift = useMemo(
    () => Boolean(assignedShifts.find((s) => s.shift_id === shift.shift_id)),
    [assignedShifts, shift]
  );

  const hints = useMemo(
    () =>
      (assignedShifts as Shift[])
        .filter((saved: Shift) => saved.shift_id !== shift?.shift_id)
        .reduce((acc: { date: Dayjs; color: string }[], savedShift: Shift) => {
          const startTime = parseInt(savedShift?.start_time || '');
          const color =
            startTime >= 6 && startTime < 15
              ? 'morning'
              : startTime >= 15 && startTime < 20
              ? 'afternoon'
              : 'night';

          const shiftCustomDates: {
            date: Dayjs;
            color: string;
          }[] = savedShift?.dates?.map((date: string) => ({
            date: dayjs(date, 'YYYY-MM-DD'),
            color: color,
          }))!;

          return shiftCustomDates ? acc.concat(...shiftCustomDates) : acc;
        }, []),
    [assignedShifts, shift]
  );

  const calendarLabels = useMemo(
    () => ({
      arrowLeftDay: t('accessibility-arrow_left_day'),
      arrowRightDay: t('accessibility-arrow_right_day'),
      arrowLeftMonth: t('accessibility-arrow_left_month'),
      arrowRightMonth: t('accessibility-arrow_right_month'),
      arrowLeftYear: t('accessibility-arrow_left_year'),
      arrowRightYear: t('accessibility-arrow_right_year'),
      closeButton: t('accessibility-calendar_close-button'),
    }),
    [t]
  );

  const scheduleMenuItems = useMemo(
    () =>
      [
        {
          id: SCHEDULE_TIME.MONDAY_TO_FRIDAY,
          children: t(SCHEDULE_TIME_TRANSLATIONS.monday_to_friday),
          onClick: () => setTimeSchedule(SCHEDULE_TIME.MONDAY_TO_FRIDAY),
        },
        {
          id: SCHEDULE_TIME.MONDAY_TO_SUNDAY,
          children: t(SCHEDULE_TIME_TRANSLATIONS.monday_to_sunday),
          onClick: () => setTimeSchedule(SCHEDULE_TIME.MONDAY_TO_SUNDAY),
        },
        {
          id: SCHEDULE_TIME.CUSTOM,
          children: t(SCHEDULE_TIME_TRANSLATIONS.custom),
          onClick: () => setTimeSchedule(SCHEDULE_TIME.CUSTOM),
        },
      ] as ButtonProps[],
    [t]
  );

  const shiftTitles = useMemo(
    () =>
      [
        { icon: getShiftIconName(shift), title: shift.name },
        {
          icon: 'clock-nine',
          title: formattedTime(shift.start_time, shift.end_time),
        },
      ] as [IconTitleProps, IconTitleProps],
    [shift]
  );

  const handleDateChange = useCallback((value?: CalendarValue) => {
    if (value) setCustomDates(value);
  }, []);

  const handleSaveSchedule = useCallback(async () => {
    let dates: string[] = [];

    if (customDates) {
      dates = (customDates as Dayjs[]).map((date) =>
        date.format(config.apiDateFormat)
      );
    }

    const savedShift = {
      ...shift,
      dates,
      pattern: timeSchedule,
      shift_id: shift.id || shift.shift_id,
    } as Shift;

    if (hasAssignedShifts) {
      shouldRevalidate.current = true;
      await assignShifts(savedShift);
    } else {
      setAssignedShifts((old) => [
        ...old.filter((s) => s.shift_id !== savedShift.shift_id),
        savedShift,
      ]);
    }

    onSaveSchedule?.();
  }, [
    customDates,
    shift,
    timeSchedule,
    shouldRevalidate,
    hasAssignedShifts,
    setAssignedShifts,
    onSaveSchedule,
    assignShifts,
  ]);

  const handleDeleteAssigned = useCallback(async () => {
    if (!(initiallyAssigned?.current as Shift[]).length) {
      setAssignedShifts((old) =>
        old.filter((s) => s.shift_id !== shift.shift_id)
      );
    } else {
      shouldRevalidate.current = true;
      await deleteAssignedShifts(shift.shift_id);
    }

    onDeleteSchedule?.();
  }, [
    shift,
    shouldRevalidate,
    initiallyAssigned,
    setAssignedShifts,
    onDeleteSchedule,
    deleteAssignedShifts,
  ]);

  return (
    <Styled.Schedule className={className}>
      <div className="select-schedule__header">
        <p className="select-schedule__title">
          {t('shift_settings-label_selected_shift')}
        </p>

        {editingShift && (
          <Button
            className="delete-selected"
            variant="delete-ghost"
            icon={<Icon name="trash-can" />}
            onClick={handleDeleteAssigned}
          />
        )}
      </div>

      <PickShiftButton
        active
        className="select-schedule__selected"
        shiftTitles={shiftTitles}
      />

      <p className="select-schedule__set-schedule">
        {t('shift_settings-title_set_a_schedule')}
      </p>

      <Menu
        className="select-schedule__menu"
        portalClassName="select-schedule__menu"
        gutter={0}
        menuItems={scheduleMenuItems}
        triggerTitle={t(SCHEDULE_TIME_TRANSLATIONS[timeSchedule])}
        triggerProps={{
          variant: 'ghost',
          iconPosition: 'right',
          icon: <Icon name="chevron-down" />,
        }}
      />

      {timeSchedule === SCHEDULE_TIME.CUSTOM && (
        <Calendar
          mode="multiple"
          keepFocus={false}
          hints={hints}
          value={calendarValue}
          calendarAriaLabels={calendarLabels}
          leftLimitDate={currentDate}
          className="select-schedule__calendar"
          onChange={handleDateChange}
        />
      )}

      <Button
        variant="primary"
        className="select-schedule__save"
        disabled={
          timeSchedule === SCHEDULE_TIME.CUSTOM &&
          !(customDates as Dayjs[])?.length
        }
        onClick={handleSaveSchedule}
      >
        {t('Save')}
      </Button>
    </Styled.Schedule>
  );
};

export default memo(SelectSchedule);
