import {
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { appUri } from '../../../../config';
import { useQuery } from '../../../../hooks';
import { BlockUI } from '../../../../helpers';
import { APIResponse } from '../../../../models';
import { SurveyInstance } from '../../../../models/Survey';

import SurveyContext, { SurveyContextProps } from './Survey.context';
import apiSurvey from '../../../../modules/api/apiSurvey';
import storageService, {
  STORAGE_KEYS,
} from '../../../../services/storageService';
import { ScrollToTop } from '../../../../components';

const INVALID_STATUSES = ['unknown', 'canceled'] as string[];

type SurveyProviderProps = PropsWithChildren;

const SurveyProvider: FC<SurveyProviderProps> = (props) => {
  const { children } = props;

  const navigate = useNavigate();

  const { surveySlug } = useParams() as { surveySlug: string };

  const [activeEmail, setActiveEmail] = useState<string>(
    storageService.getItem(STORAGE_KEYS.ACTIVE_EMAIL)
  );

  const [yourEmission, setYourEmission] = useState<number>(0);

  const getSurveyBySlug = useCallback(
    async () => await apiSurvey.getSurveyBySlug(surveySlug),
    [surveySlug]
  );

  const { data: activeSurvey } = useQuery<
    APIResponse<SurveyInstance>,
    SurveyInstance
  >({
    showSpinner: true,
    queryFn: getSurveyBySlug,
    condition: !!surveySlug,
    mappingFunction: async (res) => res.data,
    onSuccess: (data) => {
      if (INVALID_STATUSES.includes(data.status)) {
        navigate(`/${appUri.LOGIN}`);
        return;
      }

      if (data.status === 'pending') {
        navigate(`/survey/${surveySlug}/${appUri.SURVEY_SCHEDULED}`);
        return;
      }

      if (data.status === 'finished') {
        navigate(`/survey/${surveySlug}/${appUri.SURVEY_FINISHED}`);
        return;
      }
    },
  });

  const blockUI = !activeSurvey && !!surveySlug;

  const providerValues = useMemo(
    () =>
      ({
        activeEmail,
        yourEmission,
        activeSurvey,
        setYourEmission,
        setActiveEmail,
      } as SurveyContextProps),
    [activeEmail, activeSurvey, yourEmission]
  );

  return (
    <SurveyContext.Provider value={providerValues}>
      <ScrollToTop targetNodeId="body" />

      <BlockUI condition={blockUI} fallback={<Fragment />}>
        {children}
      </BlockUI>
    </SurveyContext.Provider>
  );
};

export default SurveyProvider;
