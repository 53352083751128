import { Button, useFormButtons } from '@faxi/web-component-library';
import { FormContext } from '@faxi/web-form';
import classNames from 'classnames';
import { FC, memo, PropsWithChildren, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './RewardFooter.styles';

type RewardFooterProps = PropsWithChildren<{
  editing?: boolean;
  disableEditing?: boolean;
  onCancel?: () => void;
  onDelete?: (name: string, target: HTMLElement) => void;
}>;

const RewardFooter: FC<RewardFooterProps> = (props) => {
  const { disableEditing, editing, onCancel, onDelete } = props;

  const { t } = useTranslation();
  const { isFormChanged, syncFormValid, fields } = useContext(FormContext);

  const [FormButtons] = useFormButtons({
    cancelLabel: t('cancel'),
    submitLabel: editing
      ? t('CommuteDetails_saving_message')
      : t('global_button_create'),
  });

  const disabledSubmit = useMemo(
    () => !isFormChanged() || !syncFormValid || disableEditing,
    [isFormChanged, disableEditing, syncFormValid]
  );

  return (
    <Styled.RewardFooter className={classNames({ editing: editing })}>
      <FormButtons.Submit type="submit" disabled={disabledSubmit} />

      {editing && (
        <FormButtons.Cancel
          type="button"
          className="cancel-btn"
          aria-label="cancel-reward"
          onClick={onCancel}
        />
      )}

      {editing && (
        <Button
          type="button"
          variant="delete-outline"
          className="delete-reward-btn"
          children={t('Discovery_map_delete')}
          disabled={disableEditing}
          onClick={(event) => {
            onDelete?.(
              fields['name'].value,
              event?.target as HTMLButtonElement
            );
          }}
        />
      )}
    </Styled.RewardFooter>
  );
};

export default memo(RewardFooter);
