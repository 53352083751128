// eslint-disable-next-line import/no-anonymous-default-export
export default async function(imgSrc: string) {
  const data = await fetch(imgSrc);
  try {
    const { rc } = await data.clone().json();
    if (rc !== 'error') {
      throw new Error('create an image');
    }
  } catch (er) {
    const blob = await data.blob();
    const reader = new FileReader();

    return new Promise<string>((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  return 'default';
}
