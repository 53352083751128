import {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { debounce } from 'lodash';
import {
  getColor,
  GoogleMapsContext,
  Spinner,
  theme,
  useEffectSkipFirst,
} from '@faxi/web-component-library';

import { Home } from 'models';
import { UserContext } from 'store';

type OverlappingMarkerSpiderfier = {
  addMarker(marker: google.maps.Marker): void;
  removeMarker(marker: google.maps.Marker): void;
  trigger(...args: any[]): void;
  unspiderfy(): void;
  clearMarkers(): void;
};

type MarkerMap = Record<
  string,
  {
    marker: google.maps.Marker;
    status: 'SPIDERFIED' | 'UNSPIDERFIABLE' | 'SPIDERFIABLE';
  }
>;

export const MarkerClusterContext = createContext<{
  activePin: Home | null;
  markerCluster: MarkerClusterer | null;
  overlappingMarkerSpiderfier: OverlappingMarkerSpiderfier | null;
  markerMapRef: MutableRefObject<MarkerMap>;
  setActivePin: Dispatch<SetStateAction<Home | null>>;
  addMarkerToClusterDebounced: (
    marker: google.maps.Marker,
    key: string
  ) => void;
}>({
  activePin: null,
  markerCluster: null,
  overlappingMarkerSpiderfier: null,
  markerMapRef: { current: {} },
  setActivePin: () => {},
  addMarkerToClusterDebounced: () => {},
});

const MarkerClusterProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const { map } = useContext(GoogleMapsContext);
  const markerChunk = useRef<google.maps.Marker[]>([]);

  const [markerCluster, setMarkerCluster] = useState<MarkerClusterer | null>(
    null
  );
  const [activePin, setActivePin] = useState<Home | null>(null);
  const { communityId } = useContext(UserContext);
  const markerMapRef = useRef<MarkerMap>({});

  const [overlappingMarkerSpiderfier, setOMS] =
    useState<OverlappingMarkerSpiderfier | null>(null);

  const addMarkerChunkToCluster = useMemo(
    () =>
      debounce(() => {
        markerCluster?.addMarkers(markerChunk.current);
        markerChunk.current = [];
      }, 200),
    [markerCluster]
  );

  const addMarkerToClusterDebounced = useCallback(
    (marker: google.maps.Marker, key: string) => {
      markerChunk.current.push(marker);
      markerMapRef.current[key] = { marker, status: 'UNSPIDERFIABLE' };

      addMarkerChunkToCluster();
    },
    [addMarkerChunkToCluster]
  );

  useEffect(() => {
    if (map) {
      setOMS(
        new window.OverlappingMarkerSpiderfier(map, {
          markersWontMove: true,
          markersWontHide: true,
          keepSpiderfied: true,
          circleFootSeparation: 50,
          nearbyDistance: 30,
          legWeight: 0.01,
        })
      );

      setMarkerCluster(
        new MarkerClusterer({
          map,
          renderer: {
            render: ({ markers, position }) =>
              new google.maps.Marker({
                position,
                icon: '/assets/images/cluster.png',

                label: {
                  text: `${markers?.length}`,
                  color: getColor('--BACKGROUND_1_1'),
                  fontSize: theme.sizes.SIZE_16,
                  className: 'map-cluster-pin',
                },
              }),
          },
          algorithmOptions: {
            maxZoom: 19,
          },
        })
      );
    }
  }, [map]);

  useEffectSkipFirst(() => {
    setActivePin(null);
    markerChunk.current = [];
    overlappingMarkerSpiderfier?.clearMarkers();
    markerCluster?.clearMarkers();
  }, [communityId]);

  if (!markerCluster)
    return <Spinner size={60} color={getColor('--PRIMARY_1_1')} />;

  return (
    <MarkerClusterContext.Provider
      value={{
        activePin,
        markerCluster,
        overlappingMarkerSpiderfier,
        markerMapRef,
        setActivePin,
        addMarkerToClusterDebounced,
      }}
    >
      {children}
    </MarkerClusterContext.Provider>
  );
};

export default MarkerClusterProvider;
