import styled from 'styled-components';

import { flex, marginChildren, pxToRem, size } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const SettingsTabEmptyScreenContainer = styled.div`
  ${flex('column', 'center', 'center')};
  ${marginChildren(`0 0 ${theme.sizes.SIZE_16} 0`)};
  margin: auto;

  > * {
    text-align: center;
  }

  .settings-tab-empty-screen {
    &__icon {
      svg {
        ${size(theme.sizes.SIZE_48)};
        color: var(--ACCENT_1_1);
      }
    }

    &__title {
      color: var(--SECONDARY_1_1);
    }

    &__content-text {
      max-width: ${pxToRem(theme.sizes.SIZE_472)};
    }

    &__button {
      margin-top: ${theme.sizes.SIZE_32};
    }
  }
`;
