import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  color: var(--SHADE_1_2);

  &.data-point {
    &--row {
      display: grid;
      grid-template-rows: unset;
      grid-template-columns: 30% auto;
      gap: ${theme.sizes.SIZE_20};

      .data-point {
        &__label {
          margin-right: ${theme.sizes.SIZE_24};
        }

        &__value {
          flex: 0 1 70%;
        }
      }
    }
  }

  .data-point {
    &__label {
      font-weight: 600;
      margin-bottom: auto;
    }

    &__value {
      word-break: normal;
      overflow-wrap: anywhere;
    }
  }
`;
