import { Shift } from 'models';
import {
  createContext,
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
} from 'react';

export type ShiftSettingsContextProps = {
  loading?: boolean;
  shifts?: Shift[];
  selectedShift: Shift;
  assignedShifts: Shift[];
  hasAssignedShifts: boolean;
  assignedShiftsAreSame: boolean;
  initiallyAssigned?: RefObject<Shift[]>;
  shouldRevalidate: MutableRefObject<boolean>;
  assignShifts: (shifts?: Shift) => Promise<void>;
  deleteAssignedShifts: (shift_id?: number) => Promise<void>;
  setSelectedShift: Dispatch<SetStateAction<Shift>>;
  setAssignedShifts: Dispatch<SetStateAction<Shift[]>>;
};

export const ShiftSettingsContext = createContext<ShiftSettingsContextProps>({
  loading: false,
  shifts: [],
  selectedShift: {} as Shift,
  assignedShifts: [],
  hasAssignedShifts: false,
  assignedShiftsAreSame: false,
  shouldRevalidate: {} as MutableRefObject<boolean>,
  setSelectedShift: () => {},
  setAssignedShifts: () => {},
  assignShifts: () => Promise.resolve(),
  deleteAssignedShifts: () => Promise.resolve(),
});
