import {
  FC,
  Fragment,
  ReactNode,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Image,
  getColor,
  useEffectOnceWhen,
} from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Shift, ShiftType } from 'models';
import { calculateDurationTime } from '../../utils';
import { InputField } from 'components';
import specific from 'validation/validators/specific';

import * as Styled from './ShiftRow.styles';

type ShiftRowProps = {
  shift?: Shift;
  name: string;
  shiftsInfo?: Shift[];
  children?: ReactNode;
  checkbox?: boolean;
  index: number;
  shiftName?: string;
  validations?: {
    name: ((value: string, fields?: any) => {} | null | undefined)[];
    end_time: ((value: string, fields?: any) => {} | null | undefined)[];
    start_time: ((value: string, fields?: any) => {} | null | undefined)[];
  };
  fieldArrayRef?: any;
  is_new?: boolean;
  setCheckbox?: () => void;
  removeShift?: (index: number) => void;
};

const ShiftRow: FC<ShiftRowProps> = (props: ShiftRowProps): JSX.Element => {
  const {
    shift,
    name,
    shiftsInfo,
    index,
    shiftName,
    children,
    checkbox,
    validations,
    fieldArrayRef,
    setCheckbox,
    removeShift,
    is_new,
  } = props;

  const [duration, setDuration] = useState<number>();

  const [duplicates, setDuplicates] = useState<(number | null)[]>([]);

  const [removeWarning, setRemoveWarning] = useState<number[]>([]);

  const { t } = useTranslation();

  const shiftInfo = useMemo(
    () => shiftsInfo?.find((shift) => shift.name === shiftName),
    [shiftName, shiftsInfo]
  );

  const shiftType: ShiftType = useMemo(() => {
    const startTime = parseInt(
      shift ? shift.start_time : shiftsInfo?.[index]?.start_time || ''
    );

    if (startTime >= 6 && startTime < 15) {
      return {
        type: 'morning',
        icon: 'ic_sun',
      };
    } else if (startTime >= 15 && startTime < 20) {
      return {
        type: 'afternoon',
        icon: 'ic_sunset',
      };
    } else
      return {
        type: 'night',
        icon: 'ic_moon',
      };
  }, [index, shift, shiftsInfo]);

  const validateShiftName = useMemo(
    () => [
      ...(validations?.name || []),
      specific.shiftNameDuplicate(
        t('validation-unique_name', {
          fieldname: t('predefined_shifts-shift_name').toLowerCase(),
        }),
        index
      ),
    ],
    [index, t, validations?.name]
  );

  useEffect(() => {
    setDuration(
      calculateDurationTime(shiftInfo?.start_time, shiftInfo?.end_time)
    );
  }, [index, shiftInfo?.end_time, shiftInfo?.start_time]);

  useEffect(() => {
    const times = shiftsInfo?.map(
      (item) => item.start_time.substring(0, 5) + item.end_time.substring(0, 5)
    );

    const isDuplicate = times?.map((item, idx) => {
      if (
        !shiftsInfo?.[index]?.id &&
        times?.indexOf(item) !== idx &&
        !removeWarning.includes(idx)
      ) {
        return times?.indexOf(item);
      } else return -1;
    });

    if (isDuplicate) {
      setDuplicates(isDuplicate);
    }
  }, [index, shiftsInfo, removeWarning]);

  useEffectOnceWhen(() => {
    const elements = document.getElementsByName(`${name}.name`);

    (elements[elements.length - 1] as HTMLDivElement).focus();
  }, !!is_new);

  return (
    <Styled.Container
      className={classNames('kinto-shift', `kinto-shift--${shiftType.type}`, {
        'kinto-shift--checked': checkbox,
      })}
      tabIndex={0}
    >
      <div className="kinto-shift__main">
        {shift ? (
          <Fragment>
            <div className="kinto-shift__main__content">
              <Image
                alt=""
                className="kinto-shift__main__content__image"
                src={`/assets/svg/${shiftType.icon}.svg`}
              />
              <div className="kinto-shift__main__content__name">
                {shift?.name}
              </div>
            </div>

            <div className="kinto-shift__main__times">
              <div className="kinto-shift__main__times__time">
                <div className="kinto-shift__main__times__time__label">
                  {t('predefined_shifts-start_time')}
                </div>
                {shift?.start_time.substring(0, 5)}
              </div>

              <div className="kinto-shift__main__times__divider">-</div>

              <div className="kinto-shift__main__times__time">
                <div className="kinto-shift__main__times__time__label">
                  {t('predefined_shifts-end_time')}
                </div>
                {shift?.end_time.substring(0, 5)}
              </div>
            </div>

            <div className="kinto-shift__main__duration">
              {shift?.duration} {t('predefined_shifts-hours')}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="kinto-shift__main__content">
              <div>
                <Checkbox
                  aria-label={shiftsInfo?.[index]?.name}
                  checked={checkbox}
                  onChange={setCheckbox}
                />

                <Image
                  alt=""
                  src={`/assets/svg/${shiftType.icon}.svg`}
                  className="kinto-shift__main__content__image"
                />
              </div>

              <FormField
                component={InputField}
                name={`${name}.name`}
                className="kinto-shift__shift-name-input"
                placeholder={t('predefined_shifts-shift_name')}
                validate={validateShiftName}
                autoComplete="on"
                required
                requiredLabel={t('global-input_field_required_label')}
              />
            </div>

            <div className="kinto-shift__main__times">
              <FormField
                component={InputField}
                type="time"
                name={`${name}.start_time`}
                placeholder={t('predefined_shifts-start_time')}
                required
                requiredLabel={t('global-input_field_required_label')}
                validate={validations?.start_time}
              />
              <div className="kinto-shift__main__times__divider">-</div>
              <FormField
                component={InputField}
                type="time"
                name={`${name}.end_time`}
                placeholder={t('predefined_shifts-end_time')}
                required
                requiredLabel={t('global-input_field_required_label')}
                validate={validations?.end_time}
              />
            </div>

            <div className="kinto-shift__main__duration">
              <div>
                {duration} {t('predefined_shifts-hours')}
              </div>
              {children}
            </div>
          </Fragment>
        )}
      </div>

      {!shift && duplicates?.[index] !== -1 && is_new && (
        <div className="kinto-shift__warning" role="alert">
          <div className="kinto-shift__warning__label">
            <Icon
              name="exclamation-triangle"
              color={getColor('--ALERT_WARNING_1_1')}
            />
            <div>{t('validation-shift_already_exists')}</div>
          </div>
          <div className="kinto-shift__warning__buttons">
            <Button
              variant="ghost"
              iconPosition="right"
              icon={<Icon name="chevron-right" />}
              onClick={() => {
                setRemoveWarning((old) => [...old, index]);
              }}
            >
              {t('onboarding_continue')}
            </Button>

            <Button
              variant="ghost"
              className="kinto-shift__warning__buttons__cancel-btn"
              onClick={() => {
                if (shiftsInfo?.[index]?.id) {
                  removeShift?.(shiftsInfo?.[index]?.id!);
                }
                fieldArrayRef.remove(index);
              }}
            >
              {t('cancel')}
            </Button>
          </div>
        </div>
      )}
    </Styled.Container>
  );
};

export default memo(ShiftRow);
