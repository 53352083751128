import { FC, useRef } from 'react';
import { Button, ModalProps, ModalRef } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components';
import { INameExtended } from 'components/Icon';
import Each from 'helpers/Each';
import {
  RewardType,
  RewardTypes,
  RewardVoucherTypeData,
  REWARD_TYPES,
} from 'models';

import * as Styled from './RewardDetailsModal.styles';

const REWARD_ICON_TYPE = {
  [REWARD_TYPES.MESSAGE_REWARD]: 'gift',
  [REWARD_TYPES.PARKING_REWARD]: 'square-parking',
  [REWARD_TYPES.VOUCHER_REWARD]: 'gift',
} as Record<RewardTypes, INameExtended>;

const REWARD_TITLE_TYPE = {
  [REWARD_TYPES.MESSAGE_REWARD]: 'rewards-reward_types',
  [REWARD_TYPES.PARKING_REWARD]: 'rewards-parking_spaces',
  [REWARD_TYPES.VOUCHER_REWARD]: 'rewards-field_placeholder_reward_type',
} as Record<RewardTypes, string>;

type RewardDetailsModalProps = {
  reward: RewardType;
} & Partial<ModalProps>;

const RewardDetailsModal: FC<RewardDetailsModalProps> = (props) => {
  const {
    reward: { name, type, instances, data },
    ...rest
  } = props;

  const { t } = useTranslation();
  const modalRef = useRef<ModalRef>(null);

  return (
    <Styled.RewardDetailsModal
      ref={modalRef}
      title={t('rewards-title_reward_details', {
        Rewardname: name,
      })}
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t('rewards-title_reward_details', {
          name,
        }),
      })}
      footer={
        <Button
          onClick={() => {
            modalRef.current?.close();
          }}
        >
          {t('ok')}
        </Button>
      }
      {...rest}
    >
      <p className="kinto-reward-details-modal__title">
        {t(REWARD_TITLE_TYPE[type as RewardTypes])}
      </p>

      {type !== 'voucher-reward' && instances ? (
        <Each
          of={instances}
          containerAs="div"
          className="kinto-reward-details-modal__instances"
          render={(item) => (
            <div className="reward-instance">
              <Icon name={REWARD_ICON_TYPE[type as RewardTypes]} />

              <p className="title">
                {item.name}
                <span>{item.quantity > 1 ? ` (x${item.quantity})` : ''}</span>
              </p>
            </div>
          )}
        />
      ) : (
        <div className="reward-instance">
          <Icon name={REWARD_ICON_TYPE[type as RewardTypes]} />
          <p className="title">
            {t('rewards-reward_type_codes_number', {
              number: (data as RewardVoucherTypeData)?.number_of_codes,
            })}
          </p>
        </div>
      )}
    </Styled.RewardDetailsModal>
  );
};

export default RewardDetailsModal;
