import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const NotFound = styled.div`
  ${flex('column', 'center', 'center')};
  ${size('100%')};

  padding: ${theme.sizes.SIZE_24};

  .kinto-not-found {
    &__image {
      max-height: ${theme.sizes.SIZE_240};
      margin: 0 0 ${theme.sizes.SIZE_40};
    }

    &__title {
      ${fontSize(theme.fontSizes.FONT_30, theme.sizes.SIZE_40)};

      font-weight: 600;
      text-align: center;
      color: var(--SECONDARY_1_1);
      margin: 0 0 ${theme.sizes.SIZE_12};
    }

    &__description {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      font-weight: 400;
      color: var(--SHADE_1_2);
      margin: 0 0 ${theme.sizes.SIZE_40};
    }
  }
`;
