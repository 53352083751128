import {
  config,
  SortDirections,
  SortOption,
} from '@faxi/web-component-library';
import { EarnedReward } from 'models';
import { useTranslation } from 'react-i18next';
import { TablePaginationProps, useTablePagination } from 'hooks';
import { apiGamification } from 'modules';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const TODAY = dayjs().format(config.apiDateFormat);

type UseRewardsType = {
  initialSortBy: string;
  initialSortDirection: SortDirections;
  spinnerSelector: string;
} & Partial<TablePaginationProps<EarnedReward, 'rewards'>>;

const useRewards = (args: UseRewardsType) => {
  const { initialSortBy, initialSortDirection, spinnerSelector, ...rest } =
    args;
  const { t } = useTranslation();

  const { organisationId } = useParams() as {
    organisationId: string;
  };

  const {
    data,
    count,
    search,
    loading,
    totalPages,
    activeColumnSort,
    totalCount,
    currentPage,
    setCount,
    onSearchChange,
    setCurrentPage,
    setActiveColumnSort,
  } = useTablePagination<EarnedReward, 'rewards'>({
    itemsKey: 'rewards',
    spinnerSelector,
    initialSortBy,
    initialSortDirection,
    customErrorMessage: t(`no_members_in_community`),
    applyQueryParams: false,
    mappingFunction: async (values) =>
      values.map(
        ({ id, reward, user, user_id, vrn }) =>
          ({
            id,
            reward: `${reward}`.trim() || '-',
            user: `${user}`.trim() || '-',
            user_id,
            vrn: vrn || '-',
          } as EarnedReward)
      ),
    apiRequest: (
      count: number,
      offset: number,
      search: string,
      sort_by,
      sort_direction
    ) =>
      apiGamification.getEarnedRewards({
        oid: organisationId,
        count: count,
        offset,
        search,
        sort_by,
        sort_direction,
        start_date: TODAY,
        end_date: TODAY,
      }),
    ...rest,
  });

  const activeSort = activeColumnSort as SortOption<EarnedReward>;

  return {
    data,
    count,
    search,
    loading,
    totalPages,
    activeColumnSort: activeSort,
    totalCount,
    currentPage,
    setCount,
    onSearchChange,
    setCurrentPage,
    setActiveColumnSort,
  };
};

export default useRewards;
