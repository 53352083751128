import { focusStyles, theme } from '@faxi/web-component-library';
import { position, pxToRem, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Map = styled.div`
  position: relative;

  > div.map {
    ${size('100%')};
    ${position('absolute', '0')};
  }
`;

export const MapPage = styled.div`
  ${focusStyles};

  display: grid;
  height: 100%;
  overflow: auto;
  grid-template-columns: ${pxToRem(theme.sizes.SIZE_320)} 1fr;
  border-bottom: ${theme.sizes.SIZE_1} solid var(--SHADE_1_7);

  @media (max-width: ${theme.breakAtMaxWidth}px) {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
  }

  ${Map} {
    height: 100%;
    flex: 1 1 100%;

    min-height: ${pxToRem(theme.sizes.SIZE_560)};
  }
`;

export const MapPageContainer = styled.div`
  ${size('100%')};

  position: relative;

  button,
  div,
  a {
    ${focusStyles};
  }
`;
