import { useContext, useState, useRef, useMemo, useCallback, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormField, FormRef } from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Breadcrumbs,
  Icon,
  Divider,
  useCallbackRef,
  useFormButtons,
  StatusElement,
  useUtilities,
} from '@faxi/web-component-library';
import classNames from 'classnames';

import { UserContext, AuthContext, ProfileContext } from 'store';
import CommunityActionsModal, {
  CommunityActionsModalActions,
} from 'components/_modals/CommunityActionsModal/CommunityActionsModal.component';
import DataPoint from 'components/_molecules/DataPoint';
import FormActions from 'components/_layouts/FormActions';

import * as Styled from './PermissionsPage.styles';
import { apiPeople } from 'modules';
import SwitchField from 'components/_molecules/SwitchField';
import {
  getUserStatusElementStatus,
  getUserStatusElementTranslation,
} from 'utils';

const PermissionsPage: FC = (): JSX.Element => {
  const {
    communityId,
    community,
    user,
    communities,
    communityUsersExist,
    updateUserAdminPermission,
  } = useContext(UserContext);
  const { userProfile, setUserProfile } = useContext(ProfileContext);

  const { handleLogout } = useContext(AuthContext);

  const { showSnackBar } = useUtilities();

  const [communityActionsState, setCommunityActionsState] =
    useState<CommunityActionsModalActions>(CommunityActionsModalActions.NONE);

  const [form, formRef] = useCallbackRef<FormRef>();
  const peopleContainerRef = useRef(null);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [FormButtons] = useFormButtons({
    submitLabel: t('Save'),
    loadingOverlaySelector: '.kinto-page',
  });

  const { userId } = useParams() as { userId: string };

  const isAdmin = useMemo(
    () => userProfile.is_admin === 'Y',
    [userProfile.is_admin]
  );

  const initialData = useMemo(() => ({ admin: isAdmin }), [isAdmin]);

  const adminToggleDisabled = useMemo(
    () =>
      ['N', 'D', 'R'].includes(userProfile.authorised) || !communityUsersExist,
    [communityUsersExist, userProfile.authorised]
  );

  const shouldLogout = useMemo(
    () => communities.length === 1 && `${userProfile.id}` === user?.id,
    [communities, user, userProfile.id]
  );

  const updateUserRole = useCallback(
    async (data: { admin: string }) => {
      if (!communityId) {
        return;
      }

      try {
        const { errc } = await apiPeople.promoteUserToAdmin(
          data?.admin ? 'A' : '',
          communityId,
          `${userProfile.id}`
        );

        // COMMENT: if request fails, that is because the desired user is the only admin in the community
        // and you tried to remove his permissions
        if (errc === 2013) {
          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t(
              'admin-notification_a_community_mast_have_at_least_one_admin'
            ),
            variant: 'error',
          });
        } else {
          if (`${userProfile.id}` === user?.id)
            updateUserAdminPermission(communityId, data?.admin === 'A');

          setUserProfile((old: any) => ({
            ...old,
            is_admin: data?.admin ? 'Y' : 'N',
          }));

          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t(
              data?.admin
                ? 'admin-notification_successfully_promoted_to_admin'
                : 'admin-notification_successfully_removed_admin_privileges'
            ),
            variant: 'success',
          });

          if (shouldLogout) handleLogout(false);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      userProfile.id,
      shouldLogout,
      communityId,
      user,
      updateUserAdminPermission,
      setUserProfile,
      handleLogout,
      t,
      showSnackBar,
    ]
  );

  return (
    <>
      {community && (
        <Styled.Container className="permissions-page" ref={peopleContainerRef}>
          <Breadcrumbs
            className="permissions-page__breadcrumbs"
            crumbs={[
              {
                text: community.name,
                href: `/community/${communityId}/admin/dashboard`,
                id: 'people_bdc_back_to_community',
              },
              {
                text: t('mPeople'),
                href: `/community/${communityId}/admin/people`,
                id: 'people_bdc_back_to_people',
              },
              {
                text: userProfile.name || userProfile.email,
                href: `/community/${communityId}/admin/people/${userId}`,
                id: 'people_bdc_back_to_user',
              },
              { text: t('permissions'), href: '' },
            ]}
          />

          <div className="permissions-page__row">
            <div className="permissions-page__row__status">
              <DataPoint
                className="page-profile__avatar__data"
                label={t('ga_gd_status')}
                value={
                  <StatusElement
                    status={getUserStatusElementStatus(userProfile.authorised)}
                    small
                  >
                    {t(getUserStatusElementTranslation(userProfile.authorised))}
                  </StatusElement>
                }
              />
              {/* Approved and non-admin user can only be deactivated */}
              {userProfile.authorised === 'Y' &&
                userProfile.is_admin === 'N' && (
                  <Button
                    id="deactivate_active_user_permission"
                    className="permissions-page__action-button"
                    variant="ghost"
                    icon={<Icon name="ban" />}
                    onClick={() =>
                      setCommunityActionsState(
                        CommunityActionsModalActions.DEACTIVATE_ONE_USER
                      )
                    }
                  >
                    {t('deactivate')}
                  </Button>
                )}
              {/* Pending user can be either approved or rejected */}
              {userProfile.authorised === 'N' && (
                <div className="permissions-page__action-buttons">
                  <Button
                    id="approve_pending_user_permission"
                    variant="primary-invert"
                    icon={<Icon name="check" />}
                    onClick={() =>
                      setCommunityActionsState(
                        CommunityActionsModalActions.APPROVE_ONE_USER
                      )
                    }
                  >
                    {t('approve')}
                  </Button>
                  <Button
                    id="reject_pending_user_permission"
                    className="permissions-page__action-buttons__button permissions-page__action-buttons__button--reject"
                    icon={<Icon name="xmark" />}
                    variant="ghost"
                    onClick={() =>
                      setCommunityActionsState(
                        CommunityActionsModalActions.REJECT_ONE_USER
                      )
                    }
                  >
                    {t('reject')}
                  </Button>
                </div>
              )}
              {/* Deactivated user can be reactivated to rejoin community */}
              {userProfile.authorised === 'D' && (
                <div className="permissions-page__action-buttons">
                  <Button
                    id="reactivate_deactivated_user_permission"
                    variant="primary-invert"
                    icon={<Icon name="rotate-right" />}
                    onClick={() =>
                      setCommunityActionsState(
                        CommunityActionsModalActions.REACTIVATE_ONE_USER
                      )
                    }
                  >
                    {t('global-button_reactivate')}
                  </Button>
                </div>
              )}
            </div>
            <Divider />
            <DataPoint
              className="page-profile__avatar__data"
              label={t('header_role')}
              rowOrder
              value={isAdmin ? t('admin') : t('member_web')}
            />
            <Divider />
            <Form
              onSubmit={updateUserRole}
              initialData={initialData}
              ref={formRef}
            >
              <div
                className={classNames('permissions-page__role', {
                  'permissions-page__role--disabled': adminToggleDisabled,
                })}
              >
                <p className="permissions-page__role__text">
                  <b>{t('admin')}</b>
                </p>
                <FormField
                  id="people_toggle_admin_role"
                  name="admin"
                  component={SwitchField}
                  className="permissions-page__role__toggle"
                  disabled={adminToggleDisabled}
                  label={t('controls_access_organisation_account')}
                />
              </div>
              <FormActions className="permissions-page__role__button">
                <FormButtons.Submit
                  id="submit_people_admin_role"
                  disabled={!form?.isFormChanged()}
                />
              </FormActions>
            </Form>
          </div>

          <Button
            id="return_to_user_profile"
            variant="ghost"
            iconPosition="left"
            icon={<Icon name="chevron-left" />}
            onClick={() => navigate(-1)}
          >
            {t('back_to_profile_page')}
          </Button>
          {userProfile &&
            communityId &&
            communityActionsState !== CommunityActionsModalActions.NONE && (
              <CommunityActionsModal
                className="people__community-actions-modal"
                organisationId={communityId}
                userListIds={[`${userProfile.id}`]}
                user={userProfile}
                communityName={community.name}
                action={communityActionsState}
                onConfirm={() => {
                  setCommunityActionsState(CommunityActionsModalActions.NONE);
                  navigate(-1);
                }}
                onCancel={() => {
                  setCommunityActionsState(CommunityActionsModalActions.NONE);
                }}
                parentRef={peopleContainerRef.current!}
              />
            )}
        </Styled.Container>
      )}
    </>
  );
};

export default PermissionsPage;
