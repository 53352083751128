import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '@faxi/web-component-library';

import * as Styles from './Pending.styles';

const Pending: FC = () => {
  const { t } = useTranslation();

  return (
    <Styles.PendingContainer className="kinto-pending">
      <h2 className="kinto-pending__title">
        {t('pending_community-waiting_to_be_approved')}
      </h2>

      <p className="kinto-pending__description">
        {t('onboarding-modal_body_community_request')}
      </p>

      <Image
        alt=""
        className="kinto-pending__placeholder"
        src="/assets/images/pending.webp"
      />
    </Styles.PendingContainer>
  );
};

export default Pending;
