import styled, { css } from 'styled-components';

import {
  marginChildren,
  flex,
  aboveTablet,
  mobile,
  fontSize,
} from '@faxi/web-css-utilities';
import { shadow_xl, theme } from '@faxi/web-component-library';

export const ImageContainer = styled.div`
  background: ${(props: { url: string }) => `
    url('${props.url}') no-repeat center center`};
  background-size: cover;
  color: var(--BACKGROUND_1_1);
  ${marginChildren(`0 0 ${theme.sizes.SIZE_32} 0`)};
  ${flex('column', 'flex-end', 'flex-start')};
  border-radius: ${theme.sizes.SIZE_8};
  ${shadow_xl};

  ${aboveTablet(css`
    height: 100%;
  `)}

  .image-wrapper {
    width: 60%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      var(--PRIMARY_1_1),
      var(--SECONDARY_1_1)
    );
    ${flex('column', 'flex-end', 'flex-start')};
    padding: ${theme.sizes.SIZE_56};
    border-radius: ${theme.sizes.SIZE_8} 0 0 ${theme.sizes.SIZE_8};
    word-break: normal;
    overflow-wrap: anywhere;

    ${mobile(css`
      width: unset;
      padding: ${theme.sizes.SIZE_32};
      border-radius: ${theme.sizes.SIZE_8};
    `)};

    &__title {
      color: var(--SUCCESS_1_1);
      font-weight: 600;
      ${fontSize(theme.fontSizes.FONT_18)};
      margin-bottom: ${theme.sizes.SIZE_10};

      ${mobile(css`
        ${fontSize(theme.fontSizes.FONT_16)};
      `)};
    }

    &__text {
      color: var(--BACKGROUND_1_1);
      ${fontSize(theme.fontSizes.FONT_14)};
      margin-bottom: ${theme.sizes.SIZE_20};
    }
  }
`;
