import { theme } from '@faxi/web-component-library';
import {
  abovePhablet,
  flex,
  fontSize,
  mobile,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  .empty-predefined-addresses {
    margin: auto;
    max-width: ${pxToRem(theme.sizes.SIZE_504)};

    ${flex('column', 'center', 'center')}

    &__icon {
      &,
      svg {
        ${size(theme.sizes.SIZE_64)};
      }

      margin: 0 0 ${theme.sizes.SIZE_16};
      color: var(--ACCENT_1_1);
    }
    &__title,
    &__content {
      margin: 0;
      text-align: center;
    }

    &__title {
      ${fontSize(theme.fontSizes.FONT_30, theme.sizes.SIZE_40)};
      color: var(--SECONDARY_1_1);
      margin: 0 0 ${theme.sizes.SIZE_12};
      font-weight: 600;
    }

    &__content {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};
      max-width: ${pxToRem(theme.sizes.SIZE_424)};
      color: var(--SHADE_1_2);
      margin: 0 0 ${theme.sizes.SIZE_16};
      font-weight: 400;
    }
  }

  .predefined-address-modal {
    width: ${pxToRem(theme.sizes.SIZE_680)};

    &__address-name {
      margin-bottom: ${theme.sizes.SIZE_20};
    }

    ${abovePhablet(css`
      .wcl-modal__main {
        padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_40};
      }
    `)}

    footer {
      div {
        margin-top: 0;
      }
    }

    &__form-actions {
      width: 100%;

      &__delete-btn {
        margin-left: auto;

        ${mobile(css`
          order: -2;
          align-self: flex-end;
          margin-bottom: ${theme.sizes.SIZE_12};
        `)}
      }

      ${mobile(css`
        display: grid;
        grid-template-columns: 1fr 1fr;

        > button {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
          &:first-of-type {
            grid-column: 1 / 3;
          }
        }

        .button--ghost {
          order: -1;
          margin-bottom: ${theme.sizes.SIZE_12};
        }
      `)};
    }
  }

  .predefined-addresses-list {
    &__add-address-btn {
      margin-bottom: ${theme.sizes.SIZE_32};
    }
    &__table {
      .wcl-table__data__action {
        .button {
          ${size(theme.sizes.SIZE_32)};
          min-height: unset;
        }
      }
    }
  }
`;
