import { FC } from 'react';

const isPlatform = {
  Android: () => {
    return navigator.userAgent.match(/Android/gi);
  },
  iPhone: () => {
    return navigator.userAgent.match(/(iPad|iPhone|iPod)/gi);
  },
  OtherMobiles: () => {
    return navigator.userAgent.match(
      /(IEMobile|Windows Phone|Lumia|WebOS|BlackBerry)/gi
    );
  },
  WindowsOS: () => {
    return navigator.userAgent.match(/(WinCE|Win16|Win32|Win64|Windows)/gi);
  },
  MacOS: () => {
    return navigator.userAgent.match(
      /(Macintosh|MacIntel|MacPPC|Mac68K|Mac)/gi
    );
  },
  LinuxOS: () => {
    return navigator.userAgent.match(/Linux|Linux2/gi);
  },
};

const Redirection: FC = () => {
  if (isPlatform.Android() || isPlatform.OtherMobiles()) {
    window.location.replace(
      'https://play.google.com/store/apps/details?id=com.kintojoin'
    );
  } else if (isPlatform.iPhone()) {
    window.location.replace('https://apps.apple.com/app/id1491141883');
  } else {
    window.location.replace('/download-kinto-app');
  }

  return null;
};

export default Redirection;
