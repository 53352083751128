import { useMemo } from 'react';
import {
  SortDirections,
  SortOption,
  Table,
  TableProps,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { EarnedReward } from 'models';
import { NoData } from 'Global.styles';

type EarnedRewardsReportTableProps = {
  data: EarnedReward[];
  loading: boolean;
  initialSort: {
    sortBy: keyof EarnedReward;
    sortDirection: SortDirections;
  };
  onColumnSortClicked: (sort: SortOption<EarnedReward>) => void;
} & Partial<TableProps<EarnedReward>>;

const EarnedRewardsReportTable = (props: EarnedRewardsReportTableProps) => {
  const { data, loading, initialSort, onColumnSortClicked, ...rest } = props;

  const { t } = useTranslation();

  const translationKeys = useMemo(
    () =>
      ({
        reward: t('global-reward_name'),
        user: t('global-user'),
        user_id: `${t('global-user')} ID`,
        vrn: t('global_plate_number'),
      } as Record<Partial<keyof EarnedReward>, string>),
    [t]
  );

  return (
    <Table<EarnedReward>
      tableId="earned-rewards-table-dashboard"
      className="earned-rewards-today__table"
      tableData={data}
      hasCheckboxes={false}
      translationKeys={translationKeys}
      initialSort={initialSort}
      loadingData={loading}
      excludeColumns={[
        'id',
        'reward_id',
        'campaign_id',
        'campaign',
        'date',
        'instance_name',
        'type',
      ]}
      rowActionLabel={t('view_details')}
      perPagePlaceholder={t('per_page')}
      chevronBtnAriaLabel={t('per_page')}
      goToPageInputProps={{ placeholder: t('global-go_to_page') }}
      pageSelectorAriaRightLabel={t('accessibility-button_next_page')}
      pageSelectorAriaLeftLabel={t('accessibility-button_previous_page')}
      sortIconAriaLabel={(property, orientation) =>
        t(
          orientation === 'desc'
            ? 'accessibility-button_sort_ascending'
            : 'accessibility-button_sort_descending',
          { property: property.toLowerCase() }
        )
      }
      noDataPlaceholder={
        !loading && (
          <NoData className="kinto-no-data">
            {t('gamification-empty_state_no_earned_rewards_found')}
          </NoData>
        )
      }
      onColumnSortClicked={onColumnSortClicked}
      {...rest}
    />
  );
};

export default EarnedRewardsReportTable;
