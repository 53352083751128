import { Modal, theme } from '@faxi/web-component-library';
import { flexGap, fontSize, phablet, pxToRem } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const SurveyModal = styled(Modal)`
  .wcl-modal {
    ${phablet(css`
      width: 100%;
    `)}
  }

  .calendar-labels {
    ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};

    font-weight: 400;
    margin: 0 0 ${theme.sizes.SIZE_20};
    color: var(--SECONDARY_1_1);
  }

  .kinto-survey-modal {
    &__name {
      margin-bottom: ${theme.sizes.SIZE_40};
    }

    &__start-date {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__start-date,
    &__end-date {
      max-width: ${pxToRem(theme.sizes.SIZE_304)};
    }

    &__footer {
      margin-top: unset;

      ${phablet(css`
        ${flexGap('0px', 'column')};

        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
      `)}
    }
  }
`;
