/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { GoogleMapsContext } from '@faxi/web-component-library';
import { useMemo, useEffect, useCallback, useRef, useContext } from 'react';

interface DirectionsProps {
  renderOnMap?: boolean;
  renderOptions?: google.maps.DirectionsRendererOptions;
}

interface DirectionsHookReturns {
  directionsService: google.maps.DirectionsService | null;
  findRoute:
    | ((
        request: google.maps.DirectionsRequest
      ) => Promise<google.maps.DirectionsResult>)
    | null;
  findAndRenderRoute:
    | ((
        request: google.maps.DirectionsRequest
      ) => Promise<google.maps.DirectionsResult>)
    | null;
  renderRouteOfIndex: (index: number) => void;
}

/**
 * Hook to get Google Maps Places Directions Service instance
 */
const useCustomDirections = (
  props: DirectionsProps = {}
): DirectionsHookReturns => {
  const { renderOnMap, renderOptions } = props;
  const { map } = useContext(GoogleMapsContext);
  const loading = true;
  const loadingFinishedRef = useRef(true);

  useEffect(() => {
    if (!loadingFinishedRef.current && loading) {
      loadingFinishedRef.current = true;
    }
  }, [loading]);

  // Creates a Directions Service instance
  const directionsService =
    useMemo<google.maps.DirectionsService | null>(() => {
      // Wait for map to be initialized
      if (!map || (loading && !loadingFinishedRef.current)) {
        return null;
      }

      return new google.maps.DirectionsService();
    }, [map, loading]);

  // Creates a Directions Renderer instance
  const directionsRenderer =
    useMemo<google.maps.DirectionsRenderer | null>(() => {
      // Wait for map to be initialized
      if (!map || !renderOnMap) {
        return null;
      }

      const renderer = new google.maps.DirectionsRenderer(renderOptions);
      renderer.setMap(map);

      return renderer;
    }, [map, renderOnMap]);

  // Updates the directions renderer options
  useEffect(() => {
    if (!directionsRenderer) {
      return;
    }

    directionsRenderer.setOptions(renderOptions || {});
  }, [renderOptions]);

  // Custom Directions route request
  const findRoute = useCallback(
    (
      request: google.maps.DirectionsRequest
    ): Promise<google.maps.DirectionsResult> =>
      new Promise((resolve, reject) => {
        if (directionsService) {
          directionsService.route(
            request,
            (
              result: google.maps.DirectionsResult,
              status: google.maps.DirectionsStatus
            ): void => {
              if (status === google.maps.DirectionsStatus.OK) {
                resolve(result);
              } else {
                reject(status);
              }
            }
          );
        }
      }),
    [directionsService]
  );

  // Custom Directions route request followed by directions rendering
  const findAndRenderRoute = useCallback(
    (
      request: google.maps.DirectionsRequest
    ): Promise<google.maps.DirectionsResult> =>
      new Promise((resolve, reject) => {
        if (directionsService) {
          directionsService.route(
            request,
            (
              result: google.maps.DirectionsResult,
              status: google.maps.DirectionsStatus
            ): void => {
              if (status === google.maps.DirectionsStatus.OK) {
                if (directionsRenderer) {
                  directionsRenderer.setDirections(result);
                }
                resolve(result);
              } else {
                reject(status);
              }
            }
          );
        }
      }),
    [directionsService, directionsRenderer]
  );

  // Renders directions route of given index
  const renderRouteOfIndex = (index: number) => {
    if (directionsRenderer) {
      directionsRenderer.setRouteIndex(index);
    }
  };

  return {
    directionsService,
    findRoute: directionsService && findRoute,
    findAndRenderRoute:
      directionsService && directionsRenderer && findAndRenderRoute,
    renderRouteOfIndex,
  };
};

export default useCustomDirections;
