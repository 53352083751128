import { FC, useContext } from 'react';
import { Image, Spinner, getColor } from '@faxi/web-component-library';
import classNames from 'classnames';

import { UserContext } from 'store';

import * as CommunityElementStyled from './CommunityElement.styles';
import { useTranslation } from 'react-i18next';

const CommunityElement: FC<{ className?: string }> = ({ className }) => {
  const { community } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <CommunityElementStyled.CommunityElement
      className={classNames('kinto-community-element', className)}
    >
      {!community ? (
        <Spinner size={12} color={getColor('--PRIMARY_1_1')} />
      ) : (
        <>
          <Image
            src={community.image_url || ''}
            alt={t('community_image')}
            fallbackUrl="/assets/svg/community.svg"
          />
          <span>{community.name}</span>
        </>
      )}
    </CommunityElementStyled.CommunityElement>
  );
};

export default CommunityElement;
