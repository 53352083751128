import { createContext } from 'react';
import { FormRef } from '@faxi/web-form';

interface AuthContext {
  sessionId: string;
  errorMessage: boolean;
  failedAttempts: string;
  placeholderValue: string;
  uris: string[];
  applyCredentials: (authData: any) => void;
  handleLogin: (formData: FormData, form: FormRef) => void;
  handleLogout: (sessionExpired?: boolean) => Promise<void>;
  resetPassword: (email: string) => void;
  changePassword: (
    resetCode: string,
    newPassword: string
  ) => Promise<{ errc: number }>;
}

const defaultValue = {
  sessionId: '',
  errorMessage: false,
  failedAttempts: '',
  placeholderValue: '',
  uris: [],
  handleLogin: () => {},
  handleLogout: () => new Promise(() => {}) as Promise<void>,
  resetPassword: () => {},
  applyCredentials: () => {},
  changePassword: () => new Promise<{ errc: number }>(() => {}),
};

const AuthContext = createContext<AuthContext>(defaultValue);

export default AuthContext;
