import { FC, useContext, useMemo } from 'react';
import {
  FormContext,
  FormField,
  FormFieldProps,
  validators,
} from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SnackBar from '@faxi/web-component-library/build/components/_molecules/SnackBar/SnackBar.component';

import { Each } from '../../../../../helpers';
import { InputField } from '../../../../../components';
import SurveyFormWrapper from '../../../components/SurveyFormWrapper';
import SeparatedContent from '../components/SeparatedContent';
import specific from 'validation/validators/specific';
import { SurveyTransportMode } from '../../../../../models/Survey';

export const DAYS_PER_MONTH = 30;

const modesOfTransport = [
  {
    fieldName: 'driving',
    translationKey: 'mode_of_transport-driving_a_personal_vehicle',
  },
  {
    fieldName: 'carpooling',
    translationKey: 'mode_of_transport-carpool_as_a_passenger',
  },
  {
    fieldName: 'walking',
    translationKey: 'sustainability-analysis-service_walking',
  },
  {
    fieldName: 'cycling',
    translationKey: 'sustainability-analysis-service_cycling',
  },
  {
    fieldName: 'corporate_shuttle',
    translationKey: 'mode_of_transport-corporate_shuttle',
  },
  { fieldName: 'bus', translationKey: 'mode_of_transport-bus' },
  { fieldName: 'train', translationKey: 'mode_of_transport-train' },
] as Array<{ fieldName: keyof SurveyTransportMode; translationKey: string }>;

const FormFieldMode: FC<Omit<FormFieldProps<any>, 'component'>> = (props) => {
  const { name, ...restProps } = props;
  const { fields } = useContext(FormContext);

  return (
    <FormField
      {...restProps}
      name={name}
      component={InputField}
      convertFunction={(value: String) => {
        const prevValue = fields[name].value;
        if (+value <= DAYS_PER_MONTH && +value >= 0) return value;
        else return prevValue;
      }}
    />
  );
};

const ModeOfTransport = () => {
  const { t } = useTranslation();
  const { fields } = useContext(FormContext);

  const validations = useMemo(
    () => ({
      transportMode: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_email_hint'),
          })
        ),
        specific.checkEqualitySum(
          t('Error'),
          modesOfTransport.map((mode) => mode.fieldName),
          (sum: number) => sum <= 30
        ),
        specific.checkEqualitySum(
          t('Error'),
          modesOfTransport.map((mode) => mode.fieldName),
          (sum: number) => sum > 0
        ),
      ],
    }),
    [t]
  );

  const totalDaysEntered = useMemo(
    () =>
      Object.values(fields).reduce(
        (value, field) => (value += +field.value),
        0
      ) || 0,
    [fields]
  );

  return (
    <div className="kinto-survey-form-container__wrapper">
      <SeparatedContent
        variant="small"
        leftNode={t('mode_of_transport')}
        rightNode={t('global-days_per_month')}
      />

      <Each
        of={modesOfTransport}
        render={({ fieldName, translationKey }) => (
          <SeparatedContent
            className="kinto-survey-form-container__transport-mode"
            variant="large"
            leftNode={t(translationKey)}
            rightNode={
              <FormFieldMode
                type="number"
                name={fieldName}
                min={0}
                inputMode="numeric"
                pattern="[0-9]*"
                max={DAYS_PER_MONTH}
                displayError={false}
                aria-label={t(translationKey)}
                validate={validations.transportMode}
              />
            }
          />
        )}
      />

      {totalDaysEntered > DAYS_PER_MONTH ? (
        <SnackBar
          variant="alert_2"
          className="kinto-survey-form-container__mode-warning"
          text={t('mode_of_transport-alert_days_less_days')}
        />
      ) : (
        <SeparatedContent
          variant="small"
          rightNode={totalDaysEntered}
          className="kinto-survey-form-container__total-days"
          leftNode={t('mode_of_transport-label_total_days_per_month')}
        />
      )}
    </div>
  );
};

const ModeOfTransportWrapper: FC = () => {
  return (
    <SurveyFormWrapper
      initialData={{
        bus: '0',
        train: '0',
        cycling: '0',
        driving: '0',
        walking: '0',
        carpooling: '0',
        corporate_shuttle: '0',
      }}
      className={classNames(
        'kinto-survey-form-container',
        'kinto-survey-mode-of-transport'
      )}
    >
      <ModeOfTransport />
    </SurveyFormWrapper>
  );
};

export default ModeOfTransportWrapper;
