import { FC, useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps, ModalRef } from '@faxi/web-component-library';

import { Shift } from 'models';
import { ShiftSettingsViews } from '.';
import { useView } from 'providers/Views';
import { ShiftSettingsContext } from './providers/ShiftSettings';

import * as Styled from './ShiftSettingsModa.styles';

export type ShiftSettingsModalProps = Partial<ModalProps> & {
  closeOnSave?: boolean;
  closeOnDelete?: boolean;
  onChange?: (shifts: Shift[]) => void;
};

const ShiftSettingsModal: FC<ShiftSettingsModalProps> = (props) => {
  const {
    closeOnSave = false,
    closeOnDelete = false,
    onClose,
    onChange,
    ...rest
  } = props;

  const modalRef = useRef<ModalRef>(null);

  const { t } = useTranslation();

  const {
    pushView,
    clearStack,
    BackButton,
    activeView: ActiveView,
  } = useView();

  const {
    loading,
    hasAssignedShifts,
    shouldRevalidate,
    assignedShifts,
    assignShifts,
    deleteAssignedShifts,
  } = useContext(ShiftSettingsContext);

  const closeModalWithView = useCallback(
    (condition: boolean, view: ShiftSettingsViews) => {
      if (condition && hasAssignedShifts) {
        modalRef.current?.close();
        return;
      }

      clearStack();
      pushView(view);
    },
    [hasAssignedShifts, clearStack, pushView]
  );

  const handleOnDeleteSchedule = useCallback(() => {
    closeModalWithView(
      closeOnDelete,
      assignedShifts.length === 1 ? 'pick-shift' : 'assigned-shifts'
    );
  }, [closeModalWithView, closeOnDelete, assignedShifts]);

  const handleOnSaveSchedule = useCallback(() => {
    closeModalWithView(closeOnSave, 'assigned-shifts');
  }, [closeModalWithView, closeOnSave]);

  const handleOnComplete = useCallback(async () => {
    await assignShifts();
    modalRef?.current?.close();
  }, [assignShifts]);

  const handleOnClearAll = useCallback(async () => {
    await deleteAssignedShifts();
    modalRef?.current?.close();
  }, [deleteAssignedShifts]);

  return (
    <Styled.Modal
      className="shift-settings-modal"
      ref={modalRef}
      title={t('shift_settings-button_shift_settings')}
      loading={loading}
      onClose={() => {
        if (shouldRevalidate.current) {
          onChange?.(assignedShifts);
        }

        onClose?.();
      }}
      {...rest}
    >
      <BackButton />

      <ActiveView
        onComplete={handleOnComplete}
        onClearAll={handleOnClearAll}
        onSaveSchedule={handleOnSaveSchedule}
        onDeleteSchedule={handleOnDeleteSchedule}
      />
    </Styled.Modal>
  );
};

export default ShiftSettingsModal;
