import { focusStyles, theme } from '@faxi/web-component-library';
import {
  flex,
  marginChildren,
  size,
  fontSize,
  laptop,
  mobile,
  belowMobile,
  pxToRem,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

/* 21px stands for label height of input */
const INPUT_LABEL_HEIGHT = '21px';

export const Container = styled.div`
  ${flex('column', 'flex-start', 'stretch')};
  padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_46};
  font-weight: 500;
  ${marginChildren(`0 0 ${theme.sizes.SIZE_16} 0`)};
  ${focusStyles};

  ${laptop(css`
    flex-wrap: wrap;
    padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_20};
    ${marginChildren(`0 0 ${theme.sizes.SIZE_16} 0`)};
  `)};

  &.kinto-shift {
    border-bottom: ${theme.sizes.SIZE_1} solid var(--SHADE_1_6);

    &--morning {
      border-left: ${theme.sizes.SIZE_4} solid var(--SECONDARY_3_1);
    }

    &--afternoon {
      border-left: ${theme.sizes.SIZE_4} solid var(--SECONDARY_2_1);
    }

    &--night {
      border-left: ${theme.sizes.SIZE_4} solid var(--SECONDARY_4_1);
    }

    &--checked {
      background-color: var(--ACCENT_1_6);
    }
  }

  .kinto-shift {
    &__main {
      ${flex('row', undefined, 'flex-start')};

      ${laptop(css`
        flex-wrap: wrap;
        ${marginChildren('0')};
      `)};

      &__content {
        ${marginChildren(`0 ${theme.sizes.SIZE_16} 0 0`)};
        ${flex('row', 'flex-start')};
        word-break: break-word;
        flex: 0 0 40%;

        ${laptop(css`
          flex: 0 0 100%;
          width: 100%;
          max-width: unset;
          margin-bottom: ${theme.sizes.SIZE_16};
        `)};

        &__image {
          ${size(theme.sizes.SIZE_32)};
        }

        > div:first-of-type {
          ${flex('row')};

          height: ${pxToRem(theme.sizes.SIZE_48)};
          margin-top: ${INPUT_LABEL_HEIGHT};
          align-items: center;

          img {
            margin-left: ${theme.sizes.SIZE_16};
          }

          .wcl-checkbox {
            align-items: flex-start;
          }
        }
      }

      &__times {
        ${flex('row', undefined, 'flex-start')};
        ${mobile(css`
          flex-wrap: wrap;
        `)}

        flex: 1 0 30%;

        input {
          width: ${pxToRem(theme.sizes.SIZE_176)};
        }

        &__time {
          ${flex('column')};

          &__label {
            ${fontSize(theme.fontSizes.FONT_16)};
            color: var(--SHADE_1_1);
            font-weight: 400;
          }
        }

        &__divider {
          margin: 0 ${theme.sizes.SIZE_10};
          line-height: ${pxToRem('6px')};
          margin-top: ${theme.sizes.SIZE_40};

          ${belowMobile(css`
            display: none;
          `)}
        }
      }

      &__duration {
        margin-top: ${INPUT_LABEL_HEIGHT};
        ${marginChildren(`0 ${theme.sizes.SIZE_16} 0 0`)};
        ${flex('row', 'flex-end', 'center')};
        flex-grow: 1;

        .button {
          ${size(theme.sizes.SIZE_48)};
        }
      }
    }

    &__shift-name-input {
      width: ${pxToRem(theme.sizes.SIZE_208)};
    }

    &__warning {
      ${flex('column', 'flex-start', 'stretch')};

      &__label {
        ${flex('row', 'flex-start', 'baseline')};
        ${marginChildren(`0 ${theme.sizes.SIZE_16} 0 0`)};
      }

      &__buttons {
        padding-left: ${theme.sizes.SIZE_32};
        ${marginChildren(`0 ${theme.sizes.SIZE_32} 0 0`)};
        ${flex('row', 'flex-start', 'center')};
      }
    }
  }
`;
