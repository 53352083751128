import styled, { css } from 'styled-components';

import { marginChildren, mobile, pxToRem } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const Container = styled.div`
  word-break: normal;
  overflow-wrap: anywhere;
  ${marginChildren(`0 0 ${theme.sizes.SIZE_8} 0`)};

  .kinto-consent-item {
    &__label {
      display: grid;
      grid-template-columns: 1fr ${theme.sizes.SIZE_96};
      color: var(--SHADE_1_1);
      font-weight: 500;

      &__answer {
        text-align: end;
        font-weight: 500;
        color: var(--PRIMARY_1_1);
      }

      ${mobile(css`
        grid-template-columns: 1fr ${theme.sizes.SIZE_48};
      `)}
    }

    &__description {
      color: var(--SHADE_1_2);
      padding-right: ${theme.sizes.SIZE_96};
      min-height: ${pxToRem(theme.sizes.SIZE_32)};

      ${mobile(css`
        padding-right: ${theme.sizes.SIZE_48};
      `)}
    }
  }
`;
