import { FC } from 'react';
import { Checkbox, CheckboxProps } from '@faxi/web-component-library';
import { useFieldError, FieldProps } from '@faxi/web-form';

import * as Styled from './CheckboxInfoField.styles';

type CheckboxInfoFieldProps = FieldProps<boolean> & {
  label: string;
  description?: string | JSX.Element | JSX.Element[];
  lastElement?: boolean;
} & Omit<CheckboxProps, 'ref'>;

const CheckboxInfoField: FC<CheckboxInfoFieldProps> = (
  props: CheckboxInfoFieldProps
): JSX.Element => {
  const {
    value,
    onChange,
    error,
    description,
    label,
    lastElement,
    // COMMENT: these 2 props are extracted because Checkbox does not use them
    touched,
    dirty,
    ...rest
  } = props;

  const showError = useFieldError(props);

  return (
    <Styled.Container className="checkbox-info-field">
      <Checkbox
        {...rest}
        label={label}
        aria-label={label}
        checked={Boolean(value)}
        onChange={onChange}
      />
      <div className="checkbox-info-field__privacy-body">{description}</div>
      {showError && (
        <div className="checkbox-info-field__error-msg">{error}</div>
      )}
    </Styled.Container>
  );
};

export default CheckboxInfoField;
