import { FC } from 'react';
import { PlaceholderChart as WCLPlaceholderChart } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

const PlaceholderChart: FC = (): JSX.Element => {
  const { t } = useTranslation();

  return <WCLPlaceholderChart label={t('unable_to_return_data_try_again')} />;
};

export default PlaceholderChart;
