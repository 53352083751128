import {
  Fragment,
  useContext,
  FC,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  InfiniteScroll,
  useCallbackRef,
  useEffectSkipFirst,
  useGlowScroll,
} from '@faxi/web-component-library';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

import { MessagesContext, UserContext } from 'store';
import { CachedCommunityMessages } from 'components/_molecules/MessageSender/MessageSender.component';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import MessageSender from 'components/_molecules/MessageSender';
import UserMessage from 'components/_molecules/UserMessage';
import { apiMessages, authBus } from 'modules';
import { useInfinitePagination } from 'hooks';
import { Message } from 'models';

import * as Styled from './MessagesBoard.styles';
import { markParentMessages, markParentSingleMessage } from './utils';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const MESSAGES_PER_PAGE = 20;

const MessagesBoard: FC = (): JSX.Element => {
  const {
    user,
    userReady,
    userPreferences: { dateFormat },
  } = useContext(UserContext);
  const { receiver, groupMessage } = useContext(MessagesContext);
  const { communityId } = useContext(UserContext);

  const { t } = useTranslation();

  const { organisationId, userId } = useParams() as {
    organisationId: string;
    userId: string;
  };

  const glowScrollContainerRef = useRef<HTMLDivElement>(null);

  const [infiniteScroll, infiniteScrollRef] = useCallbackRef<HTMLDivElement>();

  const {
    loading,
    items,
    currentPage,
    canContinuePagination,
    handleContainerScroll,
    concatNewItemsUnique,
    addItemRemoveOld,
  } = useInfinitePagination<Message>({
    perPage: MESSAGES_PER_PAGE,
    condition: userReady && !!user,
    resetDeps: [communityId, userId],
    spinnerParent: '.messages-board__wrapper',
    apiRequest: async (page: string) => {
      const { messages, total_count } =
        await apiMessages.getMessagesBetweenUsers({
          from: `${user?.id}`,
          to: `${userId}`,
          offset: +page,
          count: MESSAGES_PER_PAGE,
        });

      // name in messages is not returned as expected
      const markedMessages = markParentMessages(
        messages.map((m) => ({
          ...m,
          from: { ...m.from, name: `${m.from.first_name} ${m.from.last_name}` },
        }))
      );

      return {
        data: markedMessages,
        total: total_count,
      };
    },
  });

  const handleSendMessage = useCallback(
    (message: Message) => {
      const latestMsg = items[0];

      if (latestMsg) markParentSingleMessage(message, latestMsg);
      concatNewItemsUnique([message], 'start');

      authBus.broadcastEvent('message_sent', message);
    },
    [concatNewItemsUnique, items]
  );

  const handleResendMessage = (message: Message) => {
    message.ts = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');
    message.failed = false;
    addItemRemoveOld(message, 'start');
  };

  const renderDivider = (timestamp: string): string => {
    if (!timestamp) return '';
    if (dayjs(timestamp).isToday()) {
      return t('Today');
    } else if (dayjs(timestamp).isYesterday()) {
      return t('message_yesterday');
    } else {
      return dayjs(timestamp).format(dateFormat);
    }
  };

  useGlowScroll(
    glowScrollContainerRef.current,
    infiniteScroll,
    1,
    0,
    'gray',
    'vertical',
    false,
    true
  );

  useEffect(() => {
    if (groupMessage) {
      concatNewItemsUnique([groupMessage], 'start');
    }
  }, [concatNewItemsUnique, groupMessage]);

  useEffect(() => {
    const failedMessages = (storageService.getItem(
      STORAGE_KEYS.FAILED_MESSAGES
    ) || []) as CachedCommunityMessages[];

    const failedMessagesCommunityIndex = failedMessages.findIndex(
      (messages) => messages.communityId === +organisationId
    );

    if (failedMessagesCommunityIndex !== -1) {
      const failedMessagesUserIndex = failedMessages[
        failedMessagesCommunityIndex
      ].users.findIndex((elem) => elem.userId === userId);

      if (failedMessagesUserIndex !== -1) {
        const failedMessagesFiltered = failedMessages[
          failedMessagesCommunityIndex
        ].users[failedMessagesUserIndex].messages.filter((elem) =>
          dayjs(`${elem.ts}Z`).isToday()
        );

        failedMessages[failedMessagesCommunityIndex].users[
          failedMessagesUserIndex
        ].messages = failedMessagesFiltered;

        concatNewItemsUnique([failedMessagesFiltered], 'start');
        storageService.setItem(STORAGE_KEYS.FAILED_MESSAGES, failedMessages);
      }
    }
  }, [concatNewItemsUnique, organisationId, userId]);

  useEffectSkipFirst(() => {
    infiniteScroll.scrollTop = infiniteScroll.scrollHeight;
  }, [userId]);

  return (
    <Styled.MessageBoardStyled className="messages-board">
      <Styled.MessagesWrapper
        className="messages-board__wrapper"
        ref={glowScrollContainerRef}
      >
        <InfiniteScroll
          onScroll={handleContainerScroll}
          className="messages-board__messages"
          loading={currentPage !== 1 && loading}
          readyForScrolling={items.length > 0 && userReady && !!user}
          ref={infiniteScrollRef}
        >
          {items.map((message, index) => (
            <Fragment key={index}>
              {index !== 0 &&
                dayjs(`${message.ts.split(' ').join('T')}Z`).isBefore(
                  `${items[index - 1]?.ts.split(' ').join('T')}Z`,
                  'day'
                ) && (
                  <div className="messages-board__messages__divider">
                    <div>
                      {renderDivider(
                        `${items[index - 1]?.ts.split(' ').join('T')}Z`
                      )}
                    </div>
                  </div>
                )}
              <UserMessage
                loading={
                  // TODO: loading image
                  false
                }
                hasParent={!!message.hasParent}
                variant={message.from.id === user?.id ? 'right' : 'left'}
                userAvatarUrl={`${
                  message.from.id === user?.id
                    ? user.image_url ||
                      '/assets/svg/user_circle_placeholder.svg'
                    : receiver?.image_url ||
                      '/assets/svg/user_circle_placeholder.svg'
                }`}
                message={message}
                resendMessageCallback={handleResendMessage}
                organisationId={+organisationId}
              />

              {!loading && index === items.length - 1 && (
                <div className="messages-board__messages__divider">
                  <div>
                    {renderDivider(`${items[index]?.ts.split(' ').join('T')}Z`)}
                  </div>
                </div>
              )}
            </Fragment>
          ))}
          {!canContinuePagination && !loading && (
            <div className="messages-board__messages__no-more">
              {t('messages-info_text_conversation_start')}
            </div>
          )}
        </InfiniteScroll>
      </Styled.MessagesWrapper>

      {user && receiver && (
        <MessageSender
          sendMessageCallback={handleSendMessage}
          className="messages-board__message-sender"
          sender={user}
          receiver={receiver}
          organisationId={+organisationId}
        />
      )}
    </Styled.MessageBoardStyled>
  );
};

export default MessagesBoard;
