import { elevate_l, focusStyles, theme } from '@faxi/web-component-library';
import { flex, marginChildren, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Container = styled.li`
  ${flex('row', 'flex-start', 'center')};
  ${focusStyles};

  padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_20};
  ${marginChildren(`0 ${theme.sizes.SIZE_24} 0 0`)};
  border-bottom: ${theme.sizes.SIZE_1} solid var(--SHADE_1_8);
  word-break: break-word;
  cursor: pointer;

  &.active {
    ${elevate_l};
  }

  &:first-of-type {
    border-top: ${theme.sizes.SIZE_1} solid var(--SHADE_1_8);
  }

  .kinto-user-item {
    &__image {
      ${size(theme.sizes.SIZE_40)};
    }

    &__name {
      color: var(--SHADE_1_2);
    }

    &__icon {
      margin-left: auto;
      color: var(--PRIMARY_1_1);
    }
  }
`;
