import { StatusElementStatus } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { Depot, DepotState, RewardType } from 'models';

const containsOnlyStatus = (checkedStatuses: string[], status: string) =>
  checkedStatuses &&
  !!checkedStatuses.length &&
  checkedStatuses.every((elem: string) => elem === status);

const containsAtLeastOneStatusType = (statuses: string[], status: string) =>
  statuses?.some((elem: string) => elem === status);

const getUserStatusElementStatus = (authorised: string): StatusElementStatus =>
  authorised === 'Y'
    ? 'approved'
    : authorised === 'N'
    ? 'pending'
    : authorised === 'D'
    ? 'canceled'
    : 'rejected';

const getUserStatusElementTranslation = (authorised: string): string =>
  authorised === 'Y'
    ? 'approved'
    : authorised === 'N'
    ? 'fc_pending'
    : authorised === 'D'
    ? 'deactivated'
    : 'rejected';

const getCampaignStatusElementStatus = (
  state: DepotState
): StatusElementStatus =>
  state === 'finished' ? 'approved' : state === 'inactive' ? 'rejected' : state;

const getCampaignStatusElementTranslation = (
  { state, start_date }: Partial<Depot>,
  rewards?: RewardType[],
  dateFormat?: string
): string =>
  state === 'pending'
    ? i18next.t('gamification-campaigns_status_active_from', {
        date: dayjs(start_date)?.format(dateFormat),
      })
    : state === 'canceled'
    ? i18next.t('deactivated')
    : state === 'draft'
    ? i18next.t(
        rewards?.length === 0
          ? 'gamification-campaigns_status_draft_no_rewards'
          : 'global-draft'
      )
    : state === 'active'
    ? i18next.t('global-status_active')
    : state === 'finished'
    ? i18next.t('journeys-history_status_finished')
    : state === 'inactive'
    ? i18next.t('Not_active')
    : '-';

export {
  containsOnlyStatus,
  containsAtLeastOneStatusType,
  getUserStatusElementStatus,
  getUserStatusElementTranslation,
  getCampaignStatusElementStatus,
  getCampaignStatusElementTranslation,
};
