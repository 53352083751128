import { useMemo } from 'react';
import { CheckboxProps } from '@faxi/web-component-library';
import { INameExtended } from 'components/Icon';
import { DepotTriggerName } from 'models';
import { useTranslation } from 'react-i18next';

const useConstants = () => {
  const { t } = useTranslation();

  const rewardWinners: CheckboxProps<INameExtended>[] = useMemo(
    () => [
      { label: t('A_driver'), icon: 'car', value: 'driving' },
      {
        label: t('A_passenger'),
        icon: 'user-group',
        value: 'passenging',
      },
      {
        label: t('A_walker'),
        icon: 'person-walking',
        value: 'walking',
      },
      { label: t('A_cyclist'), icon: 'person-biking', value: 'cycling' },
    ],
    [t]
  );

  const statusOptions = useMemo<{ label: string; value: DepotTriggerName }[]>(
    () => [
      {
        label: t('journey-pn_title_journey_finished'),
        value: 'journey-finished-trigger',
      },
      {
        label: t('Rideshare_confirmed_basic_notif'),
        value: 'journey-verified-trigger',
      },
    ],
    [t]
  );

  const directionOptions = useMemo(
    () => [
      {
        label: t('campaign-direction_to_community').toLocaleLowerCase(),
        value: 'office',
      },
      {
        label: t('reports-legend_lebel_pick_up_address').toLocaleLowerCase(),
        value: 'home',
      },
      {
        label: t('campaign-direction_to_event').toLocaleLowerCase(),
        value: 'event',
      },
      {
        label: t('campaign-direction_to_custom_location').toLocaleLowerCase(),
        value: 'custom',
      },
    ],
    [t]
  );

  return { rewardWinners, statusOptions, directionOptions };
};

export default useConstants;
