import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  SortOption,
  SortDirections,
  useLatestRef,
  useEffectSkipFirst,
  useUtilities,
  useStateQueries,
} from '@faxi/web-component-library';
import { debounce } from 'lodash';
import { AxiosRequestConfig, isCancel } from 'axios';
import { PaginatedResponse } from 'models';
import useAbortController from './useAbortController';

type TableQueryParams = {
  search: string;
  count: number;
  page: number;
  sortBy: string;
  sortDirection: SortDirections;
};

export type TablePaginationProps<
  T extends Record<string, any>,
  ItemsKeys extends string = 'users',
  ParamsObj extends Record<string, any> = SortOption<T>
> = {
  data?: T[];
  params?: ParamsObj;
  deps?: any[];
  resetDeps?: any[];
  count?: number;
  itemsKey: string | string[];
  totalKey?: string | string[];
  customErrorMessage?: string;
  spinnerSelector?: string;
  initialSortBy?: string;
  initialSortDirection?: SortDirections;
  condition?: boolean;
  initialSkipRequest?: boolean;
  initTotalCount?: number;
  initTotalPages?: number;
  applyQueryParams?: boolean;
  onReset?: () => void;
  onDataLoad?: (data: Record<string, any>) => void;
  apiRequest: (
    per_page: number,
    currentPage: number,
    search: string,
    sort_by: keyof T,
    sort_direction: SortDirections,
    config?: Partial<AxiosRequestConfig>
  ) => Promise<PaginatedResponse<T, ItemsKeys>>;
  mappingFunction?: (values: T[]) => Promise<T[]>;
};

export default function useTablePagination<
  T extends Record<string, any>,
  ItemsKey extends string
>(args: TablePaginationProps<T, ItemsKey>) {
  const {
    apiRequest,
    mappingFunction,
    onReset,
    onDataLoad,
    count: pCount = 10,
    data: pData,
    deps = [],
    resetDeps = [],
    itemsKey,
    customErrorMessage,
    spinnerSelector = '#root',
    initialSortBy = 'id',
    initialSortDirection = 'desc',
    condition = true,
    totalKey = 'total_count',
    initialSkipRequest = false,
    initTotalCount,
    initTotalPages,
    applyQueryParams = true,
  } = args;

  const { showOverlay, hideOverlay } = useUtilities();

  const onResetRef = useRef(onReset);
  const loadRef = useRef(false);
  const skipRequest = useRef(initialSkipRequest);
  const searchStringChanged = useRef(false);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [requestError, setRequestError] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(initTotalCount || 1);
  const [totalPages, setTotalPages] = useState<number>(initTotalPages || 1);

  const [data, setData] = useState<T[]>(pData || []);

  const { abortSignal, cancelPreviousRequest } = useAbortController();

  const apiRequestRef = useLatestRef(apiRequest);
  const mappingFunctionRef = useLatestRef(mappingFunction);

  const {
    params,
    setQuery: setQueryParam,
    setQueries: setMultipleQueryParams,
  } = useStateQueries<TableQueryParams>(
    [
      {
        query: 'page',
        defaultValue: 1,
      },
      {
        query: 'count',
        defaultValue: pCount,
      },
      {
        query: 'sortBy',
        defaultValue: initialSortBy,
      },
      {
        query: 'sortDirection',
        defaultValue: initialSortDirection,
      },
    ],
    applyQueryParams
  );

  const { search, sortBy, sortDirection } = params;
  const currentPage = Number(params.page);
  const count = Number(params.count);

  const currentOffset = useMemo(
    () => (currentPage - 1) * count,
    [count, currentPage]
  );

  const loadData = useCallback(
    async (
      search = '',
      count: number,
      offset: number,
      sort: keyof T,
      direction: SortDirections
    ) => {
      cancelPreviousRequest();

      try {
        if (!apiRequestRef.current) return;

        setLoading(true);
        spinnerSelector && showOverlay(spinnerSelector);

        const data = await apiRequestRef.current(
          count,
          offset,
          search,
          sort,
          direction,
          { signal: abortSignal() }
        );

        onDataLoad?.(data as Record<string, any>);
        let tableData: T[] = [];

        if (data) {
          if (mappingFunctionRef.current) {
            if (typeof itemsKey === 'string') {
              tableData = await mappingFunctionRef.current(
                data[itemsKey as ItemsKey]
              );
            } else {
              await Promise.all(
                itemsKey.map(async (key) => {
                  const newData = await mappingFunctionRef.current!(
                    data[key as ItemsKey]
                  );

                  if (newData) {
                    tableData = [...tableData, ...newData];
                  }
                })
              );
            }
          } else {
            if (typeof itemsKey === 'string') {
              tableData = data[itemsKey as ItemsKey];
            } else {
              itemsKey.forEach((key) => {
                const newData = data[key as ItemsKey];

                if (newData) {
                  tableData = [...tableData, ...newData];
                }
              });
            }
          }

          setData(tableData as T[]);

          if (typeof totalKey === 'string') {
            setTotalCount((data as any)[totalKey]);

            setTotalPages(Math.ceil((data as any)[totalKey] / count));
          } else {
            let total = 0;

            for (let i = 0; i < totalKey.length; ++i) {
              if (total < (data as any)[totalKey[i]]) {
                total = (data as any)[totalKey[i]];
              }
            }

            setTotalCount(total);

            setTotalPages(Math.ceil(total / count));
          }
        } else {
          setData([]);
          setTotalPages(1);
          setQueryParam('page', 1);
          setErrorMessage(customErrorMessage || 'Request Failed');
        }

        loadRef.current = false;
        setTimeout(() => {
          spinnerSelector && hideOverlay(spinnerSelector);
        }, 0);
      } catch (e) {
        if (!isCancel(e)) {
          console.error(e);
        }
        setRequestError(true);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentOffset,
      spinnerSelector,
      itemsKey,
      customErrorMessage,
      abortSignal,
      setQueryParam,
    ]
  );

  const reset = useCallback(
    (revalidate = false) => {
      if (applyQueryParams) {
        setMultipleQueryParams({
          search: '',
          page: revalidate ? currentPage : 1,
          count: pCount,
          sortBy: initialSortBy,
          sortDirection: initialSortDirection,
        });
      }

      onResetRef.current?.();
      skipRequest.current = true;

      loadData(
        '',
        pCount,
        ((revalidate ? +currentPage : 1) - 1) * pCount,
        initialSortBy,
        initialSortDirection
      );
    },
    [
      applyQueryParams,
      currentPage,
      initialSortBy,
      initialSortDirection,
      pCount,
      loadData,
      setMultipleQueryParams,
    ]
  );

  const revalidate = useCallback(() => reset(true), [reset]);
  const debounceLoadData = useMemo(() => debounce(loadData, 250), [loadData]);

  const onSearchChange = useCallback(
    (search: string) => {
      skipRequest.current = true;

      setMultipleQueryParams({
        search: search,
        page: 1,
      });

      debounceLoadData(search, +count, 0, sortBy, sortDirection);
    },
    [count, debounceLoadData, sortBy, sortDirection, setMultipleQueryParams]
  );

  const pageChange = useCallback(
    (page: number, skip?: boolean) => {
      skipRequest.current = skip ?? true;

      setQueryParam('page', page);
      loadData(search, count, (page - 1) * count, sortBy, sortDirection);
    },
    [count, search, sortBy, sortDirection, loadData, setQueryParam]
  );

  const countChange = useCallback(
    (count: number) => {
      skipRequest.current = true;

      setMultipleQueryParams({
        count,
        page: 1,
      });

      loadData(search, count, 0, sortBy, sortDirection);
    },
    [search, sortBy, sortDirection, loadData, setMultipleQueryParams]
  );

  const sortChange = useCallback(
    (sort: keyof T, direction: SortDirections) => {
      skipRequest.current = true;

      setMultipleQueryParams({
        sortBy: sort as string,
        sortDirection: direction,
        page: 1,
      });

      loadData(search, count, 0, sort, direction);
    },
    [search, count, loadData, setMultipleQueryParams]
  );

  useEffect(() => {
    if (loadRef.current || !condition) return;
    if (!searchStringChanged.current && search) loadRef.current = true;

    if (skipRequest.current) {
      skipRequest.current = false;
      return;
    }

    loadData(search, count, (currentPage - 1) * count, sortBy, sortDirection);
    searchStringChanged.current = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, currentPage, count, ...deps]);

  useEffectSkipFirst(() => {
    reset();
  }, [...resetDeps]);

  useEffect(() => {
    onResetRef.current = onReset;
  });

  return {
    totalPages,
    requestError,
    errorMessage,
    data,
    count,
    loading,
    totalCount,
    currentPage,
    search,
    activeColumnSort: {
      sortBy,
      sortDirection,
    },
    revalidate,
    loadData,
    setTotalPages,
    onSearchChange,
    setCount: countChange,
    setCurrentPage: pageChange,
    setActiveColumnSort: sortChange,
  };
}
