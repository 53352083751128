import { focusStyles, theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const LeaderBoardCardContainer = styled.div`
  ${flex('column')};
  ${focusStyles};

  cursor: pointer;
  text-decoration: none;
  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  background-color: var(--BACKGROUND_1_1);
  border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_6);

  &:hover {
    border-color: var(--PRIMARY_1_1);
  }

  .kinto-leader-board-range-card {
    &__title {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_20)};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 600;
      color: var(--SHADE_1_1);
      margin: 0 0 ${theme.sizes.SIZE_20};
    }

    &__separated-text {
      margin: 0 0 ${theme.sizes.SIZE_4};
    }
  }
`;
