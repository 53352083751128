import { FC, useMemo } from 'react';
import { FormField, validators } from '@faxi/web-form';
import { Button } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { Icon, InputField } from 'components';

import * as Styled from './RewardInstance.styles';
import numberValidation from 'validation/validators/numberValidation';

type RewardInstanceProps = {
  name?: string;
  className?: string;
  hasQuantity?: boolean;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onDelete?: () => void;
};

const RewardInstance: FC<RewardInstanceProps> = (props) => {
  const {
    name,
    placeholder,
    hasQuantity,
    disabled = false,
    autoFocus,
    onDelete,
  } = props;

  const { t } = useTranslation();

  const validation = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: placeholder,
          })
        ),
        validators.general.maxLength(
          50,
          t('validation-field_validation_max_length', {
            fieldname: placeholder?.toLowerCase(),
            number: '50',
          })
        ),
      ],
      count: [
        validators.general.required(t('field_is_required')),
        numberValidation.minValue(
          t('validation-field_validation_min_value', {
            fieldname: t('input').toLowerCase(),
            number: 1,
          }),
          1
        ),
        numberValidation.maxValue(
          t('validation-field_validation_max_value', {
            fieldname: t('input').toLowerCase(),
            number: 100,
          }),
          100
        ),
      ],
    }),
    [t, placeholder]
  );

  return (
    <Styled.RewardInstance data-quantity={hasQuantity}>
      <FormField
        name={`${name}.name`}
        required
        autoComplete="off"
        autoFocus={autoFocus}
        component={InputField}
        placeholder={placeholder}
        disabled={disabled}
        requiredLabel={t('global-input_field_required_label')}
        validate={validation.name}
      />

      {hasQuantity && (
        <FormField
          component={InputField}
          type="number"
          name={`${name}.quantity`}
          placeholder={t('rewards-card_how_many')}
          min={1}
          className="create-new-campaign__rewards__add-reward__reward-count"
          required
          disabled={disabled}
          requiredLabel={t('global-input_field_required_label')}
          validate={validation.count}
        />
      )}

      <Button
        variant="delete-ghost"
        disabled={disabled}
        aria-label={t('accessibility-delete_reward_instance')}
        icon={<Icon name="trash-can" />}
        onClick={onDelete}
      />
    </Styled.RewardInstance>
  );
};

export default RewardInstance;
