import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import EmptyTab from 'components/_molecules/EmptyTab';
import SurveysBoard from '../../components/SurveysBoard';
import SustainabilityContext from '../../providers/Sustainability/Sustainability.context';

const Sustainability: FC = () => {
  const { t } = useTranslation();
  const { openModal, emptyPlaceholder } = useContext(SustainabilityContext);

  const emptyTabTitle = parse(
    t('sustainability-title_run_survey').replace(/\\n/g, '<br />')
  );

  if (emptyPlaceholder)
    return (
      <EmptyTab
        icon="leaf"
        title={emptyTabTitle}
        actionTitle={t('global-new_run_survey')}
        description={t('sustainability-subtitle_run_survey')}
        onAction={openModal}
      />
    );

  return <SurveysBoard />;
};

export default Sustainability;
