import { FC, useContext, useMemo, useRef } from 'react';
import {
  ButtonProps,
  DrawerProps,
  useUtilities,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon, InfoCard } from 'components';
import { Depot } from 'models';
import { CampaignContext } from 'store';
import { useCallbackAsync } from 'hooks';
import { apiGamification } from 'modules';
import CampaignDetails from '../CreateNewCampaign/components/CampaignDetails';
import { retrieveNestedAction } from '../CreateNewCampaign/utils';

import * as Styled from './SelectedCampaignAside.styles';

type SelectedCampaignAsideProps = {
  selectedCampaign: Depot;
  campaignState?: JSX.Element;
  onClose: (campaign?: Depot) => void;
  onUpdateCampaigns: () => Promise<void>;
} & Pick<DrawerProps, 'triggerRef'>;

const SelectedCampaignAside: FC<SelectedCampaignAsideProps> = (
  props: SelectedCampaignAsideProps
) => {
  const {
    selectedCampaign,
    campaignState,
    triggerRef,
    onClose,
    onUpdateCampaigns,
  } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { prompts, showSnackBar } = useUtilities();

  const deactivateBtnRef = useRef<HTMLButtonElement>();

  const { rewards } = useContext(CampaignContext);
  const { organisationId } = useParams<{ organisationId: string }>();

  const [handleDeactivateCampaign] = useCallbackAsync({
    showSpinner: false,
    callback: async (event) => {
      deactivateBtnRef.current = event.target as HTMLButtonElement;

      const proceed = await prompts.delete({
        btnIcon: 'ban',
        cancelBtnText: t('cancel'),
        submitBtnText: t('deactivate'),
        className: 'deactivate-campaign-prompt',
        triggerRef: deactivateBtnRef.current,
        title: t('gamification-campaigns_modal_title_are_you_sure_deactivate', {
          name: selectedCampaign?.name,
        }),
        content: (
          <InfoCard
            orientation="vertical"
            text={t('gamification-campaigns_alert_people_wont_be_able_to_see')}
            className="selected-campaign-list__deactivate-modal__info-card"
          />
        ),
      });

      if (!proceed) return;

      const data = await apiGamification.updateDepot(
        `${selectedCampaign?.id}`,
        {
          type: 'gamification',
          state: 'canceled',
        }
      );
      if (data) {
        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t('gamification-campaigns_notification_campaign_deactivated', {
            campaign: selectedCampaign?.name,
          }),
          variant: 'success',
        });
        onClose();
        onUpdateCampaigns();
      }
    },
    deps: [selectedCampaign],
  });

  const deactivateFooterAction = useMemo(
    () =>
      selectedCampaign.state &&
      ['active', 'pending'].includes(selectedCampaign.state) && {
        key: 'deactivate-action',
        children: t('deactivate'),
        variant: 'delete-outline',
        iconPosition: 'right',
        icon: <Icon name="ban" />,
        onClick: handleDeactivateCampaign,
      },
    [handleDeactivateCampaign, selectedCampaign, t]
  );

  const campaignType = useMemo(
    () =>
      retrieveNestedAction('if-accumulated', selectedCampaign?.actions?.[0])
        ? 'accumulation'
        : '',
    [selectedCampaign?.actions]
  );

  const editFooterAction = useMemo(
    () =>
      selectedCampaign.state === 'draft' &&
      ({
        key: 'edit-action',
        children: t('gamification-campaigns_button_edit_campaign_draft'),
        iconPosition: 'right',
        icon: <Icon name="pen" />,
        variant: 'outline',
        onClick: () => {
          navigate({
            pathname: `edit/${selectedCampaign.id}`,
            search: campaignType ? `type=${campaignType}` : '',
          });
        },
      } as ButtonProps),
    [campaignType, navigate, selectedCampaign, t]
  );

  const footerActions = useMemo(
    () =>
      [deactivateFooterAction, editFooterAction].filter((action) =>
        Boolean(action)
      ) as ButtonProps,
    [deactivateFooterAction, editFooterAction]
  );

  return (
    <Styled.DrawerContainer
      placement="Right"
      triggerRef={triggerRef}
      title={selectedCampaign?.name || ''}
      footerActions={footerActions as ButtonProps[]}
      ariaCloseDrawer={t('accessibility-button_close_drawer', {
        name: selectedCampaign.name,
      })}
      onClose={onClose}
      conditionallyControlled
    >
      <CampaignDetails
        asideInCampaignList
        campaign={selectedCampaign}
        campaignState={campaignState}
      />

      {rewards?.length === 0 && (
        <InfoCard
          orientation="vertical"
          linkText={t('global-gamification_go_to_rewards_page')}
          className="selected-campaign-list__summarize-info__info-card"
          text={t(
            'gamification_campaigns_alert_you_need_to_add_at_least_one_reward'
          )}
          to={`/community/${organisationId}/admin/gamification/rewards`}
        />
      )}
    </Styled.DrawerContainer>
  );
};

export default SelectedCampaignAside;
