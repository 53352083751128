import {
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext,
  useCallback,
  FC,
  PropsWithChildren,
  useDeferredValue,
} from 'react';
import {
  DatePickerValue,
  useUtilities,
  useDatePickerParams,
  useLatestRef,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { UserContext } from 'store';
import {
  ChartData,
  ChartDataTotals,
  ChartDataWithTest,
  KpiData,
  RangeStats,
} from 'models';
import {
  addChartValueInXlsx,
  formatChartData,
  setKpiDataState,
  xlsxLineSeparator,
} from 'utils/charts';
import { apiReports } from 'modules';
import { reportsConfig } from 'config/reportsConfig';
import { useAbortController } from 'hooks';

import ReportsContext from './Reports.context';
import config from 'config';
import { isCancel } from 'axios';
import useXlsxContants from './useXlsxContants';

const ReportsProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const { showOverlay, hideOverlay } = useUtilities();

  const isMounted = useRef(false);
  const location = useLocation();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  const {
    communityId,
    userPreferences: { unit, dateFormat },
    userReady,
    includeTestUsers,
    isValidCommunity,
  } = useContext(UserContext);

  const { t } = useTranslation();

  const deferredCommunityId = useDeferredValue(communityId);

  const [directions, setDirections] = useState<string>('');

  const {
    applyDefaultParams,
    dateRange: reportsDateRange,
    setDateRange: setReportsDateRange,
  } = useDatePickerParams(
    reportsConfig.range_start,
    !location.pathname.includes('dashboard')
  );

  const [loadingDistances, setLoadingDistances] = useState(true);

  const [rangeStatsReportError, setStatsByRangeReportError] = useState(false);
  const [distancesReportError, setDistancesReportError] = useState(false);

  const [kpiData, setKpiData] = useState<KpiData>();

  const [activeUsersTotal, setActiveUsersTotal] = useState<ChartDataTotals>({
    total: 0,
    testTotal: 0,
  });

  const [rangeStats, setRangeStats] = useState<RangeStats>();

  const numberOfMessagesTotal = useMemo(
    () =>
      rangeStats
        ? Object.values(rangeStats.messagesCount.chartData)
            .flatMap((el) => +el)
            .reduce((a, b) => a + b, 0)
        : 0,
    [rangeStats]
  );

  const numberOfTestMessagesTotal = useMemo(
    () =>
      rangeStats
        ? includeTestUsers
          ? Object.values(rangeStats.messagesCount.testChartData as ChartData)
              .flatMap((el) => +el)
              .reduce((a, b) => a + b, 0)
          : 0
        : 0,
    [includeTestUsers, rangeStats]
  );

  const {
    abortSignal: initDistanceAbortSignal,
    cancelPreviousRequest: cancelDistanceRequest,
  } = useAbortController();

  const {
    abortSignal: initStatByRangeSignal,
    cancelPreviousRequest: cancelStatByRangeRequest,
  } = useAbortController();

  const fetchDistances = useCallback(
    async (startDate: string, endDate: string, organisationId: number) => {
      try {
        cancelDistanceRequest();
        setLoadingDistances(true);

        showOverlay('#active-users-chart');
        showOverlay('#walking-chart');
        showOverlay('#cycling-chart');
        showOverlay('#walking_report_number');
        showOverlay('#cycling_report_number');
        showOverlay('#co2-chart');
        showOverlay('#nox-chart');
        showOverlay('#environmental_co2_report_number');
        showOverlay('#environmental_nox_report_number');

        const {
          rc,
          'date-range': dateRange,
          'active-users-total': activeUsersTotal,
          'active-users-total-test': activeUsersTotalTest,
          'no-journeys-total': noJourneysTotal,
          'no-journeys-total-test': noJourneysTotalTest,
          'verified-journeys-total': verifiedJourneysTotal,
          'verified-journeys-total-test': verifiedJourneysTotalTest,
        } = await apiReports.getKpi(
          startDate,
          endDate,
          organisationId,
          unit,
          directions,
          {
            signal: initDistanceAbortSignal(),
          }
        );

        if (rc === 'ok') {
          setDistancesReportError(false);

          setKpiData(() => ({
            activeUsers: setKpiDataState(
              dateRange,
              'active_users',
              includeTestUsers
            ),

            walkingDistances: setKpiDataState(
              dateRange,
              'walked_distance',
              includeTestUsers
            ),

            cyclingDistances: setKpiDataState(
              dateRange,
              'cycled_distance',
              includeTestUsers
            ),

            verifications: setKpiDataState(
              dateRange,
              'no_journeys',
              includeTestUsers
            ),

            verifiedJourneys: setKpiDataState(
              dateRange,
              'verified_journeys',
              includeTestUsers
            ),

            passengerDistances: setKpiDataState(
              dateRange,
              'passengers_distance',
              includeTestUsers
            ),

            co2: setKpiDataState(dateRange, 'co2', includeTestUsers),

            nox: setKpiDataState(dateRange, 'nox', includeTestUsers),

            walkingTotal: Number(
              dateRange[endDate]['walked_cumulative_distance'].toFixed(2)
            ),

            ...(includeTestUsers && {
              walkingTotalTest: Number(
                dateRange[endDate]['walked_cumulative_distance-test'].toFixed(2)
              ),
            }),

            cyclingTotal: Number(
              dateRange[endDate]['cycled_cumulative_distance'].toFixed(2)
            ),

            ...(includeTestUsers && {
              cyclingTotalTest: Number(
                dateRange[endDate]['cycled_cumulative_distance-test'].toFixed(2)
              ),
            }),

            passengerDistancesTotal: Number(
              dateRange[endDate]['passengers_cumulative_distance'].toFixed(2)
            ),

            ...(includeTestUsers && {
              passengerDistancesTotalTest: Number(
                dateRange[endDate][
                  'passengers_cumulative_distance-test'
                ].toFixed(2)
              ),
            }),

            noxTotal: Number(dateRange[endDate]['nox_cumulative'].toFixed(2)),

            ...(includeTestUsers && {
              noxTotalTest: Number(
                dateRange[endDate]['nox_cumulative-test'].toFixed(2)
              ),
            }),

            co2Total: Number(dateRange[endDate]['co2_cumulative'].toFixed(2)),

            ...(includeTestUsers && {
              co2TotalTest: Number(
                dateRange[endDate]['co2_cumulative-test'].toFixed(2)
              ),
            }),

            verificationsTotal: noJourneysTotal,
            ...(includeTestUsers && {
              verificationsTotalTest: noJourneysTotalTest,
            }),

            verifiedJourneysTotal: verifiedJourneysTotal,
            ...(includeTestUsers && {
              verifiedJourneysTotalTest: verifiedJourneysTotalTest,
            }),
          }));

          setActiveUsersTotal(() => ({
            total: activeUsersTotal,
            ...(includeTestUsers && { testTotal: activeUsersTotalTest }),
          }));
        }
      } catch (e) {
        if (!isCancel(e)) {
          console.error(e);
        }
        setDistancesReportError(true);
      } finally {
        setLoadingDistances(false);
        hideOverlay('#active-users-chart');
        hideOverlay('#walking-chart');
        hideOverlay('#walking_report_number');
        hideOverlay('#cycling-chart');
        hideOverlay('#cycling_report_number');
        hideOverlay('#co2-chart');
        hideOverlay('#nox-chart');
        hideOverlay('#environmental_co2_report_number');
        hideOverlay('#environmental_nox_report_number');
      }
    },
    [
      cancelDistanceRequest,
      showOverlay,
      unit,
      initDistanceAbortSignal,
      includeTestUsers,
      hideOverlay,
      directions,
    ]
  );

  const fetchStatsByRange = useCallback(
    async (
      organisationId: number,
      startDate: string,
      endDate: string,
      unit: string
    ) => {
      try {
        cancelStatByRangeRequest();

        showOverlay('#messages-chart');
        showOverlay('#verified-journeys-chart');
        showOverlay('#registrations-chart');
        showOverlay('#passenger-distances-chart');
        showOverlay('#registrations_report_number');
        showOverlay('#journeys_report_number');
        showOverlay('#passenger_distances_number');
        showOverlay('#message_numbers_report_number');

        const data = await apiReports.statsByRangeReport(
          organisationId,
          startDate,
          endDate,
          unit,
          { signal: initStatByRangeSignal() }
        );

        const { rc, stats } = data;

        if (rc === 'ok') {
          if (isMounted.current) {
            setRangeStats({
              registeredUsers: {
                chartData: formatChartData(stats.user.approved),
                testChartData: formatChartData(
                  stats.user['approved-test'] || {}
                ),
                exportData: formatChartData(stats.user.approved, '', false),
                testExportData: formatChartData(
                  stats.user['approved-test'] || {},
                  '',
                  false
                ),
              },
              messagesCount: {
                chartData: formatChartData(stats.message.count),
                testChartData: formatChartData(
                  stats.message?.['count-test'] || {}
                ),
                exportData: formatChartData(stats.message.count, '', false),
                testExportData: formatChartData(
                  stats.message?.['count-test'] || {},
                  '',
                  false
                ),
              },
            });

            setStatsByRangeReportError(false);
          }
        }
      } catch (e) {
        if (!isCancel(e)) {
          console.error(e);
        }
        isMounted.current && setStatsByRangeReportError(true);
      } finally {
        hideOverlay('#messages-chart');
        hideOverlay('#verified-journeys-chart');
        hideOverlay('#registrations-chart');
        hideOverlay('#passenger-distances-chart');
        hideOverlay('#registrations_report_number');
        hideOverlay('#journeys_report_number');
        hideOverlay('#passenger_distances_number');
        hideOverlay('#message_numbers_report_number');
      }
    },
    [cancelStatByRangeRequest, hideOverlay, initStatByRangeSignal, showOverlay]
  );

  const loadReportsData = useCallback(
    async (range: DatePickerValue) => {
      if (!communityId) return;

      const { from, to } = range;
      const fromDate = from.format(config.apiDateFormat);
      const toDate = to.format(config.apiDateFormat);

      fetchDistances(fromDate, toDate, communityId);
      fetchStatsByRange(communityId, fromDate, toDate, unit);
    },
    [communityId, fetchDistances, fetchStatsByRange, unit]
  );

  const xlsxConstants = useXlsxContants();

  // removeApostrophe function is used because firefox can not show date in this format
  const convertReportsDataToArrays = useCallback(async () => {
    if (!kpiData || !rangeStats || !kpiData || !communityId) {
      return;
    }

    const array: string[][] = [];

    array.push([t('start'), reportsDateRange?.from.format(dateFormat) || '']);
    array.push([t('end'), reportsDateRange?.to.format(dateFormat) || '']);

    xlsxLineSeparator(array);

    xlsxConstants[0].forEach(
      ({ dataKey, title, columns, testTitle, testColumns }) => {
        addChartValueInXlsx({
          data: (kpiData?.[`${dataKey as keyof KpiData}`] ||
            rangeStats?.[
              `${dataKey as keyof RangeStats}`
            ]) as ChartDataWithTest,
          array,
          includeTestUsers,
          dateFormat,
          title,
          columns,
          testTitle,
          testColumns,
        });
      }
    );

    xlsxConstants[1].forEach(
      ({ dataKey, title, columns, testTitle, testColumns }) => {
        addChartValueInXlsx({
          data: kpiData?.[`${dataKey as keyof KpiData}`] as ChartDataWithTest,
          array,
          includeTestUsers,
          dateFormat,
          title,
          columns,
          testTitle,
          testColumns,
        });
      }
    );

    return array;
  }, [
    kpiData,
    rangeStats,
    communityId,
    t,
    reportsDateRange?.from,
    reportsDateRange?.to,
    dateFormat,
    xlsxConstants,
    includeTestUsers,
  ]);

  const deferredCommunityIdRef = useLatestRef(deferredCommunityId);
  const isValidcommunityRef = useLatestRef<boolean>(isValidCommunity);
  const loadReportsDataRef = useLatestRef(loadReportsData);
  const applyDefaultParamsRef = useLatestRef(applyDefaultParams);

  useEffect(() => {
    if (!isValidcommunityRef.current || !reportsDateRange || !communityId) {
      return;
    }

    if (communityId !== deferredCommunityIdRef.current) {
      applyDefaultParamsRef.current();
    }

    loadReportsDataRef.current(reportsDateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReady, communityId, reportsDateRange]);

  return (
    <ReportsContext.Provider
      value={{
        kpiData,
        activeUsersTotal,
        dateRange: reportsDateRange,
        rangeStats,
        rangeStatsReportError,
        distancesReportError,
        loadingDistances,
        numberOfMessagesTotal,
        numberOfTestMessagesTotal,
        applyDefaultDateParams: applyDefaultParams,
        statsByRangeReport: fetchStatsByRange,
        updateDateRange: setReportsDateRange,
        updateDirections: setDirections,
        convertReportsDataToArrays,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export default ReportsProvider;
