import httpClient from '../httpClient';

// TODO: hardcoded version param
const getConsents = async (userId: string, platformId: number) =>
  httpClient
    .get(`/user/${userId}/consents`, {
      params: {
        idpltf: platformId,
        version: 3,
      },
    })
    .then((res) => res.data.payloadData);

const uploadConsents = async (
  userId: string,
  data: any[],
  platformId: number
) => {
  const formData = new FormData();
  formData.append('consent', JSON.stringify(data));

  return httpClient
    .post<{ rc: 'ok' | 'error' }>(`user/${userId}`, formData, {
      params: { _method: 'PUT', idpltf: platformId },
    })
    .then((res) => res.data);
};

export default {
  getConsents,
  uploadConsents,
};
