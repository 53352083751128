import { FC, useContext, useCallback, DetailedHTMLProps } from 'react';
import { GoogleMapsContext, Icon, Image } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Home } from 'models';
import { MarkerClusterContext } from 'pages/Map/providers/MarkerCluster';

import * as Styled from './UserItem.styles';

type UserItemProps = Home & {
  className?: string;
  image_url?: string;
  onClick?: () => void;
} & Omit<
    DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>,
    'ref' | 'id'
  >;

const UserItem: FC<UserItemProps> = (props) => {
  const {
    id,
    first_name,
    last_name,
    email,
    lat,
    lng,
    className,
    image_url,
    onClick,
    ...rest
  } = props;

  const { t } = useTranslation();

  const { markerMapRef, overlappingMarkerSpiderfier, activePin } =
    useContext(MarkerClusterContext);

  const { map } = useContext(GoogleMapsContext);

  const onPinClick = useCallback(() => {
    if (map) {
      if (activePin && !(lat === activePin.lat && lng === activePin.lng)) {
        map.panTo({
          lat,
          lng,
        });
      }

      const markerMeta = markerMapRef.current[`${lat},${lng}`];

      // window.google.maps.event.trigger(markerMeta.marker, 'click');
      overlappingMarkerSpiderfier?.trigger('click', markerMeta.marker, {});

      if (map.getZoom()! < 21 && !activePin) {
        map.setZoom(21);
      }
    }
    onClick?.();
  }, [
    map,
    onClick,
    activePin,
    lat,
    lng,
    markerMapRef,
    overlappingMarkerSpiderfier,
  ]);

  return (
    <Styled.Container
      id={`map_select_user_${id}`}
      className={classNames('kinto-user-item', className)}
      onClick={onPinClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onPinClick();
      }}
      {...rest}
    >
      <Image
        className={classNames('profile-img', 'kinto-user-item__image')}
        src={image_url || ''}
        alt={t('user_profile_picture', { user: first_name })}
        fallbackUrl="/assets/svg/user_circle_placeholder.svg"
      />
      <div className="kinto-user-item__name">{`${
        [first_name, last_name].join(' ').trim() || email
      }`}</div>
      <Icon className="kinto-user-item__icon" name="location-dot" />
    </Styled.Container>
  );
};

export default UserItem;
