import { useTranslation } from 'react-i18next';
import { FormField, validators } from '@faxi/web-form';

import { GoogleAutocompleteField } from '../../../../../components';
import SurveyFormWrapper from '../../../components/SurveyFormWrapper';

const DestinationAddress = () => {
  const { t } = useTranslation();

  return (
    <SurveyFormWrapper className="kinto-survey-form-container">
      <FormField
        name="destination-address"
        autoComplete="off"
        component={GoogleAutocompleteField}
        placeholder={t('groupreg_enter_validaddress1')}
        validate={validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('global-workplace_address'),
          })
        )}
      />
    </SurveyFormWrapper>
  );
};

export default DestinationAddress;
