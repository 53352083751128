import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Button, useCallbackRef } from '@faxi/web-component-library';
import ReactCrop, { ReactCropProps } from 'react-image-crop';
import { Accept, DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';

import * as Styled from './ImageCrop.styles';

function blobToFile(blob: Blob, fileName: string): File {
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });
}

type ImageCropProps = {
  file: File;
  accept?: Accept;
  className?: string;
  dropzoneProps: DropzoneRootProps;
  dropzoneInputProps: DropzoneInputProps;
  onSave?: (file: File) => void;
  onClose?: () => void;
} & Partial<ReactCropProps>;

const ImageCrop: FC<ImageCropProps> = (props) => {
  const {
    file,
    accept,
    circularCrop = false,
    dropzoneProps,
    dropzoneInputProps,
    onSave,
    onClose,
    ...rest
  } = props;

  const { t } = useTranslation();

  const fileRef = useRef<File>(file);
  fileRef.current = file;

  const [img, imgRef] = useCallbackRef<HTMLImageElement>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(
    document.createElement('canvas')
  );

  const [blobImage, setBlobImage] = useState<Blob | null>(null);

  const [completedCrop, setCompletedCrop] = useState<ReactCrop.Crop>();
  const [preview, setPreview] = useState<string>('');
  const [crop, setCrop] = useState<ReactCrop.Crop>({
    aspect: 1,
    width: 50,
    unit: '%',
  });

  const acceptableFiles = useMemo(() => {
    if (!accept) return '';

    const formats = Object.values(accept).reduce(
      (value, formats) => [...value, ...formats],
      []
    );

    return formats.reduce(
      (value, format, index, formats) =>
        index === formats.length - 1
          ? value.concat(`${t('or')} ${format}`)
          : value.concat(`${format}, `),
      ''
    );
  }, [accept, t]);

  useEffect(() => {
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(
      typeof file === 'string' ? blobToFile(file, (file as File).name) : file
    );

    reader.onload = (e) => {
      setPreview(e.target?.result as string);
    };
  }, [file]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !img) {
      return;
    }

    const canvas = previewCanvasRef.current;

    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    if (ctx && completedCrop) {
      const crop = completedCrop;
      canvas.width = completedCrop.width! * pixelRatio;
      canvas.height = completedCrop.height! * pixelRatio;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        img,
        crop.x! * scaleX,
        crop.y! * scaleY,
        crop.width! * scaleX,
        crop.height! * scaleY,
        0,
        0,
        crop.width!,
        crop.height!
      );

      canvas.toBlob(setBlobImage);
    }
  }, [completedCrop, img]);

  return (
    <Styled.ImageCropContainer>
      <div tabIndex={0} className="kinto-image-crop__title">
        {t('upload_from_device')}
      </div>

      {!file && (
        <div className="kinto-image-crop__drop-container" {...dropzoneProps}>
          <input hidden {...dropzoneInputProps} />
          <Icon
            name="image"
            className="kinto-image-crop__drop-container__img-icon"
          />

          <p className="kinto-image-crop__drop-container__content">
            {t('drop_your_image_here')} {t('Or')}
          </p>
          <Button
            variant="ghost"
            iconPosition="right"
            icon={<Icon name="chevron-right" />}
          >
            {t('select_from_file')}
          </Button>
        </div>
      )}

      {!file && (
        <p className="kinto-image-crop__acceptable-types">{`${acceptableFiles} ${t(
          'up_to'
        )} 5mb`}</p>
      )}

      {file && (
        <ReactCrop
          {...rest}
          crop={crop}
          src={preview}
          circularCrop={circularCrop}
          onChange={setCrop}
          onImageLoaded={imgRef}
          onComplete={setCompletedCrop}
        />
      )}

      <div className="kinto-image-crop__actions">
        <Button
          type="button"
          variant="primary"
          disabled={!blobImage}
          onClick={() => onSave?.(blobToFile(blobImage!, fileRef.current.name))}
        >
          {t('Save')}
        </Button>
        <Button type="button" variant="ghost" onClick={onClose}>
          {t('cancel')}
        </Button>
      </div>
    </Styled.ImageCropContainer>
  );
};

export default memo(ImageCrop);
