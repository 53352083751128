import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const RewardType = styled.div`
  ${flex('column')}
  gap: ${theme.sizes.SIZE_12};

  .kinto-reward-type {
    &__title {
      ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};

      font-weight: 400;
      margin: 0 0 ${theme.sizes.SIZE_16};
      color: var(--SECONDARY_1_1);
    }
  }
`;
