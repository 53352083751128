import styled, { css } from 'styled-components';
import {
  flex,
  fontSize,
  position,
  aboveTablet,
  phablet,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';

import { elevate_xxl, theme } from '@faxi/web-component-library';

////////////////////// BOX SHADOW STYLES ////////////////////////////

export const hideAbovePhablet = css`
  ${aboveTablet(css`
    display: none;
  `)}
`;

export const hideAboveTablet = css`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const hideBelowPhablet = css`
  ${phablet(css`
    display: none;
  `)}
`;

export const LoadingOverlay = styled.div`
  ${position('fixed', '0')};
  ${flex('column', 'center', 'center')};
`;

export const NoData = styled.p`
  ${fontSize(theme.sizes.SIZE_16, '22px')};

  font-weight: 600;
  color: var(--SHADE_1_2);
  margin: ${theme.sizes.SIZE_24};
`;

export const PanelContainer = styled.div`
  padding: 0 ${theme.sizes.SIZE_48} ${theme.sizes.SIZE_46};

  ${phablet(
    css`
      padding: 0 ${theme.sizes.SIZE_20} ${theme.sizes.SIZE_20};
      width: 100%;
    `
  )};
`;

const p16 = theme.sizes.SIZE_16;
const p24 = theme.sizes.SIZE_24;

export const SearchableUsersSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: var(--BACKGROUND_1_1);

  .navigation-list__item {
    .navigation-tab {
      ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};

      padding: ${p16} 0 ${p16} ${p24};

      &:not(&--active) {
        border-bottom: none;
      }

      &__active-border {
        background-color: var(--ACCENT_1_1);
        width: ${theme.sizes.SIZE_2};
      }

      &--active > *,
      :has([class*='unread-count']) {
        color: var(--SECONDARY_1_1);
        font-weight: 600;
      }

      &--active {
        border-bottom: 1px solid var(--SHADE_1_7);
        border-top: 1px solid var(--SHADE_1_7);
      }
    }
  }

  .kinto-searchable-users-section {
    width: ${theme.sizes.SIZE_424};
    max-width: 100%;

    &__top {
      padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_24} 0;

      h1 {
        margin: ${theme.sizes.SIZE_20} 0 0;
      }
    }

    &__send-to-all {
      ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};

      font-weight: 600;
      background-color: var(--SHADE_1_9);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: ${theme.sizes.SIZE_24};
      word-break: normal;
      overflow-wrap: anywhere;
      color: var(--SECONDARY_1_1);

      &--disabled {
        opacity: 0.5;

        > button {
          pointer-events: none;
        }
      }

      &__icon-container {
        ${size(theme.sizes.SIZE_56)};
        ${elevate_xxl};

        border: 1px solid var(--SHADE-1-7);
        padding: 0;
        aspect-ratio: 1;
        border-radius: 50%;
        margin-right: ${theme.sizes.SIZE_20};
        font-size: ${theme.fontSizes.FONT_18};
        flex: 0 0 ${pxToRem(theme.sizes.SIZE_46)};
      }
    }

    &__search {
      background-color: var(--BACKGROUND_1_1);
      padding: ${theme.sizes.SIZE_24};
    }

    &__navigation {
      width: 100%;
      flex: 1 1 100%;

      ${phablet(css`
        .kinto-tab-navigation__infinite-scroll {
          position: unset;
          inset: auto;
        }
      `)}
    }
  }
`;
