import {
  GoogleMapsContext,
  useEffectOnceWhen,
} from '@faxi/web-component-library';
import { Home } from 'models';
import { MarkerClusterContext } from 'pages/Map/providers/MarkerCluster';
import createUserMarker from 'pages/Map/utils/createUserMarker';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';

export type MarkerClickHandler = (
  pin: Home,
  marker: google.maps.Marker,
  event: google.maps.MapMouseEvent
) => void;

type UserPinProps = Home & {
  image_url?: string;
  onMarkerAdd: (id: number, marker: google.maps.Marker) => void;
  onClick?: MarkerClickHandler;
};

const UserPin: FC<UserPinProps> = (props) => {
  const { lat, lng, id, image_url, first_name, last_name, email } = props;
  const { onClick, onMarkerAdd, ...pin } = props;
  const { map } = useContext(GoogleMapsContext);
  const {
    markerCluster,
    overlappingMarkerSpiderfier,
    markerMapRef,
    addMarkerToClusterDebounced,
  } = useContext(MarkerClusterContext);

  const clickListener = useRef<google.maps.MapsEventListener>();

  const [userMarker, setUserMarker] = useState<google.maps.Marker | null>(null);

  useEffectOnceWhen(() => {
    const renderMarker = async () => {
      const marker = await createUserMarker(
        { lat, lng },
        map!,
        [first_name, last_name].join(' ').trim() || email,
        image_url
      );

      onMarkerAdd(id, marker);
      setUserMarker(marker);
    };

    renderMarker();
  }, !!(markerCluster && map));

  const pinKey = useMemo(() => `${pin.lat},${pin.lng}`, [pin]);

  useEffect(() => {
    if (!markerCluster || !userMarker || !pinKey) return;
    addMarkerToClusterDebounced(userMarker, pinKey);
    overlappingMarkerSpiderfier?.addMarker(userMarker);
    return () => {
      overlappingMarkerSpiderfier?.removeMarker(userMarker);
    };
  }, [
    markerCluster,
    userMarker,
    pinKey,
    overlappingMarkerSpiderfier,
    addMarkerToClusterDebounced,
  ]);

  useEffect(() => {
    if (userMarker) {
      setTimeout(() => {
        const markerEl = document.querySelector(`[title="${pin.id}"`);
        if (markerEl) markerEl.setAttribute('id', `map_click_user_${pin.id}`);
      }, 500);
    }
  }, [userMarker, pin.id]);

  useEffect(() => {
    if (onClick && userMarker) {
      clickListener.current = window.google.maps.event.addListener(
        userMarker,
        'spider_click',
        function (this: google.maps.Marker, ev: google.maps.MapMouseEvent) {
          onClick(pin, this, ev);
        }
      );
    }
    return () => {
      clickListener.current?.remove();
    };
  }, [userMarker, onClick, pin]);

  useEffect(() => {
    if (userMarker) {
      const listener = window.google.maps.event.addListener(
        userMarker,
        'spider_format',
        function (status) {
          if (markerMapRef.current[pinKey]) {
            markerMapRef.current[pinKey].status = status;
          }
        }
      );
      return () => {
        listener.remove();
      };
    }
  }, [markerMapRef, pinKey, userMarker]);

  return null;
};

export default UserPin;
