import { useLatestRef, useEffectSkipFirst } from '@faxi/web-component-library';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../../utils';
import { FC } from 'react';

type ScrollToTopProps = { targetNodeId: string };

const ScrollToTop: FC<ScrollToTopProps> = (props) => {
  const { targetNodeId } = props;
  const location = useLocation();

  const targetNode = useLatestRef<HTMLElement>(
    document.getElementById(targetNodeId) as HTMLElement
  );

  useEffectSkipFirst(() => {
    scrollToTop(targetNode.current || document.body);
  }, [location.pathname]);

  return <></>;
};

export default ScrollToTop;
