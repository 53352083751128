import { createContext } from 'react';
import { CarSpaceProps } from 'pages/Settings/CarParkSettings/CarParkSettings.component';

type CarSpaceContext = {
  carParkSpaces: CarSpaceProps[];
  addNewCarSpace: (carSpaceName: string) => void;
  updateCarSpace: (name: string, carSpaceId: string) => void;
  removeCarSpace: (carSpaceId: string) => void;
};

export default createContext<CarSpaceContext>({
  carParkSpaces: [],
  addNewCarSpace: () => {},
  updateCarSpace: () => {},
  removeCarSpace: () => {},
});
