import ViewsProvider, { View } from 'providers/Views';
import ShiftSettingsProvider from './providers/ShiftSettings';
import ShiftSettingsModal from './ShiftSettingsModal.component';
import { PickShift, AssignedShifts, SelectSchedule } from './views';
import { ShiftSettingsProviderProps } from './providers/ShiftSettings/ShiftSettings.provider';
import { useMemo } from 'react';

export type ShiftSettingsViews =
  | 'pick-shift'
  | 'select-schedule'
  | 'assigned-shifts';

const views = [
  {
    key: 'pick-shift',
    component: PickShift,
  },
  {
    key: 'select-schedule',
    component: SelectSchedule,
  },
  {
    key: 'assigned-shifts',
    component: AssignedShifts,
  },
] as View<ShiftSettingsViews>[];

const ViewsWrapper = ({
  shifts,
  selectedShift,
  assignedShifts,
  ...rest
}: ShiftSettingsProviderProps) => {
  const initialView = useMemo(
    () =>
      selectedShift
        ? 'select-schedule'
        : ((Object.values(assignedShifts).filter(
            (s) => s !== null && s.length > 0
          ).length > 0
            ? 'assigned-shifts'
            : 'pick-shift') as ShiftSettingsViews),
    [assignedShifts, selectedShift]
  );

  return (
    <ViewsProvider<ShiftSettingsViews> initialView={initialView} views={views}>
      <ShiftSettingsProvider
        shifts={shifts}
        selectedShift={selectedShift}
        assignedShifts={assignedShifts}
      >
        <ShiftSettingsModal {...rest} />
      </ShiftSettingsProvider>
    </ViewsProvider>
  );
};

export default ViewsWrapper;
