import { NavLink, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import {
  Button,
  getColor,
  Icon,
  ProgressDonut,
} from '@faxi/web-component-library';
import classNames from 'classnames';

import * as Styled from './OnboardingStepsSidebar.styles';

type OnboardingStepsSidebarProps = {
  steps: string[];
  activeStep: number;
  className?: string;
};
const OnboardingStepsSidebar = (props: OnboardingStepsSidebarProps) => {
  const { steps, activeStep, className } = props;

  const location = useLocation();

  return (
    <Styled.OnboardingStepsSidebar
      className={classNames(className, 'onboarding-steps-sidebar')}
    >
      <ProgressDonut percentage={(activeStep * 100) / 3} />

      <ul>
        {steps.map((step: string, index) => (
          <li key={index}>
            <Icon
              name={activeStep > index ? 'check-circle-solid' : 'circle'}
              color={activeStep > index ? getColor('--ACCENT_1_1') : 'white'}
            />
            <span>{step}</span>
          </li>
        ))}
      </ul>

      <NavLink
        to={
          activeStep === 1
            ? 'account-settings/update-profile'
            : 'account-settings/update-address'
        }
      >
        {location.pathname.includes('home') && (
          <Button
            variant="primary-invert"
            className="onboarding-steps-sidebar__complete-btn"
          >
            {t('steps_account-button_account_created')}
          </Button>
        )}
      </NavLink>
    </Styled.OnboardingStepsSidebar>
  );
};

export default OnboardingStepsSidebar;
