import { FC, useState } from 'react';
import { Heading, getColor } from '@faxi/web-component-library';
import { t } from 'i18next';

import { PreapprovedSettings } from 'components';
import PreapprovedSettingsForm from 'components/_forms/PreapprovedSettingsForm';
import { PageLayout } from 'components/_layouts';

const PreapprovedSettingsTab: FC = (): JSX.Element => {
  const [openForm, setOpenForm] = useState(false);

  const handleOpenForm = () => {
    setOpenForm(true);
  };
  const handleCloseForm = () => {
    setOpenForm(false);
  };

  return (
    <PageLayout className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('preapproved_settings')}
      </Heading>

      {openForm ? (
        <PreapprovedSettingsForm closeForm={handleCloseForm} />
      ) : (
        <PreapprovedSettings openForm={handleOpenForm} />
      )}
    </PageLayout>
  );
};

export default PreapprovedSettingsTab;
