export const AUTH_BUS_EVENTS = {
  SESSION_EXPIRED: 'session_expired',
  REDIRECT_INTERSTITIAL: 'redirect_interstitial',
  REDIRECT_LOGIN_SUCCESS: 'redirect_login_success',
  REDIRECT_DISABLED_COMMUNITY: 'redirect_disabled-community',
  REFRESH_TOKEN_SUCCESS: 'refresh_token_success',
  REFRESH_TOKEN_FAIL: 'refresh_token_failed',
  NON_EXISTING_COMMUNITY: 'non_existing_community',
  MESSAGE_SENT: 'message_sent',
  SHOW_SNACKBAR: 'show_snackbar',
} as const;
type EventName = (typeof AUTH_BUS_EVENTS)[keyof typeof AUTH_BUS_EVENTS];

export default {
  listeners: {} as Record<EventName, Function[]>,
  addEventListener<T = any>(event: EventName, callback: (data: T) => void) {
    if (!this.listeners[event]) this.listeners[event] = [];
    this.listeners[event].push(callback);
    return () => {
      this.removeEventListener(event, callback);
    };
  },
  removeEventListener<T = any>(event: EventName, callback: (data: T) => void) {
    this.listeners[event] = this.listeners[event].filter(
      (cb) => cb !== callback
    );
  },
  broadcastEvent<T = any>(event: EventName, payload?: T) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((cb) => cb(payload));
    }
  },
};
