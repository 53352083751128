import { FC, useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Heading, getColor } from '@faxi/web-component-library';
import { Outlet, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import { UserContext } from 'store';
import { PageLayout } from 'components/_layouts';
import SustainabilityProvider from './providers/Sustainability';

const SustainabilityRouter: FC = () => {
  const { communityId, community, userReady } = useContext(UserContext);

  const navigate = useNavigate();

  const premiumFeatureAvailable = useMemo(
    () => !!community?.premium_features?.find(({ name }) => name === 'survey'),
    [community]
  );

  useEffect(() => {
    if (!premiumFeatureAvailable && communityId && userReady) {
      navigate(`community/${communityId}/home`);
    }
  }, [premiumFeatureAvailable, communityId, userReady, navigate]);

  return (
    <SustainabilityProvider>
      <PageLayout className={classNames('kinto-page', 'sustainability')}>
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__heading"
        >
          {t('global-sustainability')}
        </Heading>

        <Outlet />
      </PageLayout>
    </SustainabilityProvider>
  );
};

export default SustainabilityRouter;
