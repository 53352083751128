import { elevate_l, elevate_xl, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Schedule = styled.div`
  ${elevate_l};

  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  background-color: var(--BACKGROUND_1_1);

  .select-schedule {
    &__header {
      ${flex('row', 'space-between', 'center')};

      width: 100%;
      margin: 0 0 ${theme.sizes.SIZE_12};

      .delete-selected {
        ${size(theme.sizes.SIZE_32)};
        min-height: unset;
      }
    }

    &__title {
      ${fontSize(theme.fontSizes.FONT_12, theme.sizes.SIZE_32)};

      margin: 0;
      font-weight: 600;
      color: var(--SHADE_1_2);
    }

    &__selected {
      pointer-events: none;
      margin: 0 0 ${theme.sizes.SIZE_32};
    }

    &__set-schedule {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      font-weight: 600;
      color: var(--SHADE_1_1);
    }

    &__calendar {
      ${elevate_xl};
      width: 100%;
    }

    &__save {
      margin-left: auto;
    }

    &__menu,
    &__set-schedule,
    &__calendar {
      margin: 0 0 ${theme.sizes.SIZE_20};
    }
  }
`;
