import { useState } from 'react';

export default function useThrowAsyncError<T = any>() {
  const [, setThrowAsyncError] = useState();

  return (error: T) => {
    setThrowAsyncError(() => {
      throw error;
    });
  };
}
