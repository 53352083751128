const settingsTranslations = {
  id: 'ID',

  profile: 'my_account-title_profile_image',
  firstName: 'register_fname_hint',
  lastName: 'register_lname_hint',
  address1: 'register_address_1_hint',
  city: 'register_city_hint',
  county: 'userProfile_county',
  postcode: 'register_postcode_hint',
  country: 'userProfile_country',
  email: 'register_email_hint',
  mobileNumber: 'register_mobile_hint',
  currentPassword: 'current_password',
  communityName: 'joinGroup_search_by_groupName',
  communityNumber: 'community_number',
  communityEmail: 'community_email',
  address: 'address_header',

  description: 'dw_description',
  name: 'name',
  phone: 'dashboard_details_phone_number',
  support_email: 'register_email_hint',
  expected_size: 'community_details-expected_community_size',
  numberOfJourneys: 'community_details-number_of_journeys_per_user',

  lastActive: 'last_active',
  joined: 'people-title_joined_date',
  communityDescription: 'dw_description',
  shifts: 'shift_settings-title_shift',
  includeTestUsers: 'my_settings-title_show_test_users',
  status: 'ga_gd_status',
  currency: 'groupreg_currency',
  co2: 'statistics_eco',
  nox: 'global-nox_saved',
  no_of_journeys: 'number_of_journeys',
  distance: 'miles_saved',
} as const;

export type SettingsTranslationKey = keyof typeof settingsTranslations;

export default settingsTranslations;
