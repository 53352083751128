import {
  CreateNewPasswordForm,
  DisabledCommunity,
  LoginForm,
  ResetPasswordForm,
} from 'components';
import { ExtendRouteObject } from 'router/routes';
import { Navigate } from 'react-router-dom';
import { appUri } from '../../../config';

export default [
  {
    handle: 'login-form',
    path: '',
    element: <LoginForm />,
  },
  {
    handle: 'reset-password-form',
    path: appUri.LOGIN_RESET_PASSWORD,
    element: <ResetPasswordForm />,
  },
  {
    handle: 'change-password-form',
    path: appUri.LOGIN_CHANGE_PASSWORD,
    element: <CreateNewPasswordForm />,
  },
  {
    handle: 'disabled-community',
    path: appUri.LOGIN_DISABLED_COMMUNITY,
    element: <DisabledCommunity />,
  },

  {
    handle: 'any',
    path: '*',
    element: <Navigate to="" replace />,
  },
] as ExtendRouteObject[];
