import { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Image, getColor } from '@faxi/web-component-library';

import { Icon } from 'components';
import { useHeadTitle } from 'hooks';
import { INameExtended } from 'components/Icon';
import instructionSteps from './gamificationTabs';

import * as Styled from './Gamification.styles';

type GamificationLinkProps = {
  className?: string;
  to: string;
  title?: string;
  description?: string;
  hintText?: string;
};

const GamificationLink: FC<GamificationLinkProps> = (props) => {
  const { to, title, description, hintText, className } = props;

  return (
    <Link
      to={to}
      className={classNames('kinto-gamification-tab__link', className)}
    >
      <h2 className="link-title">{title}</h2>
      <p className="link-description">{description}</p>
      {hintText && <p className="link-hint">{hintText}</p>}
      <Icon name="arrow-right" className="kinto-gamification-tab__arrow-icon" />
    </Link>
  );
};

const Gamification: FC = () => {
  const { t } = useTranslation();

  useHeadTitle(`${t('group_settings')} - ${t('global-gamification')}`);

  return (
    <Styled.Gamification className={classNames('kinto-gamification')}>
      <section className="kinto-gamification__card">
        <h1 className="kinto-gamification__card__title">
          {t('gamification-campaigns_title_reward_people')}
        </h1>

        <div className="kinto-gamification__instructions">
          {instructionSteps.map((instruction, index) => (
            <div
              className="kinto-gamification__instructions__instruction"
              key={index}
            >
              <div className="kinto-gamification__instructions__instruction__details">
                <div className="kinto-gamification__instructions__instruction__details__icon-title">
                  <Icon
                    name={instruction.icon as INameExtended}
                    color={getColor('--PRIMARY_1_1')}
                  />
                  <div className="kinto-gamification__instructions__instruction__details__icon-title__title">
                    {t(instruction.title)}
                  </div>
                </div>
                <div className="kinto-gamification__instructions__instruction__subtitle">
                  {t(instruction.description)}
                </div>
              </div>
              {index < instructionSteps.length - 1 && (
                <Icon
                  name="chevron-right"
                  color={getColor('--ACCENT_1_1')}
                  className="kinto-gamification__instructions__instruction__arrow-icon"
                />
              )}
            </div>
          ))}
          <Image
            alt=""
            src="/assets/svg/gamification_instructions.svg"
            className="kinto-gamification__instructions__illustration__image"
          />
        </div>

        <GamificationLink
          to="rewards"
          title={t('account-rewards')}
          description={t('gamification-rewards_subtitle_create_rewards')}
        />

        <GamificationLink
          to="campaigns"
          title={t('global-campaigns')}
          description={t(
            'gamification-campaigns_subtitle_motivate_your_community'
          )}
          hintText={t('gamification-campaigns_subtitle_to_be_able_to_activate')}
        />
      </section>

      <section className="kinto-gamification__card">
        <h1 className="kinto-gamification__card__title">
          {t('gamification-points_title_give_points_to_people')}
        </h1>

        <GamificationLink
          to="points-system"
          title={t('gamification-title_points_and_leaderboards')}
          description={t('gamification-subtitle_points_and_leaderboards')}
        />
      </section>

      <GamificationLink
        className="earned-rewards"
        title={t('global-earned_rewards')}
        to="earned-rewards"
      />
    </Styled.Gamification>
  );
};

export default Gamification;
