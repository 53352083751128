import { FC, Fragment, PropsWithChildren, useCallback, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  SnackBarProps,
  UtilitiesProvider,
  useUtilities,
} from '@faxi/web-component-library';
import { Wrapper } from '@googlemaps/react-wrapper';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'config/microsoftAuthConfig';

import env from 'env';
import { GOOGLE_MAPS_PLACES_SCRIPT_ID } from 'App';
import { AppProvider, AuthProvider, UserProvider } from 'store';
import GoogleProvider from 'providers/Google';
import { authBus } from 'modules';
import { NetworkErrorOverlay } from 'components';

const wait = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

const RoutesWrapper: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const handleOffline = useCallback(() => {
    document.body.style.overflow = 'hidden';
    showOverlay('#root', 'fixed', <NetworkErrorOverlay phase="1" />);
  }, [showOverlay]);

  const handleOnline = useCallback(async () => {
    hideOverlay('#root');
    showOverlay('#root', 'fixed', <NetworkErrorOverlay phase="2" />);

    await wait(2000);
    hideOverlay('#root');

    showOverlay('#root', 'fixed', <NetworkErrorOverlay phase="3" />);

    await wait(1000);
    hideOverlay('#root');
    document.body.style.overflow = 'initial';
  }, [hideOverlay, showOverlay]);

  const handleShowSnackBar = useCallback(
    (payload: {
      props: SnackBarProps;
      config?: {
        constant?: boolean;
        disappearAfter?: number;
      };
    }) => {
      showSnackBar(payload.props, payload.config);
    },
    [showSnackBar]
  );

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    authBus.addEventListener('show_snackbar', handleShowSnackBar);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      authBus.removeEventListener('show_snackbar', handleShowSnackBar);
    };
  }, [handleOffline, handleOnline, handleShowSnackBar]);

  return <Fragment>{children}</Fragment>;
};

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

const Root: FC = () => {
  return (
    <GoogleProvider clientId={env.VITE_APP_CLIENT_ID}>
      <UtilitiesProvider>
        <AppProvider>
          <MsalProvider instance={msalInstance}>
            <AuthProvider>
              <UserProvider>
                <Wrapper
                  apiKey={env.VITE_APP_GMAPKEY}
                  id={GOOGLE_MAPS_PLACES_SCRIPT_ID}
                  libraries={['places']}
                >
                  <RoutesWrapper>
                    <Outlet />
                  </RoutesWrapper>
                </Wrapper>
              </UserProvider>
            </AuthProvider>
          </MsalProvider>
        </AppProvider>
      </UtilitiesProvider>
    </GoogleProvider>
  );
};

export default Root;
