import { FC, useContext, useMemo } from 'react';
import { Chart, ChartProps, useQueryParams } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { ReportsContext, UserContext } from 'store';
import PlaceholderChart from '../PlaceholderChart';
import { useChartDate } from '../hooks';
import { ChartData } from 'models';
import { JOURNEY_DIRECTIONS } from 'utils';

const VerificationsChart: FC<Partial<ChartProps>> = (
  props: Partial<ChartProps>
): JSX.Element => {
  const { kpiData, rangeStatsReportError } = useContext(ReportsContext);

  const { includeTestUsers } = useContext(UserContext);

  const { params } = useQueryParams<{
    directions: string;
  }>();

  const { t } = useTranslation();

  const activeDirectionFilters = useMemo(
    () =>
      params.directions
        ?.split(',')
        .filter(Boolean)
        .map((d) =>
          t(
            JOURNEY_DIRECTIONS[d as keyof typeof JOURNEY_DIRECTIONS]
          ).toLowerCase()
        )
        .join(', ') || t('joinGroup_search_filter_all').toLowerCase(),
    [params, t]
  );

  const seriesChartData = useMemo(() => {
    if (
      !kpiData ||
      (includeTestUsers && !kpiData.verifications.testChartData)
    ) {
      return [];
    }

    return [
      {
        name: `${t('total_for_chosen_directions')} (${activeDirectionFilters})`,
        data: kpiData?.verifications.chartData,
      },
      ...(includeTestUsers
        ? [
            {
              name: `${t(
                'total_for_chosen_directions_test'
              )} (${activeDirectionFilters})`,
              data: kpiData?.verifications.testChartData as ChartData,
            },
          ]
        : []),
    ];
  }, [activeDirectionFilters, includeTestUsers, kpiData, t]);

  const dateObject = useChartDate(seriesChartData);

  return rangeStatsReportError ? (
    <PlaceholderChart />
  ) : (
    <Chart
      id="verifications-chart"
      series={seriesChartData}
      yAxisLabel={t('reports-chart_title_verifications')}
      ariaLabel={t('accessibility_chart_label', {
        name: t('reports-chart_title_verifications'),
        endDate: dateObject?.end,
        startDate: dateObject?.start,
      })}
      {...props}
    />
  );
};

export default VerificationsChart;
