import { ReportLegendLabelsStatus } from 'models';
import { config } from '@faxi/web-component-library';

export { default as appUri } from './appUri';

export const currencyOptions = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  //   Japanese Yen
  { value: 'JPY', label: 'JPY' },
  //   British Pound Sterling
  { value: 'GBP', label: 'GBP' },
  //   Australian Dollar
  { value: 'AUD', label: 'AUD' },
  //   Canadian Dollar
  { value: 'CAD', label: 'CAD' },
  //   Swiss Franc
  { value: 'CHF', label: 'CHF' },
  //   Chinese Yuan
  { value: 'CNY', label: 'CNY' },
  //   New Zealand Dollar
  { value: 'NZD', label: 'NZD' },
  //   Hong Kong Dollar
  { value: 'HKD', label: 'HKD' },
  // Serbian Dinar
  { value: 'RSD', label: 'RSD' },
];

export const defaultReportLegendLabelsStatus: ReportLegendLabelsStatus = {
  TO_START_PICK_UP_ADDRESS: true,
  TO_COMMUNITY: true,
  TO_START_PICK_UP_ADDRESS_TEST: true,
  TO_COMMUNITY_TEST: true,
};

export default config;
