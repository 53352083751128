import { Modal, theme } from '@faxi/web-component-library';
import { abovePhablet, flex, flexGap, pxToRem } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const EditLinkModalContainer = styled(Modal)`
  .wcl-modal {
    ${abovePhablet(css`
      top: -${theme.sizes.SIZE_8};
      max-width: ${pxToRem(theme.sizes.SIZE_376)};

      &__header[class] {
        margin-bottom: ${theme.sizes.SIZE_24};
      }
    `)}

    &__main {
      padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_40};
    }

    &__footer[class] {
      ${flex('row')};
      ${flexGap(theme.sizes.SIZE_32, 'row')};

      padding-top: unset;
      margin-top: ${theme.sizes.SIZE_24};
    }
  }

  .edit-link-modal {
    &__actions {
      ${flex('row')};
      ${flexGap(theme.sizes.SIZE_24, 'row')};
    }
  }
`;
