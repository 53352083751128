import styled from 'styled-components';
import { fontSize, pxToRem } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const SwitchContainer = styled.fieldset`
  margin-top: ${theme.sizes.SIZE_32};
  max-width: ${pxToRem(theme.sizes.SIZE_320)};

  .community-details {
    width: 100%;

    &__helper-text {
      ${fontSize('14px', theme.sizes.SIZE_20)};

      font-weight: 400;
      color: var(--SHADE_1_1);
      margin: 0 0 ${theme.sizes.SIZE_12};
    }
  }
`;
