import styled, { css } from 'styled-components';
import { flex, flexGap, phablet } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

const FormActions = styled.div`
  ${flex('row', 'flex-start', 'center')};
  ${flexGap(theme.sizes.SIZE_32, 'row')};

  width: 100%;
  margin-top: ${theme.sizes.SIZE_48};

  .delete-btn {
    margin: 0 0 0 auto;
  }

  ${phablet(css`
    ${flexGap('0px', 'row')};
    ${flexGap(theme.sizes.SIZE_12, 'column')};

    flex-direction: column;
    align-items: flex-start;

    > .button {
      width: 100%;
    }
  `)}
`;

export default FormActions;
