import { Dispatch, SetStateAction, createContext } from 'react';
import { SurveyInstance } from '../../../../models/Survey';

export interface SurveyContextProps {
  yourEmission?: number;
  activeEmail?: string;
  activeSurvey?: SurveyInstance;
  setYourEmission: Dispatch<SetStateAction<number | undefined>>;
  setActiveEmail: Dispatch<SetStateAction<string | undefined>>;
}

const SurveyContext = createContext<SurveyContextProps>({
  activeEmail: '',
  yourEmission: 0,
  activeSurvey: {} as SurveyInstance,
  setActiveEmail: () => {},
  setYourEmission: () => {},
});

export default SurveyContext;
