import { FC, memo, useCallback, useContext, useMemo, useRef } from 'react';
import { Button, Tooltip, getColor } from '@faxi/web-component-library';
import { FieldArray, FormContext, FormField, validators } from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  RewardType,
  RewardTypes,
  RewardVoucherTypeData,
  REWARD_TYPES,
} from 'models';
import { apiGamification } from 'modules';
import {
  Icon,
  ImageDropzoneField,
  InputField,
  TextareaField,
} from 'components';
import Each from 'helpers/Each';
import RewardInstance from '../../components/RewardInstance';

import * as Styled from './RewardSettings.styles';

const REWARD_TYPES_TRANSLATION = {
  [REWARD_TYPES.PARKING_REWARD]: 'rewards-parking_spaces',
  [REWARD_TYPES.MESSAGE_REWARD]: 'rewards-title_regular_reward',
  [REWARD_TYPES.VOUCHER_REWARD]: 'rewards-reward_types',
} as Record<RewardTypes, string>;

const REWARD_TYPE_PLACEHOLDER = {
  [REWARD_TYPES.MESSAGE_REWARD]: 'rewards-field_placeholder_reward_type',
  [REWARD_TYPES.PARKING_REWARD]:
    'carpark_settings-placeholder_parking_space_name',
} as Record<RewardTypes, string>;

const REWARD_TYPES_TOOLTIP = {
  [REWARD_TYPES.MESSAGE_REWARD]: 'rewards-tooltip_reward_type',
  [REWARD_TYPES.PARKING_REWARD]: 'rewards-subtitle_parking_reward',
} as Record<RewardTypes, string>;

type RewardSettingsProps = {
  className?: string;
  reward: RewardType;
  editing?: boolean;
  disableEditing?: boolean;
  shouldDeleteImage?: boolean;
  onFileDelete?: (rid: string) => void;
  onDeletingImage?: () => void;
};

const RewardSettings: FC<RewardSettingsProps> = (props) => {
  const {
    className,
    editing,
    reward,
    disableEditing,
    shouldDeleteImage,
    onFileDelete,
    onDeletingImage,
  } = props;

  const rewardFallbackURL =
    reward?.type === REWARD_TYPES.PARKING_REWARD
      ? '/assets/svg/square-parking.svg'
      : '/assets/svg/gift-icon.svg';

  const { t } = useTranslation();
  const { fields } = useContext(FormContext);

  //after one added instance focus new input container
  const instanceAdded = useRef<boolean>(false);

  const deleteRewardImage = useCallback(async () => {
    const response = await apiGamification.deleteRewardImage(`${reward?.id}`);

    onFileDelete?.(`${reward?.id}`);
    return response;
  }, [reward?.id, onFileDelete]);

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('global-reward_name'),
          })
        ),
        validators.general.maxLength(
          50,
          t('validation-field_validation_max_length', {
            fieldname: t('global-reward_name').toLowerCase(),
            number: '50',
          })
        ),
      ],
      description: [
        validators.general.maxLength(
          250,
          t('validation-field_validation_max_length', {
            fieldname: t('dw_description').toLowerCase(),
            number: '250',
          })
        ),
      ],
      redeem: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('campaigns-how_to_redeeem'),
          })
        ),
        validators.general.maxLength(
          500,
          t('validation-field_validation_max_length', {
            fieldname: t('campaigns-how_to_redeeem').toLowerCase(),
            number: '500',
          })
        ),
      ],
    }),
    [t]
  );

  return (
    <Styled.RewardSettings
      className={classNames('kinto-reward-settings', className)}
    >
      <fieldset
        className={classNames(
          'kinto-modal__fields',
          'kinto-reward-settings__image'
        )}
      >
        <legend data-hidden hidden>
          {t('accessibility-reward_image')}
        </legend>
        <FormField
          name="file"
          disabled={disableEditing}
          component={ImageDropzoneField}
          deleteCondition={shouldDeleteImage}
          fallbackUrl={rewardFallbackURL}
          onFileDelete={onDeletingImage}
          deleteRequest={deleteRewardImage}
          alt={t('accessibility-reward_image')}
          accept={{
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
          }}
        />
      </fieldset>

      <fieldset
        className={classNames(
          'kinto-modal__fields',
          'kinto-reward-settings__details'
        )}
      >
        <legend data-hidden hidden>
          {t('accessibility-reward_details')}
        </legend>
        <FormField
          name="name"
          required
          autoComplete="on"
          component={InputField}
          placeholder={t('global-reward_name')}
          requiredLabel={t('global-input_field_required_label')}
          validate={validations.name}
          disabled={disableEditing}
        />

        <FormField
          name="description"
          placeholder={t('dw_description')}
          autoComplete="off"
          component={TextareaField}
          className="kinto-modal-rewards__description"
          validate={validations.description}
          disabled={disableEditing}
        />

        <FormField
          name="redeem"
          required
          placeholder={t('campaigns-how_to_redeeem')}
          autoComplete="off"
          requiredLabel={t('global-input_field_required_label')}
          component={TextareaField}
          className="kinto-modal-rewards__redeem"
          validate={validations.redeem}
          disabled={disableEditing}
        />
      </fieldset>

      {/* For voucher type rewards (rewards crated from SA) we only show number of codes that are uploaded */}
      {reward?.type === 'voucher-reward' && (
        <div className="kinto-reward-settings__instances">
          <p className="kinto-reward-settings__tooltip-title">
            {t(REWARD_TYPES_TRANSLATION[reward?.type])}
          </p>
          <div className="kinto-reward-settings__voucher-reward">
            <div>
              {`${(reward?.data as RewardVoucherTypeData)?.number_of_codes} ${
                reward?.name
              }`}
            </div>
            <Icon name="check" color={getColor('--PRIMARY_1_1')} />
          </div>
        </div>
      )}

      {reward?.type !== 'voucher-reward' && (
        <fieldset className="kinto-reward-settings__instances">
          <legend data-hidden hidden>
            {t(REWARD_TYPES_TRANSLATION[reward?.type])}
          </legend>
          {!(fields['instances']?.value.length === 0 && editing) && (
            <p className="kinto-reward-settings__tooltip-title">
              {t(REWARD_TYPES_TRANSLATION[reward?.type])}
              <Tooltip
                placement="top-start"
                content={t(REWARD_TYPES_TOOLTIP[reward?.type])}
              >
                <span
                  tabIndex={0}
                  role="tooltip"
                  className="kinto-reward-settings__tooltip"
                  aria-label={t(REWARD_TYPES_TOOLTIP[reward?.type])}
                >
                  <Icon name="circle-info" color={getColor('--PRIMARY_1_1')} />
                </span>
              </Tooltip>
            </p>
          )}

          <FieldArray name="instances">
            {(config) => (
              <>
                <Each
                  of={config.fields}
                  render={(field, index) => {
                    return (
                      <RewardInstance
                        {...field}
                        disabled={disableEditing}
                        hasQuantity={reward?.type !== 'parking-reward'}
                        placeholder={t(REWARD_TYPE_PLACEHOLDER[reward?.type])}
                        onDelete={() => config.remove(index)}
                        autoFocus={
                          instanceAdded.current &&
                          index === config.fields.length - 1
                        }
                      />
                    );
                  }}
                />

                {!disableEditing && (
                  <Button
                    variant="ghost"
                    icon={<Icon name="plus" />}
                    aria-label={t('accessibility-add_reward_instance')}
                    onClick={() => {
                      instanceAdded.current = true;
                      config.add({ name: '', quantity: 1 });
                    }}
                  >
                    {t('add')}
                  </Button>
                )}
              </>
            )}
          </FieldArray>
        </fieldset>
      )}
    </Styled.RewardSettings>
  );
};

export default memo(RewardSettings);
