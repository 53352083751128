import { FC, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { Button, useCallbackRef } from '@faxi/web-component-library';
import { DataState, Form, FormField, FormRef } from '@faxi/web-form';
import uniqid from 'uniqid';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useSendMessage } from 'hooks';
import TextareaField from '../TextareaField';
import { MessagesContext } from 'store';
import { Icon } from 'components';
import storageService, { STORAGE_KEYS } from 'services/storageService';

import * as Styled from './MessageSender.styles';
import { Message, MessageUser } from 'models';

export type MessageSenderProps = {
  sendMessageCallback: (message: Message) => void;
  className?: string;
  sender: MessageUser;
  organisationId: number;
  receiver: MessageUser;
};

export type CachedUserMessages = {
  userId: string;
  messages: Message[];
};

export type CachedCommunityMessages = {
  communityId: number;
  users: CachedUserMessages[];
};

const MessageSender: FC<MessageSenderProps> = (
  props: MessageSenderProps
): JSX.Element => {
  const { sendMessageCallback, receiver, className, sender, organisationId } =
    props;

  const { sendMessage } = useContext(MessagesContext);

  const [form, formRef] = useCallbackRef<FormRef>();

  const { t } = useTranslation();

  const handleSendMessage = useCallback(
    async ({ message }: DataState) => {
      if (message) {
        try {
          const res = await sendMessage(
            sender.id,
            receiver.id.split('_').at(-1)!,
            message.trim(),
            organisationId,
            'U'
          );

          if (res.rc === 'error') {
            form.setFieldError('message', t('error_sending_message'));
          } else {
            // callback to concat message to messages list
            form.updateValueField('message', '');
            sendMessageCallback({
              id: res.id,
              from: sender,
              text: message,
              to: [receiver],
              ts: dayjs().utc().format('YYYY-MM-DD HH:mm:ss'),
              failed: false,
            });
          }
        } catch (e) {
          // message failed, add to failed messages list
          const newMessage = {
            id: uniqid(),
            from: sender,
            text: message,
            to: [receiver],
            ts: dayjs().utc().format('YYYY-MM-DD HH:mm:ss'),
            failed: true,
          };

          const failedMessages = storageService.getItem(
            STORAGE_KEYS.FAILED_MESSAGES
          ) as CachedCommunityMessages[];

          if (failedMessages) {
            let failedMessagesCommunityIndex = failedMessages.findIndex(
              (messages) => messages.communityId === organisationId
            );

            if (failedMessagesCommunityIndex === -1) {
              failedMessages.push({ communityId: organisationId, users: [] });
              failedMessagesCommunityIndex = failedMessages.length - 1;
            }

            const failedMessagesUserIndex = failedMessages[
              failedMessagesCommunityIndex
            ].users.findIndex((elem) => elem.userId === receiver.id);

            if (failedMessagesUserIndex === -1) {
              failedMessages[failedMessagesCommunityIndex].users.push({
                userId: receiver.id,
                messages: [newMessage],
              });
            } else {
              failedMessages[failedMessagesCommunityIndex].users[
                failedMessagesUserIndex
              ].messages.push(newMessage);
            }

            storageService.setItem(
              STORAGE_KEYS.FAILED_MESSAGES,
              failedMessages
            );

            form.updateValueField('message', '');
            // append the failed message to messages list
            sendMessageCallback(newMessage);
          }
        }
      }
    },
    [
      form,
      organisationId,
      receiver,
      sendMessage,
      sendMessageCallback,
      sender,
      t,
    ]
  );

  const { validations } = useSendMessage({
    onEnter: () => {
      if (form?.fields['message']?.value) {
        handleSendMessage({ message: form?.fields['message']?.value.trim() });
      }
    },
  });

  return (
    <Styled.MessageSenderStyled
      className={classNames('message-sender', className)}
    >
      <Form id="message_form" onSubmit={handleSendMessage} ref={formRef}>
        <FormField
          component={TextareaField}
          className="message-sender__textarea"
          placeholder={t('reply_to', { user: receiver.name })}
          prefixIcon={<Icon name="message-lines" />}
          noresize
          name="message"
          validate={validations.message}
        />
        <Button
          id="submit_message"
          variant="primary"
          aria-label={t('messages-title_send_message', {
            name: receiver.name,
          })}
          className={classNames('message-sender__button', {
            'message-sender__button--mobile-visible':
              form?.fields['message'] !== null,
          })}
          disabled={
            !form?.fields['message'] ||
            form?.fields['message'].value.trim() === '' ||
            !form.syncFormValid
          }
          type="submit"
          icon={<Icon name="paper-plane-top" />}
        />
      </Form>
    </Styled.MessageSenderStyled>
  );
};

export default MessageSender;
