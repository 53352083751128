import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Heading,
  Table,
  getColor,
  useModalUtilities,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Icon } from 'components';
import { UserContext } from 'store';
import { PredefinedAddress } from 'models';
import { apiPredefinedAddresses } from 'modules/api';
import { useCallbackAsync } from 'hooks';
import PredefinedAddressModal from './components/PredefinedAddressModal';
import { PageLayout } from 'components/_layouts';

import * as Styled from './PredefinedAddressesTab.styles';

const PredefinedAddressesTab = () => {
  const { t } = useTranslation();

  const {
    communityId,
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  const [predefinedAddresses, setPredefinedAddresses] = useState<
    PredefinedAddress[]
  >([]);

  const { open, openModal, closeModal, triggerRef } = useModalUtilities();
  const modalBtnRef = triggerRef.current as HTMLButtonElement;

  const initialData = useRef<PredefinedAddress>();

  const translationKeys = useMemo(
    () =>
      ({
        name: t('predefined-addresses-title_address_name'),
        address: t('address_header'),
        ts: t('groupreg_js_created'),
      } as Record<Partial<keyof PredefinedAddress>, string>),
    [t]
  );

  const [getPredefinedAddresses] = useCallbackAsync({
    showSpinner: true,
    callback: async (communityId: number) => {
      const { data } = await apiPredefinedAddresses.getAllPlaces(communityId);

      if (data) {
        setPredefinedAddresses(
          data.places.map(
            (predefinedAddresse: PredefinedAddress) => ({
              ...predefinedAddresse,
              ts: dayjs(predefinedAddresse.ts).format(dateFormat),
            }),
            []
          )
        );
      }
    },
    deps: [dateFormat],
  });

  useEffect(() => {
    getPredefinedAddresses(communityId);
  }, [getPredefinedAddresses, communityId]);

  useEffect(() => {
    if (!open) {
      initialData.current = undefined;
    }
  }, [open]);

  return (
    <PageLayout className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('predefined-addresses-section_title')}
      </Heading>

      <Styled.Container>
        {predefinedAddresses.length === 0 ? (
          <div className="empty-predefined-addresses">
            <Icon
              name="location-dot"
              className="empty-predefined-addresses__icon"
            />

            <p className="empty-predefined-addresses__title">
              {t('predefined-addresses-empty_state_title')}
            </p>

            <p className="empty-predefined-addresses__content">
              {t('predefined-addresses-empty_state_subtitle')}
            </p>

            <Button
              icon={<Icon name="plus" />}
              iconPosition="left"
              onClick={openModal}
            >
              {t('predefined-addresses-button_add_an_address')}
            </Button>
          </div>
        ) : (
          <div className="predefined-addresses-list">
            <Button
              icon={<Icon name="plus" />}
              iconPosition="right"
              variant="outline"
              onClick={openModal}
              className="predefined-addresses-list__add-address-btn"
            >
              {t('predefined-addresses-button_add_an_address')}
            </Button>
            {predefinedAddresses && (
              <Table
                tableId="predefined-addresses-table"
                rowsHaveAction
                tableData={predefinedAddresses || []}
                translationKeys={translationKeys}
                rowsActionHeader={t('global-table_actions')}
                className="predefined-addresses-list__table"
                editActionAriaLabel={t('accessibility-edit_predefined_address')}
                rowsActionButtonIcon="pen"
                onEditAction={(data, _, element) => {
                  initialData.current = data;
                  openModal(element);
                }}
                excludeColumns={[
                  'id',
                  'lng',
                  'lat',
                  'city',
                  'country',
                  'postcode',
                  'type',
                ]}
                excludeSortColumns={['id', 'name', 'address', 'ts']}
              />
            )}
          </div>
        )}

        {open && (
          <PredefinedAddressModal
            addresses={predefinedAddresses}
            triggerRef={modalBtnRef}
            initialData={initialData}
            onClose={closeModal}
            onSave={() => getPredefinedAddresses(communityId)}
            onDelete={() => getPredefinedAddresses(communityId)}
          />
        )}
      </Styled.Container>
    </PageLayout>
  );
};

export default PredefinedAddressesTab;
