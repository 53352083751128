import parse from 'html-react-parser';
import { getColor, Heading } from '@faxi/web-component-library';
import Each from 'helpers/Each';

import * as Styled from './AccountDeletion.styled';

const DELETING_STEPS = [
  '<b>Open the App</b>: Navigate to the main Home page',
  '<b>Go to Account</b>: Tap on your avatar in the top right corner.',
  "<b>Delete Account</b>: Scroll to the bottom of the page and tap on 'Delete Account.'",
  '<b>Confirm Deletion</b>: Follow the on-screen instructions to confirm the deletion of your account.',
];

const CONTACT_STEP =
  '<b>Email Support</b>: Send an email to <a href="mailto:support@kintojoin.io">support@kintojoin.io</a> requesting account deletion.';

const AccountDeletion = () => {
  return (
    <Styled.AccountDeletionContainer>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-account-deletion__heading"
      >
        How to delete your account?
      </Heading>

      <Heading level="2" className="kinto-account-deletion__title">
        Deleting Your Account
      </Heading>

      <p>To Delete Your Account Within the App:</p>
      <Each
        containerAs="ul"
        of={DELETING_STEPS}
        render={(step) => <li>{parse(step)}</li>}
      />

      <p>To Contact Support for Account Deletion:</p>
      <ul>
        <li>{parse(CONTACT_STEP)}</li>
      </ul>
    </Styled.AccountDeletionContainer>
  );
};

export default AccountDeletion;
