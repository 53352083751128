import { FC } from 'react';
import classNames from 'classnames';

import * as Styles from './SeparatedText.styles';

type SeparatedTextProps = {
  name: string;
  value?: string;
  className?: string;
  boldSecond?: boolean;
};

const SeparatedText: FC<SeparatedTextProps> = (props) => {
  const { className, name, value, boldSecond } = props;

  return (
    <Styles.SeparatedText
      className={classNames(
        'kinto-separated-text',
        { 'kinto-separated-text--bold-second': boldSecond },
        className
      )}
    >
      <div>{name}</div>
      <div>{value}</div>
    </Styles.SeparatedText>
  );
};

export default SeparatedText;
