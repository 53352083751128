import { Drawer, shadow_xl, theme } from '@faxi/web-component-library';
import { flex, fontSize, position, pxToRem } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const DrawerContainer = styled(Drawer)`
  .wcl-drawer__main {
    background-color: var(--BACKGROUND_2_1);
  }

  .campaign-details__reward-container {
    padding: ${theme.sizes.SIZE_16};
    background-color: var(--BACKGROUND_1_1);
  }

  .campaign-details__detail {
    &__values {
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.sizes.SIZE_12};
    }

    &__data {
      background-color: var(--BACKGROUND_1_1);
    }
  }

  .wcl-drawer__footer {
    display: flex;

    .button {
      width: fit-content;
    }
  }
`;

export const Container = styled.div`
  ${position('absolute', 'top 0 right 0 bottom 0')};
  background-color: var(--BACKGROUND_1_1);
  ${shadow_xl};
  padding: ${theme.sizes.SIZE_32};
  overflow: auto;
  z-index: 1;

  .selected-campaign-list {
    &__summarize-info {
      ${flex('column')};

      border-radius: ${theme.sizes.SIZE_8};
      background-color: var(--BACKGROUND_1_1);
      padding: ${theme.sizes.SIZE_16};
      position: relative;

      &__header {
        &__title {
          ${fontSize(theme.sizes.SIZE_24)};
          font-weight: 400;
          color: var(--SECONDARY_1_1);
          margin: 0 0 ${theme.sizes.SIZE_32};
        }

        &__close-btn {
          position: absolute;
          top: -${theme.sizes.SIZE_32};
          right: -${theme.sizes.SIZE_16};
        }
      }

      &__triggers {
        ${flex('row')};
      }

      &__container {
        ${flex('column')};
        flex: 1;
        margin-bottom: ${theme.sizes.SIZE_40};
        min-width: ${pxToRem(theme.sizes.SIZE_320)};
      }

      &__title {
        ${fontSize(theme.fontSizes.FONT_16)};
        font-weight: 600;
        color: var(--SHADE_1_1);
        margin-bottom: ${theme.sizes.SIZE_8};
      }

      &__data {
        ${fontSize(theme.fontSizes.FONT_14)};
        ${flex('row', undefined, 'center')};

        width: fit-content;

        font-weight: 400;
        border: 1px solid var(--SHADE_1_4);
        padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
        border-radius: ${theme.sizes.SIZE_8};
        margin-bottom: ${theme.sizes.SIZE_4};

        .wcl-icon {
          margin-right: ${theme.sizes.SIZE_12};
        }
      }

      &__status-data {
        color: var(--SHADE_1_2);
        margin-top: ${theme.sizes.SIZE_8};

        .campaign-list__table__state--draft {
          padding: 0;
        }
      }

      &__dates {
        margin-bottom: ${theme.sizes.SIZE_40};
      }

      &__date {
        ${flex('column')};
        margin-bottom: ${theme.sizes.SIZE_12};

        &__label {
          ${fontSize(theme.fontSizes.FONT_12)};
          color: var(--SHADE_1_2);
          margin-bottom: ${theme.sizes.SIZE_4};
        }

        &__value {
          ${flex('row')};
          ${fontSize(theme.fontSizes.FONT_14)};
          .wcl-icon {
            margin-right: ${theme.sizes.SIZE_12};
          }
        }
      }

      &__info-card {
        margin-bottom: ${theme.sizes.SIZE_40};
        max-width: ${pxToRem(theme.sizes.SIZE_374)};

        .kinto-info-card__content {
          ${flex('column')};

          .kinto-info-card__link {
            margin-top: ${theme.sizes.SIZE_16};
            text-decoration: underline;
          }
        }
      }

      &__reward-name {
        font-weight: 600;
        color: var(--SHADE_1_1);
      }

      &__reward-limit {
        color: var(--SHADE_1_2);
        text-transform: lowercase;
      }
    }

    &__deactivate-modal {
      &__info-card {
        .kinto-info-card__text {
          font-weight: 600;
          color: var(--SHADE_1_1);
        }
      }
    }
  }
`;
