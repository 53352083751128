import {
  flex,
  fontSize,
  phablet,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';

import styled, { css } from 'styled-components';
import { focusStyles, theme } from '@faxi/web-component-library';

export const Container = styled.footer`
  ${flex('row', 'flex-start', 'flex-start')};

  padding: ${`${theme.sizes.SIZE_64} ${theme.sizes.SIZE_64} ${theme.sizes.SIZE_104} ${theme.sizes.SIZE_72}`};
  background-color: var(--SUCCESS_1_1);
  margin-top: auto;

  .inner-footer {
    &__logo {
      margin-right: ${theme.sizes.SIZE_24};
      height: fit-content;
      width: ${pxToRem(theme.sizes.SIZE_120)};
    }

    &__vertical-divider {
      ${size(theme.sizes.SIZE_1, theme.sizes.SIZE_104)};

      margin: 0 ${theme.sizes.SIZE_40};
      background-color: var(--PRIMARY_1_7);
    }

    &__links {
      margin-right: ${theme.sizes.SIZE_64};
      align-items: flex-start;
      text-decoration: none;
      color: var(--PRIMARY_1_1);

      a {
        ${fontSize(theme.fontSizes.FONT_16)};
        font-weight: 500;
        color: var(--PRIMARY_1_1);
      }
    }

    &__contact {
      ${fontSize(theme.fontSizes.FONT_16)};
      color: var(--SHADE_1_1);

      &__title {
        margin-bottom: ${theme.sizes.SIZE_8};
        font-weight: 600;
      }

      &__text {
        ${focusStyles};
        color: var(--SHADE_1_1);
        text-decoration: none;
        font-weight: 500;

        &:hover {
          color: var(--PRIMARY_2_1);
          text-decoration: underline;
        }
      }
    }
  }

  ${phablet(css`
    padding: ${`${theme.sizes.SIZE_32} ${theme.sizes.SIZE_40} ${theme.sizes.SIZE_88}`};
    ${flex('column', 'flex-start', 'center')};

    .inner-footer {
      &__logo {
        margin: 0;
        margin-bottom: ${theme.sizes.SIZE_56};
        width: ${pxToRem(theme.sizes.SIZE_88)};
      }

      &__links {
        margin: 0;
        margin-bottom: ${theme.sizes.SIZE_32};
        align-items: center;

        a {
          margin-right: 0;

          span {
            padding: 0;
          }
        }
      }

      &__vertical-divider {
        display: none;
      }

      &__contact {
        text-align: center;
      }
    }
  `)};
`;
