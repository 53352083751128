const instructionSteps = [
  {
    icon: 'gift',
    title: 'global-create_a_rewards',
    description: 'gamification-new_rewards_subtitle_create_rewards',
  },
  {
    icon: 'megaphone',
    title: 'gamification-campaigns_title_create_a_campaign',
    description: 'gamification-campaigns_subtitle_create_a_campaign',
  },
  {
    icon: 'mobile',
    title: 'gamification-campaigns_title_activate_campaigns',
    description: 'gamification-campaigns_subtitle_activate_campaigns',
  },
];

export default instructionSteps;
