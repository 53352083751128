import { FC } from 'react';
import StoreButtonsStyled from './StoreButtons.styles';
import { Image } from '@faxi/web-component-library';
import classNames from 'classnames';

type StoreButtonsProps = {
  className?: string;
  countryCode?: string;
};

const StoreButtons: FC<StoreButtonsProps> = (
  props: StoreButtonsProps
): JSX.Element => {
  const { className, countryCode } = props;

  return (
    <StoreButtonsStyled className={classNames('store-buttons', className)}>
      <a
        href="https://play.google.com/store/apps/details?id=com.kintojoin"
        target="_blank"
        rel="noopener noreferrer"
        className="store-buttons__badge"
      >
        <Image
          src="/assets/images/google-play-badge.png"
          alt="Get it on Google Play"
          className="store-buttons__badge__image"
        />
      </a>
      <a
        href={`https://apps.apple.com/${
          countryCode || ''
        }/app/kinto-join/id1491141883`}
        target="_blank"
        rel="noopener noreferrer"
        className="store-buttons__badge"
      >
        <Image
          src="/assets/images/download-on-the-app-store.png"
          alt="Download it on App Store"
          className="store-buttons__badge__image"
        />
      </a>
    </StoreButtonsStyled>
  );
};

export default StoreButtons;
