import { flex, fontSize, marginChildren, size } from '@faxi/web-css-utilities';
import { focusStyles, theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const EventCardContainer = styled.button`
  ${size('auto', theme.sizes.SIZE_136)};
  ${flex('row', 'space-between', 'initial')};
  ${marginChildren(`0 ${theme.sizes.SIZE_8} 0 0`)};
  ${focusStyles};

  cursor: pointer;
  text-align: start;
  background-color: transparent;
  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_4);

  &:hover {
    border-color: var(--PRIMARY_1_1);
  }

  .kinto-event-card {
    &__data {
      ${flex('column')};
      ${marginChildren(`0 0 ${theme.sizes.SIZE_4} 0`)};

      &__name {
        ${fontSize(theme.fontSizes.FONT_16)};
        color: var(--SHADE_1_1);
        font-weight: 600;
      }

      &__time {
        ${flex('column')};
        width: 100%;
      }

      &__time,
      &__location {
        ${fontSize(theme.fontSizes.FONT_12)};
        color: var(--SHADE_1_2);
      }

      &__location {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &__arrow {
      ${flex('column', 'center', 'center')};
      color: var(--PRIMARY_1_1);
    }
  }
`;
