import ViewsProvider, { View } from 'providers/Views';
import { FC, memo, useMemo } from 'react';
import RewardModal, { RewardModalProps } from './RewardModal.component';
import RewardType from './views/RewardType';
import RewardSettings from './views/RewardSettings';

export type RewardViewsType = 'reward-type' | 'reward-settings';

const views = [
  {
    key: 'reward-type',
    component: RewardType,
  },
  {
    key: 'reward-settings',
    component: RewardSettings,
  },
] as View<RewardViewsType>[];

const ViewsRewardWrapper: FC<RewardModalProps> = (props) => {
  const initialView = useMemo(
    () =>
      (props.showModal === 'edit'
        ? 'reward-settings'
        : 'reward-type') as RewardViewsType,
    [props.showModal]
  );

  return (
    <ViewsProvider<RewardViewsType> initialView={initialView} views={views}>
      <RewardModal {...props} />
    </ViewsProvider>
  );
};

export default memo(ViewsRewardWrapper);
