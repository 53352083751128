import { FC } from 'react';
import { Textarea, TextareaProps } from '@faxi/web-component-library';
import { FieldProps, useFieldError } from '@faxi/web-form';

type ValueOnChange = (event: string) => void;

export type TextareaFieldProps = FieldProps<string, ValueOnChange> &
  TextareaProps;

const TextareaField: FC<TextareaFieldProps> = (
  props: TextareaFieldProps
): JSX.Element => {
  const { dirty, error, touched, ...rest } = props;

  const showError = useFieldError(props);

  return <Textarea errorText={String(error)} error={showError} {...rest} />;
};

export default TextareaField;
