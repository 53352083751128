import React, { createContext, MutableRefObject } from 'react';
import { Survey } from 'models';

type SustainabilityContext = {
  surveys: Survey[];
  emptyPlaceholder: boolean;
  activeSurveyId: string;
  activeSurvey?: Survey;
  surveyReportRetry: MutableRefObject<Function>;
  setActiveSurveyId: (value: string) => void;
  updateSurvey: (surveyId: string, data: Partial<Survey>) => void;
  openModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export default createContext<SustainabilityContext>({
  surveys: [],
  emptyPlaceholder: false,
  activeSurveyId: '',
  openModal: () => {},
  updateSurvey: () => {},
  setActiveSurveyId: () => {},
  surveyReportRetry: { current: () => {} },
});
