const convertNumber = (value: string, type: 'mul' | 'div' | 'string') => {
  return value !== undefined
    ? type === 'mul'
      ? `${Number(+value === 0 ? 0 : (+value * 100).toFixed(8))}`
      : type === 'div'
      ? parseFloat(`${+value / 100}`)
      : type === 'string'
      ? `${value}`
      : ''
    : '';
};

/**
 * Convert string object to number object function
 */
const parseStringObjectToNumberObject = (
  obj: Record<string, string | number>
): Record<string, number> => {
  for (const key in obj) {
    if (typeof obj[key] === 'string' && !isNaN(+obj[key])) {
      obj[key] = Number(obj[key]);
    }
  }
  return obj as Record<string, number>;
};

/**
 * Convert number object to string object function
 */
const parseNumberObjectToStringObject = (
  obj: Record<string, any>
): Record<string, number> => {
  for (const key in obj) {
    if (typeof obj[key] === 'number') {
      obj[key] = `${obj[key]}`;
    }
  }

  return obj as Record<string, number>;
};

export {
  convertNumber,
  parseNumberObjectToStringObject,
  parseStringObjectToNumberObject,
};
