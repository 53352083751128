import { theme } from '@faxi/web-component-library';
import { flex, phablet } from '@faxi/web-css-utilities';
import FormActions from 'components/_layouts/FormActions';
import styled, { css } from 'styled-components';

export const RewardFooter = styled(FormActions)`
  ${flex('row')};
  width: 100%;
  margin-top: unset;

  .delete-reward-btn {
    margin-left: auto;
  }

  ${phablet(css`
    display: grid;
    grid-template-columns: 1fr 1fr;

    .button {
      width: 100%;
    }
  `)}

  &.editing {
    ${phablet(css`
      display: grid;
      gap: ${theme.sizes.SIZE_12};
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'delete cancel'
        'save save';

      .cancel-btn {
        grid-area: cancel;
      }

      .delete-reward-btn {
        grid-column: span 2;
        grid-area: delete;
      }

      .button[type='submit'] {
        grid-column: span 2;
      }
    `)}
  }
`;
