import { Button, elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, pxToRem, size, mobile } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const ButtonCard = styled(Button)`
  ${size('100%')};
  ${flex('column')}
  ${elevate_l};

  &[class] {
    padding: ${theme.sizes.SIZE_16};
    background-color: white;
  }

  .button-card {
    &__content {
      ${flex('row', 'flex-start', 'center')}
      gap: ${theme.sizes.SIZE_16};
    }

    &__title,
    &__description {
      margin: unset;
      width: fit-content;
    }

    &__title {
      ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};
      color: var(--SHADE_1_1);
      margin: unset;
    }

    &__description {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      margin: unset;
      text-wrap: wrap;
      text-align: start;
      color: var(--SHADE_1_2);
      max-width: ${pxToRem(theme.sizes.SIZE_456)};
      margin-top: ${theme.sizes.SIZE_4};
      font-weight: 400;
    }

    &__button-icon {
      ${size(theme.sizes.SIZE_64)};
      padding: ${theme.sizes.SIZE_8};

      > svg {
        height: 100%;
      }
    }

    &__chevron-right {
      margin-left: auto;
      ${size(theme.sizes.SIZE_24)};
    }

    &__warning-info-card {
      margin-top: ${theme.sizes.SIZE_16};
      cursor: auto;

      p {
        font-weight: 600;
        color: var(--SHADE_1_1);
        max-width: 60%;
        margin-right: auto;
        text-align: left;

        ${mobile(css`
          max-width: 100%;
        `)}
      }

      .kinto-link,
      a {
        text-decoration: underline;
        margin: 0 auto 0 0;
        font-weight: 400;
      }
    }
  }

  &.button--ghost--disabled {
    .button-card {
      &__title,
      &__description {
        color: var(--SHADE_1_4);
      }
    }
  }
`;
