import {
  flex,
  flexGap,
  fontSize,
  position,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';
import styled from 'styled-components';
import { focusStyles, theme } from '@faxi/web-component-library';

export const ImageCropContainer = styled.div`
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_24, 'column')};

  .kinto-image-crop {
    &__title {
      ${focusStyles};
      ${fontSize(theme.fontSizes.FONT_14)};
      color: var(--PRIMARY_1_1);
      border-bottom: ${theme.sizes.SIZE_2} solid var(--PRIMARY_1_1);
      padding-bottom: ${theme.sizes.SIZE_8};
      font-weight: 600;
      width: fit-content;
    }

    &__acceptable-types {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      margin: 0;
      font-weight: 400;
      color: var(--SHADE_1_2);
      margin-top: ${theme.sizes.SIZE_12};
    }

    &__drop-container {
      ${flex('column', 'center', 'center')};
      ${size('100%', 'auto')};

      cursor: pointer;
      position: relative;
      border-radius: ${theme.sizes.SIZE_8};
      min-height: ${pxToRem(theme.sizes.SIZE_192)};
      border: ${theme.sizes.SIZE_1} dashed var(--SHADE_1_2);

      &__img-icon {
        ${position('absolute', 'top 0 left 0 ')};

        color: var(--PRIMARY_1_1);
        transform: translate3d(50%, 50%, 0);
      }

      &__content {
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

        margin: 0;
        font-weight: 400;
        color: var(--SHADE_1_2);
      }
    }

    &__actions {
      ${flex('row')};
      gap: ${theme.sizes.SIZE_32};
    }
  }
`;
