import { useCallback } from 'react';
import {
  Button,
  SelectOption,
  SortDirections,
  SortOption,
} from '@faxi/web-component-library';
import InputField from 'components/_molecules/InputField';
import { EarnedReward } from 'models';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { ViewsPerPage } from '@faxi/web-component-library/build/components/_organisms/Table/constants';
import EarnedRewardsReportTable from '../EarnedRewardsReportTable';
import useRewards from '../../useRewards';

import * as Styled from './EarnedRewardsReportModal.styles';

type EarnedRewardsReportModalProps = {
  initialData: any;
  initialSort: {
    sortBy: keyof EarnedReward;
    sortDirection: SortDirections;
  };
  initTotalPages: number;
  initTotalCount: number;
  onClose: () => void;
};

const EarnedRewardsReportModal = (props: EarnedRewardsReportModalProps) => {
  const { initialData, initialSort, initTotalPages, initTotalCount, onClose } =
    props;

  const { t } = useTranslation();

  const {
    data,
    count,
    search,
    loading,
    totalPages,
    activeColumnSort,
    totalCount,
    currentPage,
    setCount,
    onSearchChange,
    setCurrentPage,
    setActiveColumnSort,
  } = useRewards({
    initialSortBy: initialSort.sortBy,
    initialSortDirection: initialSort.sortDirection,
    spinnerSelector: '.modal-earned-rewards-today__table',
    initialSkipRequest: true,
    data: initialData,
    initTotalCount,
    initTotalPages,
  });

  const handleOnColumnSort = useCallback(
    (sort: SortOption<EarnedReward>) => {
      const { sortBy, sortDirection } = sort;
      setActiveColumnSort(sortBy, sortDirection);
    },
    [setActiveColumnSort]
  );

  const handleOnLimitChange = useCallback(
    (data: SelectOption) => {
      setCount(+data.value as ViewsPerPage);
    },
    [setCount]
  );

  return (
    <Styled.ModalContainer
      onClose={onClose}
      title={t('dashboard-title_earned_rewards_today')}
    >
      <div className="modal-earned-rewards-today__description">
        {t('dashboard-subtitle_earned_rewards_today')}
      </div>

      <InputField
        className="modal-earned-rewards-today__search"
        value={search}
        onChange={onSearchChange}
        prefixIcon={<Icon name="magnifying-glass" />}
        placeholder={t('selgroup_search')}
        {...(search && {
          suffixIcon: (
            <Button
              variant="ghost"
              aria-label={t('delete_input')}
              icon={<Icon name="xmark" />}
              onClick={() => {
                onSearchChange('');
              }}
            />
          ),
        })}
      />

      <EarnedRewardsReportTable
        data={data}
        className="modal-earned-rewards-today__table"
        initialSort={activeColumnSort}
        loading={loading}
        onColumnSortClicked={handleOnColumnSort}
        paginationData={{
          limit: count,
          totalPages,
          totalCount,
          currentPage,
        }}
        onPageChange={setCurrentPage}
        onLimitChange={handleOnLimitChange}
      />
    </Styled.ModalContainer>
  );
};

export default EarnedRewardsReportModal;
