import { FC } from 'react';
import { getColor, theme } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import * as FieldsStyled from './CustomFields.styles';

type IphoneFrameProps = {
  text: string;
  required: boolean;
};

const IphoneFrame: FC<IphoneFrameProps> = ({ text, required }) => {
  const { t } = useTranslation();

  return (
    <FieldsStyled.IphoneFrameContainer>
      <div className="iphone-frame">
        <svg width="412" height="567" viewBox="0 0 412 567">
          <defs>
            <clipPath id="clip-path">
              <rect
                data-name="Rectangle 6"
                width="412"
                height="567"
                transform="translate(486 784)"
                fill={getColor('--BACKGROUND_1_1')}
                stroke="#707070"
                strokeWidth="1"
              />
            </clipPath>
          </defs>
          <g transform="translate(-486 -784)">
            <g clipPath="url(#clip-path)">
              <g>
                <g>
                  <g transform="translate(-2 -80)">
                    <g data-name="Group 10" transform="translate(-7 -50)">
                      <g data-name="Group 9" transform="translate(-63 405)">
                        <path
                          id="device_bg"
                          data-name="device bg"
                          d="M789.394,1137.555H464.354a36.648,36.648,0,0,1-36.795-36.5V317.822a36.648,36.648,0,0,1,36.795-36.5h325.04a36.648,36.648,0,0,1,36.8,36.5v783.234a36.647,36.647,0,0,1-36.795,36.5Z"
                          transform="translate(136.839 232.883)"
                          fill={getColor('--BACKGROUND_1_1')}
                        />
                        <path
                          id="device_contour"
                          data-name="device contour"
                          d="M787.661,276.117H465.2a44.822,44.822,0,0,0-44.939,44.6v777.44a44.823,44.823,0,0,0,44.939,44.6H787.661a44.822,44.822,0,0,0,44.939-44.6V320.719a44.822,44.822,0,0,0-44.939-44.6Zm36.5,822.042a36.366,36.366,0,0,1-36.5,36.229H465.2a36.366,36.366,0,0,1-36.5-36.229V320.719a36.366,36.366,0,0,1,36.5-36.229h46.809a4.684,4.684,0,0,1,4.7,4.666v1.982A24.058,24.058,0,0,0,540.857,315.1H712.485a24.058,24.058,0,0,0,24.148-23.967v-1.982a4.684,4.684,0,0,1,4.7-4.666h46.326a36.366,36.366,0,0,1,36.5,36.229Z"
                          transform="translate(137.739 232.883)"
                        />
                      </g>
                      <text
                        data-name="9:41"
                        transform="translate(543 953)"
                        fontSize={theme.fontSizes.FONT_16}
                        fontFamily="HelveticaNeue, Helvetica Neue"
                      >
                        <tspan x="0" y="0">
                          9:41
                        </tspan>
                      </text>
                    </g>
                    <g id="low-battery" transform="translate(846.75 882)">
                      <path
                        d="M4.25,9h17a1.948,1.948,0,0,1,2,1.892v6.621a1.948,1.948,0,0,1-2,1.892h-17a1.948,1.948,0,0,1-2-1.892V10.892A1.948,1.948,0,0,1,4.25,9Z"
                        fill="none"
                        stroke="#000"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                      <path d="M4.75,10.5h13v7.405h-13a.965.965,0,0,1-1-.926V11.426A.965.965,0,0,1,4.75,10.5Z" />
                      <path
                        d="M24.75,12.05v4.9a2.5,2.5,0,0,0,0-4.9Z"
                        transform="translate(0 -0.297)"
                      />
                      <g transform="translate(2.25 9)">
                        <path
                          d="M4.25,9h17a1.948,1.948,0,0,1,2,1.892v6.621a1.948,1.948,0,0,1-2,1.892h-17a1.948,1.948,0,0,1-2-1.892V10.892A1.948,1.948,0,0,1,4.25,9Z"
                          transform="translate(-2.25 -9)"
                          fill="none"
                          stroke="#000"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <path
                          d="M4.75,10.5h13v7.405h-13a.965.965,0,0,1-1-.926V11.426A.965.965,0,0,1,4.75,10.5Z"
                          transform="translate(-2.25 -9)"
                        />
                        <path
                          d="M24.75,12.05v4.9a2.5,2.5,0,0,0,0-4.9Z"
                          transform="translate(-2.25 -9.297)"
                        />
                      </g>
                    </g>
                    <g transform="translate(821.925 881.75)">
                      <path d="M14.5,19.75l-2.475-2.475a3.5,3.5,0,0,1,4.95,0Z" />
                      <path
                        data-name="Path 8"
                        d="M9.9,15.154a6.5,6.5,0,0,1,9.192,0"
                        fill="none"
                        stroke="#000"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      />
                      <path
                        data-name="Path 9"
                        d="M7.075,12.325a10.5,10.5,0,0,1,14.85,0"
                        fill="none"
                        stroke="#000"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      />
                    </g>
                    <g data-name="Group 12" transform="translate(426 249)">
                      <g
                        id="wifi_1_"
                        data-name="wifi (1)"
                        transform="translate(376.099 634.5)"
                      >
                        <path
                          data-name="Path 10"
                          d="M7.5,15.024v3.669c0,.289-.448.524-1,.524h-2c-.552,0-1-.235-1-.524V15.024c0-.289.448-.524,1-.524h2C7.052,14.5,7.5,14.735,7.5,15.024Z"
                          transform="translate(0 -1.887)"
                        />
                        <path
                          data-name="Path 11"
                          d="M13.5,13.079v5.208c0,.32-.448.579-1,.579h-2c-.552,0-1-.259-1-.579V13.079c0-.32.448-.579,1-.579h2C13.052,12.5,13.5,12.759,13.5,13.079Z"
                          transform="translate(-0.866 -1.534)"
                        />
                        <path
                          data-name="Path 12"
                          d="M19.031,10.149v7.787c0,.358-.4.649-.883.649H16.383c-.488,0-.883-.291-.883-.649V10.149c0-.358.4-.649.883-.649h1.766C18.636,9.5,19.031,9.791,19.031,10.149Z"
                          transform="translate(-1.733 -1.169)"
                        />
                        <path
                          data-name="Path 13"
                          d="M25.1,6.146V16.477c0,.357-.4.646-.9.646H22.4c-.5,0-.9-.289-.9-.646V6.146c0-.357.4-.646.9-.646h1.8C24.7,5.5,25.1,5.789,25.1,6.146Z"
                          transform="translate(-2.599 0.209)"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    data-name="Path 14"
                    d="M0,0H395"
                    transform="translate(494.5 916.5)"
                    fill="none"
                    stroke="rgba(112,112,112,0.32)"
                    strokeWidth="1"
                  />
                  <text
                    id="Personal_details"
                    data-name="Personal details"
                    transform="translate(525 876)"
                    fill={getColor('--SECONDARY_1_1')}
                    fontSize={theme.fontSizes.FONT_20}
                    fontFamily="ToyotaType"
                    fontWeight="600"
                  >
                    <tspan x="0" y="0">
                      {t('settings_personal_title')}
                    </tspan>
                  </text>
                  <path
                    d="M19.435,4A15.435,15.435,0,1,0,34.87,19.435,15.454,15.454,0,0,0,19.435,4Zm0,2.572A12.862,12.862,0,1,1,6.572,19.435,12.843,12.843,0,0,1,19.435,6.572Zm-9,7.717v2.572H28.438V14.29ZM13,19.435v2.572H25.866V19.435Zm2.572,5.145v2.572h7.717V24.58Z"
                    transform="translate(812.13 847.13)"
                    fill={getColor('--SECONDARY_1_1')}
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>

        <div className="input-preview">
          <div>
            {text}
            {required && <span>&#42;</span>}
          </div>
          <div className="line" />
        </div>
      </div>
    </FieldsStyled.IphoneFrameContainer>
  );
};

export default IphoneFrame;
