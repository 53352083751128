import { focusStyles, theme } from '@faxi/web-component-library';
import {
  flex,
  flexGap,
  fontSize,
  marginChildren,
  phablet,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  &.campaign-list {
    ${marginChildren(`0 0 ${theme.sizes.SIZE_24} 0`)};

    .kinto-modal__actions {
      ${flex('row', 'flex-end')};

      button {
        margin-left: ${theme.sizes.SIZE_40};
      }
    }

    a {
      text-decoration: none;
    }
  }

  .campaign-list {
    .gray-ghost {
      margin-left: auto;
    }

    &__reuse {
      ${flex('row', 'flex-start', 'center')};

      &__tooltip {
        ${focusStyles};
        margin: ${theme.sizes.SIZE_2} 0 0 ${theme.sizes.SIZE_8};
      }
    }

    &__header-actions {
      ${flex('row', 'space-between', 'center')};
      width: 100%;

      ${phablet(css`
        ${flexGap(theme.sizes.SIZE_12, 'column')};

        flex-direction: column;
        align-items: flex-start;
      `)}
    }

    &__active-campaigns-label {
      ${fontSize(theme.sizes.SIZE_12)};
      color: var(--SHADE_1_2);
      padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_48};
      margin: 0 -${theme.sizes.SIZE_48} ${theme.sizes.SIZE_24};
    }
  }
`;
