import { theme } from '@faxi/web-component-library';
import {
  flex,
  fontSize,
  marginChildren,
  position,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';

import styled from 'styled-components';

export const UserMessageStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  padding: 0 ${theme.sizes.SIZE_20};
  align-items: flex-end;
  max-width: 80%;

  > * {
    margin: 0 ${theme.sizes.SIZE_16} 0 0;
  }

  &.kinto-user-message {
    &:not(&--left) {
      margin-left: auto;
    }

    &--left {
      > * {
        margin: 0 0 0 ${theme.sizes.SIZE_16};
      }

      align-items: flex-start;

      .kinto-user-message {
        &__content {
          &__message {
            text-align: left;
          }
        }

        &__content-wrapper,
        &__sender {
          flex-direction: row-reverse;
        }

        &__time {
          text-align: right;
        }
      }
    }

    &--parent {
    }

    &--child {
      .profile-img {
        visibility: hidden;
      }
    }
  }

  .kinto-user-message {
    &__sender {
      ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};

      display: flex;
      gap: ${theme.sizes.SIZE_10};
      align-items: center;
      margin-bottom: ${theme.sizes.SIZE_10};

      &__name {
        display: flex;
        gap: ${theme.sizes.SIZE_4};
        color: var(--SECONDARY_1_1);
        font-weight: 600;
      }

      &__time {
        color: var(--SHADE_1_2);
      }
    }

    &__avatar {
      display: inline-flex;
      place-content: center;
      width: ${theme.sizes.SIZE_48};
      height: ${theme.sizes.SIZE_48};
      flex: 0 0 ${pxToRem(theme.sizes.SIZE_40)};
    }

    &__time {
      ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};

      color: var(--SHADE_1_2);
      width: ${theme.sizes.SIZE_48};
      padding-top: ${theme.sizes.SIZE_16};
      font-weight: 600;
    }

    &__content-wrapper {
      display: flex;
      gap: ${theme.sizes.SIZE_10};
    }

    &__content {
      padding: ${theme.sizes.SIZE_16};
      background-color: var(--BACKGROUND_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      ${marginChildren(`0 0 ${theme.sizes.SIZE_8} 0`)};
      position: relative;

      &:not(:hover) + .kinto-user-message__time {
        opacity: 0;
      }

      &__message {
        ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_24)};

        color: var(--SHADE_1_1);
        text-align: right;
        white-space: pre-wrap;
        word-break: normal;
        overflow-wrap: anywhere;
        ${marginChildren(`0 0 ${theme.sizes.SIZE_4} 0`)};

        &--failed {
          color: var(--SHADE_1_5);

          .kinto-user-message__content__message__time {
            color: var(--SHADE_1_6);
          }
        }
      }

      &__failed {
        display: flex;
        color: var(--ALERT_ERROR_1_1);
        ${flex('row', 'center', 'center')};
        ${marginChildren(`0 ${theme.sizes.SIZE_12} 0 0`)};
        color: var(--ALERT_ERROR_1_1);

        > * {
          ${fontSize(theme.fontSizes.FONT_12)};
          height: fit-content;
          min-height: unset;
        }
      }

      &__cloud-icon {
        transform: rotate(90deg);
        ${size('14px', theme.sizes.SIZE_8)};
        ${position(
          'absolute',
          `bottom ${pxToRem(theme.sizes.SIZE_16)} right ${pxToRem('-11px')}`
        )};
      }
    }
  }
`;
