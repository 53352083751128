import { FC, useContext, useMemo } from 'react';
import { Image, NavigationLinks, NavLink } from '@faxi/web-component-library';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'store';

import * as Styled from './InnerFooter.styles';

export type InnerProps = {
  className?: string;
};

const InnerFooter: FC<InnerProps> = (props: InnerProps): JSX.Element => {
  const { className } = props;

  const { platform, defaultCountry } = useContext(AppContext);

  const { t } = useTranslation();

  const footerLinks: NavLink[] = useMemo(
    () =>
      defaultCountry && platform
        ? [
            {
              id: 'footer_nav_privacy_policy',
              name: t('register_privacy_and_policy'),
              route: `/corp/privacy-policy?country=${defaultCountry.name.parametrify()}&language=${platform.default_language.parametrify()}`,
            },
            {
              id: 'footer_nav_t&c',
              name: t('TermsCondition'),
              route: `/corp/terms-and-conditions?country=${defaultCountry.name.parametrify()}&language=${platform.default_language.parametrify()}`,
            },
            {
              id: 'footer_nav_cookie_policy',
              name: t('CookiePolicy'),
              route: `/corp/cookie-policy?country=${defaultCountry.name.parametrify()}`,
            },
          ]
        : [],
    [defaultCountry, platform, t]
  );

  return (
    <Styled.Container
      id="footer"
      className={classNames('inner-footer', className)}
    >
      <Image
        src="/assets/svg/kinto_join_logo_stacked.svg"
        className="inner-footer__logo"
        alt={t('app_name')}
      />
      <div className="inner-footer__vertical-divider" />
      <NavigationLinks
        links={footerLinks}
        target="_blank"
        className="inner-footer__links"
      />
      <div className="inner-footer__contact">
        <div className="inner-footer__contact__title">{t('support_email')}</div>
        <a
          id="footer_support_email_link"
          href={`mailto:${platform?.support_email}`}
          target="_blank"
          rel="noreferrer"
          className="inner-footer__contact__text"
        >
          {platform?.support_email}
        </a>
      </div>
    </Styled.Container>
  );
};

export default InnerFooter;
