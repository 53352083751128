import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';
import {
  useEffectSkipFirst,
  useModalUtilities,
} from '@faxi/web-component-library';

import { UserContext } from 'store';
import { APIResponse, Survey } from 'models';
import { useQuery } from 'hooks';
import { getDatesBetween, mapSurveys } from '../../utils';
import apiSurvey from 'modules/api/apiSurvey';
import SurveyModal from '../../components/SurveyModal';
import SustainabilityContext from './Sustainability.context';
// import ShareLinkModal from '../../components/ShareLinkModal';

type SustainabilityProviderProps = PropsWithChildren;

const SustainabilityProvider: FC<SustainabilityProviderProps> = (props) => {
  const { children } = props;

  const navigate = useNavigate();
  const { communityId } = useContext(UserContext);

  const {
    open: surveyModalOpen,
    triggerRef,
    openModal: openSurveyModal,
    closeModal: closeSurveyModal,
  } = useModalUtilities();

  // const {
  //   open: shareLinkModalOpen,
  //   openModal: openShareLinkModal,
  //   closeModal: closeShareLinkModal,
  // } = useModalUtilities();

  const modalBtnRef = triggerRef.current as HTMLButtonElement;

  const surveyReportRetry = useRef<Function>(() => {});
  const [activeSurveyId, setActiveSurveyId] = useState<string>('');
  // const [activeSlug, setActiveSlug] = useState<string>('');

  const surveysQuery = useCallback(
    async (signal: () => AbortSignal) =>
      await apiSurvey.getSurveys(`${communityId}`, {
        signal: signal(),
      }),
    [communityId]
  );

  const {
    data: surveys,
    placeholder: emptyPlaceholder,
    retry: retrySurveys,
    setData: setSurveys,
  } = useQuery<APIResponse<Survey[]>, Survey[]>({
    initialValue: [],
    initialLoading: true,
    showSpinner: !activeSurveyId,
    condition: !!communityId,
    spinnerParent: '.kinto-page',
    queryFn: surveysQuery,
    mappingFunction: async (res) => mapSurveys(res.data),
    deps: [communityId],
  });

  const disabledDates = useMemo(
    () =>
      surveys?.reduce(
        (dates, s) =>
          s.status !== 'canceled'
            ? [
                ...dates,
                ...getDatesBetween(dayjs(s.start_date), dayjs(s.end_date)),
              ]
            : dates,
        [] as Dayjs[]
      ),
    [surveys]
  );

  const activeSurvey = useMemo(
    () => surveys?.find((s) => `${s.id}` === activeSurveyId),
    [surveys, activeSurveyId]
  );

  const updateSurvey = useCallback(
    (surveyId: string, data: Partial<Survey>) => {
      setSurveys((old) =>
        old.map((survey) =>
          `${survey.id}` === surveyId
            ? ({ ...survey, ...data } as Survey)
            : survey
        )
      );
    },
    [setSurveys]
  );

  const revalidateData = useCallback(() => {
    //revalidate data when create or update surve
    if (activeSurvey) {
      surveyReportRetry.current();
    }

    retrySurveys();
    closeSurveyModal();

    // setActiveSlug('');
    // closeShareLinkModal();
  }, [activeSurvey, closeSurveyModal, retrySurveys]);

  const providerValues = useMemo(
    () => ({
      surveys,
      activeSurvey,
      activeSurveyId,
      emptyPlaceholder,
      surveyReportRetry,
      updateSurvey,
      openModal: openSurveyModal,
      setActiveSurveyId,
    }),
    [
      surveys,
      activeSurvey,
      activeSurveyId,
      emptyPlaceholder,
      openSurveyModal,
      updateSurvey,
      setActiveSurveyId,
    ]
  );

  // COMMENT: surveys don't care if users are marked as 'test'
  // const numberOfActiveUsers = useMemo(
  //   () =>
  //     Number(community?.approved_users) +
  //     Number(community?.['approved_users-test']),
  //   [community]
  // );

  //return to previous route when community id has been changed
  useEffectSkipFirst(() => {
    if (!activeSurvey) return;
    navigate('.');
  }, [communityId, navigate]);

  return (
    <SustainabilityContext.Provider value={providerValues}>
      {surveyModalOpen && (
        <SurveyModal
          oid={communityId}
          triggerRef={modalBtnRef}
          activeSurvey={activeSurvey}
          disabledDates={disabledDates}
          onClose={closeSurveyModal}
          onSurveyRun={(survey, isEditing) => {
            // setActiveSlug(survey.additional_data['survey-slug']);
            // !isEditing ? openShareLinkModal() : revalidateData();
            revalidateData();
          }}
        />
      )}

      {/* {shareLinkModalOpen && (
        <ShareLinkModal
          justOneMember={numberOfActiveUsers === 1}
          slug={activeSlug}
          onClose={revalidateData}
          onOverlayClick={revalidateData}
        />
      )} */}

      {children}
    </SustainabilityContext.Provider>
  );
};

export default SustainabilityProvider;
