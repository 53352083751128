import { SVGProps } from 'react';
import { getColor, theme } from '@faxi/web-component-library';

import { datafyColor } from '../components/MapUserPins/utils/datafyColor';

type UserPinProps = {
  className?: string;
  src: string;
} & SVGProps<any>;

const UserPinIcon = (props: UserPinProps) => {
  const { src, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58 68"
      width={theme.sizes.SIZE_56}
      height={theme.sizes.SIZE_64}
      {...rest}
    >
      <defs>
        <clipPath id="user-pin-clip">
          <circle cx="29" cy="29" r="25"></circle>
        </clipPath>
      </defs>

      <style>{`.user-pin-img{clip-path: circle(50%); -webkit-clip-path: circle(50%);}`}</style>
      <g fill={datafyColor(getColor('--PRIMARY_1_1'))}>
        <circle cx="29" cy="29" r="29" />
        <polygon points="22,56 29,68 36,56" />
        <image
          className="user-pin-img"
          x="4"
          y="4"
          width="50"
          height="50"
          href={src}
        />
      </g>
    </svg>
  );
};

export default UserPinIcon;
