import { AxiosRequestConfig, Method } from 'axios';
import httpClient from '../httpClient';
import { CarSpaceProps } from 'pages/Settings/CarParkSettings/CarParkSettings.component';
import {
  Community,
  Country,
  TestUsersDataResponse,
  ApiCarpark,
  Home,
  APIResponseCustom,
  ParkingSpot,
} from 'models';

const getMagicLink = (oid: number) =>
  httpClient.get(`organisations/${oid}/fastcode`);

const updateJoinCode = async (oid: number, joincode?: boolean) => {
  const formData = new FormData();
  formData.append('joincode', `${Number(joincode)}`);

  return httpClient
    .post<APIResponseCustom<{ joincode: string }>>(
      `organisation/${oid}/`,
      formData,
      {
        params: { _method: 'PUT' },
      }
    )
    .then((res) => res.data);
};

const updateMagicLink = async (oid: number, fastcode: string) => {
  const formData = new FormData();
  formData.append('fastcode', fastcode);

  return httpClient
    .post<{ rc: 'ok' | 'error' }>(`organisation/${oid}/`, formData, {
      params: { _method: 'PUT' },
    })
    .then((res) => res.data);
};

const checkCommunityName = (communityName: string) =>
  httpClient.get<{ errc: number; count: number }>(`organisation/`, {
    params: { name: communityName },
  });

const getCommunityDescription = (
  oid: number,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient.get<{ rc: string; value: string }>(`organisation/${oid}`, {
    params: { key: 'gDesc' },
    ...config,
  });

const updateCommunity = async (
  oid: number,
  data: object,
  method?: Method,
  config?: Partial<AxiosRequestConfig>
) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined && value !== null) formData.append(key, value);
  });

  return httpClient
    .post<{ rc: 'ok' | 'error'; image_url?: string }>(
      `organisation/${oid}`,
      formData,
      method && {
        params: { _method: method },
        ...config,
      }
    )
    .then((res) => res.data);
};

const getCommunityById = (id: number, config?: Partial<AxiosRequestConfig>) =>
  httpClient.get<{ rc: 'ok' | 'error'; organisations: Community[] }>(
    `organisation/${id}`,
    {
      params: { ca: 1 },
      ...config,
    }
  );

const getPendingRequests = async (userId: string) =>
  httpClient.get<{
    rc: 'ok' | 'error';
    organisations: Community[];
  }>(`user/${userId}/organisations_managing`);

const getParkingSlotAllocation = (
  oid: number,
  date: string,
  count?: number,
  offset?: number,
  search?: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient
    .get<{ rc: 'ok' | 'error'; carpark: ParkingSpot[]; total: number }>(
      `admin/carpark`,
      {
        params: { oid, date, count, offset, search },
        ...config,
      }
    )
    .then((res) => res.data);

const getCommunityUsers = (oid: number, config?: Partial<AxiosRequestConfig>) =>
  httpClient.get<{
    rc: 'ok' | 'error';
    users: (Omit<Home, 'lat' | 'lng'> & {
      latitude: number;
      longitude: number;
      image_url?: string;
    })[];
  }>(`organisation/${oid}/users`, {
    params: { client: 'web' },
    ...config,
  });

const getCarParks = (
  oid: number,
  date?: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient
    .get<{ rc: 'ok' | 'error'; carparks: ApiCarpark[] }>(
      `organisation/${oid}/carpark`,
      {
        params: { date },
        ...config,
      }
    )
    .then((res) => res.data);

const getCarParkSpaces = (oid: number, carparkId: string) =>
  httpClient
    .get<{ rc: 'ok' | 'error'; carparkspace: CarSpaceProps[] }>(
      `organisation/${oid}/carpark/${carparkId}/space`
    )
    .then((res) => res.data);

const updateCarParkName = async (
  oid: number,
  carparkId: string,
  data: FormData
) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined && value !== null) formData.append(key, value);
  });

  return httpClient
    .post<{ rc: 'ok' | 'error' }>(
      `organisation/${oid}/carpark/${carparkId}`,
      formData,
      { params: { _method: 'PUT' } }
    )
    .then((res) => res.data);
};

const addCarSpace = async (
  oid: number,
  carparkId: string,
  carSpaceName: string
) => {
  const formData = new FormData();

  formData.append('count', '1');
  formData.append('names', JSON.stringify({ names: [carSpaceName] }));

  return httpClient
    .post<{ rc: 'ok' | 'error' }>(
      `organisation/${oid}/carpark/${carparkId}/space`,
      formData
    )
    .then((res) => res.data);
};

const updateCarSpace = async (
  oid: number,
  carparkId: string,
  carspaceId: string,
  name: string
) => {
  const formData = new FormData();
  formData.append('name', name);

  return httpClient
    .post<{ rc: 'ok' | 'error' }>(
      `organisation/${oid}/carpark/${carparkId}/space/${carspaceId}`,
      formData,
      { params: { _method: 'PUT' } }
    )
    .then((res) => res.data);
};

const removeCarSpace = (oid: number, carparkId: string, carspaceId: string) =>
  httpClient
    .post<{ rc: 'ok' | 'error' }>(
      `organisation/${oid}/carpark/${carparkId}/space/${carspaceId}`,
      {},
      { params: { _method: 'DELETE' } }
    )
    .then((res) => res.data);

const fetchPlatformCountries = (): Promise<{
  data: Country[];
}> => httpClient.get(`platform/countries`).then((res) => res.data);

const getPreapprovedEmails = async (
  oid: number,
  count: number,
  offset: number,
  search: string
) =>
  httpClient
    .get<{
      preapproved_emails: string[];
      count: number;
      total_count: number;
    }>(`organisations/${oid}/preapproved-emails`, {
      params: {
        count,
        offset,
        search,
      },
    })
    .then((res) => res.data);

const addPreapprovedEmails = async (oid: number, emails: string[]) => {
  const formData = new FormData();
  formData.set('emails', JSON.stringify(emails));
  return httpClient
    .post(`organisation/${oid}/preapproved-emails`, formData)
    .then((res) => res.data);
};

const deletePreapprovedEmails = async (oid: number, emails: string[]) => {
  const formData = new FormData();
  formData.set('emails', JSON.stringify(emails));
  return httpClient
    .post(`organisation/${oid}/preapproved-emails`, formData, {
      params: { _method: 'DELETE' },
    })
    .then((res) => res.data);
};

const includeTestUsers = async (
  includeTestUsers: boolean,
  oid: number,
  method: Method
) => {
  const formData = new FormData();

  formData.append('include_test_users', includeTestUsers ? 'Y' : 'N');
  formData.append('oid', `${oid}`);

  return httpClient.post(`admin/include_test_users`, formData, {
    params: { _method: method },
  });
};

const getIncludeTestUsers = async (oid: number) => {
  return httpClient.get<TestUsersDataResponse>(`admin/include_test_users`, {
    params: { oid },
  });
};

export default {
  getMagicLink,
  checkCommunityName,
  updateCommunity,
  updateJoinCode,
  getCommunityById,
  getPendingRequests,
  getCommunityUsers,
  getCommunityDescription,
  getParkingSlotAllocation,
  getCarParks,
  getCarParkSpaces,
  updateCarParkName,
  addCarSpace,
  updateCarSpace,
  removeCarSpace,
  updateMagicLink,
  fetchPlatformCountries,
  addPreapprovedEmails,
  getPreapprovedEmails,
  deletePreapprovedEmails,
  includeTestUsers,
  getIncludeTestUsers,
};
