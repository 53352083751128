import { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { config, StatusElement } from '@faxi/web-component-library';

import { dateStringLocale } from 'utils';
import { Icon, SeparatedText } from 'components';
import { SurveyStatus, Survey, SURVEY_STATUS } from 'models';
import { SURVEY_STATUS_ELEMENT, SURVEY_STATUS_TRANSLATIONS } from '../../utils';

import * as Styles from './SurveyCard.styles';

type SurveyCardProps = {
  className?: string;
  onSurveyClick?: (value: string) => void;
} & Survey;

const SurveyCard: FC<SurveyCardProps> = (props) => {
  const {
    className,
    id,
    name,
    status,
    start_date,
    start_time,
    end_date,
    end_time,
    participants_total,
    onSurveyClick,
  } = props;

  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Styles.SurveyCard
      className={classNames('kinto-survey-card', className)}
      aria-label={t('accessibility-open_survey_report', { name })}
      to={`${location.pathname}/${id}`}
      onClick={() => onSurveyClick?.(`${id}`)}
    >
      <div className="kinto-survey-card__title" aria-label={name}>
        {name}
      </div>

      <SeparatedText
        className="separated-text"
        name={t('campaign-start_date')}
        value={dateStringLocale(
          start_date,
          start_time,
          config.dateFormatSimple
        )}
      />
      <SeparatedText
        className="separated-text"
        name={t('campaign-end_date')}
        value={dateStringLocale(end_date, end_time, config.dateFormatSimple)}
      />
      <SeparatedText
        className="separated-text"
        name={t('global-participants')}
        value={`${participants_total || 0}`}
      />

      <div className="kinto-survey-card__status">
        <StatusElement
          small
          className="kinto-survey-card__status__element"
          status={SURVEY_STATUS_ELEMENT[status as SurveyStatus]}
          {...(status === SURVEY_STATUS.FINISHED && { icon: 'circle-check' })}
        >
          {t(SURVEY_STATUS_TRANSLATIONS[status as SurveyStatus])}
        </StatusElement>

        <Icon className="kinto-survey-card__arrow" name="chevron-right" />
      </div>
    </Styles.SurveyCard>
  );
};

export default SurveyCard;
