import { theme } from '@faxi/web-component-library';
import { flex, phablet, pxToRem } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const SurveysContainer = styled.div`
  ${flex('column')};
  row-gap: ${theme.sizes.SIZE_32};

  .kinto-survey-board {
    &__surveys {
      display: grid;
      grid-gap: ${theme.sizes.SIZE_20};
      grid-template-columns: repeat(auto-fit, ${pxToRem(theme.sizes.SIZE_256)});

      ${phablet(css`
        grid-template-columns: 1fr;
        grid-gap: ${theme.sizes.SIZE_8};
      `)}
    }
  }
`;
