import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const FALLBACK_LANG = 'en';
export const SUPPORTED_LANGUAGES = [
  'da',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'nl',
  'no',
  'pl',
  'pl_BR',
  'sr',
  'sr_Latn',
  'sv',
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    backend: {
      loadPath: '/assets/kinto/locales/{{lng}}/{{ns}}.json',
    },
    // react: {
    //   useSuspense: false,
    // },
    detection: {
      order: [
        'querystring',
        'localStorage',
        'cookie',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      lookupQuerystring: 'language',
    },
    // COMMENT: LET'S CONSIDER THIS SO WE DON'T LET i18n TRY FETCH THE DEFAULT "en-GB" bundle
    supportedLngs: SUPPORTED_LANGUAGES,
    // nonExplicitSupportedLngs: true, // omit en-US
    //nsSeparator: '_',
    load: 'unspecific',
    fallbackLng: FALLBACK_LANG,
    // debug: true,
    returnEmptyString: false,

    // default namespace (needs no prefix on calling t)
    ns: 'translation',
    defaultNS: 'translation',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
