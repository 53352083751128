import { useEffect, useCallback } from 'react';
import { authBus } from 'modules';
import { useNavigate } from 'react-router-dom';
import { appUri } from '../config';

const useLoginRedirections = () => {
  const navigate = useNavigate();

  const redirectToInterstitial = useCallback(() => {
    navigate(`/${appUri.INTERSTITIAL}`);
  }, [navigate]);

  const redirectToDisabledCommunity = useCallback(
    () => navigate(appUri.LOGIN_DISABLED_COMMUNITY),
    [navigate]
  );

  const redirectToLoginSuccess = useCallback(
    () => navigate(appUri.LOGIN_SUCCESSFUL),
    [navigate]
  );

  useEffect(() => {
    authBus.addEventListener('redirect_interstitial', redirectToInterstitial);

    return () => {
      authBus.removeEventListener(
        'redirect_interstitial',
        redirectToInterstitial
      );
    };
  }, [redirectToInterstitial]);

  useEffect(() => {
    authBus.addEventListener(
      'redirect_disabled-community',
      redirectToDisabledCommunity
    );

    return () => {
      authBus.removeEventListener(
        'redirect_disabled-community',
        redirectToDisabledCommunity
      );
    };
  }, [redirectToDisabledCommunity]);

  useEffect(() => {
    authBus.addEventListener('redirect_login_success', redirectToLoginSuccess);

    return () => {
      authBus.removeEventListener(
        'redirect_login_success',
        redirectToLoginSuccess
      );
    };
  }, [redirectToLoginSuccess]);
};

export default useLoginRedirections;
