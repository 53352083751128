const CALENDAR_LABELS = {
  days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
  monthsShort: [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ],
  months: [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ],
};

export { CALENDAR_LABELS };
