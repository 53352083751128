import { getColor } from '@faxi/web-component-library';
import classNames from 'classnames';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';

const usePointsPromptProps = () => {
  const { t } = useTranslation();

  const deactivatePromptProps = {
    btnIcon: 'ban',
    iconPosition: 'right',
    cancelBtnText: t('cancel'),
    submitBtnText: t('deactivate'),
    submitBtnVariant: 'delete-ghost',
    className: classNames('points-prompt-modal', 'deactivate'),
    title: t('gamification-points_modal_deactivate_points'),
    content: (
      <div
        className={classNames(
          'kinto-points-form__alert-content',
          'kinto-points-form__alert-content--deactivate'
        )}
      >
        <Icon
          name="triangle-exclamation"
          color={getColor('--ALERT_WARNING_1_1')}
        />
        {t('gamification-points_modal_alert_deactivate_points')}
      </div>
    ),
  };

  const activatePromptProps = {
    cancelBtnText: t('cancel'),
    submitBtnText: t('global-activate'),
    className: classNames('points-prompt-modal', 'activate'),
    title: t('gamification-points_modal_activate_points'),
    content: (
      <div
        className={classNames(
          'kinto-points-form__alert-content',
          'kinto-points-form__alert-content--activate'
        )}
      >
        <Icon name="circle-info" color={getColor('--ACCENT_1_1')} />
        {t('gamification-points_modal_alert_activate_points')}
      </div>
    ),
  };

  return [deactivatePromptProps, activatePromptProps];
};

export default usePointsPromptProps;
