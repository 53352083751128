import { FC, memo, useCallback, useMemo, useRef } from 'react';
import {
  Button,
  CheckboxProps,
  Modal,
  ModalProps,
  ModalRef,
  RadioGroupOption,
  useCallbackRef,
  useFormButtons,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef } from '@faxi/web-form';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import { Each } from 'helpers';
import { isNonEmptyString } from '../../../utils';
import { CheckboxGroupField, FormActions, RadioGroupField } from 'components';

export type Filter = {
  legendLabel: string;
  checkboxes: CheckboxProps[];
  name: string;
};

export type RadioFilter = Pick<Filter, 'name' | 'legendLabel'> & {
  options: RadioGroupOption[];
};

type FiltersModalProps = {
  filters: Filter[];
  radioFilters?: RadioFilter[];
  className?: string;
  initialData: Record<string, string[] | string | undefined>;
  onSubmit: (data: Record<string, any>) => Promise<void>;
} & Omit<ModalProps, 'onTransitionEnd'>;

const FiltersModal: FC<FiltersModalProps> = (props) => {
  const {
    filters = [],
    radioFilters = [],
    initialData,
    triggerRef,
    onSubmit,
    ...rest
  } = props;

  const { t } = useTranslation();

  const modalRef = useRef<ModalRef>(null);
  const [form, formRef] = useCallbackRef<FormRef>();

  const formWrapper = useCallback(
    ({ children, className }: any) => (
      <Form
        ref={formRef}
        children={children}
        strictValidation={false}
        initialData={initialData}
        className={className}
        onSubmit={async (data) => {
          modalRef.current?.close();
          onSubmit(data);
        }}
      />
    ),
    [formRef, initialData, onSubmit]
  );

  const totalFormValues = useMemo(
    () =>
      !form
        ? 0
        : Object.values(form.fields).reduce(
            (total, { value }) =>
              total +
              (Array.isArray(value)
                ? value.length
                : typeof value === 'string' && isNonEmptyString(value)
                ? 1
                : 0),
            0
          ),
    [form]
  );

  const [FormButtons] = useFormButtons({
    cancelLabel: t('cancel'),
    submitLabel: `${t(totalFormValues > 0 ? 'apply' : 'global-show_all')}${
      totalFormValues > 0 ? ' (' + totalFormValues + ')' : ''
    }`,
  });

  return (
    <Modal
      ref={modalRef}
      className="kinto-filters-modal"
      title={t('global-apply_filters')}
      triggerRef={triggerRef}
      childrenWrapper={formWrapper}
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t('global-apply_filters'),
      })}
      footer={
        <FormActions className="kinto-filters-modal__form-actions">
          <FormButtons.Submit
            disabled={!form?.isFormChanged() || !form.syncFormValid}
          />

          <FormButtons.Cancel
            type="button"
            className="cancel-btn"
            aria-label="cancel-reward"
            onClick={() => {
              modalRef.current?.close();
            }}
          />

          <Button
            variant="ghost"
            icon={<Icon name="xmark" />}
            className="kinto-filters-modal__form-actions__clear-all"
            onClick={() => {
              form.resetAllFieldValues();
            }}
          >
            {t('filters_clear_all')}
          </Button>
        </FormActions>
      }
      {...rest}
    >
      <div className="kinto-modal__fields">
        <Each
          of={filters}
          render={({ legendLabel, checkboxes, name }) => (
            <fieldset>
              <legend>{legendLabel}</legend>

              <FormField
                component={CheckboxGroupField}
                checkboxes={checkboxes}
                name={name}
                variant="chip"
              />
            </fieldset>
          )}
        />

        <Each
          of={radioFilters}
          render={({ legendLabel, options, name }) => (
            <fieldset>
              <legend>{legendLabel}</legend>

              <FormField
                name={name}
                component={RadioGroupField}
                orientation="column"
                options={options}
              />
            </fieldset>
          )}
        />
      </div>
    </Modal>
  );
};

export default memo(FiltersModal);
