import {
  elevate_l,
  Modal as ModalComponent,
  theme,
} from '@faxi/web-component-library';
import { flex, flexGap, fontSize, phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Modal = styled(ModalComponent)`
  .wcl-modal {
    background-color: var(--BACKGROUND_2_1);

    ${phablet(css`
      width: 100%;
      max-width: 95%;
    `)}
  }

  .shift-settings-modal {
    &__assigned-shifts {
      ${flex('column')};
      ${flexGap(theme.sizes.SIZE_8, 'column')};

      width: 100%;
    }

    &__multiple-assigned {
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
      ${elevate_l};

      font-weight: 600;
      padding: ${theme.sizes.SIZE_16};
      color: var(--SHADE_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      background-color: var(--BACKGROUND_1_1);
    }

    &__actions {
      ${flex('row', 'space-between', 'center')};

      width: 100%;
      margin-top: ${theme.sizes.SIZE_40};

      .assign-complete,
      .clear-all-assign {
        margin-left: auto;
      }
    }
  }
`;
