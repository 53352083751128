import {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  ModalProps,
  ModalRef,
  ProgressBar,
  getColor,
  useCallbackRef,
  useEffectOnceWhen,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components';
import { Survey } from 'models';
import { UserContext } from 'store';
import { debounce } from 'lodash';
import { useCallbackAsync } from 'hooks';
import { convertKgToTonsIfNeeded, sliceStringLikeEllipsis } from 'utils';

import * as Styled from './PDFModal.styles';

type PDFModalProps = ModalProps & { survey: Survey };

const PDFModal: FC<PDFModalProps> = (props) => {
  const { survey } = props;

  const {
    userPreferences: { dateFormat, unit },
  } = useContext(UserContext);

  const { t } = useTranslation();

  const pagesRef = useRef<(HTMLDivElement | null)[]>([]);
  const observers = useRef<IntersectionObserver[]>([]);
  const lastScrollTop = useRef(0);
  const scrollDirection = useRef('downwards');
  const pageVisibilities = useRef<boolean[]>([]);
  const [modal, modalRef] = useCallbackRef<ModalRef>();

  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const [generatePDF] = useCallbackAsync({
    callback: async () => {
      const pdf = new jsPDF('portrait', 'pt', 'a4');

      for (let i = 0; i < pagesRef.current.length; ++i) {
        const canvas = await html2canvas(pagesRef.current[i]!, {
          scale: 2,
        });

        const imgData = canvas.toDataURL('image/jpeg', 1.0);

        const ratio = canvas.height / canvas.width;
        const width = pdf.internal.pageSize.getWidth();
        const height = width * ratio;

        pdf.addImage(imgData, 'JPEG', 0, 0, width, height);

        if (i !== pagesRef.current.length - 1) {
          pdf.addPage();
        }
      }

      pdf.save(`report_${dayjs().format('DD.MM.YYYY.')}.pdf`);
    },
    showSpinner: true,
    spinnerParent: '.wcl-modal',
  });

  const pdfHeader = useMemo(
    () => (
      <div className="kinto-pdf__page__header">
        <img
          src="/assets/svg/kinto_join.svg"
          className="kinto-pdf__page__header__logo"
          alt=""
        />
      </div>
    ),
    []
  );

  const determineScrollDirection = debounce(() => {
    if (!modal?.main) return;

    const currScroll = modal.main.scrollTop;

    scrollDirection.current =
      currScroll > lastScrollTop.current ? 'downwards' : 'upwards';

    if (
      scrollDirection.current === 'downwards' &&
      currentPage &&
      currentPage < pageVisibilities.current.length &&
      pageVisibilities.current[currentPage]
    ) {
      setCurrentPage(currentPage + 1);
    }

    if (
      scrollDirection.current === 'upwards' &&
      currentPage &&
      currentPage > 1 &&
      pageVisibilities.current[currentPage - 2]
    ) {
      setCurrentPage(currentPage - 1);
    }

    lastScrollTop.current = Math.max(0, currScroll);
  }, 50);

  // scroll listener to determine scroll direction
  useEffect(() => {
    if (!modal?.main) return;

    modal.main.addEventListener('scroll', determineScrollDirection);

    return () => {
      modal.main.removeEventListener('scroll', determineScrollDirection);
    };
  }, [modal, determineScrollDirection]);

  // set current page and page visibilities after dom rendered
  useEffectOnceWhen(() => {
    setTimeout(() => {
      setNumberOfPages(pagesRef.current.length);

      pageVisibilities.current = Array.from(
        { length: pagesRef.current.length },
        () => false
      );
    }, 0);
  });

  // observe intersections to determine current page
  useEffect(() => {
    const observersLocal = observers.current;

    setTimeout(() => {
      const options = {
        root: null,
        rootMargin: '0px',
      };

      for (let i = 0; i < pagesRef.current.length; ++i) {
        const observer = new IntersectionObserver((entries) => {
          const { isIntersecting } = entries[0];

          pageVisibilities.current[i] = isIntersecting;

          if (isIntersecting) {
            // the element has just appeared in the viewport
            setCurrentPage(i + 1);
          } else {
            // a change occurred, an element has been scrolled away from the viewport
            // depending on the scroll direction, set next visible page as current
            // 'any' is due to issues with tests... (jest)
            setCurrentPage(
              (pageVisibilities.current as any[])[
                scrollDirection.current === 'downwards'
                  ? ('findIndex' as any)
                  : ('findLastIndex' as any)
              ](Boolean) + 1
            );
          }
        }, options);

        observersLocal.push(observer);

        observer.observe(pagesRef.current[i]!);
      }
    }, 0);

    return () => {
      observersLocal.forEach((o) => o.disconnect());
    };
  }, []);

  return (
    <Styled.Container
      ref={modalRef}
      title={t('global-button_pdf_preview')}
      className="kinto-pdf"
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t(`global-button_pdf_preview`),
      })}
      footer={
        <div className="kinto-pdf__footer">
          <div className="kinto-pdf__footer__pagination">
            {t('sustainability-pdf_page', {
              page: currentPage,
              total: numberOfPages,
            })}
          </div>
          <Button
            icon={<Icon name="download" />}
            onClick={() => {
              generatePDF();
            }}
          >
            {t('sustainability-button_download_report')}
          </Button>
        </div>
      }
      {...props}
    >
      <div
        className="kinto-pdf__page"
        ref={(el) => {
          pagesRef.current[0] = el;
        }}
      >
        {pdfHeader}

        <div className="kinto-pdf__page__main">
          <h1>{sliceStringLikeEllipsis(survey.name, 40)}</h1>

          <p className="kinto-pdf__page__main__first-paragraph">
            {t('survey_pdf-body_description')}
          </p>

          <section
            className={classNames('kinto-pdf-card', 'community-details')}
          >
            <h2>{t('ga_group_details')}</h2>
            <div>{sliceStringLikeEllipsis(survey?.organisation_name, 20)}</div>
            <p>{survey?.formatted_address?.split(', ').join('\n')}</p>
          </section>

          <img
            src="/assets/svg/survey_car_charging.svg"
            className="kinto-pdf__page__main__illustration"
            alt=""
          />

          <div className="kinto-pdf-card-row">
            <section className={classNames('kinto-pdf-card', 'survey-dates')}>
              <h2>{t('survey_sustainability-title_survey_dates')}</h2>
              <ul>
                <li>
                  <span>{t('campaign-start_date')}</span>
                  <span>{dayjs(survey.start_date).format(dateFormat)}</span>
                </li>
                <li>
                  <span>{t('campaign-end_date')}</span>
                  <span>{dayjs(survey.end_date).format(dateFormat)}</span>
                </li>
              </ul>
            </section>

            <section className={classNames('kinto-pdf-card', 'audience')}>
              <h2>{t('global-audience')}</h2>
              <ul>
                <li>
                  <span>{`${t(
                    'sustainability-audience-total_participants'
                  )}`}</span>
                  <span>
                    {survey.participants_total + survey.newcomers_total}
                  </span>
                </li>
                <li>
                  <span>{`${t(
                    'sustainability-audience-community_completed_surveys'
                  )}`}</span>
                  <span>
                    {survey.participants_finished + survey.newcomers_finished}
                  </span>
                </li>
                <li>
                  <span>{`${t('sustainability-completed_web_surveys')}`}</span>
                  <span>{survey.public_inputs_total}</span>
                </li>
              </ul>
            </section>
          </div>

          <h2>{t('survey_sustainability-title_regulations')}</h2>

          <p className="kinto-pdf__page__main__second-paragraph">
            {t('survey_sustainability-body_regulations')}
          </p>
        </div>
      </div>

      <div
        className="kinto-pdf__page"
        ref={(el) => {
          pagesRef.current[1] = el;
        }}
      >
        {pdfHeader}

        <div className="kinto-pdf__page__main">
          <section className={classNames('kinto-pdf-card', 'co2-emissions')}>
            <div className="co2-emissions__stats">
              <div>
                <div className="co2-emissions__stats__value">
                  {convertKgToTonsIfNeeded(survey.co2).toFixed(2)}
                </div>
                <div className="co2-emissions__stats__unit">
                  {`${survey.co2 > 1000 ? 't' : 'kg'}`}CO2e
                </div>
              </div>

              <div>
                <ProgressBar
                  currentStep={survey?.surveyed_users_percentage || 0}
                  numberOfSteps={100}
                  caption={t('global-user_data')}
                  variant="rich"
                  color={getColor('--SECONDARY_4_1')}
                />

                <ProgressBar
                  currentStep={survey?.predicted_users_percentage || 0}
                  numberOfSteps={100}
                  caption={t('global-estimation')}
                  variant="rich"
                  color={getColor('--SECONDARY_6_1')}
                />
              </div>
            </div>

            <div className="co2-emissions__description">
              <h2>{t('global-CO2_emissions')}</h2>
              <p>{t('survey_sustainability-pdf-body_emissions')}</p>
              <p>{t('report-emissions_estimation')}</p>
            </div>
          </section>

          <div className="kinto-pdf-card-row">
            <section
              className={classNames('kinto-pdf-card', 'commuting-pattern')}
            >
              <h2>{t('global-commuting_pattern')}</h2>
              <ul>
                <li>
                  <span>
                    {t('sustainability-working_pattern_working_from_home')}
                  </span>
                  <span>{survey.remote_percent}%</span>
                </li>
                <li>
                  <span>{t('fuel_type-label_hybrid')}</span>
                  <span>{survey.hybrid_percent}%</span>
                </li>
                <li>
                  <span>
                    {t('sustainability-working_pattern_going_to_the_office')}
                  </span>
                  <span>{survey.on_site_percent}%</span>
                </li>
              </ul>
            </section>

            <section className={classNames('kinto-pdf-card', 'averages')}>
              <h2>{t('sustainability-report-average_travel_distance')}</h2>
              <div>
                {survey.average_distance.toFixed(2)} {unit}
              </div>
              <h2>{t('sustainability-report-average_days_on_site')}</h2>
              <div>
                {t('global-days_month_count', { days: survey.average_on_site })}
              </div>
            </section>
          </div>

          <section
            className={classNames('kinto-pdf-card', 'mode-of-transport')}
          >
            <h2>{t('mode_of_transport')}</h2>

            <ul>
              <li>
                <span>{t('dw_type')}</span>
                <div>
                  <span>
                    {t('units')} ({t(unit === 'km' ? 'km' : 'miles')})
                  </span>
                  <span>{t('people-bottom_navigation_tab')}</span>
                </div>
              </li>
              <li>
                <span>{t('mode_of_transport-driving_a_personal_vehicle')}</span>
                <div>
                  <span>{survey.driving?.distance}</span>
                  <span>{survey.driving?.users}</span>
                </div>
              </li>
              <li>
                <span>{t('mode_of_transport-carpool_as_a_passenger')}</span>
                <div>
                  <span>{survey.carpooling?.distance}</span>
                  <span>{survey.carpooling?.users}</span>
                </div>
              </li>
              <li>
                <span>{t('cp_map_walking')}</span>
                <div>
                  <span>{survey.walking?.distance}</span>
                  <span>{survey.walking?.users}</span>
                </div>
              </li>
              <li>
                <span>{t('cp_map_cycling')}</span>
                <div>
                  <span>{survey.cycling?.distance}</span>
                  <span>{survey.cycling?.users}</span>
                </div>
              </li>
              <li>
                <span>{t('mode_of_transport-corporate_shuttle')}</span>
                <div>
                  <span>{survey.corporate_shuttle?.distance}</span>
                  <span>{survey.corporate_shuttle?.users}</span>
                </div>
              </li>
              <li>
                <span>{t('mode_of_transport-bus')}</span>
                <div>
                  <span>{survey.bus?.distance}</span>
                  <span>{survey.bus?.users}</span>
                </div>
              </li>
              <li>
                <span>{t('mode_of_transport-train')}</span>
                <div>
                  <span>{survey.train?.distance}</span>
                  <span>{survey.train?.users}</span>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </Styled.Container>
  );
};

export default memo(PDFModal);
