import { FC, useCallback, useMemo } from 'react';
import { Button, getColor, useUtilities } from '@faxi/web-component-library';

import { Icon } from 'components';
import { useTranslation } from 'react-i18next';
import { apiPeople } from 'modules';
import { PeoplePageUser } from 'models';

import * as Styled from '../ActionModal/ActionModal.styles';

export enum CommunityActionsModalActions {
  APPROVE,
  REJECT,
  DEACTIVATE,
  REACTIVATE,
  APPROVE_ONE_USER,
  REJECT_ONE_USER,
  DEACTIVATE_ONE_USER,
  REACTIVATE_ONE_USER,
  NONE,
}

export type CommunityActionsModalProps = {
  action: CommunityActionsModalActions;
  className?: string;
  communityName: string;
  organisationId: number;
  userListIds: string[];
  user?: PeoplePageUser;
  parentRef?: any;
  someUsersAreAdmins?: boolean;
  onConfirm: (ids?: string[]) => void;
  onCancel: () => void;
};

const CommunityActionsModal: FC<CommunityActionsModalProps> = (
  props: CommunityActionsModalProps
): JSX.Element => {
  const {
    user,
    action,
    userListIds,
    communityName,
    organisationId,
    someUsersAreAdmins,
    onConfirm,
    onCancel,
  } = props;

  const { showSnackBar } = useUtilities();

  const { t } = useTranslation();

  const userName = useMemo(
    () => `${user?.first_name} ${user?.last_name}`.trim(),
    [user]
  );

  const titleAcceptUsers = useMemo(() => t('people-approve_users_modal'), [t]);

  const titleAcceptUser = useMemo(
    () =>
      t('access_community_approval', {
        numberOfPeople: undefined,
        people: userName,
        communityName: communityName,
      }),
    [communityName, t, userName]
  );

  const titleRejectUsers = useMemo(() => t('people-reject_users_modal'), [t]);

  const titleRejectUser = useMemo(
    () =>
      t('access_community_rejection', {
        numberOfPeople: undefined,
        people: userName,
        communityName: communityName,
      }),
    [communityName, t, userName]
  );

  const titleDeactivateUsers = useMemo(
    () => t('people-deactivate_users_modal'),
    [t]
  );

  const titleDeactivateUser = useMemo(
    () =>
      t('deactivate_user_title', {
        userName: userName,
        communityName: communityName,
      }),
    [communityName, t, userName]
  );

  const bodyDeactivateUser = useMemo(
    () =>
      t('deactivate_user_text', {
        userName: userName,
        communityName: communityName,
      }),
    [communityName, t, userName]
  );

  const titleReactivateUsers = useMemo(
    () => t('people-reactivate_users_modal'),
    [t]
  );

  const titleReactivateUser = useMemo(
    () =>
      t('reactivate_user_title', {
        userName: userName,
        communityName: communityName,
      }),
    [communityName, t, userName]
  );

  const deactivationSnackBarText = useCallback(
    (userName: string) => {
      return t('deactivate_user_message', {
        userName: userName,
        communityName: communityName,
      });
    },
    [communityName, t]
  );

  const renderTitle = useMemo<string>(() => {
    switch (action) {
      case CommunityActionsModalActions.APPROVE:
        return titleAcceptUsers;
      case CommunityActionsModalActions.REJECT:
        return titleRejectUsers;
      case CommunityActionsModalActions.DEACTIVATE:
        return titleDeactivateUsers;
      case CommunityActionsModalActions.REACTIVATE:
        return titleReactivateUsers;
      case CommunityActionsModalActions.APPROVE_ONE_USER:
        return titleAcceptUser;
      case CommunityActionsModalActions.REJECT_ONE_USER:
        return titleRejectUser;
      case CommunityActionsModalActions.DEACTIVATE_ONE_USER:
        return titleDeactivateUser;
      case CommunityActionsModalActions.REACTIVATE_ONE_USER:
        return titleReactivateUser;
      default:
        return '';
    }
  }, [
    action,
    titleAcceptUser,
    titleAcceptUsers,
    titleDeactivateUser,
    titleDeactivateUsers,
    titleReactivateUser,
    titleReactivateUsers,
    titleRejectUser,
    titleRejectUsers,
  ]);

  const renderIdPlaceholder = useMemo<string>(() => {
    switch (action) {
      case CommunityActionsModalActions.APPROVE:
        return 'approve_pending_users';
      case CommunityActionsModalActions.REJECT:
        return 'reject_pending_users';
      case CommunityActionsModalActions.DEACTIVATE:
        return 'deactivate_active_users';
      case CommunityActionsModalActions.REACTIVATE:
        return 'reactivate_deactivated_users';
      case CommunityActionsModalActions.APPROVE_ONE_USER:
        return 'approve_pending_user';
      case CommunityActionsModalActions.REJECT_ONE_USER:
        return 'reject_pending_user';
      case CommunityActionsModalActions.DEACTIVATE_ONE_USER:
        return 'deactivate_active_user';
      case CommunityActionsModalActions.REACTIVATE_ONE_USER:
        return 'reactivate_deactivated_user';
      default:
        return '';
    }
  }, [action]);

  const buttonType = useMemo<
    | 'primary'
    | 'ghost'
    | 'outline'
    | 'primary-invert'
    | 'delete-ghost'
    | 'delete-outline'
    | undefined
  >(() => {
    switch (action) {
      case CommunityActionsModalActions.APPROVE:
        return 'primary';
      case CommunityActionsModalActions.REJECT:
        return 'delete-outline';
      case CommunityActionsModalActions.DEACTIVATE:
        return 'delete-outline';
      case CommunityActionsModalActions.REACTIVATE:
        return 'primary';
      case CommunityActionsModalActions.APPROVE_ONE_USER:
        return 'primary';
      case CommunityActionsModalActions.REJECT_ONE_USER:
        return 'delete-outline';
      case CommunityActionsModalActions.DEACTIVATE_ONE_USER:
        return 'delete-outline';
      case CommunityActionsModalActions.REACTIVATE_ONE_USER:
        return 'primary';
      default:
        return;
    }
  }, [action]);

  const userAction = useCallback(
    async (
      action: (
        oId: number,
        userListIds: string[]
      ) => Promise<{
        rc: 'ok' | 'error';
      }>,
      manyUsers: boolean,
      msgText: string
    ) => {
      try {
        let response: any = {};
        if (manyUsers) {
          response = await action(organisationId, userListIds);
        } else if (user) {
          response = await action(organisationId, [user.id.toString()]);
        }
        if (response?.rc === 'ok') {
          if (manyUsers) {
            onConfirm(userListIds);
          } else if (user) {
            onConfirm([user.id.toString()]);
          }

          if (response?.successful.length > 0) {
            showSnackBar({
              actionButtonText: t('dismiss'),
              text: msgText,
              variant: 'success',
            });
          } else {
            showSnackBar({
              actionButtonText: t('dismiss'),
              text: t(
                'admin-notification_a_community_mast_have_at_least_one_admin'
              ),
              variant: 'error',
            });
          }
        } else {
          showSnackBar({
            actionButtonText: t('dismiss'),
            text: `${t('rm_alert_title_travel_mode')} ${t(
              'something_went_wrong'
            )}`,
            variant: 'error',
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [onConfirm, organisationId, showSnackBar, t, user, userListIds]
  );

  const actionFunction = useCallback(
    () => () => {
      switch (action) {
        case CommunityActionsModalActions.APPROVE:
          return userAction(
            () => apiPeople.approveSelectedPeople(organisationId, userListIds),
            true,
            t('approved_to_join_community_message', {
              Community: communityName,
            })
          );
        case CommunityActionsModalActions.REJECT:
          return userAction(
            () => apiPeople.rejectSelectedPeople(organisationId, userListIds),
            true,
            t('reject_to_join_community_message', {
              Community: communityName,
            })
          );
        case CommunityActionsModalActions.DEACTIVATE:
          return userAction(
            () =>
              apiPeople.deactivateSelectedPeople(organisationId, userListIds),
            true,
            t('people-deactivated_users', {
              Community: communityName,
            })
          );
        case CommunityActionsModalActions.REACTIVATE:
          return userAction(
            () =>
              apiPeople.reactivateSelectedPeople(organisationId, userListIds),
            true,
            t('approved_to_rejoin_community_message', {
              Community: communityName,
            })
          );
        case CommunityActionsModalActions.APPROVE_ONE_USER:
          if (user) {
            return userAction(
              () =>
                apiPeople.approveSelectedPeople(organisationId, [
                  user.id.toString(),
                ]),
              false,
              t('has_joined_community', {
                personName: userName,
                communityName: communityName,
              })
            );
          }
          break;
        case CommunityActionsModalActions.REJECT_ONE_USER:
          if (user) {
            return userAction(
              () =>
                apiPeople.rejectSelectedPeople(organisationId, [
                  user.id.toString(),
                ]),
              false,
              t('has_been_rejected_from_community', {
                personName: userName,
                communityName: communityName,
              })
            );
          }
          break;
        case CommunityActionsModalActions.DEACTIVATE_ONE_USER:
          if (user) {
            return userAction(
              () =>
                apiPeople.deactivateSelectedPeople(organisationId, [
                  user.id.toString(),
                ]),
              false,
              deactivationSnackBarText(userName)
            );
          }
          break;
        case CommunityActionsModalActions.REACTIVATE_ONE_USER:
          if (user) {
            return userAction(
              () =>
                apiPeople.reactivateSelectedPeople(organisationId, [
                  user.id.toString(),
                ]),
              false,
              t('has_rejoined_community', {
                personName: userName,
                communityName: communityName,
              })
            );
          }
          break;
        default:
          return null;
      }
    },
    [
      action,
      communityName,
      deactivationSnackBarText,
      organisationId,
      t,
      user,
      userAction,
      userListIds,
      userName,
    ]
  );

  const actionButtonLabel = useMemo<string>(() => {
    switch (action) {
      case CommunityActionsModalActions.APPROVE:
        return t('approve') + ' ' + t('selected');
      case CommunityActionsModalActions.REJECT:
        return t('reject') + ' ' + t('selected');
      case CommunityActionsModalActions.DEACTIVATE:
        return t('deactivate') + ' ' + t('selected');
      case CommunityActionsModalActions.REACTIVATE:
        return t('global-button_reactivate') + ' ' + t('selected');
      case CommunityActionsModalActions.APPROVE_ONE_USER:
        return t('approve');
      case CommunityActionsModalActions.REJECT_ONE_USER:
        return t('reject');
      case CommunityActionsModalActions.DEACTIVATE_ONE_USER:
        return t('deactivate');
      case CommunityActionsModalActions.REACTIVATE_ONE_USER:
        return t('global-button_reactivate');
      default:
        return '';
    }
  }, [action, t]);

  return (
    <Styled.ActionModal
      open
      onClose={onCancel}
      className="action-modal"
      title={renderTitle}
    >
      {someUsersAreAdmins &&
        action === CommunityActionsModalActions.DEACTIVATE && (
          <div className="action-modal__note">
            <Icon
              name="exclamation-triangle"
              color={getColor('--PRIMARY_1_1')}
              className="action-modal__note__icon"
            />
            <span>{t('people-deactivate_alert_some_of_the_people')}</span>
          </div>
        )}

      {action === CommunityActionsModalActions.DEACTIVATE_ONE_USER && (
        <div className="action-modal__text">
          <Icon
            name="exclamation-triangle"
            color={getColor('--LABEL_TEXT_1_1')}
            className="action-modal__text__icon"
          />
          <span>{bodyDeactivateUser}</span>
        </div>
      )}

      <div className="action-modal__actions">
        <Button
          id={`cancel_${renderIdPlaceholder}`}
          className="action-modal__actions__cancel-btn"
          variant="ghost"
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>

        <Button
          id={`confirm_${renderIdPlaceholder}`}
          className="action-modal__actions__confirm-btn"
          variant={buttonType}
          onClick={actionFunction()}
        >
          {actionButtonLabel}
        </Button>
      </div>
    </Styled.ActionModal>
  );
};

export default CommunityActionsModal;
