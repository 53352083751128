import { useCallback, useContext, useState } from 'react';
import {
  Button,
  Icon,
  SortOption,
  SORT_OPTIONS,
} from '@faxi/web-component-library';
import { EarnedReward } from 'models';
import { useTranslation } from 'react-i18next';
import EarnedRewardsReportModal from './components/EarnedRewardsReportModal';
import EarnedRewardsReportTable from './components/EarnedRewardsReportTable';
import useRewards from './useRewards';
import { UserContext } from '../../../store';

import * as Styled from './EarnedRewardsReport.style';

const SHOWN_NUMBER_OF_REWARDS_ON_DASHBOARD = 5;

const INITIAL_REWARDS_SORT = {
  sortBy: 'reward',
  sortDirection: SORT_OPTIONS.ASC,
} as SortOption<EarnedReward>;

const EarnedRewards = () => {
  const { t } = useTranslation();

  const { communityId } = useContext(UserContext);

  const [viewAllRewardsModal, setViewAllRewardsModal] = useState(false);

  const {
    data,
    loading,
    totalPages,
    activeColumnSort,
    totalCount,
    setActiveColumnSort,
  } = useRewards({
    initialSortBy: INITIAL_REWARDS_SORT.sortBy,
    initialSortDirection: INITIAL_REWARDS_SORT.sortDirection,
    spinnerSelector: '.earned-rewards-today__table',
    deps: [communityId],
  });

  const handleOnColumnSort = useCallback(
    (sort: SortOption<EarnedReward>) => {
      const { sortBy, sortDirection } = sort;
      setActiveColumnSort(sortBy, sortDirection);
    },
    [setActiveColumnSort]
  );

  return (
    <Styled.Container className={'earned-rewards-today'}>
      <div className="earned-rewards-today__title">
        {t('dashboard-title_earned_rewards_today')}
      </div>

      <div className="earned-rewards-today__description">
        {t('dashboard-subtitle_earned_rewards_today')}
      </div>

      <EarnedRewardsReportTable
        data={data.slice(0, SHOWN_NUMBER_OF_REWARDS_ON_DASHBOARD)}
        loading={loading}
        initialSort={activeColumnSort}
        onColumnSortClicked={handleOnColumnSort}
      />

      {totalCount > SHOWN_NUMBER_OF_REWARDS_ON_DASHBOARD && (
        <Button
          iconPosition="right"
          icon={<Icon name="chevron-right" />}
          variant="ghost"
          onClick={() => {
            setViewAllRewardsModal(true);
          }}
        >
          {t('view_whole_list')}
        </Button>
      )}

      {viewAllRewardsModal && (
        <EarnedRewardsReportModal
          initialData={data}
          initTotalPages={totalPages}
          initTotalCount={totalCount}
          initialSort={activeColumnSort}
          onClose={() => {
            setViewAllRewardsModal(false);
          }}
        />
      )}
    </Styled.Container>
  );
};

export default EarnedRewards;
