import { createContext, MutableRefObject } from 'react';
import { Method } from 'axios';
import { Community } from 'models';

type CommunitySettingsContext = {
  loadingCommunity?: boolean;
  community?: Community;
  shouldUpdateCommunity: MutableRefObject<boolean>;
  updateCommunityDescription: (
    oid: number,
    gDesc: string,
    method: Method
  ) => Promise<any>;
  updateSettingsCommunity: React.Dispatch<
    React.SetStateAction<Community | undefined>
  >;
};

const CommunitySettingsContext = createContext<CommunitySettingsContext>({
  loadingCommunity: false,
  community: undefined,
  shouldUpdateCommunity: { current: false },
  updateCommunityDescription: () => new Promise(() => {}),
  updateSettingsCommunity: () => {},
});

export default CommunitySettingsContext;
