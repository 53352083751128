import { FC, Fragment } from 'react';
import Router from './router/Router';

import { GlobalStyle } from 'globalStyles';
import { GlobalStyle as WCLGlobalStyle } from '@faxi/web-component-library';

export const GOOGLE_MAPS_PLACES_SCRIPT_ID = 'google_maps_places_script';

const App: FC = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <WCLGlobalStyle />
      <Router />
    </Fragment>
  );
};

export default App;
