import { Image, Switch, useLatestRef } from '@faxi/web-component-library';
import classNames from 'classnames';
import { Community } from 'models';
import { ChangeEvent, FC, memo, PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './SwitchCommunityEmail.styles';

const SwitchMemoized = memo(Switch);

type SwitchType = 'Y' | 'N';

type SwitchCommunityEmailProps = PropsWithChildren<{
  className?: string;
  community: Community;
  onJoinRequest: (community: Community, checked: SwitchType) => void;
}>;

const SwitchCommunityEmail: FC<SwitchCommunityEmailProps> = (props) => {
  const { className, community, onJoinRequest } = props;

  const { t } = useTranslation();

  const onJoinRequestRef = useLatestRef(onJoinRequest);

  const handleOnJoinRequest = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const checked = target.checked ? 'Y' : 'N';
      onJoinRequestRef.current(community, checked);
    },
    [community, onJoinRequestRef]
  );

  return (
    <Styled.SwitchCommunityEmail
      tabIndex={0}
      data-id={community.id}
      aria-label={community.name}
      className={classNames(
        'kinto-switch-community-email',
        {
          'kinto-switch-community-email--disabled':
            community.rightprofile !== 'A',
        },
        className
      )}
    >
      <div className="kinto-switch-community-email__community-details">
        <Image
          className="kinto-switch-community-email__image"
          alt=""
          src={community.image_url || ''}
          fallbackUrl="/assets/svg/users-icon.svg"
        />
        <p className="community-title">{community.name}</p>

        {community.rightprofile !== 'A' && (
          <div className="rights-label">
            {t('notifications-label_admin_required')}
          </div>
        )}
      </div>

      <div className="kinto-switch-community-email__email-switch">
        <div className="kinto-switch-community-email__action">
          <p id="join-request" className="action-title">
            {t('notification_settings-request_to_join_community_notification')}
          </p>
          <SwitchMemoized
            aria-labelledby="join-request"
            aria-label={t(
              'notification_settings-request_to_join_community_notification'
            )}
            value={community.join_request === 'Y'}
            onChange={handleOnJoinRequest}
          />
        </div>
      </div>
    </Styled.SwitchCommunityEmail>
  );
};

export default memo(SwitchCommunityEmail);
