import httpClient from '../httpClient';

const getAllPlaces = (communityId: number) =>
  httpClient.get(`organisations/${communityId}/place/all`);

const createPlace = (communityId: number, data?: FormData) =>
  httpClient
    .post(`organisations/${communityId}/place`, data)
    .then((res) => res.data);

const deletePlace = (communityId: number, placeId: number) =>
  httpClient.post(
    `organisations/${communityId}/place/${placeId}`,
    {},
    { params: { _method: 'DELETE' } }
  );

const updatePlace = (communityId: number, placeId: number, data?: FormData) =>
  httpClient.post(`organisations/${communityId}/place/${placeId}`, data, {
    params: { _method: 'PUT' },
  });

const checkPlaceName = (communityId: number, name: string) =>
  httpClient.get(`organisations/${communityId}/places/check-name`, {
    params: { name },
  });

export default {
  createPlace,
  getAllPlaces,
  deletePlace,
  updatePlace,
  checkPlaceName,
};
