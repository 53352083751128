import { RewardType } from 'models';

export const REWARD_SUGGESTIONS = [
  {
    name: 'global-free_parking',
  },
  {
    name: 'global-coffee',
  },
] as RewardType[];

export const TRANSLATION_KEYS = {
  SUCCESSFULLY_CREATED:
    'gamification-rewards_notification_reward_successfully_created',
  SUCCESSFULLY_UPDATED:
    'gamification-rewards_dialog_reward_successfully_updated',
  DELETE_DIALOG: 'gamification-rewards_dialog_delete_reward',
  SUCCESSFULLY_DELETED:
    'gamification-rewards_dialog_reward_successfully_deleted',
} as const;
