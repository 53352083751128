import styled from 'styled-components';
import { theme } from '@faxi/web-component-library';

export const OnboardingStepsSidebar = styled.div`
  background-color: var(--SECONDARY_1_1);
  border-radius: ${theme.sizes.SIZE_8};
  width: 100%;

  transition: width 300ms;

  color: var(--BACKGROUND_1_1);

  padding: 8px;

  height: fit-content;

  > * {
    white-space: nowrap;
  }

  button {
    white-space: nowrap;

    opacity: 1;
  }

  &.collapsed {
    padding: 0;
    background-color: transparent;
    width: 55px;

    ul {
      display: none;
    }

    button {
      display: none;
    }

    .progress-dounat {
      transform: scale(0.75);
    }
  }

  ul {
    list-style-type: none;

    transition: width, opacity 300ms;

    margin: 0;
    padding: 0;
    margin: ${theme.sizes.SIZE_16} 0;

    li {
      display: flex;
      margin: ${theme.sizes.SIZE_8} 0;

      svg {
        margin-right: ${theme.sizes.SIZE_4};
      }
    }
  }

  &.onboarding-steps-sidebar {
    &__complete-btn,
    button {
      width: 100%;
    }
  }
`;
