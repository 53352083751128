import { FC, useContext, useMemo } from 'react';
import { Chart } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { ReportsContext, UserContext } from 'store';
import PlaceholderChart from '../PlaceholderChart';
import { useChartDate } from '../hooks';
import { ChartData } from 'models';

const WalkingChart: FC = (): JSX.Element => {
  const { kpiData, distancesReportError } = useContext(ReportsContext);

  const {
    includeTestUsers,
    userPreferences: { unit },
  } = useContext(UserContext);

  const { t } = useTranslation();

  const walkingChartData = useMemo(() => {
    if (
      !kpiData ||
      (includeTestUsers && !kpiData.walkingDistances.testChartData)
    ) {
      return [];
    }
    return [
      {
        name: `${t('cp_map_walking')} (${unit})`,
        data: kpiData.walkingDistances.chartData,
      },
      ...(includeTestUsers
        ? [
            {
              name: `${t('cp_map_walking')} ${t(
                'reports_export_test_users'
              )} (${unit})`,
              data: kpiData.walkingDistances.testChartData as ChartData,
            },
          ]
        : []),
    ];
  }, [kpiData, includeTestUsers, t, unit]);

  const dateObject = useChartDate(walkingChartData);

  return distancesReportError ? (
    <PlaceholderChart />
  ) : (
    <Chart
      id="walking-chart"
      series={walkingChartData}
      yAxisLabel={t('cp_map_walking')}
      ariaLabel={t('accessibility_chart_label', {
        name: t('cp_map_walking'),
        endDate: dateObject?.end,
        startDate: dateObject?.start,
      })}
    />
  );
};

export default WalkingChart;
