import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { RewardType } from 'models';
import { apiGamification } from 'modules';

import UserContext from '../UserProvider/User.context';
import CampaignContext from './Campaign.context';

import { useCallbackAsync } from 'hooks';

const CampaignProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const { organisationId } = useParams() as {
    organisationId: string;
  };

  const { communityId } = useContext(UserContext);

  const [rewards, setRewards] = useState<RewardType[]>();
  const [enablePoints, setEnablePoints] = useState<boolean>();

  const [getRewards] = useCallbackAsync({
    spinnerParent: '#rewards_fieldset',
    showSpinner: true,
    deps: [communityId],
    callback: async () => {
      const {
        data: { rewards },
      } = await apiGamification.getRewardsPaginated(organisationId, {});

      setRewards(rewards);
    },
  });

  const [loadPointsDepot] = useCallbackAsync({
    showSpinner: false,
    callback: async () => {
      const {
        data: {
          data: { depots },
        },
      } = await apiGamification.getOrganisationDepots(+organisationId, 'point');

      const depot = depots[depots.length - 1];

      setEnablePoints(depot?.state === 'active');
    },
    deps: [communityId],
  });

  useEffect(() => {
    getRewards();
  }, [getRewards]);

  useEffect(() => {
    loadPointsDepot();
  }, [loadPointsDepot, communityId]);

  return (
    <CampaignContext.Provider
      value={{
        rewards,
        enablePoints,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignProvider;
