import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Breadcrumbs,
  DatePicker,
  Input,
  SelectOption,
  SortOption,
  SORT_OPTIONS,
  Table,
  config,
  useResize,
  Button,
  Icon,
  useDatePickerParams,
  useUtilities,
  IName,
  Tooltip,
  getColor,
} from '@faxi/web-component-library';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { responsiveSm } from '@faxi/web-css-utilities';
import dayjs from 'dayjs';

import { InfoCard } from 'components';
import { UserContext } from 'store';
import { apiGamification } from 'modules';
import { useHeadTitle, useTablePagination } from 'hooks';
import { EarnedReward, RewardTypes, REWARD_TYPES } from 'models';
import { delayWithExecution } from 'utils';
import { reportsConfig } from 'config/reportsConfig';
import { ViewsPerPage } from 'pages/People/People.page';
import DeactivateRewardModal from './components/DeactivateRewardModal';

import * as Styles from './EarnedRewards.styles';
import { NoData } from 'Global.styles';

const INITIAL_REWARDS_SORT = {
  sortBy: 'date',
  sortDirection: SORT_OPTIONS.DSC,
} as SortOption<EarnedReward>;

const REWARD_TYPES_TRANSLATION = {
  [REWARD_TYPES.PARKING_REWARD]: 'rewards-title_parking_reward',
  [REWARD_TYPES.MESSAGE_REWARD]: 'rewards-title_regular_reward',
} as Record<RewardTypes, string>;

const EarnedRewards: FC = () => {
  const { t } = useTranslation();

  const { organisationId } = useParams() as {
    organisationId: string;
  };

  const { userPreferences, communityId } = useContext(UserContext);

  useHeadTitle(
    `${t('group_settings')} - ${t('global-gamification')} - ${t(
      'global-earned_rewards'
    )}`
  );

  const [openDeactivateRewardModal, setOpenDeactivateRewardModal] =
    useState<EarnedReward>();

  const { prompts, showSnackBar, showOverlay, hideOverlay } = useUtilities();
  const belowPhablet = useResize(responsiveSm);

  const { dateRange, setDateRange, applyDefaultParams } = useDatePickerParams(
    reportsConfig.range_start
  );

  const breadcrumbsLinks = useMemo(
    () => [
      {
        id: 'gamification-link',
        text: t('global-gamification'),
        href: `/community/${organisationId}/admin/gamification`,
      },
      {
        id: 'earned-rewards-link',
        text: t('global-earned_rewards'),
        href: `/community/${organisationId}/admin/gamification/earned-rewards`,
      },
    ],
    [t, organisationId]
  );

  const calendarLabels = useMemo(
    () => ({
      arrowLeftDay: t('accessibility-arrow_left_day'),
      arrowRightDay: t('accessibility-arrow_right_day'),
      arrowLeftMonth: t('accessibility-arrow_left_month'),
      arrowRightMonth: t('accessibility-arrow_right_month'),
      arrowLeftYear: t('accessibility-arrow_left_year'),
      arrowRightYear: t('accessibility-arrow_right_year'),
      closeButton: t('accessibility-calendar_close-button'),
    }),
    [t]
  );

  const translationKeys = useMemo(
    () =>
      ({
        date: t('parkNRide_date'),
        reward_id: `${t('reward_download_page')} ID`,
        reward: t('global-reward_name'),
        type: t('rewards-field_placeholder_reward_type'),
        instance_name: t('reward_download_page'),
        user: t('global-user'),
        user_id: `${t('global-user')} ID`,
        campaign: t('global-campaign'),
        campaign_id: `${t('global-campaign')} ID`,
        vrn: t('global_plate_number'),
      } as Record<Partial<keyof EarnedReward>, string>),
    [t]
  );

  const {
    data,
    count,
    search,
    loading,
    totalPages,
    activeColumnSort,
    totalCount,
    currentPage,
    setCount,
    onSearchChange,
    setCurrentPage,
    setActiveColumnSort,
    revalidate,
  } = useTablePagination<EarnedReward, 'rewards'>({
    itemsKey: 'rewards',
    deps: [dateRange],
    resetDeps: [communityId, dateRange],
    condition: !!dateRange,
    initialSortBy: INITIAL_REWARDS_SORT.sortBy,
    initialSortDirection: INITIAL_REWARDS_SORT.sortDirection,
    customErrorMessage: t(`no_members_in_community`),
    applyQueryParams: false,
    mappingFunction: async (values) =>
      values.map(
        ({
          id,
          date,
          reward_id,
          reward,
          type,
          vrn,
          instance_name,
          user,
          user_id,
          campaign,
          campaign_id,
          depot_instance_reward_id,
          hidden,
          cancel_why,
          canceled_at,
        }) =>
          ({
            id,
            date: dayjs(date).format('DD/MM/YYYY HH:mm'),
            reward_id,
            reward: `${reward}`.trim() || '-',
            type: t(REWARD_TYPES_TRANSLATION[type]),
            instance_name: instance_name || '-',
            user: `${user}`.trim() || '-',
            user_id,
            vrn: vrn || '-',
            campaign: `${campaign}`.trim() || '-',
            campaign_id,
            depot_instance_reward_id,
            hidden,
            cancel_why,
            canceled_at,
          } as EarnedReward)
      ),
    apiRequest: (
      count: number,
      offset: number,
      search: string,
      sort_by,
      sort_direction,
      configCancel
    ) => {
      return apiGamification.getEarnedRewards({
        oid: organisationId,
        count,
        offset,
        search,
        sort_by,
        sort_direction,
        ...(dateRange && {
          start_date: dateRange.from.format(config.apiDateFormat),
          end_date: dateRange.to.format(config.apiDateFormat),
        }),
        config: configCancel,
      });
    },
  });

  const handleHideReward = useCallback(
    async (reward: EarnedReward) => {
      if (
        await prompts.standard({
          btnIcon: 'eye-slash' as IName,
          className: 'hide-reward-prompt',
          content: (
            <div>
              <h1>
                {parse(
                  t('earned_reward-dialog_hide_reward', {
                    rewardname: `<b>${reward.reward}</b>`,
                  })
                )}
              </h1>
              <InfoCard
                orientation="vertical"
                text={t('earned_rewards-modal_alert')}
              />
            </div>
          ),
          submitBtnVariant: 'ghost',
          iconPosition: 'right',
          cancelBtnText: t('cancel'),
          submitBtnText: t('global-hide'),
        })
      ) {
        try {
          showOverlay('.main-template');
          const { data } = await apiGamification.hideEarnedReward(
            reward.depot_instance_reward_id!,
            organisationId
          );

          if (data.status === 'Success') {
            showSnackBar({
              actionButtonText: t('dismiss'),
              text: parse(
                t('earned_reward-toast_successfully_hidden', {
                  rewardname: `<b>${reward.reward}</b>`,
                })
              ) as string,
              variant: 'success',
            });

            // We set timeout here because on backend side
            // there is some asynchronous behaviour with updating reward state followed by fetching rewards
            await delayWithExecution(() => revalidate(), 1000);
          }
        } catch (e) {
          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t('something_went_wrong'),
            variant: 'error',
          });
        } finally {
          hideOverlay('.main-template');
        }
      }
    },
    [
      hideOverlay,
      organisationId,
      prompts,
      revalidate,
      showOverlay,
      showSnackBar,
      t,
    ]
  );

  const earnedRewardsTableActions = useMemo(
    () => (reward: EarnedReward) =>
      (
        <Fragment>
          {reward.canceled_at ? (
            <div
              className="kinto-earned-rewards__reward-is-hidden"
              id={`deactivate_reward_label_${reward.depot_instance_reward_id}`}
            >
              {t('earned_rewards-label_reward_is_deactivated')}
              <Tooltip content={reward.cancel_why} placement="top">
                <div tabIndex={0} role="tooltip" aria-label={reward.cancel_why}>
                  <Icon name="circle-info" color={getColor('--PRIMARY_1_1')} />
                </div>
              </Tooltip>
            </div>
          ) : (
            <Button
              variant="delete-ghost"
              icon={<Icon name="ban" />}
              iconPosition="left"
              id={`deactivate_reward_${reward.depot_instance_reward_id}`}
              className="kinto-earned-rewards__reward-action"
              onClick={async (e) => {
                e.stopPropagation();
                setOpenDeactivateRewardModal(reward);
              }}
            >
              {t('deactivate')}
            </Button>
          )}
          {reward.hidden ? (
            <div
              className="kinto-earned-rewards__reward-is-hidden"
              id={`hide_reward_label_${reward.depot_instance_reward_id}`}
            >
              {t('earned_reward-label_reward_hidden')}
            </div>
          ) : (
            <Button
              variant="ghost"
              icon={<Icon name="eye-slash" />}
              iconPosition="left"
              id={`hide_reward_${reward.depot_instance_reward_id}`}
              className="kinto-earned-rewards__reward-action"
              onClick={async (e) => {
                e.stopPropagation();
                handleHideReward(reward);
              }}
            >
              {t('global-hide')}
            </Button>
          )}
        </Fragment>
      ),
    [handleHideReward, t]
  );

  const handleOnColumnSort = useCallback(
    (sort: SortOption<EarnedReward>) => {
      const { sortBy, sortDirection } = sort;
      setActiveColumnSort(sortBy, sortDirection);
    },
    [setActiveColumnSort]
  );

  const handleOnLimitChange = useCallback(
    (data: SelectOption) => {
      setCount(+data.value as ViewsPerPage);
    },
    [setCount]
  );

  useEffect(() => {
    if (!communityId) return;

    applyDefaultParams();
  }, [communityId, applyDefaultParams]);

  return (
    <Styles.EarnedRewards className="kinto-earned-rewards">
      <Breadcrumbs
        tabIndex={0}
        aria-label="breadcrumbs"
        crumbs={breadcrumbsLinks}
      />

      <div className="kinto-earned-rewards__actions">
        <Input
          placeholder="Search..."
          value={search}
          onChange={onSearchChange}
          {...(search && {
            suffixIcon: (
              <Button
                variant="ghost"
                icon={<Icon name="xmark" />}
                aria-label={t('delete_input')}
                onClick={() => onSearchChange('')}
              />
            ),
          })}
        />

        <DatePicker
          value={dateRange}
          calendarAriaLabels={calendarLabels}
          startingRangeDate={reportsConfig.range_start}
          dateFormat={userPreferences.dateFormat}
          openPosition={`bottom-${belowPhablet ? 'right' : 'left'}`}
          formButtonsLabels={{
            submitLabel: t('apply'),
            cancelLabel: t('cancel'),
          }}
          buttonsLabels={{
            customDate: t('custom_date'),
            last3Days: t('last_value_days', { value: 3 }),
            last7Days: t('last_value_days', { value: 7 }),
            last30Days: t('last_value_days', { value: 30 }),
          }}
          onChange={setDateRange}
        />
      </div>

      <Table<EarnedReward>
        tableId="earned-rewards-table"
        className="kinto-earned-rewards__table"
        tableData={data}
        tableActions={earnedRewardsTableActions}
        expandable
        hasCheckboxes={false}
        translationKeys={translationKeys}
        initialSort={activeColumnSort as SortOption<EarnedReward>}
        loadingData={loading}
        excludeColumns={[
          'id',
          'depot_instance_reward_id',
          'hidden',
          'cancel_why',
          'canceled_at',
        ]}
        rowActionLabel={t('view_details')}
        perPagePlaceholder={t('per_page')}
        chevronBtnAriaLabel={t('per_page')}
        goToPageInputProps={{ placeholder: t('global-go_to_page') }}
        pageSelectorAriaRightLabel={t('accessibility-button_next_page')}
        pageSelectorAriaLeftLabel={t('accessibility-button_previous_page')}
        sortIconAriaLabel={(property, orientation) =>
          t(
            orientation === 'desc'
              ? 'accessibility-button_sort_ascending'
              : 'accessibility-button_sort_descending',
            { property: property.toLowerCase() }
          )
        }
        noDataPlaceholder={
          !loading && (
            <NoData className="kinto-no-data">
              {t('gamification-empty_state_no_earned_rewards_found')}
            </NoData>
          )
        }
        paginationData={{
          limit: count,
          totalPages,
          totalCount,
          currentPage,
        }}
        onPageChange={setCurrentPage}
        onLimitChange={handleOnLimitChange}
        onColumnSortClicked={handleOnColumnSort}
      />

      {openDeactivateRewardModal && (
        <DeactivateRewardModal
          reward={openDeactivateRewardModal}
          onClose={() => setOpenDeactivateRewardModal(undefined)}
          onDeactivate={revalidate}
        />
      )}
    </Styles.EarnedRewards>
  );
};

export default EarnedRewards;
