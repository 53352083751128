import { Modal, theme } from '@faxi/web-component-library';
import { flexGap, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const LeaderBoardModal = styled(Modal)`
  .wcl-modal {
    &__main {
      ${flexGap(theme.sizes.SIZE_40, 'column')};
    }

    &__footer > div {
      margin: 0;
    }
  }

  fieldset {
    max-width: ${theme.sizes.SIZE_304};
  }

  .calendar-labels {
    ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};

    font-weight: 400;
    margin: 0 0 ${theme.sizes.SIZE_20};
    color: var(--SECONDARY_1_1);
  }
`;
