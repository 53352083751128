import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import SurveyContext from '../../providers/Survey/Survey.context';
import SurveyPageWrapper from '../../components/SurveyPageWrapper';

const SurveyNotStarted: FC = () => {
  const { t } = useTranslation();

  const { activeSurvey } = useContext(SurveyContext);

  return (
    <SurveyPageWrapper
      title={activeSurvey?.name || ''}
      subtitle={t('sustainability-web_survey-not_started').replace(
        /\\n/g,
        '\n'
      )}
      imageUrl="/assets/svg/survey_woman_autumn.svg"
      paragraph={t('sustainability-web_survey-not_started-subtext').replace(
        /\\n/g,
        '\n'
      )}
    />
  );
};

export default SurveyNotStarted;
