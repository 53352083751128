import { FC, useContext, useMemo } from 'react';
import { Chart } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { ReportsContext, UserContext } from 'store';
import { useChartDate } from '../hooks';
import { ChartData } from 'models';

const Co2Chart: FC = (): JSX.Element => {
  const { includeTestUsers } = useContext(UserContext);
  const { kpiData } = useContext(ReportsContext);

  const { t } = useTranslation();

  const co2ChartData = useMemo(() => {
    if (!kpiData || (includeTestUsers && !kpiData.co2.testChartData)) {
      return [];
    }

    return [
      {
        name: t('co2'),
        data: kpiData.co2.chartData,
      },
      ...(includeTestUsers
        ? [
            {
              name: `${t('co2')} ${t('reports_export_test_users')}`,
              data: kpiData.co2.testChartData as ChartData,
            },
          ]
        : []),
    ];
  }, [kpiData, includeTestUsers, t]);

  const dateObject = useChartDate(co2ChartData);

  return (
    <Chart
      id="co2-chart"
      series={co2ChartData}
      yAxisLabel={t('co2')}
      ariaLabel={t('accessibility_chart_label', {
        name: t('co2'),
        endDate: dateObject?.end,
        startDate: dateObject?.start,
      })}
    />
  );
};

export default Co2Chart;
