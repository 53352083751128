import { ObjectValues } from './Generics';

export const SCHEDULE_TIME = {
  MONDAY_TO_FRIDAY: 'monday_to_friday',
  MONDAY_TO_SUNDAY: 'monday_to_sunday',
  CUSTOM: 'custom',
} as const;

export type ScheduleTime = ObjectValues<typeof SCHEDULE_TIME>;

export type Shift = {
  dates: string[];
  pattern: ScheduleTime;
  created_at: string;
  duration: string;
  end_time: string;
  id: number;
  name: string;
  start_time: string;
  updated_at: string;
  shift_id: number;
  user_shift_id?: number;
};

export type UserShift = Shift & {
  dates: string[];
};

export type ShiftType = {
  type: string;
  icon: string;
};
