import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { isCancel } from 'axios';

export default function useAbortController() {
  const controller: MutableRefObject<AbortController | null> = useRef(null);

  const initAbortSignal = useCallback((): AbortSignal => {
    controller.current = new AbortController();
    return controller.current.signal;
  }, []);

  const cancelPreviousRequest = useCallback((callback?: () => void) => {
    if (controller.current) {
      controller.current?.abort();
      callback?.();
    }
  }, []);

  useEffect(() => {
    return () => {
      cancelPreviousRequest();
    };
  }, [cancelPreviousRequest]);

  return {
    controller,
    abortSignal: initAbortSignal,
    cancelPreviousRequest,
    isCancel,
  };
}
