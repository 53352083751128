export const REPORT_DATE_RANGE = {
  LAST_MONTH: 'reports_date_range_1_month',
  LAST_7_DAYS: 'reports_date_range_7_days',
  LAST_3_DAYS: 'reports_date_range_3_days',
  CUSTOM_DATE: 'report_date_range_custom',
} as const;

export type ReportDateRange =
  typeof REPORT_DATE_RANGE[keyof typeof REPORT_DATE_RANGE];

export const DATE_RANGE_VALUES = {
  LAST_7_DAYS: 6,
  LAST_3_DAYS: 2,
  CUSTOM_DATE: null,
} as const;

export const validateDateString = (
  day: string,
  month: string,
  year: string
): boolean => {
  const date = new Date(+year, +month - 1, +day);

  const yearMatches = date.getFullYear() === +year;
  const monthMatches = date.getMonth() === +month - 1;
  const dayMatches = date.getDate() === +day;

  return yearMatches && monthMatches && dayMatches;
};

export const JOURNEY_DIRECTIONS = {
  'to-office': 'reports-legend_lebel_to_community',
  'to-home': 'reports-legend_lebel_pick_up_address',
  'to-event': 'campaign-direction_to_event',
  'to-custom': 'campaign-direction_to_custom_location',
};

export const removeApostrophe = (value: string) => value.replace("'", '');
