import { theme } from '@faxi/web-component-library';
import { size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const RewardInstance = styled.div`
  display: grid;
  position: relative;
  align-items: flex-start;
  grid-template-columns: 1fr ${theme.sizes.SIZE_120} auto;
  gap: 20px;

  &[data-quantity='false'] {
    grid-template-columns: 1fr auto;
  }

  .button {
    ${size(theme.sizes.SIZE_24)};

    min-height: unset;
    transform: translate3d(0, 32px, 0);
  }
`;
