import {
  useState,
  useCallback,
  useContext,
  useEffect,
  FC,
  useRef,
  PropsWithChildren,
} from 'react';
import { useUtilities } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { CarParkProps } from 'pages/Settings/CarParkSettings/CarParkSettings.component';
import { UserContext } from 'store';
import { apiCommunity } from 'modules';
import CarParkContext from './CarPark.context';

const CarParkProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const [carParks, setCarParks] = useState<CarParkProps[]>();

  const lastEditCarParkId = useRef<string>('');

  const { communityId, isValidCommunity } = useContext(UserContext);

  const [savingField, setSavingField] = useState(false);
  const [editCarParkName, setEditCarParkName] = useState(false);
  const { t } = useTranslation();

  const getCarParks = useCallback(async () => {
    try {
      if (!isValidCommunity || !communityId) return;

      showOverlay('.kinto-page');

      const { rc, carparks } = await apiCommunity.getCarParks(communityId);

      if (rc === 'ok') {
        setCarParks(carparks);
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.kinto-page');
    }
  }, [communityId, hideOverlay, isValidCommunity, showOverlay]);

  const updateCarParkName = useCallback(
    async (data: FormData, carParkId: string) => {
      if (!communityId) {
        return;
      }

      try {
        setSavingField(true);
        const { rc } = await apiCommunity.updateCarParkName(
          communityId,
          carParkId,
          data
        );

        if (rc === 'ok') {
          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t('changes_saved'),
            variant: 'success',
          });

          setCarParks((old) =>
            old?.map((carPark) => {
              if (carPark.id === carParkId) {
                carPark.name = (data as any).name;
              }
              return carPark;
            })
          );

          setEditCarParkName(false);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setSavingField(false);
      }
    },
    [communityId, showSnackBar, t]
  );

  useEffect(() => {
    getCarParks();

    return () => {
      hideOverlay('.kinto-page');
    };
  }, [getCarParks, hideOverlay]);

  return (
    <CarParkContext.Provider
      value={{
        carParks,
        lastEditCarParkId,
        updateCarParkName,
        savingField,
        editCarParkName,
        setEditCarParkName,
      }}
    >
      {children}
    </CarParkContext.Provider>
  );
};

export default CarParkProvider;
