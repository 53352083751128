import { focusStyles, theme } from '@faxi/web-component-library';
import { flex, fontSize, pxToRem } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const PointsPage = styled.div`
  .kinto-points-form {
    margin-top: ${theme.sizes.SIZE_32};
    border-radius: ${theme.sizes.SIZE_8};
    background-color: var(--BACKGROUND_1_1);
    max-width: ${pxToRem(theme.sizes.SIZE_1016)};
    border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_7);
    padding: ${theme.sizes.SIZE_16};

    &__header {
      ${flex('column')};
      margin-top: ${theme.sizes.SIZE_16};
      margin-bottom: ${theme.sizes.SIZE_32};
      gap: ${theme.sizes.SIZE_8};

      &__title {
        ${fontSize(theme.fontSizes.FONT_26, theme.sizes.SIZE_32)};
        color: var(--SHADE_1_1);
        font-weight: 600;
        margin: 0;
        ${focusStyles};
      }

      &__description {
        ${fontSize(theme.fontSizes.FONT_12, theme.sizes.SIZE_16)};
        ${focusStyles};

        color: var(--SHADE_1_2);
        font-weight: 400;
        margin-right: ${theme.sizes.SIZE_48};
        max-width: ${theme.sizes.SIZE_632};
      }
    }
  }
`;
