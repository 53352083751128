import { FC, useContext, useMemo, useRef, useState } from 'react';
import {
  StatusElement,
  Table,
  Tooltip,
  useCallbackRef,
  useKeyboardListener,
  Link,
  SelectOption,
  getColor,
  useModalUtilities,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Icon } from 'components';
import SelectedCampaignAside from '../SelectedCampaignAside';
import { retrieveNestedAction } from '../CreateNewCampaign/utils';
import CampaignTypeModal from '../CampaignTypeModal';

import { useColumnSettings } from 'hooks';
import { ApiUser, Depot, RewardType } from 'models';
import { UserContext } from 'store';
import {
  getCampaignStatusElementStatus,
  getCampaignStatusElementTranslation,
} from 'utils';

import * as Styled from './CampaignList.styles';

type CampaignListProps = {
  campaigns?: Depot[];
  onUpdateCampaigns: () => Promise<void>;
};

const CampaignList: FC<CampaignListProps> = (props: CampaignListProps) => {
  const { campaigns, onUpdateCampaigns } = props;
  const {
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  const { t } = useTranslation();

  const actionTriggerRef = useRef<HTMLButtonElement>();
  const [selectedCampaign, setSelectedCampaign] = useState<Depot>();

  const {
    columnBtnRef,
    columnTranslations,
    columnSettingsOpen,
    closeColumnSettings,
    ColumnSettingsButton,
  } = useColumnSettings();

  const {
    open: selectCampaignTypeModal,
    triggerRef: selectCampaignTypeBtnRef,
    closeModal: closeSelectCampaignTypeModal,
    ModalButton: SelectCampaignTypeButton,
  } = useModalUtilities();

  const translationKeys = useMemo(
    () =>
      ({
        id: 'ID',
        campaignType: t('reward_campaign-title_campaign_type'),
        name: t('name'),
        start_date: t('campaign-start_date'),
        end_date: t('campaign-end_date'),
        state: t('userProfile_status_bio'),
        reuse: t('gamification-campaigns_reusable'),
      } as unknown as Record<Partial<keyof Depot>, string>),
    [t]
  );

  const mapData = useMemo(
    () =>
      campaigns?.map(
        ({ id, name, start_date, end_date, state, data, actions }: Depot) => ({
          id,
          campaignType: retrieveNestedAction('if-accumulated', actions?.[0])
            ? t('rewards-title_accumulation_campaign')
            : t('rewards-title_regular_campaign'),
          name,
          start_date: dayjs(start_date)?.format(dateFormat),
          end_date: end_date
            ? dayjs(end_date)?.format(dateFormat)
            : t('gamification-campaigns_end_date_never'),
          state: (
            <StatusElement status={getCampaignStatusElementStatus(state)}>
              {getCampaignStatusElementTranslation(
                { state, start_date },
                data.rewards as RewardType[],
                dateFormat
              )}
            </StatusElement>
          ),
          reuse: ['canceled', 'finished'].includes(`${state}`) ? (
            <div className="campaign-list__reuse">
              <Link
                to={{
                  pathname: `edit/${id}`,
                  search: retrieveNestedAction('if-accumulated', actions?.[0])
                    ? 'type=accumulation'
                    : '',
                }}
              >
                {t('global-reuse')}
              </Link>
              <Tooltip
                content={t('gamification-campaigns_tooltip_reuse_campaign')}
                placement="top"
              >
                <span
                  tabIndex={0}
                  role="tooltip"
                  className="campaign-list__reuse__tooltip"
                  aria-label={t(
                    'gamification-campaigns_tooltip_reuse_campaign'
                  )}
                >
                  <Icon name="circle-info" color={getColor('--PRIMARY_1_1')} />
                </span>
              </Tooltip>
            </div>
          ) : (
            '-'
          ),
        })
      ) || [],
    [dateFormat, campaigns, t]
  );

  const numberOfActiveCampaigns = useMemo(
    () => campaigns?.filter((campaign) => campaign.state === 'active').length,
    [campaigns]
  );

  const [campaignList, campaignListRef] = useCallbackRef<HTMLDivElement>();

  useKeyboardListener(
    [
      {
        keyCodes: ['Escape'],
        callbackFn: () => {
          if (selectedCampaign) {
            setSelectedCampaign(undefined);
          }
        },
      },
    ],
    campaignList
  );
  return (
    <Styled.Container className="campaign-list" ref={campaignListRef}>
      <div className="campaign-list__active-campaigns-label">
        {t('gamification-campaigns_active_campaigns', {
          number: numberOfActiveCampaigns,
        })}
      </div>
      <div className="campaign-list__header-actions">
        <SelectCampaignTypeButton
          icon={<Icon name="plus" />}
          iconPosition="right"
          variant="outline"
        >
          {t('global-new_campaign')}
        </SelectCampaignTypeButton>
        <ColumnSettingsButton />
      </div>
      {campaigns && (
        <Table<
          Partial<Omit<Depot, 'state'>> & {
            id: number;
            campaignType: string;
            state: JSX.Element | string;
            reuse: JSX.Element | string;
          }
        >
          tableId="campaign-table"
          cacheColumns
          disableSort
          rowsHaveAction
          tableData={mapData}
          translationKeys={translationKeys as any}
          columnsModalLabels={columnTranslations}
          columnSettingsOpen={columnSettingsOpen}
          columnsBtnElement={columnBtnRef.current!}
          className="campaign-list__table"
          rowsActionHeader={t('global-table_actions')}
          editActionAriaLabel={t('accessibility-button_preview_campaign')}
          excludeColumns={[
            'type',
            'data',
            'description',
            'triggers',
            'actions',
            // TODO: remove this after release5.5
            'campaignType',
          ]}
          onColumnsModalClose={closeColumnSettings}
          onRowClick={(index, element) => {
            if (element) actionTriggerRef.current = element;
            const campaign = campaigns.find((_, idx) => index === idx);

            setSelectedCampaign({
              ...campaign!,
              excluded:
                (
                  campaign?.excluded as unknown as (Pick<
                    ApiUser,
                    'first_name' | 'last_name'
                  > & { id_user: string })[]
                )?.map(
                  (e) =>
                    ({
                      id: +e.id_user,
                      label: [e.first_name, e.last_name].join(' ').trim(),
                      value: [e.first_name, e.last_name].join(' ').trim(),
                    } as unknown as SelectOption<string>)
                ) || [],
            });
          }}
        />
      )}
      {selectedCampaign && (
        <SelectedCampaignAside
          selectedCampaign={selectedCampaign}
          triggerRef={actionTriggerRef.current}
          onClose={() => setSelectedCampaign(undefined)}
          campaignState={
            <StatusElement
              status={getCampaignStatusElementStatus(selectedCampaign.state)}
            >
              {getCampaignStatusElementTranslation(
                selectedCampaign,
                selectedCampaign.data.rewards as RewardType[],
                dateFormat
              )}
            </StatusElement>
          }
          onUpdateCampaigns={onUpdateCampaigns}
        />
      )}
      {selectCampaignTypeModal && (
        <CampaignTypeModal
          onClose={closeSelectCampaignTypeModal}
          triggerRef={selectCampaignTypeBtnRef.current as HTMLButtonElement}
        />
      )}
    </Styled.Container>
  );
};

export default CampaignList;
