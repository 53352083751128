import { FC, useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Heading, getColor } from '@faxi/web-component-library';
import { UserContext } from 'store';
import { BlockUI } from 'helpers';
import Pending from '../Pending';

import * as Styles from './UserLayout.styles';

const UserLayout: FC = () => {
  const { communities } = useContext(UserContext);
  const { organisationId } = useParams() as { organisationId: string };

  const community = useMemo(
    () => communities.find((org) => `${org.id}` === organisationId),
    [communities, organisationId]
  );

  return (
    <Styles.UserLayout>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-user-layout__heading"
      >
        {community?.name}
      </Heading>

      <BlockUI
        fallback={<Pending />}
        condition={community?.status === 'pending'}
      >
        <Outlet />
      </BlockUI>
    </Styles.UserLayout>
  );
};

export default UserLayout;
