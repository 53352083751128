import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import i18next from 'i18next';
import { authBus, AUTH_BUS_EVENTS } from 'modules';
import refreshTokenService from 'services/refreshTokenService';
import storageService, { STORAGE_KEYS } from 'services/storageService';

let refreshTimeout: NodeJS.Timeout | number;

const origin = window.location.origin.includes('localhost')
  ? 'https://app-dev.kinto-join.it'
  : window.location.origin;

const httpClient = axios.create({
  baseURL: `${origin}/api/`,
});

const setSession = (config: AxiosRequestConfig) => {
  const sid = storageService.getItem(STORAGE_KEYS.SID);
  if (sid) (config.headers as any).sid = sid;
};

const refresh = async (config: AxiosRequestConfig) => {
  return new Promise<void>((resolve, reject) => {
    const onRefreshTokenSuccess = () => {
      setSession(config);
      refreshTokenService.reinit();
      removeListeners();
      resolve();
    };

    const onRefreshTokenFail = () => {
      authBus.broadcastEvent(AUTH_BUS_EVENTS.SESSION_EXPIRED);
      removeListeners();
      reject();
    };

    const removeSuccessListener = authBus.addEventListener(
      AUTH_BUS_EVENTS.REFRESH_TOKEN_SUCCESS,
      onRefreshTokenSuccess
    );

    const removeFailListener = authBus.addEventListener(
      AUTH_BUS_EVENTS.REFRESH_TOKEN_FAIL,
      onRefreshTokenFail
    );

    const removeListeners = () => {
      removeFailListener();
      removeSuccessListener();
    };

    if (!refreshTimeout) {
      refreshTimeout = setTimeout(() => {
        removeListeners();
        refreshTokenService.reinit();
        reject(new Error('Couldn`t refresh token'));
      }, refreshTokenService.checkRefreshTreshold);
    }
  });
};

httpClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    setSession(config);

    if (refreshTokenService.refreshingAtm && !(config as any).isRefresh) {
      try {
        await refresh(config);
      } catch (e) {
        return Promise.reject('Couldn`t refresh token');
      }
    }

    if (refreshTokenService.triedRefresh) {
      refreshTokenService.reinit();
    }

    if ((config as any).isRefresh) {
      config.headers.sid = null;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(async (response: AxiosResponse) => {
  const { config, data } = response;
  const apiStatusCode = data.errc;

  if (
    response &&
    ([401].includes(response.status) || [1036, 9007].includes(apiStatusCode))
  ) {
    if ((config as any).isRefresh) return;

    if (refreshTokenService.refreshingAtm) {
      try {
        await refresh(config);
      } catch (e) {
        return Promise.reject('Couldn`t refresh token');
      }
      return httpClient.request(config);
    }

    try {
      await refreshTokenService.refresh();

      return httpClient.request(config);
    } catch (e) {
      console.error(e);
      authBus.broadcastEvent(AUTH_BUS_EVENTS.SESSION_EXPIRED);
      authBus.broadcastEvent(AUTH_BUS_EVENTS.REFRESH_TOKEN_FAIL);
      refreshTokenService.reinit();
      return Promise.reject('Couldn`t refresh token');
    }
  }
  return response as any;
});

httpClient.interceptors.response.use(
  (res) => {
    if (res.data.errc === 9006) {
      authBus.broadcastEvent<string>(
        AUTH_BUS_EVENTS.NON_EXISTING_COMMUNITY,
        res.data.errc
      );
      return Promise.reject(res.data.errc);
    }

    if (
      res?.data?.errc &&
      ![
        1001, 1003, 1005, 1007, 1009, 1010, 1011, 1016, 1036, 1037, 1038, 1039,
        1040, 1042, 2008, 4006, 4010, 9023, 9025, 2013, 2001,
      ].includes(res?.data?.errc)
    ) {
      authBus.broadcastEvent('show_snackbar', {
        props: {
          text: i18next.t(`error_${res?.data?.errc}`),
          variant: 'error',
          actionButtonText: i18next.t('dismiss'),
        },
      });
      return Promise.reject(res?.data?.errc);
    }
    return res;
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);

export default httpClient;
