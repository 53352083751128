import { ButtonProps } from '@faxi/web-component-library';
import { createContext, FC, NamedExoticComponent, ReactNode } from 'react';

export type View<T> = {
  key: T;
  component: ReactNode | NamedExoticComponent;
  fallback?: string;
  validate?: () => boolean;
};

export interface ViewsContextProps<T> {
  views: View<T>[];
  initialView?: T;
  activeView: FC<any>;
  BackButton: FC<ButtonProps>;
  pushView: (key: T, replace?: boolean) => void;
  goBack: () => void;
  clearStack: () => void;
}

const ViewsContext = createContext<ViewsContextProps<any>>({
  views: [],
  initialView: '',
  BackButton: {} as FC,
  activeView: {} as FC,
  pushView: () => {},
  clearStack: () => {},
  goBack: () => {},
});

export default ViewsContext;
