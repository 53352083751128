import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router-dom';
import { Button, Image, useEffectSkipFirst } from '@faxi/web-component-library';

import Icon from 'components/Icon';

import * as Styled from './ErrorFallback.styles';

const ErrorFallback: React.FC = () => {
  const { t } = useTranslation();

  const error = useRouteError();
  const navigate = useNavigate();

  const location = window.location.pathname;

  const handleResetClick = useCallback(() => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });

    caches.keys().then((keyList) => {
      return Promise.all(
        keyList.map((key) => {
          return caches.delete(key);
        })
      );
    });

    navigate(location);
  }, [location, navigate]);

  useEffectSkipFirst(() => {
    //Reset error boundary on pathname change
    if (!error) return;

    navigate(location);
  }, [location]);

  return (
    <Styled.ErrorContainer className="error-fallback">
      <a
        target="_blank"
        rel="noreferrer"
        className="error-fallback__logo"
        href="https://www.kintojoin.io"
      >
        <Image
          src="/assets/svg/error_fallback.svg"
          alt={`${t('app_name')} ${t('logo')}`}
        />
      </a>

      <h1 className="error-fallback__ops">{t('error-fallback_title')}</h1>
      <h3 className="error-fallback__desc">{t('error-fallback_subtitle_1')}</h3>
      <h3 className="error-fallback__desc">{t('error-fallback_subtitle_2')}</h3>

      <Button
        variant="outline"
        className="error-fallback__refresh"
        icon={<Icon name="rotate-right" />}
        onClick={handleResetClick}
      >
        {t('global-try_again')}
      </Button>
    </Styled.ErrorContainer>
  );
};

export default ErrorFallback;
