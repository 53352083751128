import { theme } from '@faxi/web-component-library';
import {
  phablet,
  marginChildren,
  flex,
  fontSize,
  pxToRem,
} from '@faxi/web-css-utilities';

import styled, { css } from 'styled-components';

export const MessagesStyled = styled.div`
  display: grid;
  grid-template-columns: ${pxToRem(theme.sizes.SIZE_424)} 1fr;
  height: 100%;
  overflow: auto;

  ${phablet(css`
    overflow-x: hidden;
  `)}

  main {
    height: 100%;
    overflow: auto;
  }

  .kinto-tabs__panel {
    height: 100%;
    max-width: unset;
    border: unset;
    box-shadow: unset;
    border-radius: unset;
  }

  @media (max-width: ${theme.breakAtMaxWidth}px) {
    grid-template-columns: unset;

    &.on-tab {
      .kinto-searchable-users-section {
        display: none;
      }
    }

    &:not(.on-tab) {
      .kinto-tabs__panel {
        display: none;
      }
    }
  }
`;

export const NoConversationSelectedStyled = styled.div`
  padding-bottom: ${theme.sizes.SIZE_80};
  height: 100%;
  ${flex('column', 'center', 'center')};
  text-align: center;
  background-color: var(--BACKGROUND_2_1);

  .kinto-no-conversation-selected {
    &__container {
      ${flex('column', 'center', 'center')};
      ${marginChildren(`0 0 ${theme.sizes.SIZE_16} 0`)};

      max-width: ${pxToRem(theme.sizes.SIZE_424)};

      &__title {
        ${fontSize(theme.fontSizes.FONT_30, '42px')};
        font-weight: 600;
        color: var(--SECONDARY_1_1);
      }

      &__content {
        ${fontSize(theme.fontSizes.FONT_16, '23px')};
        font-weight: 400;
        color: var(--SHADE_1_2);
      }
    }
  }
`;
