import { elevate_l, Modal, theme } from '@faxi/web-component-library';
import { flex, fontSize, phablet, pxToRem } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

// TODO: please do not override over and over again. Use defined styles from global, as used in EventModal :)
export const RewardModal = styled(Modal)`
  .wcl-modal {
    overflow: hidden;
    width: clamp(
      ${pxToRem(theme.sizes.SIZE_96)},
      100%,
      ${pxToRem(theme.sizes.SIZE_680)}
    );
  }

  &.reward-type {
    .wcl-modal {
      background-color: var(--BACKGROUND_2_1);
    }
  }

  .kinto-modal-rewards {
    &__info {
      margin: 0 0 ${theme.sizes.SIZE_32};
    }

    &__suggestions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding-top: ${theme.sizes.SIZE_16};
      margin: 0 0 ${theme.sizes.SIZE_24};
      gap: ${theme.sizes.SIZE_8};

      .suggestion-btn {
        width: 100%;
        justify-content: start;
        color: var(--SHADE_1_1);

        &:after {
          border-color: var(--SHADE_1_8);
          ${elevate_l};
        }

        &[disabled] {
          color: var(--SHADE_1_4);

          &:after {
            box-shadow: unset;
            border-color: var(--SHADE_1_8);
          }
        }
      }

      &__title {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        color: var(--SHADE_1_2);
        margin: 0;
      }

      ${phablet(css`
        grid-template-columns: repeat(2, 1fr);
      `)}
    }

    &__description,
    &__redeem {
      .textarea__container {
        &__textarea {
          min-height: ${pxToRem(theme.sizes.SIZE_152)};
        }
      }
    }

    &__form {
      ${flex('column')};
      overflow: auto;
    }
  }
`;
