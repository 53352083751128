import { theme } from '@faxi/web-component-library';
import { flex, fontSize, pxToRem, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const SeparatedContentContainer = styled.div`
  ${flex('row', 'space-between', 'center')};
  ${size('100%', 'auto')};

  width: 100%;
  min-height: ${pxToRem(theme.sizes.SIZE_48)};

  &.large {
    ${fontSize(theme.fontSizes.FONT_20)};

    font-weight: 400;
    color: var(--SHADE_1_1);
  }

  &.small {
    ${fontSize(theme.fontSizes.FONT_16)};

    font-weight: 400;
    color: var(--SHADE_1_2);
  }
`;
