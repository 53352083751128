import { FC, PropsWithChildren, ReactNode } from 'react';

const BlockUI: FC<
  PropsWithChildren & {
    condition: boolean;
    fallback: ReactNode;
  }
> = (props) => {
  const { children, condition, fallback } = props;
  return condition ? <>{fallback}</> : <>{children}</>;
};

export default BlockUI;
