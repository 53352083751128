import { FC, useCallback, useContext, useMemo } from 'react';
import {
  FormField,
  Form,
  FormRef,
  validators,
  useFormRefValues,
  DataState,
} from '@faxi/web-form';
import {
  Heading,
  getColor,
  useCallbackRef,
  useFormButtons,
  useUtilities,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { AuthContext, UserContext } from 'store';
import { FormActions, PasswordField } from 'components';
import regex from 'validation/regex';

import * as Styled from 'components/_layouts/Containers';
import { PageLayout } from 'components/_layouts';

export type InputFieldItem = {
  name: string;
};

const SettingsPasswordForm: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const { handleLogout } = useContext(AuthContext);
  const { user, updateUser } = useContext(UserContext);

  const { showSnackBar } = useUtilities();

  const [form, formRef] = useCallbackRef<FormRef>();

  const [FormButtons] = useFormButtons({
    submitLabel: t('Save'),
    cancelLabel: t('cancel'),
    loadingOverlaySelector: '.kinto-page',
  });

  const password = useFormRefValues(form, 'pwd')?.pwd;

  const handleSubmit = useCallback(
    async (event: DataState) => {
      if (!user) {
        return;
      }

      try {
        const { errc } = await updateUser(user.id, event, 'PUT');

        if (errc === 1016) {
          form.setFieldError('curpassword', t('error_1016'));
          form.setFieldFocus('curpassword');
        } else {
          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t('password_successfully_updated'),
            variant: 'success',
          });

          await handleLogout(true);
        }
      } catch (err) {
        console.error(err);
        if (err === 1016) {
          form.setFieldError('curpassword', t('error_1016'));
          form.setFieldFocus('curpassword');
        }
      }
    },
    [user, updateUser, form, t, showSnackBar, handleLogout]
  );

  const validations = useMemo(
    () => ({
      curpassword: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('current_password'),
          })
        ),
      ],
      pwd: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('new_password'),
          })
        ),
        validators.general.regex(
          regex.passwordValidator,
          t('validation-field_new_password')
        ),
        validators.general.regex(
          regex.trimWhiteSpaces,
          t('validation-field_new_password')
        ),
      ],
      confirmNewPassword: [
        validators.general.required(
          t('validation-field_new_confirm_new_password')
        ),
        validators.general.reEnterFieldDoesntMatch(
          password,
          t('passwords_match_validation')
        ),
      ],
    }),
    [password, t]
  );

  return (
    <PageLayout className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('change_password')}
      </Heading>
      <Styled.SettingsForm>
        <Form id="password_form" ref={formRef} onSubmit={handleSubmit}>
          <fieldset className="form__fields">
            <legend data-hidden hidden>
              {t('Password')}
            </legend>
            <FormField
              required
              requiredLabel={t('global-input_field_required_label')}
              id="person_current_password"
              name="curpassword"
              component={PasswordField}
              placeholder={t('current_password')}
              validate={validations.curpassword}
            />
            <FormField
              required
              requiredLabel={t('global-input_field_required_label')}
              id="person_new_password"
              name="pwd"
              component={PasswordField}
              placeholder={t('new_password')}
              validate={validations.pwd}
              hasRules
            />
            <FormField
              required
              requiredLabel={t('global-input_field_required_label')}
              id="person_confirm_new_password"
              name="confirmNewPassword"
              component={PasswordField}
              placeholder={t('confirm_new_password')}
              validate={validations.confirmNewPassword}
            />
          </fieldset>
          <FormActions className="form__actions">
            <FormButtons.Submit
              id="submit_password"
              disabled={!form?.isFormChanged() || !form?.syncFormValid}
            />
          </FormActions>
        </Form>
      </Styled.SettingsForm>
    </PageLayout>
  );
};

export default SettingsPasswordForm;
