import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TabView } from '@faxi/web-component-library';
import { NavLink } from 'react-router-dom';

import { CarParkContext } from 'store';
import { Icon } from 'components';

export const CarParkList: FC = () => {
  const { carParks, lastEditCarParkId } = useContext(CarParkContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!lastEditCarParkId.current) return;
    const carParkElement = document.querySelector(
      `#${lastEditCarParkId.current}`
    );

    if (carParkElement) {
      (carParkElement as HTMLElement).focus();
    }
  }, [lastEditCarParkId]);

  return (
    <TabView hasBorders>
      {carParks && carParks.length === 0 ? (
        <div className="car-park-list__empty">
          <div className="car-park-list__empty__title">
            {t('no_car_parks_added')}
          </div>
          <div>{t('empty_car_park_list_description')}</div>
        </div>
      ) : (
        carParks?.map(({ id, name }) => (
          <div className="tab-view__row" key={`${id}_${name}`}>
            <div className="tab-view__row__title">{`[ID] ${t('name')}`}</div>
            <div className="tab-view__row__content">
              <div>{`[${id}] ${name}`}</div>
              <NavLink
                id={`edit_car_park_list_${id}`}
                className="tab-view__row__edit"
                to={`edit/${id}`}
                onClick={() => {
                  lastEditCarParkId.current = `edit_car_park_list_${id}`;
                }}
              >
                <span>{t('as_edit')}</span>
                <Icon name="pen" />
              </NavLink>
            </div>
          </div>
        ))
      )}
    </TabView>
  );
};
