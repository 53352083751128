import { StatusElementStatus } from '@faxi/web-component-library';
import dayjs, { Dayjs } from 'dayjs';

import { Survey, SURVEY_STATUS } from 'models';

const SURVEY_STATUS_ELEMENT = {
  [SURVEY_STATUS.ACTIVE]: 'active',
  [SURVEY_STATUS.FINISHED]: 'approved',
  [SURVEY_STATUS.PENDING]: 'pending',
  [SURVEY_STATUS.CANCELED]: 'canceled',
} as Record<Survey['status'], StatusElementStatus>;

const SURVEY_STATUS_TRANSLATIONS = {
  [SURVEY_STATUS.ACTIVE]: 'global-ongoing',
  [SURVEY_STATUS.FINISHED]: 'global-completed',
  [SURVEY_STATUS.PENDING]: 'global-scheduled',
  [SURVEY_STATUS.CANCELED]: 'journeys-history_status_aborted',
} as Record<Survey['status'], string>;

const SURVEY_MOD_OF_TRANSPORT = {
  driving: 'mode_of_transport-driving_a_personal_vehicle',
  carpooling: 'mode_of_transport-carpool_as_a_passenger',
  walking: 'cp_map_walking',
  cycling: 'cp_map_cycling',
  corporate_shuttle: 'mode_of_transport-corporate_shuttle',
  bus: 'mode_of_transport-bus',
  train: 'mode_of_transport-train',
} as Record<string, string>;

const mapSurvey = (survey: Survey) => {
  const end_date_time = survey.end_time?.split(' ');
  const start_date_time = survey.start_time?.split(' ');

  return {
    ...survey,
    end_date: end_date_time?.[0],
    end_time: end_date_time?.[1],
    start_date: start_date_time?.[0],
    start_time: start_date_time?.[1],
  } as Survey;
};

const mapSurveys = (surveys: Survey[]) => {
  return surveys.map((s) => mapSurvey(s));
};

const getDatesBetween = (startDate: Dayjs, endDate: Dayjs) => {
  const dates: Dayjs[] = [];
  let currentDate = dayjs(startDate);

  while (dayjs(endDate).isAfter(currentDate)) {
    dates.push(currentDate);
    currentDate = dayjs(currentDate).add(1, 'day');
  }

  dates.push(endDate);
  return dates;
};

export {
  SURVEY_STATUS_ELEMENT,
  SURVEY_STATUS_TRANSLATIONS,
  SURVEY_MOD_OF_TRANSPORT,
  mapSurveys,
  mapSurvey,
  getDatesBetween,
};
