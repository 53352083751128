import { outline } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Container = styled.button`
  ${flex('row', 'center', 'center')};

  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-color: var(--SHADE_1_9);
  padding: 10px;
  height: fit-content;
  min-height: unset;

  &.apple-button {
    background-color: black;
  }

  &.social-button--disable {
    opacity: 0.6;
    cursor: not-allowed;
    ${outline({ outlineColor: 'transparent' })}
  }
`;
