import { CommunityDetailsForm } from 'components';
import {
  CarParkList,
  CarParkSettings,
  CarSpaceSettings,
  PreapprovedSettingsTab,
  PredefinedAddressesTab,
  PredefinedShiftsTab,
} from 'pages/Settings';
import { Navigate } from 'react-router-dom';
import { ExtendRouteObject } from 'router/routes';
import { CommunitySettingsProvider, CustomFieldsProvider } from 'store';
import { appUri } from '../../../../../config';

export default [
  {
    handle: 'Community details',
    path: appUri.COMMUNITY_SETTINGS_DETAILS,
    element: (
      <CommunitySettingsProvider>
        <CommunityDetailsForm />
      </CommunitySettingsProvider>
    ),
  },
  {
    handle: 'Community custom information',
    path: appUri.COMMUNITY_SETTINGS_CUSTOM_INFO,
    element: <CustomFieldsProvider />,
  },
  {
    handle: 'Car park settings',
    path: appUri.COMMUNITY_CAR_PARK_SETTINGS,
    element: <CarParkSettings />,
    children: [
      {
        handle: 'Car park list',
        path: '',
        element: <CarParkList />,
      },
      {
        handle: 'Edit car park',
        path: appUri.COMMUNITY_CAR_PARK_SETTINGS_EDIT,
        element: <CarSpaceSettings />,
      },
    ],
  },
  {
    handle: 'Predefined addresses',
    path: appUri.COMMUNITY_SETTINGS_PREDEFINED_ADDRESSES,
    element: <PredefinedAddressesTab />,
  },
  {
    handle: 'Preapproved settings',
    path: appUri.COMMUNITY_SETTINGS_PREAPPROVED_SETTINGS,
    element: (
      <CommunitySettingsProvider>
        <PreapprovedSettingsTab />
      </CommunitySettingsProvider>
    ),
  },
  {
    handle: 'Predefined shifts',
    path: appUri.COMMUNITY_SETTINGS_PREDEFINED_SHIFTS,
    element: <PredefinedShiftsTab />,
  },
  {
    index: true,
    element: <Navigate to={appUri.COMMUNITY_SETTINGS_DETAILS} replace />,
  },
] as ExtendRouteObject[];
