const maxValue = (msg: string, maxValue: number) => (value: number) => {
  if (value && value > maxValue) {
    return msg;
  }
  return '';
};

const minValue = (msg: string, minValue: number) => (value: number) => {
  if (value && value < minValue) {
    return msg;
  }
  return '';
};
export default {
  maxValue,
  minValue,
};

// TODO: clean up and organize these utils
