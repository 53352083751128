import { ReactNode, useEffect, useRef } from 'react';

type Function = () => void;
type ReturningFunction = () => Function;

export default function useEffectOnceWhen(
  callback: Function | ReturningFunction,
  condition: ReactNode = true
): void {
  const hasRunOnceRef = useRef(false);

  const callbackRef = useRef(callback);
  const unmountFunctionRef = useRef<Function>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    if (condition && !hasRunOnceRef.current) {
      unmountFunctionRef.current = (callbackRef.current as ReturningFunction)();
      hasRunOnceRef.current = true;
    }

    return () => {
      if (!unmountFunctionRef.current) return;
      unmountFunctionRef?.current();
    };
  }, [condition]);
}
