import {
  useContext,
  useCallback,
  FC,
  useRef,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';

import { CommunitySettingsContext, UserContext } from 'store';
import { Method } from 'axios';
import { apiCommunity } from 'modules';
import { useAbortController, useCallbackAsync } from 'hooks';
import { Community } from 'models';

const CommunitySettingsProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const {
    community: currentCommunity,
    communityId,
    userReady,
  } = useContext(UserContext);

  const [community, setCommunity] = useState(currentCommunity);

  const tmpOrganisation = useRef<Partial<Community>>({});
  const shouldUpdateCommunity = useRef<boolean>(true);

  const {
    abortSignal: abortCommunitySignal,
    cancelPreviousRequest: cancelCommunitiesRequest,
  } = useAbortController();

  const {
    abortSignal: abortCommunityDescSignal,
    cancelPreviousRequest: cancelCommunityDescRequest,
  } = useAbortController();

  const [getCommunityDescription] = useCallbackAsync({
    showSpinner: false,
    catchAsyncErrors: false,
    callback: async (oid: number) => {
      cancelCommunityDescRequest();

      const {
        data: { value },
      } = await apiCommunity.getCommunityDescription(oid, {
        signal: abortCommunityDescSignal(),
      });

      tmpOrganisation.current = {
        ...tmpOrganisation.current,
        description: value,
      };
    },
  });

  const [getCommunity, loadingCommunity] = useCallbackAsync({
    showSpinner: true,
    catchAsyncErrors: false,
    callback: async (oid: number) => {
      cancelCommunitiesRequest();

      const {
        data: {
          organisations: [organisation],
        },
      } = await apiCommunity.getCommunityById(oid, {
        signal: abortCommunitySignal(),
      });

      tmpOrganisation.current = {
        ...tmpOrganisation.current,
        ...organisation,
        formatted_address: organisation.formatted_address,
      };
    },
  });

  const updateCommunityDescription = useCallback(
    async (oid: number, gDesc: string, method?: Method) => {
      try {
        await apiCommunity.updateCommunity(
          oid,
          { key: 'gDesc', value: gDesc },
          method
        );
        setCommunity((old) => ({
          ...old!,
          description: gDesc,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  useEffect(() => {
    if (shouldUpdateCommunity.current) {
      setCommunity((old) => ({ ...old!, ...currentCommunity }));
    } else {
      shouldUpdateCommunity.current = true;
    }
  }, [currentCommunity]);

  // FETCH COMMUNITY DATA
  useEffect(() => {
    if (!communityId || !userReady) {
      return;
    }

    const fetchCommunityData = async () => {
      await Promise.all([
        getCommunityDescription(communityId),
        getCommunity(communityId),
      ]);

      setCommunity((old) => ({ ...old!, ...tmpOrganisation.current }));
    };

    fetchCommunityData();
  }, [communityId, getCommunity, getCommunityDescription, userReady]);

  return (
    <CommunitySettingsContext.Provider
      value={{
        community,
        loadingCommunity,
        updateSettingsCommunity: setCommunity,
        shouldUpdateCommunity,
        updateCommunityDescription,
      }}
    >
      {children}
    </CommunitySettingsContext.Provider>
  );
};

export default CommunitySettingsProvider;
