import {
  forwardRef,
  ForwardRefRenderFunction,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getColor, GoogleMapsContext } from '@faxi/web-component-library';

import { Coordinates } from 'pages/Map/providers/MapData/MapData.provider';
import useCustomDirections from '../../hooks/useCustomDirections';

const renderOptions: google.maps.DirectionsRendererOptions = {
  suppressMarkers: true,
  polylineOptions: {
    strokeColor: getColor('--PRIMARY_1_1'),
  },
  preserveViewport: true,
};

type MapRouteProps = {
  className?: string;
  points: Coordinates[];
};

export type MapRouteRef = google.maps.LatLngBounds | undefined;

const MapRoute: ForwardRefRenderFunction<MapRouteRef, MapRouteProps> = (
  { points },
  ref
) => {
  const { map } = useContext(GoogleMapsContext);
  const { findRoute } = useCustomDirections();

  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();

  const { t } = useTranslation();

  useImperativeHandle(ref, () => bounds, [bounds]);

  useEffect(() => {
    if (!map) return;

    if (points.length < 2) return;

    const [first] = points;
    const last = points[points.length - 1];

    const origin = new window.google.maps.LatLng(first.lat, first.lng);
    const destination = new window.google.maps.LatLng(last.lat, last.lng);

    const directionsRenderer = new window.google.maps.DirectionsRenderer(
      renderOptions
    );

    directionsRenderer.setMap(map);

    const waypoints: google.maps.DirectionsWaypoint[] =
      points.length === 2
        ? []
        : points.slice(1, points.length - 1).map(({ lat, lng }) => ({
            location: new window.google.maps.LatLng({ lat, lng }),
            stopover: true,
          }));

    const drawRoute = async () => {
      try {
        const directionsResults = await findRoute?.({
          origin,
          destination,
          waypoints,
          travelMode: window.google.maps.TravelMode.DRIVING,
        });

        if (directionsResults) {
          directionsRenderer.setDirections(directionsResults);
          setBounds(directionsResults.routes[0].bounds);
        }
      } catch (er) {
        console.error(er);
      }
    };

    drawRoute();

    return () => {
      setTimeout(() => {
        directionsRenderer.setMap(null);
      }, 0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findRoute, t, points]);

  return null;
};

export default forwardRef(MapRoute);
