import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Expander, TabView } from '@faxi/web-component-library';

import { Icon } from 'components';
import { CustomFieldsContext, UserContext } from 'store';
import classNames from 'classnames';

const CustomInformation: FC = () => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const addInputBtnRef = useRef<HTMLButtonElement>(null);

  const {
    status,
    fields,
    lastFocusEditId,
    startCreateNewField,
    startEditField,
  } = useContext(CustomFieldsContext);

  const disabled = useCallback(
    (field: any) =>
      user?.rightprofile !== 'A' && field.last_edited_by === 'SUPER_ADMIN',
    [user?.rightprofile]
  );

  useEffect(() => {
    if (!lastFocusEditId?.current) return;
    const lastEditElement = document.querySelector(lastFocusEditId.current);

    if (lastEditElement) {
      (lastEditElement as HTMLElement).focus();
    }
  }, [lastFocusEditId, status]);

  return (
    <Fragment>
      <TabView hasBorders>
        <div>
          <Expander
            contentId="community_custom_info_help_text"
            title={t('create_custom_inputs')}
            icon={<Icon name="circle-info" />}
            body={t('create_custom_inputs_info').replace(/\\n/g, '<br />')}
          />
          {fields.length >= 5 && (
            <div className="kinto-custom-fields-banner">
              {t('custom_fields_limit')}
            </div>
          )}
        </div>

        {fields
          .sort((el1, el2) => (el1.name > el2.name ? 1 : -1))
          .map((field, index) => (
            <div
              key={index}
              className={classNames('tab-view__row', {
                'tab-view__row--disabled': disabled(field),
              })}
            >
              <div className="tab-view__row__title">
                {field.mandatory === 'Y'
                  ? t('custom_input_field') + ` (${t('required_field_short')})`
                  : t('custom_input_field')}
              </div>

              <div className="tab-view__row__content">
                <div>{field.label}</div>

                <div>
                  <Button
                    id={`edit_custom_info_input_${field.id}`}
                    icon={<Icon name="pen" />}
                    variant="ghost"
                    iconPosition="right"
                    disabled={disabled(field)}
                    aria-label={t('accessibility-edit_custom_input', {
                      name: field.label,
                    })}
                    onClick={() => startEditField(`${field.id}`)}
                  >
                    {t('as_edit')}
                  </Button>
                </div>
                {disabled(field) && (
                  <Expander
                    title={t('field_locked')}
                    icon={<Icon name="circle-info" />}
                    body={t('field_locked_info')}
                  />
                )}
              </div>
            </div>
          ))}
      </TabView>

      <Button
        ref={addInputBtnRef}
        id="custom_info_add_input"
        className="kinto-custom-information__action-button"
        iconPosition="left"
        icon={<Icon name="plus" />}
        disabled={fields.length >= 5}
        onClick={() => startCreateNewField('text_field')}
      >
        {t('add_input')}
      </Button>
    </Fragment>
  );
};

export default CustomInformation;
