import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  FormField,
  FormRef,
  useFormRefValues,
  validators,
} from '@faxi/web-form';
import {
  Button,
  useCallbackRef,
  useFormButtons,
  useUtilities,
} from '@faxi/web-component-library';

import { useCallbackAsync } from 'hooks';
import { apiCustomFields } from 'modules';
import { CustomFieldsContext, UserContext } from 'store';
import { FormActions, Icon, InputField, IphoneFrame } from 'components';
import { CustomField } from 'models';
import regex from 'validation/regex';

import SwitchField from '../SwitchField';
import { SettingsForm } from 'components/_layouts/Containers';

const CustomInputForm: FC = () => {
  const { prompts } = useUtilities();
  const { status, managingInput, cancelEdit, editField, fetchCustomFields } =
    useContext(CustomFieldsContext);
  const { communityId } = useContext(UserContext);

  const { showSnackBar } = useUtilities();

  const { t } = useTranslation();

  const [FormButtons] = useFormButtons({
    submitLabel: t('Save'),
    cancelLabel: t('cancel'),
    loadingOverlaySelector: '.kinto-page',
  });

  const [form, formRef] = useCallbackRef<FormRef>();

  const label = useFormRefValues(form, 'label')?.label;
  const mandatory = useFormRefValues(form, 'mandatory')?.mandatory;

  const initialData = useMemo(
    () => ({
      label: managingInput?.label,
      mandatory: managingInput?.mandatory === 'Y',
    }),
    [managingInput?.label, managingInput?.mandatory]
  );

  const isEdit = useMemo(() => status === 'EDIT_FIELD', [status]);

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', { fieldname: t('input_name') })
        ),
        validators.general.regex(
          regex.customInputLabelAndShiftNameRegex,
          t('validation-field_name', {
            fieldname: t('input_name').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          32,
          t('validation-field_validation_max_length', {
            fieldname: t('input_name').toLowerCase(),
            number: '32',
          })
        ),
      ],
    }),
    [t]
  );

  const [saveField] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.kinto-page',
    callback: async (data: { label: string; mandatory: boolean }) => {
      if (!communityId) {
        return;
      }

      const generateNameByLabel = (label: string) =>
        (label.trim() + ' id').toLowerCase().replace(/ /g, '_');

      const field: CustomField = {
        ...data,
        mandatory: data.mandatory === true ? 'Y' : 'N',
        name: generateNameByLabel(data.label),
        type: 'text_field',
      };

      if (
        isEdit &&
        field.label === managingInput?.label &&
        field.mandatory === managingInput?.mandatory
      ) {
        // skip edit if it keeps the same value
        cancelEdit();
        return;
      }

      try {
        const {
          data: { rc: responseCode, errc },
        } = await (isEdit
          ? apiCustomFields.editField(communityId, field, managingInput!.name)
          : apiCustomFields.createNewField(communityId, field));

        let fieldError = '';
        switch (errc) {
          case 0:
            break;
          case 9008:
            fieldError = t('name_or_type_not_defined');
            break;

          case 9005:
            fieldError = t('wrong_role');
            break;

          case 2004:
            fieldError = t('max_custom_fields_exceeded');
            break;

          case 2008:
            fieldError = t('error_2008');
            break;

          case 9011:
            fieldError = t('error_1024');
            break;
          default:
            break;
        }

        if (fieldError) {
          form.setFieldError('label', fieldError);
          return;
        }

        if (responseCode === 'ok') {
          if (isEdit) {
            editField({ ...managingInput, ...field });
          } else {
            fetchCustomFields();
          }
          cancelEdit();
        } else {
          form.setFieldError('label', t('Error'));
        }
      } catch (e) {
        console.error(e);
      }
    },
  });

  const [handleDeleteInput] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.kinto-page',
    condition: async (_, event) =>
      await prompts.delete({
        submitBtnText: t('Discovery_map_delete'),
        cancelBtnText: t('cancel'),
        triggerRef: event.target as HTMLButtonElement,
        title: `${t('custom_fields_delete_message_1')} ${t(
          'custom_fields_delete_message_2'
        )}`,
      }),
    callback: async (fieldName: string) => {
      if (!communityId) return;

      const {
        data: { rc },
      } = await apiCustomFields.deleteField(communityId, fieldName);

      if (rc === 'ok') {
        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t('custom_fields_delete_success'),
          variant: 'success',
        });
      }

      cancelEdit();
      fetchCustomFields();
    },
  });

  return (
    <SettingsForm className="kinto-settings-form">
      <Form
        id="custom_info_form"
        onSubmit={saveField}
        initialData={initialData}
        className="custom-input-form"
        ref={formRef}
      >
        <fieldset>
          <legend data-hidden hidden>
            {t('custom_information')}
          </legend>
          <div className="form__fields">
            <FormField
              id="custom_info_new_input"
              name="label"
              type="text"
              component={InputField}
              placeholder={t('input_name')}
              required
              requiredLabel={t('global-input_field_required_label')}
              validate={validations.name}
              autoComplete="on"
            />
            <FormField
              id="custom_info_checkbox"
              name="mandatory"
              component={SwitchField}
              label={t('field_required')}
              aria-label={t('field_required')}
            />
          </div>

          <div className="custom-input-form__phone-preview">
            <div className="custom-input-form__phone-preview__label">
              {t('what_people_see')}
            </div>
            <IphoneFrame text={label} required={mandatory} />
          </div>

          <FormActions className="form__actions">
            <FormButtons.Submit
              id="submit_custom_info_new_input"
              disabled={!form?.isFormChanged() || !form?.syncFormValid}
            />
            <FormButtons.Cancel
              onClick={cancelEdit}
              id="cancel_custom_info_new_input"
            />
            {isEdit && (
              <Button
                id="delete_custom_info_input"
                type="button"
                icon={<Icon name="trash-can" />}
                variant="delete-ghost"
                iconPosition="right"
                onClick={async (event) =>
                  handleDeleteInput(managingInput?.name!, event)
                }
              >
                {t('delete_input')}
              </Button>
            )}
          </FormActions>
        </fieldset>
      </Form>
    </SettingsForm>
  );
};

export default CustomInputForm;
