import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const AssignedShift = styled.div`
  ${flex('row', 'space-between', 'center')};

  width: 100%;
  ${elevate_l}

  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  background-color: var(--BACKGROUND_1_1);

  .assigned-shift {
    &__title {
      ${flex('row')};
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

      margin: 0;
      font-weight: 600;
      gap: ${theme.sizes.SIZE_12};
      color: var(--SHADE_1_1);
    }

    &__data,
    &__custom-data {
      ${fontSize(theme.fontSizes.FONT_11, theme.sizes.SIZE_20)};

      margin: 0;
      font-weight: 400;
      color: var(--SHADE_1_3);

      span {
        color: var(--SHADE_1_2);
        margin-left: ${theme.sizes.SIZE_4};
      }
    }

    &__custom-data {
      color: var(--SHADE_1_2);
    }

    &__edit {
      ${size(theme.sizes.SIZE_48)};
    }
  }
`;
