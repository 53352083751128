import { FC, PropsWithChildren } from 'react';
import { Spinner, StatusElement, getColor } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components';
import { INameExtended } from 'components/Icon';
import { SurveyStatus, SURVEY_STATUS } from 'models';
import {
  SURVEY_STATUS_ELEMENT,
  SURVEY_STATUS_TRANSLATIONS,
} from 'pages/Settings/Sustainability/utils';

import * as Styles from './ReportCard.styles';

type ReportCardProps = PropsWithChildren<{
  className?: string;
  title: string;
  loading?: boolean;
  footerTitle?: string;
  icon: INameExtended;
  status?: any;
}>;

const ReportCard: FC<ReportCardProps> = (props) => {
  const {
    children,
    loading = false,
    className,
    title,
    footerTitle,
    icon,
    status,
  } = props;

  const { t } = useTranslation();

  return (
    <Styles.ReportCard className={className}>
      <div className="kinto-report-card__header">
        <div className="kinto-report-card__header__title">
          <Icon name={icon} />
          <div>{title}</div>
        </div>

        {status && (
          <StatusElement
            small
            className="kinto-report-card__status"
            status={SURVEY_STATUS_ELEMENT[status as SurveyStatus]}
          >
            <div>{t(SURVEY_STATUS_TRANSLATIONS[status as SurveyStatus])}</div>
            {status === SURVEY_STATUS.FINISHED && <Icon name="circle-check" />}
          </StatusElement>
        )}
      </div>

      {loading ? (
        <Spinner color={getColor('--PRIMARY_1_1')} size={30} />
      ) : (
        <>
          {children}

          {footerTitle && (
            <div className="kinto-report-card__footer">{footerTitle}</div>
          )}
        </>
      )}
    </Styles.ReportCard>
  );
};

export default ReportCard;
