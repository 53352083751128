import { FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';
import { FormProps, StepsContext } from '@faxi/web-form';

import SurveyFormFooter from '../SurveyFormFooter';
import { FormContainer } from '../../Survey.styles';
import { RouteStep, surveyFormSteps } from '../../routes';

export type SurveyFormWrapperProps = PropsWithChildren<{
  className?: string;
}> &
  Omit<
    FormProps<any> & {
      initialData?: {
        [key: string]: any;
      };
    },
    'onSubmit'
  >;

const SurveyFormWrapper: FC<SurveyFormWrapperProps> = (props) => {
  const { children, className, ...rest } = props;

  const { t } = useTranslation();
  const matches = useMatches();

  const { nextPage } = useContext(StepsContext);

  const { title, question } = useMemo(
    () =>
      surveyFormSteps.find((step) =>
        matches.find((match) => match.handle === step.handle)
      ) as RouteStep,
    [matches]
  );

  const handleSubmit = useCallback(
    async (data: Record<string, any>) => nextPage(data),
    [nextPage]
  );

  return (
    <FormContainer {...rest} className={className} onSubmit={handleSubmit}>
      <div className="kinto-survey-form-container__title">{t(title)}</div>
      <h1 className="kinto-survey-form-container__question">{t(question)}</h1>

      <div className="kinto-survey-form-container__container">{children}</div>
      <SurveyFormFooter />
    </FormContainer>
  );
};

export default SurveyFormWrapper;
