import { createContext, MutableRefObject } from 'react';
import { CustomField, CustomFieldTypes } from 'models';

export type CustomFieldsStatus = 'EDIT_FIELD' | 'ADD_FIELD' | 'LIST_VIEW';

type CustomFieldsContext = {
  fields: CustomField[];
  status: CustomFieldsStatus;
  managingInput: CustomField | null;
  lastFocusEditId?: React.MutableRefObject<string>;
  addNewField(field: CustomField): void;
  fetchCustomFields(): void;
  startCreateNewField(fieldType: CustomFieldTypes): void;
  startEditField(fieldId: string): void;
  editField(field: CustomField): void;
  cancelEdit(): void;
};

export default createContext<CustomFieldsContext>({
  fields: [],
  status: 'LIST_VIEW',
  managingInput: null,
  lastFocusEditId: {} as MutableRefObject<string>,
  addNewField: () => {},
  fetchCustomFields: () => {},
  startCreateNewField: () => {},
  startEditField: () => {},
  editField: () => {},
  cancelEdit: () => {},
});
