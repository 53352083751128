import { Modal, theme } from '@faxi/web-component-library';
import { fontSize, pxToRem } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const CalculatedModal = styled(Modal)`
  .wcl-modal {
    width: ${pxToRem(theme.sizes.SIZE_664)};

    &__header__title {
      text-transform: capitalize;
    }
  }

  .calculated-body-emission,
  .calculated-estimation {
    ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

    margin: 0;
    color: var(--SHADE_1_2);
    max-width: ${pxToRem(theme.sizes.SIZE_504)};
  }

  .calculated-body-emission {
    margin: 0 0 ${theme.sizes.SIZE_24};
  }
`;
