import { FC, PropsWithChildren, useCallback, useState } from 'react';
import MessagesContext from './Messages.context';
import { apiMessages } from 'modules';
import { Message, MessageUser } from 'models';

const MessagesProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const [groupMessage, setGroupMessage] = useState<Message>();
  const [receiver, setReceiver] = useState<MessageUser>();

  const sendMessage = useCallback(
    async (
      from: string,
      to: string,
      text: string,
      oid: number,
      type: 'A' | 'U'
    ) => {
      const formData = new FormData();
      // TODO: subject and what params should not be hardcoded
      formData.append('from', from);
      formData.append('to', to);
      formData.append('text', text);
      formData.append('oid', `${oid}`);
      formData.append('type', type);
      formData.append(
        'link',
        `${window.location.origin}/message.php?recuid=${to}&seluid=${from}&oid=${oid}&selmid=%%MID%%`
      );

      formData.append('subject', 'You have revieved a new message');
      formData.append('what', 'MsgNotif');

      return await apiMessages.sendMessage(formData);
    },
    []
  );

  const markMessageAsRead = async (from: string, to: string) => {
    const formData = new FormData();

    formData.append('readto', to);
    formData.append('readfrom', from);

    return await apiMessages.markMessageAsRead(formData);
  };

  return (
    <MessagesContext.Provider
      value={{
        groupMessage,
        receiver,
        setReceiver,
        setGroupMessage,
        sendMessage,
        markMessageAsRead,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesProvider;
