import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const ClipboardElement = styled.div`
  ${flex('row')};
  gap: ${theme.sizes.SIZE_12};

  .kinto-clipboard-element {
    &__content {
      ${flex('row', 'center', 'center')};
      width: 100%;
      padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_10};
      border-radius: ${theme.sizes.SIZE_8};
      background-color: var(--BACKGROUND_2_1);
      cursor: pointer;

      &__edit-button {
        ${size(theme.sizes.SIZE_32)};
        min-height: unset;
        margin-left: auto;
      }

      &__invite {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        font-weight: 600;
        text-align: start;
        word-break: break-all;
        color: var(--SHADE_1_1);
        margin-right: ${theme.sizes.SIZE_10};
        width: 100%;

        &__spinner {
          margin: auto;
        }
      }
    }

    &__copy {
      height: fit-content;
      margin: auto;
    }
  }
`;
