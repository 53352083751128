import Pages from 'pages';
import { Navigate } from 'react-router-dom';
import { ExtendRouteObject } from 'router/routes';
import { MessagesProvider, ReportsProvider } from 'store';
import { peopleRoutes } from './subrouters/People';
import { settingsRoutes } from './subrouters/CommunitySettings';
import { sustainabilityRoutes } from './subrouters/Sustainability';
import { gamificationRoutes } from './subrouters/Gamification';
import { messagesRoutes } from './subrouters/Messages';
import {
  EventsTab,
  GamificationRouter,
  SustainabilityRouter,
} from 'pages/Settings';
import { appUri } from 'config';
import GuardedPremiumRoute from './GuardedPremiumRoute';

export default [
  {
    handle: 'Dashboard',
    path: appUri.COMMUNITY_DASHBOARD,
    title: 'Dashboard',
    element: (
      <ReportsProvider>
        <Pages.Dashboard />
      </ReportsProvider>
    ),
  },
  {
    handle: 'People',
    path: appUri.COMMUNITY_PEOPLE,
    children: peopleRoutes,
  },
  {
    handle: 'Bookings',
    path: appUri.COMMUNITY_BOOKINGS,
    element: (
      <GuardedPremiumRoute
        element={<Pages.Bookings />}
        permission="booking_client_admin"
      />
    ),
  },
  {
    handle: 'Reports',
    path: appUri.COMMUNITY_REPORTS,
    element: (
      <ReportsProvider>
        <Pages.Reports />
      </ReportsProvider>
    ),
  },
  {
    handle: 'Leaderboards',
    path: appUri.COMMUNITY_LEADERBOARDS,
    element: <Pages.Leaderboards />,
  },
  {
    handle: 'Map',
    path: appUri.MAP,
    element: (
      <GuardedPremiumRoute
        element={<Pages.Map />}
        permission="map_client_admin"
      />
    ),
  },
  {
    handle: 'Messages',
    path: appUri.COMMUNITY_MESSAGES,
    element: (
      <MessagesProvider>
        <Pages.Messages />
      </MessagesProvider>
    ),
    children: messagesRoutes,
  },
  {
    handle: 'Events',
    path: appUri.EVENTS,
    element: (
      <GuardedPremiumRoute element={<EventsTab />} permission="event_feature" />
    ),
  },
  {
    handle: 'Gamification',
    path: appUri.GAMIFICATION,
    element: (
      <GuardedPremiumRoute
        element={<GamificationRouter />}
        permission="gamification_feature"
      />
    ),
    children: gamificationRoutes,
  },
  {
    handle: 'Sustainability',
    path: appUri.SUSTAINABILITY,
    element: <SustainabilityRouter />,
    children: sustainabilityRoutes,
  },
  {
    handle: 'Settings',
    path: appUri.COMMUNITY_SETTINGS,
    children: settingsRoutes,
  },
  {
    index: true,
    element: <Navigate to={appUri.COMMUNITY_DASHBOARD} replace />,
  },
  {
    path: '*',
    element: <Navigate to={appUri.COMMUNITY_DASHBOARD} replace />,
  },
] as ExtendRouteObject[];
