import styled, { css } from 'styled-components';
import {
  flex,
  marginChildren,
  phablet,
  pxToRem,
} from '@faxi/web-css-utilities';
import { elevate_l, theme } from '@faxi/web-component-library';

export const Container = styled.div`
  height: 100%;
  padding: ${theme.sizes.SIZE_32};
  background-color: var(--SHADE_1_9);
  align-items: 'center';

  ${phablet(css`
    padding: ${`${theme.sizes.SIZE_20} ${theme.sizes.SIZE_16}`};
  `)};

  .permissions-page {
    &__breadcrumbs {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__row {
      ${flex('column')};

      border-radius: 8px;
      margin-bottom: ${theme.sizes.SIZE_46};
      background-color: var(--BACKGROUND_1_1);
      max-width: ${pxToRem(theme.sizes.SIZE_1064)};
      padding: ${`${theme.sizes.SIZE_32} ${theme.sizes.SIZE_56} ${theme.sizes.SIZE_56}`};

      ${phablet(css`
        ${flex('column', 'flex-start', 'flex-start')};
        width: 100%;
        height: fit-content;
        padding: ${theme.sizes.SIZE_40};
        margin-bottom: ${theme.sizes.SIZE_32};
      `)};

      &__name {
        margin-left: ${theme.sizes.SIZE_32};
        margin-right: ${theme.sizes.SIZE_164};

        ${phablet(css`
          margin: 0;
          margin-bottom: ${theme.sizes.SIZE_40};
        `)};
      }

      &__data {
        margin-right: ${theme.sizes.SIZE_40};

        ${phablet(css`
          margin-bottom: ${theme.sizes.SIZE_24};
        `)};
      }

      .data-point--row {
        grid-template-columns: 10% auto;
        margin: ${theme.sizes.SIZE_10} 0;

        ${phablet(css`
          grid-template-columns: 1fr;
        `)}
      }

      hr {
        margin: 10px 0;
      }

      &__status {
        margin-bottom: ${theme.sizes.SIZE_10};
        .data-point__label {
          margin-bottom: ${theme.sizes.SIZE_10};
        }
      }
    }

    &__image {
      ${elevate_l};

      ${phablet(css`
        margin-bottom: ${theme.sizes.SIZE_24};
      `)};
    }

    &__action-button {
      margin-top: ${theme.sizes.SIZE_20};
      justify-content: left;
    }

    &__action-buttons {
      ${marginChildren(`0 ${theme.sizes.SIZE_32} 0 0`)};
      margin-top: ${theme.sizes.SIZE_20};
      ${flex('row')};

      &__button {
        &--reject {
          > * {
            color: var(--ALERT_ERROR_1_1);
            &:hover {
              color: var(--ALERT_ERROR_1_2);
            }
          }
        }
      }
    }

    &__role {
      ${flex('row', 'space-between', 'center')};
      width: 100%;

      &--disabled {
        opacity: 0.5;
      }

      &__text {
        color: var(--SHADE_1_2);
        margin: 0 auto 0 0;
      }

      &__toggle {
        align-items: flex-end;
      }

      &__button {
        justify-content: start;
      }
    }
  }
`;
