import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useUtilities } from '@faxi/web-component-library';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { UserContext } from 'store';

const useGenerateXLSX = (
  sheetName: string,
  apiRequest: () => Promise<string[][] | undefined>,
  columnWidths: { wch: number }[]
) => {
  const {
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  const { showOverlay, hideOverlay, showSnackBar, hideSnackBar } =
    useUtilities();

  const { t } = useTranslation();

  const stringToArrayBuffer = useCallback((s: string) => {
    const buffer = new ArrayBuffer(s.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buffer;
  }, []);

  const generateXLSX = useCallback(async () => {
    showOverlay('body', 'fixed');

    const id = showSnackBar(
      {
        text: t('waiting_download'),
        variant: 'loading',
        loadingText: t('downloading'),
      },
      { constant: true }
    );

    const data = await apiRequest();

    if (!data) {
      return;
    }

    const book = XLSX.utils.book_new();

    book.Props = {
      Title: sheetName,
      Author: 'Kinto',
      CreatedDate: new Date(),
    };

    book.SheetNames.push(sheetName);

    const sheet = XLSX.utils.aoa_to_sheet(data);

    sheet['!cols'] = columnWidths;

    book.Sheets[sheetName] = sheet;

    const bookout = XLSX.write(book, { bookType: 'xlsx', type: 'binary' });

    saveAs(
      new Blob([stringToArrayBuffer(bookout)], {
        type: 'application/octet-stream',
      }),
      `${sheetName}_${dayjs().format(dateFormat)}.xlsx`
    );

    hideSnackBar(id);
    hideOverlay('body');
  }, [
    apiRequest,
    columnWidths,
    dateFormat,
    hideOverlay,
    hideSnackBar,
    sheetName,
    showOverlay,
    showSnackBar,
    stringToArrayBuffer,
    t,
  ]);

  return generateXLSX;
};

export default useGenerateXLSX;
