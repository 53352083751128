import styled, { css } from 'styled-components';
import { flex, phablet, pxToRem } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const DisabledCommunityStyled = styled.div`
  padding: ${theme.sizes.SIZE_96} ${theme.sizes.SIZE_144};

  h1 {
    color: var(--SECONDARY_1_1);
    margin-bottom: ${theme.sizes.SIZE_12};
    font-size: ${theme.fontSizes.FONT_30};
  }

  .disabled-community {
    &__help-txt {
      color: var(--SHADE_1_2);
      font-size: ${theme.fontSizes.FONT_16};
      font-weight: 400;
      margin-bottom: ${theme.sizes.SIZE_40};
      width: ${pxToRem(theme.sizes.SIZE_374)};
    }
  }

  ${phablet(css`
    ${flex('column', 'center', 'center')};

    padding: ${`${theme.sizes.SIZE_46} ${theme.sizes.SIZE_16}`};
    text-align: center;
    height: 100%;

    .disabled-community {
      &__help-txt {
        width: 100%;
      }
    }
  `)};
`;
