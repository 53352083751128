import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  Outlet,
  matchRoutes,
  useLocation,
  useParams,
} from 'react-router-dom';
import classNames from 'classnames';
import { Image } from '@faxi/web-component-library';

import { appUri } from '../../config';
import SurveyContext from './providers/Survey/Survey.context';
import LanguageSelector from '../../components/_molecules/LanguageSelector';

import * as Styled from './Survey.styles';

const Survey: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { activeSurvey } = useContext(SurveyContext);
  const { surveySlug } = useParams() as { surveySlug: string };

  const surveyStartedOrEnded = useMemo(
    () =>
      location.pathname.includes('start') ||
      location.pathname.includes('your-emissions'),
    [location]
  );

  const exactRouteMatch = useMemo(
    () =>
      matchRoutes(
        [
          { path: `/survey/${surveySlug}` },
          { path: `/survey/${surveySlug}/${appUri.SURVEY_FINISHED}` },
          { path: `/survey/${surveySlug}/${appUri.SURVEY_SCHEDULED}` },
          { path: `/survey/${surveySlug}/${appUri.SURVEY_ALREADY_COMPLETED}` },
        ],
        location
      ),
    [surveySlug, location]
  );

  return (
    <Styled.Container
      className={classNames('kinto-survey', {
        'kinto-survey--started-or-ended': surveyStartedOrEnded,
      })}
    >
      <header
        className={classNames('kinto-survey__header', {
          'kinto-survey__header--started-or-ended': surveyStartedOrEnded,
        })}
      >
        <div className="kinto-survey__header__content">
          <NavLink className="wcl-sidebar__header__logo" to="/">
            <Image
              src="/assets/svg/kinto_join_inline.svg"
              alt={t('app_name')}
            />
          </NavLink>

          <LanguageSelector />
        </div>

        {!exactRouteMatch && (
          <p className="kinto-survey__header__title">{activeSurvey?.name}</p>
        )}
      </header>

      <Outlet />
    </Styled.Container>
  );
};

export default Survey;
