import styled, { css } from 'styled-components';
import {
  flex,
  fontSize,
  phablet,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export type StyledCarParkProps = {
  activePercentage?: number;
};

export const CarParkOccupancy = styled.div`
  display: flex;
  align-items: flex-end;

  &.kinto-car-park-occupancy {
    margin-bottom: ${theme.sizes.SIZE_20};
  }

  .kinto-car-park-occupancy {
    &__name {
      flex: 30%;
      ${fontSize(theme.fontSizes.FONT_14)};
      color: var(--SECONDARY_1_1);
      margin-right: ${theme.sizes.SIZE_20};
    }
    &__bar-chart {
      display: flex;
      flex-direction: column;
      flex: 70%;
    }
    &__numbers {
      display: flex;
      margin-bottom: ${theme.sizes.SIZE_4};
      align-items: center;
      div {
        margin-left: ${theme.sizes.SIZE_12};
        color: var(--PRIMARY_1_1);
        font-weight: 500;
      }
    }
    &__bar {
      height: ${pxToRem(theme.sizes.SIZE_8)};
      background-color: var(--SHADE_1_7);
      border-radius: ${theme.sizes.SIZE_8};
    }
    &__bar-progress {
      background-color: var(--ACCENT_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      width: ${(props: StyledCarParkProps) =>
        `${props.activePercentage}%` || '0%'};
      height: ${pxToRem(theme.sizes.SIZE_8)};
    }
  }
`;

export const CarParkLegend = styled.div`
  ${fontSize(theme.fontSizes.FONT_14)};
  color: var(--SHADE_1_1);
  display: flex;
  ${phablet(css`
    flex-direction: column;
  `)};
  .kinto-car-park-legend {
    &__occupied,
    &__available {
      display: flex;
      align-items: center;
      &::before {
        ${size(theme.sizes.SIZE_8)};
        content: '';
        display: block;
        border-radius: 50%;
        margin-right: ${theme.sizes.SIZE_10};
      }
    }
    &__occupied {
      margin-right: ${theme.sizes.SIZE_20};
      &::before {
        background-color: var(--ACCENT_1_1);
      }
    }
    &__available {
      &::before {
        background-color: var(--SHADE_1_7);
      }
    }
  }
`;

export const CarParks = styled.div`
  height: 100%;
  overflow: hidden;
  .kinto-car-parks {
    &__container {
      height: 100%;
      overflow: auto;
    }
  }
`;

export const InTheCarPark = styled.div`
  flex: 1;
  padding-top: ${theme.sizes.SIZE_24};
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  .in-the-car-park {
    &__placeholder {
      ${flex('row', 'center', 'center')};
      color: var(--SHADE_1_2);
      font-weight: 500;
      flex: 1 0 50%;
    }
    &__footer {
      ${flex('row', 'space-between')};
      padding: ${theme.sizes.SIZE_8};
    }
  }
`;
