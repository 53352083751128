import { theme } from '@faxi/web-component-library';
import { fontSize, phablet, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const UserLayout = styled.div`
  ${size('100%')}
  padding: ${theme.sizes.SIZE_48} ${theme.sizes.SIZE_56};

  .kinto-user-layout {
    &__heading {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      margin: 0;
      font-wight: 400;
      max-width: ${theme.sizes.SIZE_320};
      word-break: break-word;
    }
  }

  ${phablet(css`
    padding: ${theme.sizes.SIZE_24};
  `)}
`;
