import { MessagesBoard } from 'components';
import NoConversationSelected from 'pages/Messages/NoConversationSelected.component';
import { ExtendRouteObject } from 'router/routes';
import { appUri } from '../../../../../config';

export default [
  {
    path: '',
    element: <NoConversationSelected />,
  },
  {
    path: appUri.COMMUNITY_MESSAGES_USER,
    element: <MessagesBoard />,
  },
  {
    index: true,
    element: <NoConversationSelected />,
  },
] as ExtendRouteObject[];
