import { createContext, Dispatch, SetStateAction } from 'react';
import { Method } from 'axios';
import { ConsentsPayload, PREMIUM_FEATURES } from 'models';
import { Community, User, UserPreferences } from 'models';

export interface KeyValue {
  [key: string]: string;
}
interface UserContext {
  user?: User;
  communities: Community[];
  community?: Community;
  userReady: boolean;
  userPreferences: UserPreferences;
  includeTestUsers: boolean;
  isValidCommunity: boolean;
  communityUsersExist: boolean;
  isAdminAtLeastOnce: boolean;
  adminForCommunity: boolean;
  onboardingStep: number;
  setOnboardingStep: Dispatch<SetStateAction<number>>;
  updateUserPreferences: (key: string, value: string) => Promise<any>;
  addOrganisation: (data: Community) => void;
  setUser: Dispatch<SetStateAction<User | undefined>>;

  getUser: (userId?: string, key?: string) => Promise<any>;
  updateUser: (userId: string, data: object, method?: Method) => Promise<any>;
  updateTestUserInOrganisation: (oid: number, includeTestUser: boolean) => void;
  updateUserAdminPermission: (oid: number, isAdmin: boolean) => void;
  updateCommunity: (
    oid: number,
    organisation: object,
    method?: Method
  ) => Promise<any>;
  updateCommunityPendingRequests: (
    oid: number,
    newPendingRequests: number
  ) => void;
  getConsents: () => Promise<ConsentsPayload>;

  communityId?: number;
  setCommunityId: (id: number | undefined) => void;
  navigationItemRoute: (urlFunction: (id: string) => string) => string;
  premiumFeatureAvailable: (feature: keyof typeof PREMIUM_FEATURES) => boolean;
}

export const userDefaultValue: UserContext = {
  isValidCommunity: true,
  communities: [],
  community: undefined,
  user: undefined,
  userReady: false,
  userPreferences: { dateFormat: '', unit: 'km' },
  communityUsersExist: false,
  includeTestUsers: false,
  isAdminAtLeastOnce: false,
  adminForCommunity: false,
  onboardingStep: 0,
  setOnboardingStep: () => {},
  setUser: () => {},
  addOrganisation: () => {},
  getConsents: () => new Promise(() => {}),
  getUser: () => new Promise(() => {}),
  updateCommunity: () => new Promise(() => {}),
  updateCommunityPendingRequests: () => {},
  updateUser: () => new Promise(() => {}),
  updateTestUserInOrganisation: () => {},
  updateUserAdminPermission: () => {},
  updateUserPreferences: () => new Promise(() => {}),
  communityId: undefined,
  setCommunityId: () => {},
  navigationItemRoute: () => '',
  premiumFeatureAvailable: () => false,
};

const UserContext = createContext<UserContext>(userDefaultValue);

export default UserContext;
