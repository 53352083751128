import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize } from '@faxi/web-css-utilities';
import { NoData } from 'Global.styles';
import styled from 'styled-components';

export const PickShift = styled.div`
  ${elevate_l};

  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  background-color: var(--BACKGROUND_1_1);

  .pick-shift {
    &__title {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      font-weight: 600;
      color: var(--SHADE_1_1);
      margin: 0 0 ${theme.sizes.SIZE_24};
    }

    &__shifts {
      ${flex('column')};
      ${flexGap(theme.sizes.SIZE_10, 'column')};
    }
  }
`;

export const PickShiftNoData = styled(NoData)`
  margin: 0;
`;
