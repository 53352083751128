import { FC, PropsWithChildren } from 'react';
import { FieldProps } from '@faxi/web-form';
import { TagsInput } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

type TagsInputComponent = typeof TagsInput;
type ExtractPropsTypes<Type> = Type extends FC<infer P> ? P : never;
type TagsInputProps = ExtractPropsTypes<TagsInputComponent>;

type ValueOnChange = (event: string[]) => void;

type TagsFieldProps = FieldProps<string[], ValueOnChange> & TagsInputProps;

const TagsField: FC<PropsWithChildren<TagsFieldProps>> = (props) => {
  const {
    value,
    error,
    dirty,
    children,
    touched,
    onChange,
    onBlur,
    id,
    ...rest
  } = props;

  const { t } = useTranslation();

  if (!value) return null;

  return (
    <TagsInput
      id={id}
      {...rest}
      initialTags={value as string[]}
      onChange={onChange}
      removeTagAriaLabel={(name) => t('accessibility-button_delete', { name })}
    />
  );
};

export default TagsField;
