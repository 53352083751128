import {
  SurveyReport,
  Sustainability,
} from 'pages/Settings/Sustainability/pages';
import { ExtendRouteObject } from 'router/routes';
import { appUri } from '../../../../../config';

export default [
  {
    path: '',
    element: <Sustainability />,
  },
  {
    path: appUri.SUSTAINABILITY_SURVEY,
    element: <SurveyReport />,
  },
] as ExtendRouteObject[];
