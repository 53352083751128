import { FC } from 'react';
import { Button, Heading } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as Styled from './DisabledCommunity.styles';
import { appUri } from '../../../config';

/*
  This component will be used when we finish register/login flow completely,
  when social register will be available through web app
***/
const DisabledCommunity: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Styled.DisabledCommunityStyled>
      <Heading level="1">
        {t('community_disabled-empty_state_title_you_cant_log_in')}
      </Heading>
      <div className="disabled-community__help-txt">
        {t('community_disabled-empty_state_subtitle_you_cant_log_in')}
      </div>

      <Button variant="outline" onClick={() => navigate(appUri.LOGIN)}>
        {t('login_back')}
      </Button>
    </Styled.DisabledCommunityStyled>
  );
};
export default DisabledCommunity;
