import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export default function useLatestState<T>(value: T) {
  const [latestValue, setLatestValue] = useState<T>(value);

  useEffect(() => {
    if (!value) return;

    setLatestValue(value);
  }, [value]);

  return [latestValue, setLatestValue] as [T, Dispatch<SetStateAction<T>>];
}
