import { theme } from '@faxi/web-component-library';
import {
  flex,
  marginChildren,
  padding,
  aboveTablet,
  phablet,
  tablet,
} from '@faxi/web-css-utilities';

import styled, { css } from 'styled-components';

export const PreapprovedSettingsContainer = styled.div`
  ${marginChildren(`0 0 ${theme.sizes.SIZE_32} 0`)};

  > :last-child {
    justify-content: flex-start;

    @media (max-width: 1024px) {
      button {
        max-width: unset;
      }
    }

    ${tablet(css`
      margin-top: ${theme.sizes.SIZE_72};
    `)}
  }

  .tags-error {
    ${aboveTablet(css`
      margin-left: ${theme.sizes.SIZE_48};
    `)};
    ${phablet(css`
      margin-left: ${theme.sizes.SIZE_20};
    `)};
  }

  .tags-input {
    ${padding(
      `${theme.sizes.SIZE_8}`,
      `${theme.sizes.SIZE_20}`,
      `${theme.sizes.SIZE_8}`,
      `${theme.sizes.SIZE_48}`
    )};

    &__entry {
      border-radius: 8px;
      padding: ${theme.sizes.SIZE_10};

      > span {
        /* this line is in addition of imperfect font */
        margin-top: -0.25rem;
      }
    }

    &__wrapper {
      padding: 0;
    }

    &__placeholder {
      margin-left: 0;

      &::first-letter {
        text-transform: capitalize;
      }
    }
    ${phablet(css`
      ${padding(`${theme.sizes.SIZE_8}`, `${theme.sizes.SIZE_20}`)};
    `)};
  }
`;

export const ButtonContainer = styled.div`
  ${flex('row')};
  ${marginChildren(`0 ${theme.sizes.SIZE_72} 0 0`)};

  ${phablet(css`
    flex-direction: column;
    ${marginChildren(`0 0 ${theme.sizes.SIZE_24} 0`)};

    button {
      width: 100%;
    }
  `)};
`;
