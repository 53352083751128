import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useUtilities } from '@faxi/web-component-library';

import { apiAuth } from 'modules';
import { AuthContext } from 'store';
import { useLoginRedirections } from 'hooks';
import { appUri } from '../../../config';

const MicrosoftRedirection = () => {
  const { instance } = useMsal();
  const { showOverlay, hideOverlay } = useUtilities();
  const { applyCredentials } = useContext(AuthContext);
  const navigate = useNavigate();

  useLoginRedirections();

  useEffect(() => {
    showOverlay('#root');

    const authType = localStorage.getItem('signup');
    const platformId = localStorage.getItem('platformId');

    const callbackId = instance.addEventCallback(async (event: any) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        try {
          if (authType) {
            const authData = await apiAuth.socialRegister(
              'microsoft',
              event?.payload?.idToken,
              event?.payload?.account?.name,
              '',
              platformId!
            );

            applyCredentials(authData);

            if (authData.error) {
              navigate(`/${appUri.LOGIN}`);
            }
          } else {
            const authData = await apiAuth.socialLogin(
              'microsoft',
              event?.payload?.idToken
            );

            applyCredentials(authData);

            if (authData.error) {
              navigate(`/${appUri.LOGIN}`);
            }
          }
        } catch (e) {
          console.error(e);
        } finally {
          hideOverlay('#root');
          localStorage.removeItem('platformId');
          localStorage.removeItem('signupType');
        }
      } else if (
        event.eventType === EventType.LOGIN_FAILURE ||
        event.eventType === EventType.ACQUIRE_TOKEN_FAILURE
      ) {
        navigate(`/${appUri.LOGIN}`);
      }
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
        hideOverlay('#root');
      }
    };
  }, [applyCredentials, hideOverlay, navigate, instance, showOverlay]);

  return <></>;
};

export default MicrosoftRedirection;
