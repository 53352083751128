import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const SeparatedText = styled.div`
  ${flex('row', 'space-between', 'center')};
  ${fontSize(theme.fontSizes.FONT_16)};

  margin: 0;
  width: 100%;
  font-weight: 400;
  color: var(--SHADE_1_2);

  &.kinto-separated-text {
    &--bold-second {
      > div:last-of-type {
        ${fontSize(theme.fontSizes.FONT_20, theme.sizes.SIZE_32)};

        font-weight: 600;
        color: var(--PRIMARY_1_1);
      }
    }
  }
`;
