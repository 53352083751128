import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import * as Styled from './DataPoint.styles';

export type DataPointProps = {
  label?: string;
  value?: string | number | ReactNode;
  className?: string;
  rowOrder?: boolean;
};

const DataPoint: FC<DataPointProps> = (props: DataPointProps): JSX.Element => {
  const { label, value, className, rowOrder } = props;

  return (
    <Styled.Container
      className={classNames('data-point', className, {
        'data-point--row': rowOrder,
      })}
    >
      <div className="data-point__label">{label}</div>
      <div className="data-point__value">{value}</div>
    </Styled.Container>
  );
};

export default DataPoint;
