import styled from 'styled-components';
import { fontSize, pxToRem } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const Container = styled.div`
  width: 100%;
  position: relative;

  > label {
    ${fontSize(theme.fontSizes.FONT_16)}

    color: var(--SHADE_1_2);
    display: flex;
    align-items: flex-end;
    margin-bottom: ${theme.sizes.SIZE_4};
    height: ${pxToRem(theme.sizes.SIZE_24)};
  }

  .kinto-password-field {
    &__eye {
      cursor: pointer;
    }

    &__caps-lock-active {
      margin-top: ${theme.sizes.SIZE_8};
      ${fontSize(theme.fontSizes.FONT_12)};
      color: var(--LABEL_TEXT_DARK_1_1);
      animation: slideDown 300ms;
      word-break: normal;
      overflow-wrap: anywhere;
    }
  }
`;
