import { Modal, theme } from '@faxi/web-component-library';
import { flex, flexGap } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const ImageCropModal = styled(Modal)`
  .wcl-modal {
    width: clamp(
      ${theme.sizes.SIZE_296},
      ${theme.sizes.SIZE_712},
      ${theme.sizes.SIZE_712}
    );
  }

  .kinto-image-crop-modal {
    &__preview-actions {
      ${flex('row')};
      ${flexGap(theme.sizes.SIZE_56, 'row')};

      margin-top: ${theme.sizes.SIZE_64};
    }
  }
`;
