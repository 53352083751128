import dayjs from 'dayjs';
import i18next from 'i18next';
import { ChartData, ChartDataWithTest } from '../models';

export const formatChartData = (
  data: Record<string, number | Record<string, number>>,
  extractDeepProp?: string,
  toLocale = true
) => {
  const finalData: Record<string, number> = {};

  Object.entries(data).forEach(([key, value]) => {
    const date = new Date(key);

    const newKey = `${dayjs(date).format('DD')} ${i18next.t(
      `global-month_${dayjs(date).format('MMM').toLowerCase()}`
    )} '${date.getFullYear().toString().slice(2, 4)}`;

    if (!extractDeepProp) {
      finalData[toLocale ? newKey : key] = value as number;
    } else {
      finalData[toLocale ? newKey : key] = (value as Record<string, number>)[
        extractDeepProp
      ];
    }
  });

  return finalData;
};

export const setKpiDataState = (
  data: Record<string, Record<string, number>>,
  propName: string,
  includeTestUsers: boolean
) => {
  return {
    chartData: formatChartData(data, propName),
    ...(includeTestUsers && {
      testChartData: formatChartData(data, `${propName}-test`),
    }),
    exportData: formatChartData(data, propName, false),
    ...(includeTestUsers && {
      testExportData: formatChartData(data, `${propName}-test`, false),
    }),
  };
};

export const xlsxLineSeparator = (array: string[][]) => {
  array.push([]);
  array.push([]);
};

export const xlsxAddValue = (
  data: ChartData,
  array: string[][],
  dateFormat: string
) => {
  Object.entries(data).forEach(([key, value]) => {
    array.push([dayjs(key).format(dateFormat), `${value}`]);
  });
  xlsxLineSeparator(array);
};

type AddChartValueInXlsx = {
  data: ChartDataWithTest;
  array: string[][];
  includeTestUsers: boolean;
  dateFormat: string;
  title: string[];
  columns: string[];
  testTitle: string[];
  testColumns: string[];
};

export const addChartValueInXlsx = ({
  data,
  array,
  includeTestUsers,
  dateFormat,
  title,
  columns,
  testTitle,
  testColumns,
}: AddChartValueInXlsx) => {
  array.push(title);
  array.push(columns);

  xlsxAddValue(data.exportData, array, dateFormat);

  if (includeTestUsers && data.testExportData) {
    array.push(testTitle);
    array.push(testColumns);

    xlsxAddValue(data.testExportData, array, dateFormat);
  }
};
