import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAbortController, useCallbackAsync, useEffectOnceWhen } from 'hooks';
import {
  Community,
  NotificationPreferences,
  PerCommunity as NotificationsPerCommunity,
} from 'models';
import { apiUser } from 'modules';
import { UserContext } from 'store';
import SwitchCommunityEmail from './components/SwitchCommunityEmail';
import { snackBarErrorMessage, snackBarSuccessMessage } from 'utils';

import * as Styled from './EmailNotificationsTab.styles';
import { PageLayout } from 'components/_layouts';
import { Heading, getColor } from '@faxi/web-component-library';
import classNames from 'classnames';

const getNotificationValue = (
  community: Community,
  notifications: NotificationPreferences,
  type: 'join_request'
) => {
  if (!notifications?.per_community) {
    return 'Y';
  }

  if (notifications?.per_community)
    return notifications.per_community[community.id]?.[type] || 'Y';
};

const EmailNotificationsTab: FC = () => {
  const { t } = useTranslation();
  const { user, communities } = useContext(UserContext);

  const [notifications, setNotifications] = useState<NotificationPreferences>();

  const prevNotifications = useRef<NotificationPreferences>();

  const { abortSignal, cancelPreviousRequest } = useAbortController();

  const [loadNotifications] = useCallbackAsync({
    showSpinner: true,
    callback: async () => {
      const { notification_preferences } =
        await apiUser.getUserNotificationPreferences(user!.id);

      setNotifications(notification_preferences);
    },
  });

  const [handleOnSwitch] = useCallbackAsync({
    showSpinner: false,
    catchAsyncErrors: false,
    callback: async (
      community: Community,
      value: 'Y' | 'N',
      type: keyof NotificationsPerCommunity
    ) => {
      cancelPreviousRequest();

      const formData = new FormData();
      const old = notifications?.per_community?.[community.id] ?? {};
      const notification = { [community.id]: { ...old, [type]: value } };

      formData.append('per_community', JSON.stringify(notification));

      setNotifications(
        (old) =>
          ({
            ...old,
            per_community: { ...old?.per_community, ...notification },
          } as NotificationPreferences)
      );

      await apiUser.setUserNotificationPreferences(user?.id!, formData, {
        signal: abortSignal(),
      });

      snackBarSuccessMessage(t('changes_saved'));
    },
    onError: (e) => {
      if (e.code === 'ERR_CANCELED') return;

      setNotifications(prevNotifications.current);
      snackBarErrorMessage(t('something_went_wrong'));
    },
  });

  const finalCommunities = useMemo(
    () =>
      notifications &&
      communities
        .reduce(
          (old: Community[], c: Community) => [
            ...old,
            {
              ...c,
              ...user?.organisations?.find(({ id: oId }) => +oId === c.id),
            },
          ],
          []
        )
        .map(
          (comm) =>
            ({
              ...comm,
              join_request: getNotificationValue(
                comm,
                notifications,
                'join_request'
              ),
            } as Community)
        ),
    [communities, notifications, user]
  );

  const handleJoinRequestSwitch = useCallback(
    (community: Community, value: 'Y' | 'N') => {
      prevNotifications.current = notifications;
      handleOnSwitch(community, value, 'join_request');
    },
    [notifications, handleOnSwitch]
  );

  useEffectOnceWhen(() => {
    loadNotifications();
  }, !!user);

  return (
    <PageLayout className={classNames('kinto-page', 'email-notifications')}>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('settings_notification')}
      </Heading>
      <Styled.EmailNotifications>
        {finalCommunities?.map((community) => (
          <SwitchCommunityEmail
            key={community.id}
            community={community}
            onJoinRequest={handleJoinRequestSwitch}
          />
        ))}
      </Styled.EmailNotifications>
    </PageLayout>
  );
};

export default EmailNotificationsTab;
