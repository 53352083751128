import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Leaderboards = styled.div`
  .leaderboards {
    &__breadcrumbs {
      margin-bottom: ${theme.sizes.SIZE_24};
    }

    &__tabs {
      margin-bottom: ${theme.sizes.SIZE_20};
    }

    &__filters {
      ${flex('row', undefined, 'center')};
      gap: ${theme.sizes.SIZE_16};
      margin-bottom: ${theme.sizes.SIZE_12};

      button {
        margin-top: 0;
      }
    }

    &__date-picker {
      margin-left: auto;
      margin-bottom: ${theme.sizes.SIZE_12};
      width: fit-content;
    }
  }
`;
