import { FC } from 'react';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from 'react-router-dom';
import { Role } from '../../models/User';
import routes, { ExtendRouteObject } from '../routes';
import storageService, { STORAGE_KEYS } from 'services/storageService';

const rolePermission = (userRoles: Role[], routeRoles: Role[]) => {
  return userRoles.some((role) => routeRoles.includes(role));
};

const protectRoutes = (routes: ExtendRouteObject[]): RouteObject[] => {
  return routes.map(
    ({
      children,
      fallbackUrl = '/login',
      authorized = false,
      title,
      onlyPublic,
      roles,
      loader: routeLoader,
      ...route
    }) =>
      ({
        ...route,
        children: children
          ? protectRoutes(children as ExtendRouteObject[])
          : null,
        loader: (args) => {
          const isLoggedIn = !!storageService.getItem(STORAGE_KEYS.SID);
          const userRoles = storageService.getItem(STORAGE_KEYS.ROLE) as Role[];

          //throw - conceptual similar to server returning 400
          //level error, user is not authenticated

          if (authorized && !isLoggedIn) throw redirect(fallbackUrl);
          if (roles && userRoles && !rolePermission(userRoles, roles)) {
            throw redirect(
              `/community/${storageService.getItem(STORAGE_KEYS.COMMUNITY_ID)}`
            );
          }

          if (onlyPublic && isLoggedIn) {
            throw redirect('/');
          }

          return {
            ...(typeof routeLoader === 'boolean'
              ? routeLoader
              : routeLoader?.(args)),
            title,
          };
        },
      } as RouteObject)
  );
};

const Router: FC = () => {
  const router = createBrowserRouter(protectRoutes(routes));
  return <RouterProvider router={router} />;
};

export default Router;
