import { FC, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, FormField } from '@faxi/web-form';

import { appUri } from '../../../../config';
import { useCallbackAsync, useValidations } from '../../../../hooks';
import { InputField, FormFooter } from '../../../../components';
import SurveyContext from '../../providers/Survey/Survey.context';
import SurveyPageWrapper from '../../components/SurveyPageWrapper';
import apiSurvey from '../../../../modules/api/apiSurvey';
import storageService, {
  STORAGE_KEYS,
} from '../../../../services/storageService';

type InitialFormType = {
  email: string;
};

const Initial: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { validations } = useValidations();
  const { activeSurvey, setActiveEmail } = useContext(SurveyContext);

  const { surveySlug } = useParams() as { surveySlug: string };

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.kinto-survey',
    callback: async (values: InitialFormType) => {
      const { email } = values;

      const { data } = await apiSurvey.checkSurveyEmail(email, surveySlug);

      if (data.status === 'Error') {
        navigate(appUri.SURVEY_ALREADY_COMPLETED);
        return;
      }

      const navigationStep = `${appUri.SURVEY_START}/${appUri.SURVEY_START_COMMUTING_PATTERN}`;

      setActiveEmail(email);
      storageService.setItem(STORAGE_KEYS.ACTIVE_EMAIL, email);

      navigate(navigationStep);
    },
  });

  return (
    <SurveyPageWrapper
      className="kinto-survey-initial"
      title={activeSurvey?.name || ''}
      imageUrl="/assets/svg/survey_main_image.svg"
      paragraph={t('sustainability-body_introduction').replace(/\\n/g, '\n')}
    >
      <Form
        className="kinto-survey-main__left-section__form"
        onSubmit={handleSubmit}
      >
        <FormField
          required
          name="email"
          autoComplete="on"
          component={InputField}
          validate={validations.email}
          placeholder={t('onboarding-title_enter_your_email')}
        />

        <FormFooter submitLabel={t('global-start')} />
      </Form>
    </SurveyPageWrapper>
  );
};

export default Initial;
