import { theme } from '@faxi/web-component-library';
import { pxToRem } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  &.calendar-container {
    section {
      padding: 0;
    }
  }

  .calendar-container__input {
    .input__container__placeholder {
      transition: unset;
    }
  }

  .wcl-calendar {
    border: unset;
    width: ${pxToRem(theme.sizes.SIZE_320)};
  }
`;

export const PortalContainer = css`
  .wcl-calendar {
    padding: ${theme.sizes.SIZE_12};
    max-width: ${pxToRem(theme.sizes.SIZE_304)};
    min-height: ${pxToRem(theme.sizes.SIZE_374)};

    .wcl-year-picker {
      gap: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_24};
      margin-top: ${theme.sizes.SIZE_24};
      margin: auto;
    }

    .wcl-month-picker {
      gap: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_48};
      margin: auto;
    }

    .wcl-calendar-actions {
      padding: 0 ${theme.sizes.SIZE_12};
    }

    .wcl-day-picker {
      gap: 0;
      margin-top: ${theme.sizes.SIZE_8};
    }
  }
`;
