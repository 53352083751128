import { FC, useContext, useMemo } from 'react';
import { Chart } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { ReportsContext, UserContext } from 'store';
import { useChartDate } from '../hooks';
import { ChartData } from 'models';

const NoxChart: FC = (): JSX.Element => {
  const { includeTestUsers } = useContext(UserContext);
  const { kpiData } = useContext(ReportsContext);

  const { t } = useTranslation();

  const noxChartData = useMemo(() => {
    if (!kpiData || (includeTestUsers && !kpiData.nox.testChartData)) {
      return [];
    }
    return [
      {
        name: t('nox'),
        data: kpiData.nox.chartData,
      },
      ...(includeTestUsers
        ? [
            {
              name: `${t('nox')} ${t('reports_export_test_users')}`,
              data: kpiData.nox.testChartData as ChartData,
            },
          ]
        : []),
    ];
  }, [kpiData, includeTestUsers, t]);

  const dateObject = useChartDate(noxChartData);

  return (
    <Chart
      id="nox-chart"
      series={noxChartData}
      yAxisLabel={t('nox')}
      ariaLabel={t('accessibility_chart_label', {
        name: t('nox'),
        endDate: dateObject?.end,
        startDate: dateObject?.start,
      })}
    />
  );
};

export default NoxChart;
