export const STORAGE_KEYS = {
  AUTH: 'KINTO_CLIENT_ADMIN__AUTH',
  USER: 'KINTO_CLIENT_ADMIN__USER',
  ROLE: 'KINTO_CLIENT_ADMIN__ROLE',
  SID: 'KINTO_CLIENT_ADMIN__SID',
  FORM: 'KINTO_SURVEY__FORM',
  ACTIVE_EMAIL: 'KINTO_SURVEY__EMAIL',
  COMPLETED_STEPS: 'KINTO_SURVEY__COMPLETED_STEPS',
  USER_ID: 'KINTO_CLIENT_ADMIN__USER_ID',
  UNIT: 'KINTO_CLIENT_ADMIN__UNIT',
  DATE_FORMAT: 'KINTO_CLIENT_ADMIN__DATE_FORMAT',
  COMMUNITY_ID: 'KINTO_CLIENT_ADMIN__COMMUNITY_ID',
  REFRESH_TOKEN: 'KINTO_CLIENT_ADMIN__REFRESH_TOKEN',
  FAILED_MESSAGES: 'KINTO_CLIENT_ADMIN__FAILED_MESSAGES',
  REGISTERED_USER_PLATFORMS: 'KINTO_CLIENT_ADMIN__REGISTERED_USER_PLATFORMS',
};

export type StorageServiceType = {
  ram: Record<string, string | undefined>;
  setItem: (key: string, value: string | Record<string, any>) => void;
  removeItem: (key: string) => void;
  getItem: <T>(key: string) => T;
};

export default {
  ram: {} as Record<string, string | undefined>,
  setItem(key: string, value: Record<string, unknown> | string): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(e);
      this.ram[key] = JSON.stringify(value);
    }
  },
  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn(e);
      this.ram[key] = undefined;
    }
  },
  getItem<T>(key: string): T {
    try {
      return JSON.parse(localStorage.getItem(key) as string);
    } catch (e) {
      console.warn(e);
      return JSON.parse(this.ram[key] as string);
    }
  },
} as StorageServiceType;
