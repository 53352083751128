import { createContext } from 'react';
import { RewardType } from 'models';

type CampaignContext = {
  rewards?: RewardType[];
  enablePoints?: boolean;
};

export default createContext<CampaignContext>({
  rewards: [],
  enablePoints: false,
});
