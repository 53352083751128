import { Modal as ModalComponent, theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .wcl-modal {
    max-width: ${theme.sizes.SIZE_504};
  }
  .wcl-modal__header__title {
    color: var(--SHADE_1_1);
    font-size: ${theme.fontSizes.FONT_18};
    font-weight: 400;
  }

  .wcl-modal__header {
    padding-top: ${theme.sizes.SIZE_24};
  }

  .kinto-info-card__text {
    color: var(--SHADE_1_1);
    font-weight: 600;
    font-size: ${theme.fontSizes.FONT_18};
  }

  .textarea {
    margin-top: ${theme.sizes.SIZE_32};
  }

  .textarea__container {
    width: 100%;

    textarea {
      min-height: ${theme.sizes.SIZE_120};
    }
  }

  .kinto-modal__actions {
    button {
      &:first-child {
        margin-left: auto;
        margin-right: ${theme.sizes.SIZE_80};
      }
      &:last-child {
        margin: unset;
      }
    }
  }
`;
