import styled, { css } from 'styled-components';
import { fontSize, maxWidth, pxToRem } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const Container = styled.div`
  margin-bottom: ${theme.sizes.SIZE_32};
  line-height: ${pxToRem(theme.sizes.SIZE_20)};

  ${maxWidth(
    '920px',
    css`
      .checkbox {
        width: 100%;
        justify-content: space-between;
      }
    `
  )}

  .wcl-checkbox {
    margin: 0 0 ${theme.sizes.SIZE_8};

    &__label {
      justify-content: flex-start;
    }
  }

  .checkbox-info-field {
    &__error-msg {
      padding-right: ${theme.sizes.SIZE_48};
      ${fontSize(theme.fontSizes.FONT_14)};
      color: var(--ALERT_ERROR_1_1);
    }

    &__privacy-body {
      display: block;
      padding-right: ${theme.sizes.SIZE_24};
      ${fontSize(theme.fontSizes.FONT_14)};
      color: var(--SHADE_1_2);
      a {
        color: var(--ACCENT_1_1);
      }
    }
  }
  &.checkbox-info-field__no-border {
    border-bottom: none;
  }
`;
