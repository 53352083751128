import { useContext, useMemo, useCallback, FC } from 'react';
import { Button, Heading, getColor } from '@faxi/web-component-library';
import {
  FormField,
  Form,
  DataState,
  validators,
  validationRegexes,
} from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icon, InputField } from 'components';
import { AuthContext } from 'store';

import Styled from 'components/_layouts/Containers';
import { useHeadTitle } from 'hooks';
import { appUri } from '../../../config';

const ResetPasswordForm: FC = (): JSX.Element => {
  const { resetPassword } = useContext(AuthContext);

  const navigate = useNavigate();
  const { t } = useTranslation();
  useHeadTitle(t('Reset_password_dialog_title'));

  const validations = useMemo(
    () => ({
      email: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_email_hint'),
          })
        ),
        validators.general.regex(
          validationRegexes.workEmail,
          t('validation-field_valid_email', {
            fieldname: t('register_email_hint').toLowerCase(),
          })
        ),
      ],
    }),
    [t]
  );

  const handleResetPassword = useCallback(
    async (event: DataState) => {
      resetPassword(event.email);
    },
    [resetPassword]
  );

  return (
    <Styled.AuthGrid className="reset-password-form">
      <Form
        id="password_reset_form"
        className="form"
        onSubmit={handleResetPassword}
      >
        <div className="form__fields">
          <Heading level="1" color={getColor('--PRIMARY_1_1')}>
            {t('Reset_password_dialog_title')}
          </Heading>
          <FormField
            component={InputField}
            name="email"
            autoComplete="on"
            label={t('register_email_hint')}
            id="reset_password_email"
            prefixIcon={<Icon name="envelope" />}
            className="form__fields__field"
            placeholder={t('enter_email')}
            validate={validations.email}
            required
            requiredLabel={t('global-input_field_required_label')}
          />
          <div className="form__fields__commands">
            <div className="form__fields__commands--standard">
              <Button
                id="submit_password_reset"
                type="submit"
                className="login-btn"
              >
                {t('Reset_password_dialog_title')}
              </Button>
              <Button
                id="back_to_login"
                type="button"
                variant="ghost"
                className="return-login-btn"
                onClick={() => {
                  navigate(appUri.LOGIN);
                }}
                icon={<Icon name="chevron-left" />}
                iconPosition="left"
              >
                {t('return_to_login')}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Styled.AuthGrid>
  );
};

export default ResetPasswordForm;
