import { FC, PropsWithChildren } from 'react';
import InnerFooter from 'components/InnerFooter';

import * as Styled from './BaseTemplate.styles';

const BaseTemplate: FC<
  PropsWithChildren<{
    baseClassName?: string;
  }>
> = (props): JSX.Element => {
  const { children, baseClassName } = props;

  return (
    <Styled.Container className={baseClassName}>
      {children}
      <InnerFooter />
    </Styled.Container>
  );
};

export default BaseTemplate;
