import { FC } from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

import * as Styled from './ErrorMessage.styles';

export type ErrorMessageProps = {
  className?: string;
  text: string;
};

const ErrorMessage: FC<ErrorMessageProps> = (
  props: ErrorMessageProps
): JSX.Element => {
  const { className, text } = props;

  return (
    <Styled.Container className={classNames('error-message', className)}>
      <Icon name={'exclamation-triangle'} className="error-message__icon" />
      <div className="error-message__text">{text}</div>
    </Styled.Container>
  );
};

export default ErrorMessage;
