import { FC } from 'react';
import { LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { Link, useResize } from '@faxi/web-component-library';
import { responsiveSm } from '@faxi/web-css-utilities';

import Icon from 'components/Icon';

import * as Styled from './InfoCard.styles';

type InfoCardProps = {
  className?: string;
  text: string;
  linkText?: string;
  orientation?: 'horizontal' | 'vertical';
} & Partial<LinkProps>;

const InfoCard: FC<InfoCardProps> = (props) => {
  const {
    className,
    text,
    linkText,
    orientation = 'horizontal',
    ...rest
  } = props;

  const isBelowPhablet = useResize(responsiveSm);

  return (
    <Styled.InfoCardContainer
      className={classNames(
        'kinto-info-card',
        `kinto-info-card--${orientation}`,
        { 'kinto-info-card--vertical': isBelowPhablet },
        className
      )}
    >
      <Icon name="triangle-exclamation" />

      <div className="kinto-info-card__content">
        <p className="kinto-info-card__text">{text}</p>
        {linkText && (
          <Link className="kinto-info-card__link" {...(rest as LinkProps)}>
            {linkText}
          </Link>
        )}
      </div>
    </Styled.InfoCardContainer>
  );
};

export default InfoCard;
