import { FC, useContext, useMemo } from 'react';
import { Chart } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { ReportsContext, UserContext } from 'store';
import PlaceholderChart from '../PlaceholderChart';
import { useChartDate } from '../hooks';
import { ChartData } from 'models';

const RegistrationsChart: FC = (): JSX.Element => {
  const { rangeStats, rangeStatsReportError } = useContext(ReportsContext);
  const { includeTestUsers } = useContext(UserContext);

  const { t } = useTranslation();

  const registrationChartData = useMemo(() => {
    if (
      !rangeStats ||
      (includeTestUsers && !rangeStats.registeredUsers.testChartData)
    ) {
      return [];
    }

    return [
      {
        name: t('registrations'),
        data: rangeStats.registeredUsers.chartData,
      },
      ...(includeTestUsers
        ? [
            {
              name: `${t('registrations')} ${t('reports_export_test_users')}`,
              data: rangeStats.registeredUsers.testChartData as ChartData,
            },
          ]
        : []),
    ];
  }, [includeTestUsers, rangeStats, t]);

  const dateObject = useChartDate(registrationChartData);

  return rangeStatsReportError ? (
    <PlaceholderChart />
  ) : (
    <Chart
      id="registrations-chart"
      series={registrationChartData}
      yAxisLabel={t('registrations')}
      ariaLabel={t('accessibility_chart_label', {
        name: t('registrations'),
        endDate: dateObject?.end,
        startDate: dateObject?.start,
      })}
    />
  );
};

export default RegistrationsChart;
