import { FC, memo, useCallback, useState } from 'react';
import classNames from 'classnames';

import CopyLink from '../CopyLink';
import EditLinkModal from '../../_modals/InvitePeopleModal/components/EditLinkModal';

import * as Styled from './ClipboardElement.styles';

type ClipboardElementProps = {
  className?: string;
  link: string;
  code?: string;
  loading?: boolean;
  onCopy?: () => void;
  onUpdate?: (code: string) => void;
};

const ClipboardElement: FC<ClipboardElementProps> = (props) => {
  const { loading, link, code, className, onCopy, onUpdate } = props;

  const [editLink, setEditLink] = useState(false);

  const onModalSave = useCallback(
    (value: string) => onUpdate?.(value),
    [onUpdate]
  );

  return (
    <Styled.ClipboardElement
      className={classNames('kinto-clipboard-element', className)}
    >
      <CopyLink
        href={link}
        loading={loading}
        onCopy={onCopy}
        onEdit={() => setEditLink(true)}
      />

      {editLink && code && (
        <EditLinkModal
          code={code}
          onClose={() => {
            setEditLink(false);
          }}
          onSave={onModalSave}
        />
      )}
    </Styled.ClipboardElement>
  );
};

export default memo(ClipboardElement);
