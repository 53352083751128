import { elevate_l, focusStyles, theme } from '@faxi/web-component-library';
import {
  flex,
  flexGap,
  fontSize,
  laptop,
  phablet,
  position,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Gamification = styled.div`
  ${size('100%')};
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_12, 'column')};

  .kinto-gamification {
    &__card {
      ${flex('column')};
      padding: ${theme.sizes.SIZE_20};
      background-color: var(--BACKGROUND_2_1);
      border-radius: ${theme.sizes.SIZE_8};
      gap: ${theme.sizes.SIZE_24};

      &__title {
        ${fontSize(theme.fontSizes.FONT_22, theme.sizes.SIZE_32)};
        font-weight: 600;
        color: var(--SECONDARY_1_1);
        margin: unset;
      }
    }

    &__instructions {
      ${flex('row', 'flex-start', 'flex-start')};
      padding: 0 ${theme.sizes.SIZE_20};
      gap: ${theme.sizes.SIZE_24};

      ${phablet(css`
        flex-direction: column;
      `)};

      &__instruction {
        ${flex('row', 'flex-start', 'center')};
        flex: 1 1 25%;
        gap: ${theme.sizes.SIZE_12};

        ${phablet(css`
          flex-direction: column;
          align-items: flex-start;
        `)};

        &__details {
          gap: ${theme.sizes.SIZE_8};
          ${flex('column')};

          &__icon-title {
            gap: ${theme.sizes.SIZE_8};
            ${flex('column')};

            > .wcl-icon {
              width: fit-content;
            }

            @media (max-width: ${theme.breakAtMaxWidth}px) {
              gap: ${theme.sizes.SIZE_12};
              flex-direction: row;
            }

            &__title {
              ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};
              font-weight: 400;
              color: var(--SHADE_1_1);
            }
          }
        }

        &__subtitle {
          ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
          color: var(--SHADE_1_2);
        }

        &__arrow-icon {
          ${phablet(css`
            transform: rotate(90deg);
          `)};
        }
      }

      &__illustration__image {
        flex: 1 1 25%;
        max-width: ${pxToRem(theme.sizes.SIZE_200)};

        ${laptop(css`
          flex: unset;
          display: none;
        `)};
      }
    }
  }

  .kinto-gamification-tab {
    &__link {
      ${flex('column')};
      ${size('100%')};
      ${focusStyles};
      position: relative;
      text-decoration: none;
      gap: ${theme.sizes.SIZE_4};
      padding: ${theme.sizes.SIZE_16};
      padding-right: ${theme.sizes.SIZE_56};
      color: var(--SHADE_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_6);
      background-color: var(--BACKGROUND_1_1);
      ${elevate_l}

      .link-title {
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};
        font-weight: 600;
        margin: 0;
      }

      .link-description {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_24)};
        color: var(--SHADE_1_2);
        font-weight: 400;
        margin: 0;
      }

      .link-hint {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        font-weight: 600;
        color: var(--LABEL_TEXT_2_1);
        margin: 0;
      }

      &.earned-rewards {
        height: fit-content;

        .link-title {
          margin: auto 0;
        }
      }

      &:hover {
        border-color: var(--PRIMARY_1_1);
      }
    }

    &__arrow-icon {
      ${position('absolute', `top 50% right ${pxToRem(theme.sizes.SIZE_16)}`)};
      ${size(theme.sizes.SIZE_24)};
      transform: translate3d(0, -50%, 0);
      color: var(--PRIMARY_1_1);
    }
  }
`;
