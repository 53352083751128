import Page from 'pages';
import { ExtendRouteObject } from 'router/routes';
import Profile from 'components/_organisms/Profile';
import { ProfileProvider } from 'store';
import PermissionsPage from 'components/_organisms/PermissionsPage';
import { appUri } from '../../../../../config';

export default [
  {
    handle: 'people-table',
    path: '',
    element: <Page.People />,
  },
  {
    handle: 'profile-page',
    path: appUri.COMMUNITY_PEOPLE_PROFILE,
    element: <Profile />,
  },
  {
    handle: 'profile-page-edit-status',
    path: appUri.COMMUNITY_PEOPLE_PROFILE_PERMISSIONS,
    element: (
      <ProfileProvider>
        <PermissionsPage />
      </ProfileProvider>
    ),
  },
] as ExtendRouteObject[];
