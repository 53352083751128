import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import * as Styles from './SeparatedContent.styles';

type SeparatedContentProps = {
  className?: string;
  leftNode: ReactNode;
  rightNode: ReactNode;
  variant: 'large' | 'small';
};

const SeparatedContent: FC<SeparatedContentProps> = (props) => {
  const { className, leftNode, rightNode, variant } = props;

  return (
    <Styles.SeparatedContentContainer
      className={classNames(className, variant)}
    >
      <div>{leftNode}</div>
      <div>{rightNode}</div>
    </Styles.SeparatedContentContainer>
  );
};

export default SeparatedContent;
