import { useContext } from 'react';
import { Heading, getColor } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'store';
import { Icon, LoginTemplate } from 'components';

const InterstitialPage = (): JSX.Element => {
  const { uris } = useContext(AuthContext);

  const { t } = useTranslation();

  // TODO: this can soon be deleted

  return (
    <LoginTemplate
      imageUrl="/assets/images/interstitial-bgd.png"
      headerClassName="interstitial-page__template-header"
      footerClassName="interstitial-page__template-footer"
      mainClassName="interstitial-page__template-main"
      renderOutlet={false}
    >
      <div className="interstitial-page__main">
        <div className="interstitial-page__main__content">
          <Heading level="1" className="interstitial-page__main__content__text">
            {t('interstitial_page_text')}
          </Heading>
          <div className="interstitial-page__main__content__table">
            {uris.map((item, index) => (
              <div
                key={index}
                className="interstitial-page__main__content__table__wrapper"
              >
                <Icon
                  name="link"
                  color={getColor('--ACCENT_1_1')}
                  className="interstitial-page__main__content__table__wrapper__icon"
                />
                <a
                  href={`${item}/admin`}
                  target="_blank"
                  rel="noreferrer"
                  className="interstitial-page__main__content__table__wrapper__link"
                >
                  {item.replace('https://', '')}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </LoginTemplate>
  );
};

export default InterstitialPage;
