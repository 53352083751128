import { useState } from 'react';
import useEffectOnceWhen from './useEffectOnceWhen';

export default function useScript(src?: string) {
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

  useEffectOnceWhen(() => {
    // Fetch existing script element by src
    // It may have been added by another intance of this hook
    const script = document.querySelectorAll(`script[src="${src}"]`)[0];

    if (script) {
      setScriptLoaded(true);
      return;
    }

    const scriptTag = document.createElement('script');
    scriptTag.src = src!;
    scriptTag.async = true;
    scriptTag.defer = true;

    scriptTag.onload = () => {
      setScriptLoaded(true);
    };

    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, !!src);

  return { scriptLoaded };
}
