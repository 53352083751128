import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from '@faxi/web-component-library';
import { useFieldError, FieldProps } from '@faxi/web-form';
import classNames from 'classnames';

export type SelectFieldProps = Omit<
  FieldProps<string, (event: string) => void> & {
    className?: string;
    description?: string;
    disabled?: boolean;
  },
  'value'
> &
  SelectProps<string>;

const SelectField: FC<SelectFieldProps> = (props: SelectFieldProps) => {
  const {
    className,
    description,
    dirty,
    error,
    touched,
    value,
    options,
    onChange,
    multiple,
    ...rest
  } = props;

  const { t } = useTranslation();
  const showError = useFieldError(props);

  const selectOnChange = useCallback(
    (value: any) => {
      onChange?.(value as string);
    },
    [onChange]
  );

  return (
    <div className={classNames('kinto-select-field', className)}>
      <Select
        {...rest}
        multiple={multiple as any}
        options={options}
        value={value as string}
        closeOptionsOnClear
        deleteInputAriaLabel={t('delete_input')}
        chevronBtnAriaLabel={t('accessibility-show_select_options')}
        onChange={(ev: string | { value: string }) =>
          selectOnChange(multiple ? ev : (ev as { value: string }).value)
        }
        error={showError}
        errorText={String(error)}
      />
    </div>
  );
};

export default SelectField;
