import { Shift } from 'models';
import i18next from 'i18next';

export const calculateDurationTime = (
  startTime?: string,
  endTime?: string
): number => {
  const startTimeHours = Number(startTime?.substring(0, 2));
  const endTimeHours = Number(endTime?.substring(0, 2));

  const startTimeMinutes = Number(startTime?.substring(3, 5));

  const endTimeMinutes = Number(endTime?.substring(3, 5));

  let duration = 8;

  if (startTimeHours < endTimeHours) {
    duration = endTimeHours - startTimeHours;
  } else {
    duration = endTimeHours + (24 - startTimeHours);
  }
  if (endTimeMinutes - startTimeMinutes > 30 && duration < 24) ++duration;
  if (startTimeMinutes - endTimeMinutes > 30) --duration;

  return duration;
};

//   //New start time should be previouse end time
export const newEndTime = (last: Shift) => {
  const endTime = (parseInt(last?.end_time.substring(0, 2)) + 8) % 24;
  if (endTime) {
    if (endTime >= 10) {
      return `${endTime}:${last?.end_time.substring(3, 5)}`;
    } else {
      return `0${endTime}:${last?.end_time.substring(3, 5)}`;
    }
  } else {
    return '17:00';
  }
};

// New shift name should be same as previous just to have max possible ordinal from the list of shiftsName
export const newShiftName = (formValues: Shift[]) => {
  const shiftNames: string[] = formValues.map((shift: Shift) => shift.name);

  let shiftWithGratestNumberInName = 0;

  // regex for checking only shift names with pattern 'Shift {ordinal}'
  const shiftNameOnSelectedLanguageRegex = new RegExp(
    '^' + i18next.t('shift_settings-title_shift') + ' [0-9]*$'
  );

  shiftNames.forEach((shiftName: string) => {
    if (shiftNameOnSelectedLanguageRegex.test(shiftName)) {
      const currentNumber = +shiftName.replace(/[^0-9]/g, '');

      if (shiftWithGratestNumberInName < currentNumber) {
        shiftWithGratestNumberInName = currentNumber;
      }
    }
  });

  return `${i18next.t('shift_settings-title_shift')} ${
    shiftWithGratestNumberInName + 1
  }`;
};
