import { createContext } from 'react';
import { SelectOption } from '@faxi/web-component-library';

import { Country, Platform } from 'models';

interface AppContext {
  platform?: Platform;
  allPlatforms?: Platform[];
  loadingCountries: boolean;
  countryOptions: SelectOption[];
  defaultCountry?: Country;
  languageOptions: SelectOption[];
  selectedLanguage?: SelectOption;
  fetchPlatformCountries: () => Promise<any>;
  fetchLanguages: () => Promise<void>;
  fetchPlatforms: () => Promise<void>;
}

const AppContext = createContext<AppContext>({
  platform: undefined,
  allPlatforms: [],
  loadingCountries: false,
  countryOptions: [],
  defaultCountry: undefined,
  languageOptions: [],
  selectedLanguage: undefined,
  fetchPlatformCountries: () => new Promise(() => {}),
  fetchLanguages: () => new Promise(() => {}),
  fetchPlatforms: () => new Promise(() => {}),
});

export default AppContext;
