import { useMemo, useRef } from 'react';
import { FormField, validators } from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@faxi/web-component-library';
import dayjs from 'dayjs';

import { SelectField } from '../../../../../components';
import SurveyFormWrapper from '../../../components/SurveyFormWrapper';
import SeparatedContent from '../components/SeparatedContent';

const VehicleInformation = () => {
  const { t } = useTranslation();

  const year = useRef(dayjs().year());
  const yearOptions = useMemo(
    () =>
      Array.from({ length: 30 }, (_, i) => ({
        label: `${year.current - i}`,
        value: `${year.current - i}`,
      })).reverse(),
    []
  );

  const fuelOptions = useMemo<SelectOption[]>(
    () => [
      {
        label: t('fuel_type-label_petrol'),
        value: 'petrol',
      },
      {
        label: t('fuel_type-label_diesel'),
        value: 'diesel',
      },
      {
        label: t('fuel_type-label_electric'),
        value: 'electric',
      },
      {
        label: t('fuel_type-label_hybrid'),
        value: 'hybrid',
      },
      {
        label: t('fuel_type-label_gas'),
        value: 'gas',
      },
    ],
    [t]
  );

  return (
    <SurveyFormWrapper className="kinto-survey-form-container">
      <SeparatedContent
        variant="large"
        leftNode={t('vehicle_information-label_year')}
        rightNode={
          <FormField
            name="year"
            component={SelectField}
            placeholder={t('vehicle_information-label_year')}
            options={yearOptions}
            validate={validators.general.required(
              t('validation-field_is_required', {
                fieldname: t('vehicle_information-label_year'),
              })
            )}
          />
        }
      />

      <SeparatedContent
        variant="large"
        leftNode={t('vehicle_information-fuel')}
        rightNode={
          <FormField
            component={SelectField}
            name="engine_type"
            placeholder={t('vehicle_information-fuel')}
            options={fuelOptions}
            validate={validators.general.required(
              t('validation-field_is_required', {
                fieldname: t('vehicle_information-fuel'),
              })
            )}
          />
        }
      />
    </SurveyFormWrapper>
  );
};

export default VehicleInformation;
