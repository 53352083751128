import { FC, useCallback, useContext, useMemo } from 'react';
import { Select } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'store';

export const getWithoutLocalizeURL = (url: string) => {
  return url.replace(/^\/[a-z]{2}(?:_[A-Za-z]+)?(?=\/)/, '');
};

const LanguageSelector: FC = () => {
  const { languageOptions } = useContext(AppContext);

  const { i18n, t } = useTranslation();

  const selectedLanguage = useMemo(
    () => languageOptions?.find((lang) => lang.value === i18n.language),
    [i18n, languageOptions]
  );

  const changeLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  return (
    <Select
      options={languageOptions}
      value={selectedLanguage}
      placeholder={t('language')}
      className="language-selector"
      deleteInputAriaLabel={t('delete_input')}
      chevronBtnAriaLabel={t('accessibility-show_select_options')}
      onChange={(val) => changeLanguage(val.value)}
    />
  );
};

export default LanguageSelector;
