import { useMemo } from 'react';
import classNames from 'classnames';
import { ButtonProps, Image } from '@faxi/web-component-library';
import { LoginPlatform } from 'models';

import * as Styled from './SocialButton.styles';

type SocialButtonProps = {
  provider: LoginPlatform;
} & ButtonProps;

const SocialButton = (props: SocialButtonProps) => {
  const { provider, disabled, ...rest } = props;

  const imageSrc = useMemo(() => {
    switch (provider) {
      case 'microsoft':
        return '/assets/svg/microsoft_login.svg';
      case 'apple':
        return '/assets/svg/apple_login.svg';
      case 'google':
        return '/assets/svg/google_login.svg';
    }
  }, [provider]);

  return (
    <Styled.Container
      {...rest}
      type="button"
      className={classNames(`${provider}-button`, {
        'social-button--disable': disabled,
      })}
      disabled={disabled}
    >
      <Image alt="social-login-button-icon" src={imageSrc} />
    </Styled.Container>
  );
};

export default SocialButton;
