import { Modal, theme } from '@faxi/web-component-library';
import { flex, mobile, phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Container = styled(Modal)`
  .wcl-modal {
    overflow: auto;
    background-color: var(--BACKGROUND_2_1);

    &__footer {
      padding-top: ${theme.sizes.SIZE_12};
      padding-bottom: ${theme.sizes.SIZE_20};
    }
  }

  .kinto-pdf {
    &__page {
      page-break-after: always;
      width: 595px;
      height: 842px;
      flex: 0 0 842px;
      background-color: var(--BACKGROUND_1_1);
      outline: 1px solid lightgray;

      &__header {
        background: linear-gradient(
          to right,
          var(--PRIMARY_1_1),
          var(--PRIMARY_2_1)
        );
        height: ${theme.sizes.SIZE_64};

        &__logo {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          margin-left: ${theme.sizes.SIZE_24};
          width: ${theme.sizes.SIZE_80};
        }
      }

      &__main {
        padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_32};
      }
    }

    &__footer {
      width: 100%;
      ${flex('column', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_24};

      &__pagination {
        font-size: ${theme.fontSizes.FONT_14};
        color: var(--SHADE_1_1);
      }
    }
  }

  .total-reports-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: ${theme.sizes.SIZE_12};
    gap: ${theme.sizes.SIZE_12};

    @media (max-width: 1400px) {
      grid-template-columns: repeat(2, 1fr);
    }

    ${phablet(css`
      margin-bottom: ${theme.sizes.SIZE_12};
    `)};

    ${mobile(css`
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: ${theme.sizes.SIZE_12};
      gap: ${theme.sizes.SIZE_12};
    `)};

    .mini-report-total {
      padding: ${theme.sizes.SIZE_12};
    }

    .wcl-report-container {
      padding: ${`${theme.sizes.SIZE_24} ${theme.sizes.SIZE_24}
        ${theme.sizes.SIZE_12}`};
    }

    .mini-report-total__arrow {
      display: none;
    }
  }
`;
