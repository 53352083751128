import { FC, RefObject, useContext, useMemo, MouseEvent } from 'react';
import { FormContext } from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import {
  ModalRef,
  useFormButtons,
  FormButtonsType,
  Button,
} from '@faxi/web-component-library';

import FormActions from 'components/_layouts/FormActions';

type FormFooterProps = {
  className?: string;
  modalRef?: RefObject<ModalRef>;
  deleteLabel?: string;
  onDelete?: (event: MouseEvent<HTMLButtonElement>) => void;
} & Partial<Pick<FormButtonsType, 'submitLabel' | 'cancelLabel'>>;

const FormFooter: FC<FormFooterProps> = (props) => {
  const { t } = useTranslation();

  const {
    className,
    modalRef,
    submitLabel = t('Save'),
    cancelLabel = t('cancel'),
    deleteLabel = t('Discovery_map_delete'),
    onDelete,
  } = props;

  const { isFormChanged, syncFormValid, asyncFormValid } =
    useContext(FormContext);

  const [FormButtons] = useFormButtons({
    cancelLabel,
    submitLabel,
  });

  const disabledSubmit = useMemo(
    () => !(isFormChanged() && asyncFormValid && syncFormValid),
    [asyncFormValid, syncFormValid, isFormChanged]
  );

  return (
    <FormActions className={className}>
      <FormButtons.Submit disabled={disabledSubmit} />

      {modalRef && (
        <FormButtons.Cancel
          onClick={() => {
            modalRef.current?.close();
          }}
        />
      )}

      {onDelete && (
        <Button
          className="delete-btn"
          variant="delete-outline"
          onClick={onDelete}
        >
          {deleteLabel}
        </Button>
      )}
    </FormActions>
  );
};

export default FormFooter;
