import {
  useState,
  useCallback,
  useEffect,
  useContext,
  FC,
  PropsWithChildren,
} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'store';

import { apiPeople } from 'modules';
import { PeoplePageUser } from 'models';

import ProfileContext, { userProfileDefaultValue } from './Profile.context';
import { useUtilities } from '@faxi/web-component-library';

const UserProfileProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;
  const { communityId } = useContext(UserContext);

  const { showOverlay, hideOverlay } = useUtilities();

  const { userId } = useParams() as {
    userId: string;
  };

  const { t } = useTranslation();

  const [userProfile, setUserProfile] = useState<PeoplePageUser>(
    userProfileDefaultValue.userProfile
  );
  const [loadingUserProfile, setLoadingUserProfile] = useState<boolean>(true);

  const getExactUser = useCallback(
    async (communityId: number, userId: string) => {
      try {
        const { users: exactUser } = await apiPeople.getExactUser(
          communityId,
          userId
        );

        if (exactUser) {
          const users: PeoplePageUser[] = exactUser.map(
            ({
              id,
              first_name: fName,
              last_name: lName,
              joined_ts,
              image_url,
              lastactive_ts: lastActive,
              email,
              messages_count,
              authorised,
              shifts,
              is_admin,
            }) =>
              ({
                id,
                first_name: fName,
                last_name: lName,
                name: [fName, lName].join(' ').trim(),
                image_url,
                email,
                lastactive_ts: lastActive,
                messages_count,
                joined_ts,
                authorised,
                is_admin,
                shifts: shifts?.[0].name,
                status:
                  authorised === 'Y'
                    ? t('approved')
                    : authorised === 'N'
                    ? t('fc_pending')
                    : authorised === 'D'
                    ? t('deactivated')
                    : t('rejected'),
              } as PeoplePageUser)
          );
          setUserProfile(users[0]);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingUserProfile(false);
      }
    },
    [t]
  );

  useEffect(() => {
    if (!communityId || !userId) {
      return;
    }

    getExactUser(communityId, userId);
  }, [communityId, userId, getExactUser]);

  useEffect(() => {
    loadingUserProfile && showOverlay('.permissions-page');

    return () => {
      hideOverlay('.permissions-page');
    };
  }, [hideOverlay, loadingUserProfile, showOverlay]);

  return (
    <ProfileContext.Provider
      value={{
        userProfile,
        setUserProfile,
        loadingUserProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default UserProfileProvider;
