import httpClient from '../httpClient';

const sendEmail = async (email: string) =>
  httpClient
    .get(`user`, { params: { resetpwd: 1, email } })
    .then((res) => res.data);

export default {
  sendEmail,
};
