import { useContext, useMemo } from 'react';
import { UserContext } from '../../../store';
import { ChartData } from '../../../models';
import dayjs from 'dayjs';

type ChartDataType = {
  name: string;
  data: ChartData;
};

export default function useChartDate(data: ChartDataType[]) {
  const chartData = data[0] as ChartDataType;

  const {
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  const dateObject = useMemo(() => {
    if (!data.length) return;

    const formatData = (date: string) => {
      return dayjs(date).format(dateFormat);
    };

    return {
      start: formatData(Object.keys(chartData.data)[0]),
      end: formatData(Object.keys(chartData.data).pop()!),
    };
  }, [chartData, dateFormat, data]);

  return dateObject;
}
