import { FC } from 'react';
import {
  IName,
  Icon,
  IconProps,
  extendPropToFaNameMap,
  propToFaNameMap,
} from '@faxi/web-component-library';

export type INameExtended =
  | (
      | 'apple'
      | 'arrow-left'
      | 'arrow-right'
      | 'arrow-up'
      | 'bell'
      | 'browser'
      | 'browser-solid'
      | 'car-bus'
      | 'building'
      | 'calendar-day'
      | 'download'
      | 'grid-round-2'
      | 'calendar-days'
      | 'calendar-week'
      | 'calendar-week-solid'
      | 'chart-simple'
      | 'chart-simple-solid'
      | 'check-circle'
      | 'circle-xmark'
      | 'clock'
      | 'clock-nine'
      | 'cloud-fog'
      | 'cloud'
      | 'clouds'
      | 'copy'
      | 'cup-togo'
      | 'do-not-enter'
      | 'ellipsis'
      | 'ellipsis-solid'
      | 'envelope'
      | 'exclamation-circle'
      | 'exclamation-triangle'
      | 'eye'
      | 'eye-slash'
      | 'file-pdf'
      | 'file-spreadsheet'
      | 'filter'
      | 'gamepad-modern'
      | 'gamepad-modern-solid'
      | 'gift'
      | 'image'
      | 'layer-plus'
      | 'leaf'
      | 'leaf-solid'
      | 'light-chevron-left'
      | 'light-chevron-right'
      | 'link'
      | 'location-dot'
      | 'location-dot'
      | 'lock-keyhole'
      | 'map-location-dot'
      | 'map-location-dot-solid'
      | 'megaphone'
      | 'message-lines'
      | 'message-lines-solid'
      | 'minus'
      | 'mobile'
      | 'moon'
      | 'paper-plane-top'
      | 'percent'
      | 'person-biking'
      | 'person-walking'
      | 'phone'
      | 'road'
      | 'rotate-right'
      | 'route'
      | 'sliders-h'
      | 'sliders-up'
      | 'sort-up'
      | 'square-parking'
      | 'sun'
      | 'sunset'
      | 'upload'
      | 'user-circle'
      | 'user-group'
      | 'users'
      | 'users-solid'
      | 'wifi-slash'
      | 'circle-star'
      | 'house'
      | 'house-solid'
      | 'magnifying-glass-chart'
      | 'mug-hot'
      | 'ranking-star'
      | 'check-circle-solid'
      | 'calendar-clock'
      | 'calendar-clock-solid'
      | 'trophy-star'
      | 'trophy-star-solid'
    )
  | IName;

const customMapNameToFa: Record<INameExtended, string[]> = {
  'arrow-up': ['fa-light', 'fa-arrow-up'],
  bell: ['fa-light', 'fa-bell'],
  'car-bus': ['fa-light', 'fa-car-bus'],
  building: ['fa-light', 'fa-building'],
  minus: ['fa-light', 'fa-minus'],
  users: ['fa-light', 'fa-users'],
  'users-solid': ['fa-solid', 'fa-users'],
  'sliders-h': ['fa-light', 'fa-sliders-h'],
  download: ['fa-light', 'fa-download'],
  'map-location-dot': ['fa-light', 'fa-map-location-dot'],
  'map-location-dot-solid': ['fa-solid', 'fa-map-location-dot'],
  'message-lines': ['fa-light', 'fa-message-lines'],
  'message-lines-solid': ['fa-solid', 'fa-message-lines'],
  browser: ['fa-light', 'fa-browser'],
  'browser-solid': ['fa-solid', 'fa-browser'],
  'sort-up': ['fa-light', 'fa-sort-up'],
  'calendar-day': ['fa-light', 'fa-calendar-day'],
  'grid-round-2': ['fa-light', 'fa-grid-round-2'],
  envelope: ['fa-light', 'fa-envelope'],
  'circle-xmark': ['fa-light', 'fa-circle-xmark'],
  'light-chevron-right': ['fa-light', 'fa-chevron-right'],
  'light-chevron-left': ['fa-light', 'fa-chevron-left'],
  'lock-keyhole': ['fa-light', 'fa-lock-keyhole'],
  upload: ['fa-light', 'fa-upload'],
  filter: ['fa-light', 'fa-filter'],
  image: ['fa-light', 'fa-image'],
  phone: ['fa-light', 'fa-phone-alt'],
  'user-circle': ['fa-light', 'fa-user-circle'],
  ellipsis: ['fa-light', 'fa-ellipsis'],
  'ellipsis-solid': ['fa-solid', 'fa-ellipsis'],
  eye: ['fa-light', 'fa-eye'],
  'eye-slash': ['fa-light', 'fa-eye-slash'],
  'exclamation-triangle': ['fa-light', 'fa-exclamation-triangle'],
  'check-circle': ['fa-light', 'fa-check-circle'],
  'check-circle-solid': ['fa-solid', 'fa-circle-check'],
  'layer-plus': ['fa-light', 'fa-layer-plus'],
  copy: ['fa-light', 'fa-copy'],
  link: ['fa-light', 'fa-link'],
  'person-walking': ['fa-light', 'fa-person-walking'],
  'person-biking': ['fa-light', 'fa-person-biking'],
  route: ['fa-light', 'fa-route'],
  'ranking-star': ['fa-light', 'fa-ranking-star'],
  'rotate-right': ['fa-light', 'fa-rotate-right'],
  'do-not-enter': ['fa-light', 'fa-do-not-enter'],
  'wifi-slash': ['fa-light', 'fa-wifi-slash'],
  'file-spreadsheet': ['fa-light', 'fa-file-spreadsheet'],
  'file-pdf': ['fa-light', 'fa-file-pdf'],
  leaf: ['fa-light', 'fa-leaf'],
  'leaf-solid': ['fa-solid', 'fa-leaf'],
  road: ['fa-light', 'fa-road'],
  cloud: ['fa-light', 'fa-cloud'],
  clouds: ['fa-light', 'fa-clouds'],
  'exclamation-circle': ['fa-light', 'fa-exclamation-circle'],
  clock: ['fa-light', 'fa-clock'],
  'clock-nine': ['fa-light', 'fa-clock-nine'],
  'calendar-days': ['fa-light', 'fa-calendar-days'],
  'calendar-week': ['fa-light', 'fa-calendar-week'],
  'calendar-week-solid': ['fa-solid', 'fa-calendar-week'],
  'user-group': ['fa-light', 'fa-user-group'],
  house: ['fa-light', 'fa-house'],
  'house-solid': ['fa-solid', 'fa-house'],
  'gamepad-modern': ['fa-light', 'fa-gamepad-modern'],
  'gamepad-modern-solid': ['fa-solid', 'fa-gamepad-modern'],
  'arrow-right': ['fa-light', 'fa-arrow-right'],
  'arrow-left': ['fa-light', 'fa-arrow-left'],
  percent: ['fa-light', 'fa-percent'],
  'paper-plane-top': ['fa-light', 'fa-paper-plane-top'],
  gift: ['fa-light', 'fa-gift'],
  megaphone: ['fa-light', 'fa-megaphone'],
  mobile: ['fa-light', 'fa-mobile'],
  'sliders-up': ['fa-light', 'fa-sliders-up'],
  'cup-togo': ['fa-light', 'fa-cup-togo'],
  'square-parking': ['fa-light', 'fa-square-parking'],
  'chart-simple': ['fa-light', 'fa-chart-simple'],
  'chart-simple-solid': ['fa-solid', 'fa-chart-simple'],
  'cloud-fog': ['fa-light', 'fa-cloud-fog'],
  apple: ['fa-brands', 'fa-apple'],
  moon: ['fa-kit', 'fa-moon'],
  sun: ['fa-kit', 'fa-sun'],
  sunset: ['fa-kit', 'fa-sunset'],
  'circle-star': ['fa-light', 'fa-circle-star'],
  'magnifying-glass-chart': ['fa-solid', 'fa-magnifying-glass-chart'],
  'mug-hot': ['fa-solid', 'fa-mug-hot'],
  'calendar-clock': ['fa-light', 'fa-calendar-clock'],
  'calendar-clock-solid': ['fa-solid', 'fa-calendar-clock'],
  'trophy-star': ['fa-light', 'fa-trophy-star'],
  'trophy-star-solid': ['fa-solid', 'fa-trophy-star'],
  ...propToFaNameMap,
};

extendPropToFaNameMap(customMapNameToFa);

export default Icon as FC<IconProps<INameExtended>>;
