import { useMemo } from 'react';
import { useKeyboardListener } from '@faxi/web-component-library';
import { validators } from '@faxi/web-form';
import i18next from 'i18next';

type SendMessageProps = {
  onEnter: () => void;
  onClose?: () => void;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: SendMessageProps) {
  const { onEnter, onClose } = props;

  const validations = useMemo(
    () => ({
      message: [
        validators.general.maxLength(
          1500,
          i18next.t('validation-field_validation_max_length', {
            fieldname: i18next.t('message')?.toLowerCase(),
            number: 1500,
          })
        ),
      ],
    }),
    []
  );

  useKeyboardListener(
    [
      {
        keyCodes: ['Escape'],
        callbackFn: () => {
          onClose?.();
        },
      },
      {
        keyCodes: ['MetaLeft', 'Enter'],
        callbackFn: onEnter,
      },
      {
        keyCodes: ['ControlLeft', 'Enter'],
        callbackFn: onEnter,
      },
    ],
    window
  );

  return { validations };
}
