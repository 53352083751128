import { validationRegexes, validators } from '@faxi/web-form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useValidations() {
  const { t } = useTranslation();

  const validations = useMemo(
    () => ({
      email: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_email_hint'),
          })
        ),
        validators.general.regex(
          validationRegexes.workEmail,
          t('validation-field_valid_email', {
            fieldname: t('register_email_hint').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          80,
          t('validation-field_validation_max_length', {
            fieldname: t('register_email_hint').toLowerCase(),
            number: 80,
          })
        ),
      ],
    }),
    [t]
  );

  return { validations };
}
