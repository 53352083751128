import { theme } from '@faxi/web-component-library';
import { flex, flexGap, position, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const FilePreviewContainer = styled.div`
  ${flex('row', 'flex-start', 'center')};
  ${flexGap(theme.sizes.SIZE_32, 'row')};

  width: fit-content;

  .kinto-image-preview {
    ${size(theme.sizes.SIZE_136)};
    ${flex('row', 'center', 'center')};

    &__spinner {
      ${position('absolute', '0')};
      ${flex('column', 'center', 'center')};
      ${size('100%')};

      border-radius: ${theme.sizes.SIZE_24};
    }

    &__wrapper {
      ${size(theme.sizes.SIZE_136)};

      overflow: hidden;
      user-select: none;
      position: relative;

      &:after {
        ${position('absolute', '0')};
        ${size('100%')};

        cursor: pointer;
        border-radius: ${theme.sizes.SIZE_24};
        background-color: var(--BACKGROUND_1_4);
      }

      &:hover {
        &:after {
          content: '';
        }
      }

      &--non-exist {
        &:hover {
          &:after {
            display: none;
          }
        }
      }
    }

    &__edit-btn {
      ${position('absolute', 'bottom 0 right 0')};
      ${size(theme.sizes.SIZE_32)}

      z-index: 1;
      min-height: unset;
      border-radius: 50%;
      transform: translate3d(6px, 6px, 0);
      background-color: var(--BACKGROUND_1_1);
      border: ${theme.sizes.SIZE_2} solid var(--SHADE_1_6);
    }

    &__img {
      ${size('100%')};
      object-fit: cover;
      pointer-events: all;
      border-radius: ${theme.sizes.SIZE_24};
    }

    &__actions {
      ${flex('column')};
    }
  }
`;
