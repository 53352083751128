import { theme } from '@faxi/web-component-library';
import {
  flex,
  flexGap,
  fontSize,
  phablet,
  position,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const ReportCard = styled.div`
  ${flex('column')};

  position: relative;
  border-radius: ${theme.sizes.SIZE_8};
  background-color: var(--BACKGROUND_1_1);
  padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_20} ${theme.sizes.SIZE_32};

  .spinner {
    ${position('absolute', 'top 50% left 50%')};
    transform: translate3d(-50%, -50%, 0);
  }

  .kinto-report-card {
    &__header {
      ${flex('row', 'space-between', 'center')};

      width: 100%;
      margin-bottom: ${theme.sizes.SIZE_16};

      &__title {
        ${flex('row')};
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_32)};

        margin: 0;
        font-weight: 600;
        text-transform: uppercase;
        color: var(--SHADE_1_2);
        margin-right: ${theme.sizes.SIZE_12};

        .wcl-icon {
          ${size(theme.sizes.SIZE_32)};

          padding: 6px;
          color: var(--PRIMARY_1_1);
          margin-right: ${theme.sizes.SIZE_4};

          svg {
            ${size('100%')};
          }
        }
      }
    }

    &__status {
      > div {
        ${flex('row')};
        ${flexGap(theme.sizes.SIZE_10, 'row')};
      }
    }

    &__labels {
      ${flex('column')};
      ${flexGap(theme.sizes.SIZE_10, 'column')};
    }

    &__footer {
      ${fontSize(theme.fontSizes.FONT_20)};

      margin: 0;
      font-weight: 600;
      margin-top: auto;
      color: var(--PRIMARY_1_1);

      ${phablet(css`
        margin-top: ${theme.sizes.SIZE_24};
      `)}
    }
  }
`;
