import { useMemo, useCallback, useContext, FC } from 'react';
import { Form, FormField, FormRef, useFormRefValues } from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Heading,
  getColor,
  useCallbackRef,
  useUtilities,
  Image,
} from '@faxi/web-component-library';

import {
  FormActions,
  GoogleAutocompleteField,
  PageLayout,
  Containers,
  Icon,
} from 'components';

import { UserContext } from 'store';
import { useNavigate } from 'react-router-dom';

const UpdateAddressForm: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const { user, onboardingStep, updateUser, setOnboardingStep } =
    useContext(UserContext);

  const { showOverlay, hideOverlay } = useUtilities();

  const navigate = useNavigate();

  const [form, formRef] = useCallbackRef<FormRef>();

  const handleSubmit = useCallback(
    async ({ formatted_address }: any) => {
      if (!user) {
        return;
      }

      showOverlay('.kinto-page');

      try {
        await updateUser(user.id, formatted_address, 'PUT');

        if (onboardingStep === 2) {
          setOnboardingStep(3);
          navigate(`/home`);
        }

        form.updateValueField('formatted_address', formatted_address);
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.kinto-page');
      }
    },
    [
      user,
      showOverlay,
      updateUser,
      onboardingStep,
      form,
      setOnboardingStep,
      navigate,
      hideOverlay,
    ]
  );

  const formValues = useFormRefValues(form, 'formatted_address');

  const initialData = useMemo(
    () => ({
      formatted_address: {
        formatted_address: user?.formatted_address,
        lat: user?.lat,
        lng: user?.lng,
        city: user?.city,
        country: user?.country,
      },
    }),
    [user]
  );

  const disableSaveButton = useMemo(
    () =>
      initialData.formatted_address.formatted_address ===
        formValues?.formatted_address?.formatted_address ||
      !form?.asyncFormValid ||
      !form?.syncFormValid,

    [
      form?.asyncFormValid,
      form?.syncFormValid,
      formValues?.formatted_address,
      initialData.formatted_address,
    ]
  );

  return (
    <PageLayout className="kinto-page">
      <div className="kinto-page__header__actions">
        <Button
          variant="ghost"
          icon={<Icon name="chevron-left" />}
          onClick={() => navigate(`/account-settings/update-profile`)}
        >
          {t('Back')}
        </Button>

        <Button
          variant="ghost"
          icon={<Icon name="xmark" />}
          onClick={() => navigate(`/home`)}
          className="kinto-page__header__actions__close-btn"
        />
      </div>

      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('account-personal_details_update_address')}
      </Heading>

      {/* TODO: consider eliminating the SettingsForm component */}
      <Containers.SettingsForm>
        <Form
          id="address_form"
          ref={formRef}
          initialData={initialData}
          onSubmit={handleSubmit}
          className="update-address-form"
        >
          <fieldset className="form__fields">
            <legend data-hidden hidden>
              {t('settings_home_title')}
            </legend>

            <p>{t('onboarding-body_add_your_pick_up_address')}</p>

            <FormField
              name="formatted_address"
              id="new_community_address"
              component={GoogleAutocompleteField}
              placeholder={t('address_header')}
              required
              requiredLabel={t('global-input_field_required_label')}
            />
            <FormActions className="form__actions">
              <Button
                type="submit"
                id="submit_address"
                disabled={disableSaveButton}
              >
                {onboardingStep >= 3 ? t('Save') : t('Done')}
              </Button>
            </FormActions>
          </fieldset>

          <Image
            alt="Update profile address"
            src="/assets/svg/update_address.svg"
          />
        </Form>
      </Containers.SettingsForm>
    </PageLayout>
  );
};

export default UpdateAddressForm;
