import { createContext } from 'react';

interface GoogleContextProps {
  clientId: string;
  scriptLoaded: boolean;
}

const GoogleContext = createContext<GoogleContextProps>({
  clientId: '',
  scriptLoaded: false,
});

export default GoogleContext;
