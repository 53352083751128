import { theme } from '@faxi/web-component-library';
import { flex, fontSize, pxToRem } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const InfoCardContainer = styled.div`
  ${flex('row')};
  gap: ${theme.sizes.SIZE_16};
  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  color: var(--LABEL_TEXT_DARK_1_1);
  background-color: var(--LABEL_TEXT_1_7);

  .kinto-info-card {
    .wcl-icon {
      min-height: ${pxToRem(theme.sizes.SIZE_24)};
    }

    &__content {
      ${flex('row', 'flex-start', 'center')};
      width: 100%;
      gap: ${theme.sizes.SIZE_16};
    }

    &__text {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      margin: unset;
      font-weight: 400;
      max-width: ${pxToRem(theme.sizes.SIZE_664)};
    }

    &__link {
      margin-left: auto;
      font-weight: 500;
      text-align: left;
      width: fit-content;
      white-space: nowrap;
      color: var(--LABEL_TEXT_DARK_1_1);
    }
  }

  &.kinto-info-card {
    &--vertical {
      .wcl-icon {
        align-items: flex-start;
        margin-top: ${theme.sizes.SIZE_8};
      }

      .kinto-info-card {
        &__content {
          flex-direction: column;
          gap: ${theme.sizes.SIZE_20};
        }
      }
    }
  }
`;
