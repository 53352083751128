import { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';

import { Shift, ShiftType } from 'models';

import * as Styled from './ShiftElement.styles';

const ShiftElement: FC<{
  shift: Shift;
  children?: ReactNode;
}> = ({ shift, children }): JSX.Element => {
  const shiftType: ShiftType = useMemo(() => {
    const startTime = parseInt(shift?.start_time || '');
    if (startTime >= 6 && startTime < 15) {
      return {
        type: 'morning',
        icon: 'ic_sun',
      };
    } else if (startTime >= 15 && startTime < 20) {
      return {
        type: 'afternoon',
        icon: 'ic_sunset',
      };
    } else
      return {
        type: 'night',
        icon: 'ic_moon',
      };
  }, [shift]);

  return (
    <Styled.ShiftElement
      className={classNames(
        'kinto-shift-element',
        `kinto-shift-element--${shiftType.type}`
      )}
    >
      {children}
    </Styled.ShiftElement>
  );
};

export default ShiftElement;
