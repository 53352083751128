import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Container = styled.div`
  .campaign-details {
    &__reward-instances {
      ${flex('row')};

      flex-wrap: wrap;
      gap: ${theme.sizes.SIZE_10};
    }

    &__reward-container {
      width: 100%;
      padding: ${theme.sizes.SIZE_16};
      border-radius: ${theme.sizes.SIZE_8};
      border: 1px solid var(--SHADE_1_5);
      background-color: var(--BACKGROUND_1_1);
    }

    &__detail {
      ${flex('column')};
      margin-bottom: ${theme.sizes.SIZE_40};
      background-color: var(--BACKGROUND_2_1);

      &__title {
        ${fontSize(theme.fontSizes.FONT_16)};
        font-weight: 600;
        color: var(--SHADE_1_1);
        margin-bottom: ${theme.sizes.SIZE_8};
      }

      &__subtitle {
        ${fontSize(theme.fontSizes.FONT_11)};
        margin-bottom: ${theme.sizes.SIZE_4};
        color: var(--SHADE_1_2);
      }

      &__data {
        ${fontSize(theme.fontSizes.FONT_14)};
        ${flex('row', undefined, 'center')};

        width: fit-content;

        font-weight: 400;
        border: 1px solid var(--SHADE_1_4);
        background-color: var(--BACKGROUND_1_1);
        padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
        border-radius: ${theme.sizes.SIZE_8};
        margin-bottom: ${theme.sizes.SIZE_4};

        .wcl-icon {
          margin-right: ${theme.sizes.SIZE_12};
        }

        &--reward {
          font-weight: 600;
        }

        &--reward-limit {
          margin-left: ${theme.sizes.SIZE_4};
          font-weight: 400;
          text-transform: lowercase;
        }

        &--lowercase {
          text-transform: lowercase;
        }

        > span {
          margin-left: ${theme.sizes.SIZE_4};
        }
      }

      &__date-container {
        ${flex('row')};
        width: 100%;
      }

      &__date {
        ${flex('column')};
        flex: 1;
        margin-bottom: ${theme.sizes.SIZE_8};

        &__label {
          ${fontSize(theme.fontSizes.FONT_11)};
          color: var(--SHADE_1_2);
          margin-bottom: ${theme.sizes.SIZE_4};
        }

        &__value {
          ${flex('row', 'flex-start', 'center')};
          ${fontSize(theme.fontSizes.FONT_14)};

          .wcl-icon {
            margin-right: ${theme.sizes.SIZE_12};
          }
        }
      }
    }

    &__reward-repeatable {
      color: var(--SHADE_1_2);

      font-size: ${theme.fontSizes.FONT_14};
      font-weight: 400;

      &--value {
        color: var(--SHADE_1_1);
      }
    }
  }
`;
