import { useCallback, useContext, useMemo, useState } from 'react';
import {
  Button,
  Heading,
  Switch,
  useCallbackRef,
} from '@faxi/web-component-library';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginRedirections } from 'hooks';

import {
  Form,
  FormField,
  FormRef,
  validationRegexes,
  validators,
} from '@faxi/web-form';
import regex from 'validation/regex';
import { apiPlatform } from 'modules';
import { AppContext, UserContext } from 'store';
import { User } from 'models';
import { appUri } from 'config';
import {
  PasswordField,
  InputField,
  Microsoft,
  Apple,
  Google,
} from 'components';

import Styled from 'components/_layouts/Containers';

const SignUpForm = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const { platform, defaultCountry } = useContext(AppContext);
  const { setUser } = useContext(UserContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form, formRef] = useCallbackRef<FormRef>();

  const validations = useMemo(
    () => ({
      email: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_email_hint'),
          })
        ),
        validators.general.regex(
          validationRegexes.workEmail,
          t('validation-field_valid_email', {
            fieldname: t('register_email_hint').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          80,
          t('validation-field_validation_max_length', {
            fieldname: t('register_email_hint').toLowerCase(),
            number: 80,
          })
        ),
      ],
      pwd: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('new_password'),
          })
        ),
        validators.general.regex(
          regex.passwordValidator,
          t('validation-field_new_password')
        ),
        validators.general.regex(
          regex.trimWhiteSpaces,
          t('validation-field_new_password')
        ),
      ],
    }),
    [t]
  );

  const handleSignUpUser = useCallback(
    async (values: any) => {
      try {
        const { data } = await apiPlatform.register(
          values.email,
          values.pwd,
          `${platform?.id}`
        );

        if (data.rc === 'ok') {
          setUser(
            (old) => ({ ...old, registeredEmail: values?.email! } as User)
          );

          navigate(`/${appUri.RESEND_EMAIL}`);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [navigate, platform?.id, setUser]
  );

  useLoginRedirections();

  const agreedOnPPandTC = useMemo(
    () => privacyPolicy && termsAndConditions,
    [privacyPolicy, termsAndConditions]
  );

  return (
    <Styled.AuthGrid className="sign-up-form">
      <Form className="form" onSubmit={handleSignUpUser} ref={formRef}>
        <div className="form__fields">
          <Heading level="1">{t('new_onboarding_sign_up')}</Heading>

          <FormField
            className="form__fields__field"
            name="email"
            component={InputField}
            placeholder={t('register_email_hint')}
            validate={validations.email}
            required
            autoComplete="off"
          />

          <FormField
            className="form__fields__field"
            required
            requiredLabel={t('global-input_field_required_label')}
            name="pwd"
            component={PasswordField}
            placeholder={t('new_password')}
            validate={validations.pwd}
            hasRules
            autoComplete="off"
          />

          <div className="form__fields__terms">
            <Switch
              name="privacyPolicy"
              className="form__fields__switch"
              value={privacyPolicy}
              onChange={async (ev) => {
                setPrivacyPolicy((old) => !old);
              }}
              label={
                <Trans
                  i18nKey={t('onboarding-i_agree_to_the_privacy')
                    .replace(/{/g, '<a>$t(')
                    .replace(/}/g, ')</a>')}
                  t={t}
                  components={{
                    a: (
                      <a
                        href={`/corp/privacy-policy/?country=${defaultCountry?.name.parametrify()}&language=${platform?.default_language.parametrify()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('register_privacy_and_policy')}
                      </a>
                    ),
                  }}
                />
              }
            />

            <Switch
              name="termsCondition"
              className="form__fields__switch"
              value={termsAndConditions}
              onChange={async (ev) => {
                setTermsAndConditions((old) => !old);
              }}
              label={
                <Trans
                  i18nKey={t('onboarding-i_agree_to_the_terms')
                    .replace(/{/g, '<a>$t(')
                    .replace(/}/g, ')</a>')}
                  t={t}
                  components={{
                    a: (
                      <a
                        href={`/corp/terms-and-conditions/?country=${defaultCountry?.name.parametrify()}&language=${platform?.default_language.parametrify()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('TermsCondition')}
                      </a>
                    ),
                  }}
                />
              }
            />
          </div>
          <div className="form__fields__commands">
            <div className="form__fields__commands--standard">
              <Button
                id="submit_login"
                type="submit"
                className="login-btn"
                disabled={!form?.syncFormValid || !agreedOnPPandTC}
              >
                {t('onboarding_continue')}
              </Button>
            </div>

            <div className="form__fields__commands--or">
              <span>{t('or')}</span>
            </div>

            <div className="form__fields__commands--social">
              <Apple signup disabled={!agreedOnPPandTC} />
              <Google signup disabled={!agreedOnPPandTC} />
              <Microsoft signup disabled={!agreedOnPPandTC} />
            </div>

            <span className="form__redirect-link">
              <Trans
                i18nKey={t('signup-body_already_have_an_account')
                  .replace(/{/g, '<a>$t(')
                  .replace(/}/g, ')</a>')}
                t={t}
                components={{ a: <Link to="/login" /> }}
              />
            </span>
          </div>
        </div>
      </Form>
    </Styled.AuthGrid>
  );
};

export default SignUpForm;
