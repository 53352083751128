import { elevate_s, theme } from '@faxi/web-component-library';
import {
  flex,
  flexGap,
  fontSize,
  phablet,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const InvitePeopleCode = styled.div`
  ${flex('row')};

  width: 100%;
  padding: ${theme.sizes.SIZE_8} 0;
  margin-top: ${theme.sizes.SIZE_16};

  .invite-people-code {
    &__content {
      ${flex('column')};
      ${flexGap(theme.sizes.SIZE_8, 'column')};

      &__title,
      &__description {
        margin: 0;
        color: var(--SHADE_1_1);
        max-width: ${pxToRem(theme.sizes.SIZE_232)};
      }

      &__title {
        ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};
        font-weight: 600;
      }

      &__description {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        font-weight: 400;
      }
    }

    &__qr-code {
      ${size(theme.sizes.SIZE_176)};
      ${elevate_s};

      margin-left: auto;
      padding: ${theme.sizes.SIZE_8};
      border-radius: ${theme.sizes.SIZE_8};
      border: 1px solid var(--SHADE_1_7);
    }
  }

  ${phablet(css`
    ${flexGap(theme.sizes.SIZE_12, 'column')};
    flex-direction: column;

    .invite-people-code {
      &__qr-code {
        margin-left: unset;
      }
    }
  `)}
`;
