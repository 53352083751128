import { FC, PropsWithChildren } from 'react';
import { Button, Heading, Image } from '@faxi/web-component-library';
import classNames from 'classnames';

import * as Styles from './SurveyPageWrapper.styles';

type SurveyPageWrapperProps = PropsWithChildren<{
  className?: string;
  title?: string;
  subtitle?: string;
  paragraph?: string;
  imageUrl: string;
  buttonLabel?: string;
  onAction?: () => void;
}>;

const SurveyPageWrapper: FC<SurveyPageWrapperProps> = (props) => {
  const {
    className,
    title,
    subtitle,
    paragraph,
    imageUrl,
    buttonLabel,
    onAction,
    children,
  } = props;

  return (
    <Styles.PageWrapper className={classNames('kinto-survey-main', className)}>
      <section className="kinto-survey-main__left-section">
        {title && (
          <Heading
            level="1"
            className="kinto-survey-main__left-section__heading"
          >
            {title}
          </Heading>
        )}

        <Image
          className="kinto-survey-main__left-section__mobile-image"
          src={imageUrl}
          alt=""
        />

        {subtitle && (
          <p className="kinto-survey-main__left-section__subtitle">
            {subtitle}
          </p>
        )}

        {paragraph && (
          <p className="kinto-survey-main__left-section__paragraph">
            {paragraph}
          </p>
        )}

        {children}

        {onAction && (
          <Button
            className="kinto-survey-main__left-section__action"
            type="button"
            variant="primary"
            onClick={onAction}
          >
            {buttonLabel}
          </Button>
        )}
      </section>

      <section className="kinto-survey-main__right-section">
        <Image src={imageUrl} alt="" />
      </section>
    </Styles.PageWrapper>
  );
};

export default SurveyPageWrapper;
