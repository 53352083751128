import { useEffect, useState, useMemo, useCallback, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@faxi/web-component-library';

import AppContext from './App.context';
import { apiCommunity, apiPlatform } from 'modules';
import { Country, Language, Platform } from 'models';

export const LANGUAGE_CODES = {
  sr_Latn: 'sr-Latn-CS',
} as Record<string, string>;

export const getLanguageCode = (code: string) => {
  return LANGUAGE_CODES[code] || code;
};

const AppProvider = (props: { children: ReactNode }): JSX.Element => {
  const { children } = props;

  const { t } = useTranslation();

  const [platform, setPlatform] = useState<Platform>();
  const [allPlatforms, setAllPlatforms] = useState<Platform[]>();
  const [countries, setCountries] = useState<Country[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [loadingCountries, setLoadingCountries] = useState(false);

  const defaultCountry = useMemo(
    () =>
      platform
        ? platform.countries.find(
            (p) => p.code === platform.default_country_code
          )
        : undefined,
    [platform]
  );

  const countryOptions = useMemo(
    () =>
      countries
        .map((el) => ({
          label: t(`global-country_${el.code}`),
          value: el.code,
        }))
        .sort((el1, el2) => (el1.label < el2.label ? -1 : 1)),
    [countries, t]
  );

  const languageOptions = useMemo<SelectOption[]>(
    () =>
      languages?.map(({ label, code }) => ({
        label,
        value: code,
        optionProps: {
          lang: code,
        },
      })),
    [languages]
  );

  const fetchPlatformCountries = useCallback(async () => {
    try {
      setLoadingCountries(true);
      const { data } = await apiCommunity.fetchPlatformCountries();

      if (data.length > 0) {
        const res = data.sort((elem1, elem2) =>
          elem1.name < elem2.name ? -1 : 1
        );

        setCountries(res);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingCountries(false);
    }
  }, []);

  const fetchPlatforms = useCallback(async () => {
    try {
      const {
        location: { origin },
      } = window;

      const platforms = await apiPlatform.getPlatforms();
      setAllPlatforms(platforms);

      // if not matched by domain/uri, fallback to global sandbox which is id: 10
      const platform =
        platforms?.find(
          (p) =>
            origin === p.domain || origin === p.uri || origin.includes(p.domain)
        ) || platforms.find((p) => p.id === 10);

      if (platform) {
        setPlatform(platform);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const fetchLanguages = useCallback(async () => {
    try {
      const { languages } = await apiPlatform.getLanguages();
      setLanguages(languages);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    fetchPlatforms();
  }, [fetchPlatforms]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  return (
    <AppContext.Provider
      value={{
        platform,
        allPlatforms,
        loadingCountries,
        countryOptions,
        defaultCountry,
        languageOptions,
        fetchPlatformCountries,
        fetchLanguages,
        fetchPlatforms,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
