import { theme } from '@faxi/web-component-library';
import { flex} from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Container = styled.div`
  ${flex('column')};

  grid-column-start: 1;
  grid-column-end: 3;

  @media (max-width: 1400px) {
    grid-column-end: 1;
  }

  background-color: var(--BACKGROUND_1_1);
  border-radius: ${theme.sizes.SIZE_8};
  padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_12};

  .earned-rewards-today {
    &__title {
      color: var(--SECONDARY_1_1);
      font-weight: 500;
      font-size: ${theme.fontSizes.FONT_18};
      margin-bottom: ${theme.sizes.SIZE_8};
    }

    &__description {
      font-size: ${theme.fontSizes.FONT_12};
      color: var(--SHADE_1_2);
      margin-bottom: ${theme.sizes.SIZE_20};
    }

    &__table {
      margin-bottom: ${theme.sizes.SIZE_24};
    }
  }
`;
