import { focusStyles, theme } from '@faxi/web-component-library';
import {
  flex,
  flexGap,
  fontSize,
  phablet,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const SwitchCommunityEmail = styled.div`
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_12, 'column')};
  ${focusStyles};

  width: 100%;
  max-width: 90%;
  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  background-color: var(--BACKGROUND_1_1);

  ${phablet(css`
    max-width: unset;
  `)}

  .kinto-switch-community-email {
    &__image {
      ${size(theme.sizes.SIZE_40)};
      border-radius: ${theme.sizes.SIZE_8};
    }

    &__community-details {
      ${flex('row', 'flex-start', 'flex-start')};
      width: 100%;

      .community-title {
        ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};

        margin: 0;
        font-weight: 600;
        color: var(--PRIMARY_1_1);
        margin-left: ${theme.sizes.SIZE_12};
        word-break: break-all;
      }

      .rights-label {
        margin-left: auto;
        color: var(--ALERT_ERROR_DARK_1_1);
        ${fontSize(theme.fontSizes.FONT_14)};
      }
    }

    &__email-switch {
      padding-left: 51px;

      hr {
        ${size('100%', theme.sizes.SIZE_1)};

        border: unset;
        background-color: var(--SHADE_1_7);
      }

      ${phablet(css`
        padding-left: unset;
      `)}
    }

    &__action {
      ${flex('row', 'space-between', 'center')};
      width: 100%;

      .action-title {
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

        margin: 0;
        font-weight: 400;
        color: var(--SHADE_1_2);
      }
    }
  }

  &.kinto-switch-community-email {
    &--disabled {
      .community-title,
      .kinto-switch-community-email__image,
      .kinto-switch-community-email__email-switch {
        opacity: 0.5;
      }

      .wcl-switch {
        pointer-events: none;
      }
    }
  }
`;
