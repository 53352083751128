import { FC, memo, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'styled-components';
import {
  Button,
  Image,
  Modal,
  Spinner,
  getColor,
} from '@faxi/web-component-library';

import Icon from 'components/Icon';

import * as Styled from './ImagePreview.styles';

type ImagePreviewProps = {
  className?: string;
  url?: string;
  alt?: string;
  style?: CSSProperties;
  circularPreview?: boolean;
  fallbackUrl?: string;
  hideActions?: boolean;
  hideModal?: boolean;
  loading?: boolean;
  onEdit?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ImagePreview: FC<ImagePreviewProps> = (props) => {
  const {
    className,
    url,
    alt = '',
    style: pStyle,
    circularPreview,
    hideActions = false,
    hideModal = false,
    loading,
    fallbackUrl = '/assets/svg/gift-icon.svg',
    onDelete,
    onEdit,
  } = props;

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const previewStyle = useMemo(
    () => ({
      ...pStyle,
      ...(circularPreview && { borderRadius: '50%' }),
    }),
    [circularPreview, pStyle]
  );

  return (
    <Styled.FilePreviewContainer
      className={classNames('kinto-image-preview', className)}
    >
      <div
        className={classNames('kinto-image-preview__wrapper', {
          'kinto-image-preview__wrapper--non-exist': !url || hideModal,
        })}
        role="group"
        style={previewStyle}
        aria-label={t('accessibility_open_image_preview')}
        onClick={() => url && setPreviewOpen(true)}
      >
        {loading && (
          <Spinner
            className="kinto-image-preview__spinner"
            color={getColor('--BACKGROUND_1_1')}
            backgroundColor={getColor('--ACCENT_2_4')}
            size={40}
          />
        )}
        <Image
          className="kinto-image-preview__img"
          src={url}
          alt={alt}
          fallbackUrl={fallbackUrl}
        />
      </div>

      {!hideActions && (
        <div className="kinto-image-preview__actions">
          <Button
            iconPosition="left"
            variant="ghost"
            icon={<Icon name="pen" />}
            onClick={onEdit}
          >
            {t('as_edit')}
          </Button>
          <Button
            iconPosition="left"
            variant="delete-ghost"
            icon={<Icon name="trash-can" />}
            onClick={onDelete}
          >
            {t('Discovery_map_delete')}
          </Button>
        </div>
      )}

      {previewOpen && !hideModal && (
        <Modal
          renderAsPortal
          position="center"
          className="image-preview"
          onClose={() => setPreviewOpen(false)}
        >
          <img
            src={url}
            alt={alt}
            style={previewStyle}
            onError={(e) => {
              (e.target as HTMLImageElement).src = fallbackUrl;
            }}
          />
        </Modal>
      )}
    </Styled.FilePreviewContainer>
  );
};

export default memo(ImagePreview);
