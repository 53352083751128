import { useMemo } from 'react';
import { Shift } from 'models';
import { SavedShiftPatternStyled } from '../PredefinedShiftsTab.styles';
import { Icon, Button, getColor } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

export enum SCHEDULE_TIME {
  monday_to_friday = 'shift_settings-every_weekday_monday_to_friday',
  monday_to_sunday = 'shift_settings-everyday',
  custom = 'shift_settings-custom',
}

export enum DAYS_TRANSLATION {
  Mo = 'csd_mon',
  Tu = 'csd_tue',
  We = 'csd_wed',
  Th = 'csd_thu',
  Fr = 'csd_fri',
  Sa = 'csd_sat',
  Su = 'csd_sun',
}

type Days = 'Mo' | 'Tu' | 'We' | 'Th' | 'Fr' | 'Sa' | 'Su';

const SavedShiftPattern = (props: {
  shift: Shift | undefined;
  className?: string;
  onEdit: () => void;
}) => {
  const { shift, className, onEdit } = props;

  const { t } = useTranslation();

  const selectedDays = useMemo<Days[]>(() => {
    switch (shift?.pattern) {
      case 'monday_to_friday':
        return ['Mo', 'Tu', 'We', 'Th', 'Fr'];
      case 'monday_to_sunday':
        return ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
      default:
        return [];
    }
  }, [shift]);

  return (
    <SavedShiftPatternStyled className={className}>
      <div className="saved-shift-pattern">
        <div className="saved-shift-pattern__shift-info">
          <span>{shift?.name}</span>
          <span>{`${shift?.start_time.substring(
            0,
            5
          )} - ${shift?.end_time.substring(0, 5)}`}</span>
        </div>
        {shift?.pattern !== 'custom' ? (
          <>
            <div>
              <span className="saved-shift-pattern__selected-date__label">
                {`${t('shift_settings-selected_date_range')}:`}
              </span>
              <span className="saved-shift-pattern__selected-date__value">
                {` ${t(
                  SCHEDULE_TIME[
                    `${shift?.pattern}` as keyof typeof SCHEDULE_TIME
                  ]
                )}`}
              </span>
            </div>
            <div>
              <span className="saved-shift-pattern__selected-date__label">
                {`${t('shift_settings-selected_days')}:`}
              </span>
              {selectedDays?.map((selectedDay: Days, ind: number) => (
                <span
                  key={ind}
                  className="saved-shift-pattern__selected-date__value"
                >
                  {ind !== selectedDays.length - 1
                    ? ` ${t(
                        DAYS_TRANSLATION[
                          `${selectedDay}` as keyof typeof DAYS_TRANSLATION
                        ]
                      )},`
                    : ` ${t(
                        DAYS_TRANSLATION[
                          `${selectedDay}` as keyof typeof DAYS_TRANSLATION
                        ]
                      )}`}
                </span>
              ))}
            </div>
          </>
        ) : (
          <div>
            <span className="saved-shift-pattern__selected-date__value">
              {` ${t('shifts-pattern_custom_multiple_days_selected')}`}
            </span>
          </div>
        )}
      </div>
      <Button
        variant="ghost"
        className="saved-shift-pattern__edit-btn"
        icon={<Icon name="pen" color={getColor('--PRIMARY_1_1')} />}
        aria-label={t('accessibility-edit_shift_pattern', {
          name: shift?.name,
        })}
        onClick={() => onEdit()}
      />
    </SavedShiftPatternStyled>
  );
};

export default SavedShiftPattern;
