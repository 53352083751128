import { useContext, useRef } from 'react';

import { apiAuth } from 'modules';
import { AppContext, AuthContext } from 'store';
import { useCallbackAsync } from 'hooks';
import useGoogleLogin, {
  CredentialResponse,
} from 'providers/Google/hooks/useGoogleLogin';
import SocialButton from 'components/_atoms/SocialButton';

const Google = (props: { signup?: boolean; disabled?: boolean }) => {
  const { signup, disabled } = props;

  const { applyCredentials } = useContext(AuthContext);
  const initialOneTap = useRef<boolean>(true);

  const { platform } = useContext(AppContext);

  const [socialLoginHandler] = useCallbackAsync({
    showSpinner: true,
    catchAsyncErrors: false,
    callback: async (response: CredentialResponse) => {
      const { credential } = response;
      if (!credential) return;
      initialOneTap.current = false;

      if (signup) {
        const jwtTokenProfile = credential.split('.');
        const userInfo: { name: string } = JSON.parse(atob(jwtTokenProfile[1]));

        const authData = await apiAuth.socialRegister(
          'google',
          credential!,
          userInfo?.name,
          '',
          `${platform?.id}`
        );

        applyCredentials(authData);
      } else {
        const authData = await apiAuth.socialLogin('google', credential);
        applyCredentials(authData);
      }
    },
  });

  const { googleLogin } = useGoogleLogin({
    onSuccess: socialLoginHandler,
    useOneTap: initialOneTap.current,
  });

  return (
    <SocialButton provider="google" onClick={googleLogin} disabled={disabled} />
  );
};

export default Google;
