import { useMemo, useCallback, useContext, useEffect, FC } from 'react';
import {
  Form,
  FormField,
  validators,
  validationRegexes,
  FormRef,
  DataState,
  useFormRefValues,
} from '@faxi/web-form';
import {
  Heading,
  getColor,
  useCallbackRef,
  useFormButtons,
  useUtilities,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { CommunitySettingsContext, UserContext, AppContext } from 'store';

import { Community } from 'models';
import { useCallbackAsync } from 'hooks';
import { snackBarSuccessMessage } from 'utils';
import { apiCommunity } from 'modules';
import { currencyOptions } from 'config';

import {
  GoogleAutocompleteField,
  TextareaField,
  SwitchField,
  ImageDropzoneField,
  InputField,
  SelectField,
  FormActions,
} from 'components';

import specific from 'validation/validators/specific';
import regex from 'validation/regex';
import numberValidation from 'validation/validators/numberValidation';

import { SettingsForm } from '../../_layouts/Containers';
import { PageLayout } from '../../_layouts';

import * as Styled from './CommunityDetailsForm.styles';

const CommunityDetailsForm: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const { countryOptions, fetchPlatformCountries } = useContext(AppContext);
  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const {
    community,
    loadingCommunity,
    shouldUpdateCommunity,
    updateSettingsCommunity,
    updateCommunityDescription,
  } = useContext(CommunitySettingsContext);

  const { communityId, updateCommunity } = useContext(UserContext);

  const initialData = useMemo<Record<string, any>>(
    () => ({
      communityDescription: community?.description,
      name: community?.name,
      phone: community?.phone,
      support_email: community?.support_email,
      expected_size: community?.expected_size,
      numberOfJourneys: community?.journeys_limit,
      currency: community?.currency?.toUpperCase(),
      status: community?.status === 'active',
      includeTestUsers: community?.include_test_users === 'Y',
      profile: community?.image_url,
      // this is intentional, it is important that show_address is boolean value in initialData not number
      show_address: !community?.show_address,
      formatted_address: {
        formatted_address: community?.formatted_address,
        lat: community?.lat,
        lng: community?.lng,
        city: community?.city,
        country: community?.country,
      },
    }),
    [community]
  );

  const [form, formRef] = useCallbackRef<FormRef>();
  const formValues = useFormRefValues(form, 'formatted_address');
  const [FormButtons] = useFormButtons({ submitLabel: t('Save') });

  const handleSubmit = useCallback(
    async (data: DataState) => {
      if (!communityId) {
        return;
      }

      delete data['profile'];

      let newData: DataState = data;

      newData = {
        ...newData,
        ...data.formatted_address,
      };

      form.updateValueField('formatted_address', data.formatted_address);

      showOverlay('.kinto-page');

      try {
        const {
          data: { errc, count },
        } = await apiCommunity.checkCommunityName(newData.name);
        // if community name is unique
        // rc in response is always 'ok' from backend thing
        // 4006 error is for 'organisation not found'

        if (count === 1 || errc === 4006) {
          try {
            if (form.syncFormValid) {
              if (
                (initialData as { communityDescription: string })
                  .communityDescription !== newData.communityDescription
              ) {
                await updateCommunityDescription(
                  communityId,
                  newData.communityDescription,
                  'PUT'
                );
              }

              if (JSON.stringify(initialData) !== JSON.stringify(newData)) {
                const communityData = {
                  ...newData,
                  name: newData.name,
                  phone: newData.phone,
                  expected_size: newData.expected_size,
                  journeys_limit: newData.numberOfJourneys,
                  description: newData.communityDescription,
                  support_email: newData.support_email,
                  currency: newData.currency,
                  status: newData.status ? 'active' : 'hidden',
                  show_address: +!newData.show_address,
                  include_test_users: newData.includeTestUsers ? 'Y' : 'N',
                };

                await updateCommunity(communityId, communityData, 'PUT');

                updateSettingsCommunity(
                  (old) =>
                    ({
                      ...old,
                      ...communityData,
                    } as Community)
                );
              }

              showSnackBar({
                actionButtonText: t('dismiss'),
                text: t('community_details_successfully_updated'),
                variant: 'success',
              });
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          form.setFieldError('name', t('register_community_exists'));
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.kinto-page');
      }
    },
    [
      communityId,
      form,
      showOverlay,
      initialData,
      showSnackBar,
      t,
      updateCommunityDescription,
      updateCommunity,
      updateSettingsCommunity,
      hideOverlay,
    ]
  );

  const disableSaveButton = useMemo(
    () =>
      (!form?.isFormChanged(['formatted_address']) &&
        initialData.formatted_address.formatted_address ===
          formValues?.formatted_address?.formatted_address) ||
      !form?.syncFormValid ||
      !form?.asyncFormValid,

    [
      form,
      formValues?.formatted_address?.formatted_address,
      initialData.formatted_address.formatted_address,
    ]
  );

  const validations = useMemo(
    () => ({
      communityDescription: [
        validators.general.regex(
          regex.communityDescriptionRegex,
          t('validation-description_validation_type')
        ),
        validators.general.maxLength(
          1000,
          t('validation-field_validation_max_length', {
            fieldname: t('dw_description').toLowerCase(),
            number: '1000',
          })
        ),
      ],
      name: [
        validators.general.required(
          t('validation-field_is_required', { fieldname: t('name') })
        ),
        validators.general.regex(
          validationRegexes.communityName,
          t('validation-name_field_type')
        ),
        validators.general.maxLength(
          60,
          t('validation-field_validation_max_length', {
            fieldname: t('name').toLowerCase(),
            number: '60',
          })
        ),
      ],
      contactNumber: [
        validators.phone.regex(
          specific.PHONE_NUMBER,
          t('validation-field_phone_number')
        ),
      ],
      support_email: [
        validators.general.regex(
          validationRegexes.workEmail,
          t('validation-field_valid_email', {
            fieldname: t('register_email_hint').toLowerCase(),
          })
        ),
      ],
      expected_size: [
        specific.positiveNumbersOnly(
          t('validation-field_positive_round_number_only', {
            fieldname: t(
              'community_details-expected_community_size'
            ).toLowerCase(),
          })
        ),
        numberValidation.maxValue(
          t('validation-field_validation_max_value', {
            fieldname: t(
              'community_details-expected_community_size'
            ).toLowerCase(),
            number: 1000000,
          }),
          1000000
        ),
      ],
      numberOfJourneys: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('community_details-number_of_journeys_per_user'),
          })
        ),
        specific.positiveNumbersOnly(
          t('validation-field_positive_round_number_only', {
            fieldname: t(
              'community_details-number_of_journeys_per_user'
            ).toLowerCase(),
          })
        ),
        numberValidation.maxValue(
          t('validation-field_validation_max_value', {
            fieldname: t(
              'community_details-number_of_journeys_per_user'
            ).toLowerCase(),
            number: 100,
          }),
          100
        ),
      ],
    }),
    [t]
  );

  const [uploadCommunityImage] = useCallbackAsync({
    showSpinner: true,
    callback: async (image: File) => {
      if (!communityId) return;

      shouldUpdateCommunity.current = false;

      const { image_url } = await updateCommunity(communityId, {
        file: 'LOGO',
        image,
      });

      snackBarSuccessMessage(t('my_account_notification_image_uploaded'));
      updateSettingsCommunity((old) => ({ ...old, image_url } as Community));
      return image_url;
    },
  });

  const [deleteCommunityImage] = useCallbackAsync({
    showSpinner: true,
    callback: async () => {
      if (!communityId) return;
      const response = await updateCommunity(
        communityId,
        {
          file: 'LOGO',
        },
        'DELETE'
      );

      snackBarSuccessMessage(
        t('my_account-personal_image_image_successfully_removed')
      );

      return response;
    },
  });

  useEffect(() => {
    if (countryOptions.length === 0) {
      fetchPlatformCountries();
    }
  }, [countryOptions.length, fetchPlatformCountries]);

  return (
    <PageLayout className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('ga_group_details')}
      </Heading>

      <SettingsForm>
        <Form
          id="community_details_form"
          ref={formRef}
          initialData={initialData}
          onSubmit={handleSubmit}
        >
          <fieldset>
            <legend data-hidden hidden>
              {t('community_image')}
            </legend>
            <FormField
              name="profile"
              loading={loadingCommunity}
              alt={t('community_image')}
              component={ImageDropzoneField}
              title={t('my_account-title_profile_image')}
              fallbackUrl="/assets/svg/users-icon.svg"
              uploadRequest={uploadCommunityImage}
              deleteRequest={deleteCommunityImage}
              accept={{
                'image/png': ['.png'],
                'image/jpg': ['.jpg'],
                'image/jpeg': ['.jpeg'],
              }}
            />
          </fieldset>

          <fieldset className="form__fields">
            <legend data-hidden hidden>
              {t('ga_group_details')}
            </legend>
            <FormField
              id="community_description"
              component={TextareaField}
              placeholder={t('dw_description')}
              name="communityDescription"
              className="community-description"
              rows={6}
              error={false}
              verticalResize
              autoComplete="on"
              validate={validations.communityDescription}
            />

            <FormField
              id="community_name"
              name="name"
              type="text"
              component={InputField}
              required
              placeholder={t('name')}
              className="community-details__name"
              validate={validations.name}
              autoComplete="on"
            />

            <FormField
              id="community_number"
              name="phone"
              type="text"
              component={InputField}
              placeholder={t('dashboard_details_phone_number')}
              className="community-details__number"
              validate={validations.contactNumber}
              autoComplete="on"
            />

            <FormField
              id="community_email"
              name="support_email"
              type="text"
              component={InputField}
              placeholder={t('register_email_hint')}
              validate={validations.support_email}
              autoComplete="on"
            />

            <FormField
              id="community_size"
              name="expected_size"
              type="number"
              component={InputField}
              placeholder={t('community_details-expected_community_size')}
              className="community-details-size"
              validate={validations.expected_size}
              autoComplete="on"
            />
            <FormField
              id="community_journeys_per_user"
              name="numberOfJourneys"
              type="number"
              component={InputField}
              placeholder={t('community_details-number_of_journeys_per_user')}
              className="community-details-no-journeys"
              validate={validations.numberOfJourneys}
              required
              autoComplete="on"
            />

            <FormField
              name="formatted_address"
              id="new_community_address"
              component={GoogleAutocompleteField}
              placeholder={t('address_header')}
              required
            />

            <FormField
              name="currency"
              component={SelectField}
              options={currencyOptions}
              renderAsPortal
              placeholder={t('groupreg_currency')}
              searchable
              searchableNoResultsTitle={t('search_no_results_found')}
            />
          </fieldset>

          <Styled.SwitchContainer>
            <legend className="community-details__helper-text">
              {t('community_settings-title_hide_address')}
            </legend>
            <FormField
              name="show_address"
              label={t('community_settings-info_hide_address')}
              aria-label={t('community_settings-info_hide_address')}
              component={SwitchField}
            />
          </Styled.SwitchContainer>

          <Styled.SwitchContainer>
            <legend className="community-details__helper-text">
              {t('global-visible')}
            </legend>
            <FormField
              name="status"
              label={t('community_settings-description_show_community')}
              aria-label={t('community_settings-description_show_community')}
              component={SwitchField}
            />
          </Styled.SwitchContainer>

          <Styled.SwitchContainer>
            <legend className="community-details__helper-text">
              {t('my_settings-title_show_test_users')}
            </legend>
            <FormField
              id="include_test_users"
              name="includeTestUsers"
              label={t('my_settings-body_you_will_see_test_users')}
              aria-label={t('my_settings-body_you_will_see_test_users')}
              component={SwitchField}
            />
          </Styled.SwitchContainer>

          <FormActions className="form__actions">
            <FormButtons.Submit disabled={disableSaveButton} />
          </FormActions>
        </Form>
      </SettingsForm>
    </PageLayout>
  );
};

export default CommunityDetailsForm;
