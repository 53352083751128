import styled, { css } from 'styled-components';
import { NavLink as RouterLink } from 'react-router-dom';
import { fontSize, phablet, position, pxToRem } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const Link = styled(RouterLink)`
  ${fontSize(theme.sizes.SIZE_16, theme.sizes.SIZE_24)};
  ${position(
    'fixed',
    `top ${pxToRem(theme.sizes.SIZE_8)} left ${pxToRem(theme.sizes.SIZE_72)}`
  )};

  opacity: 0;
  z-index: 4;
  font-weight: 500;
  pointer-events: none;
  text-decoration: none;
  padding: ${theme.sizes.SIZE_8};
  border-radius: ${theme.sizes.SIZE_8};
  color: var(--BACKGROUND_1_1);
  background: var(--PRIMARY_1_1);

  &:focus {
    opacity: 1;
    outline-offset: 0;
    pointer-events: all;
    outline: 3px solid var(--ACCENT_1_1);
  }

  &:hover {
    background-color: var(--PRIMARY_2_1);
  }

  ${phablet(css`
    ${position(
      'absolute',
      `top ${pxToRem(theme.sizes.SIZE_72)} left ${pxToRem(
        theme.sizes.SIZE_10
      )}}`
    )};
  `)}
`;
