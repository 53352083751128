import XRegExp from 'xregexp';

const customInputLabelAndShiftNameRegex = XRegExp(
  '^[\\p{L}\\p{M}\\p{Number}\\p{Space_Separator}\\-_$&@]*$'
);

const dateURLRegex = XRegExp('^\\d{4}-\\d{2}-\\d{2}$');

const passwordSpecialCharacter = XRegExp(
  '^.*(?=.*[~!@#$%^&?<>*-+.’,/\'":()]).+?$'
);

const trimWhiteSpaces = XRegExp('^[^ ].*[^ ]$');

const passwordValidator = XRegExp(
  '^(?=.*[\\p{Ll}\\p{M}])(?=.*[\\p{Lu}\\p{M}])(?=.*[0-9])(?=.*[ !"#$%&\'()*+,-./:;<=>?@\\[\\]^_`{|}~])[\\p{Ll}\\p{M}\\p{Lu}\\p{M} 0-9!"#$%&\'()*+,-./:;<=>?@\\[\\]^_`{|}~]{10,}$'
);

const communityDescriptionRegex = XRegExp(
  '^([\\p{L}0-9_\\-/$£€@#()!%+\\.&, ]*(?!(\r\n|\r|\n))[\\p{L}0-9_\\-/$£€@#()!%+\\.&, ]*)$'
);

const countyRegex = XRegExp("^([\\p{L}\\{M}\\s'.-]*)$");

const carParkRegex = XRegExp("^[\\p{L}\\p{M}0-9_+-.,&`' @\t]*$");

const domainRegex = /^[a-z0-9]+(-[a-z0-9]+)?(\.[a-z]{2,}){1,}/i;

export default {
  customInputLabelAndShiftNameRegex,
  dateURLRegex,
  passwordSpecialCharacter,
  trimWhiteSpaces,
  passwordValidator,
  communityDescriptionRegex,
  countyRegex,
  carParkRegex,
  domainRegex,
};
