import { FC, useCallback, useMemo } from 'react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FieldProps } from '@faxi/web-form';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import {
  Calendar,
  Dropdown,
  Input,
  DropdownRef,
  InputProps,
  useQueryParams,
  CalendarValue,
  CalendarProps,
  DropdownProps,
  useCallbackRef,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import config from 'config';
import { CALENDAR_LABELS } from './utils';

import * as Styled from './CalendarField.styles';

dayjs.extend(customParseFormat);

const NON_FOCUSABLE_NODES = ['INPUT', 'TEXTAREA'];

type CalendarFieldProps = {
  leftLimitDate?: Dayjs;
  rightLimitDate?: Dayjs;
  renderAsPortal?: boolean;
  dateFormat?: string;
  onSetDate?: (date: Dayjs | null) => void;
  onChange?: (date: Dayjs | null) => void;
} & Omit<InputProps, 'value' | 'onBlur'> &
  Pick<
    CalendarProps,
    'disableFuture' | 'disablePast' | 'mode' | 'value' | 'disabledDates'
  > &
  Pick<DropdownProps, 'openPosition'> &
  FieldProps<string, (event: string) => void>;

const CalendarField: FC<CalendarFieldProps> = (
  props: CalendarFieldProps
): JSX.Element => {
  const {
    dirty,
    className,
    placeholder,
    renderAsPortal,
    leftLimitDate,
    rightLimitDate,
    value: pValue,
    disableFuture,
    disabledDates,
    disablePast,
    required,
    openPosition,
    error,
    touched,
    dateFormat = config.dateFormat,
    onSetDate,
    onChange,
    onBlur,
    disabled,
    ...rest
  } = props;

  const {
    params: { search },
  } = useQueryParams<{ search: string }>();

  const { t } = useTranslation();

  const [dropdown, dropdownRef] = useCallbackRef<DropdownRef>();
  const hasError = useMemo(() => error && touched, [error, touched]);

  const calendarLabels = useMemo(
    () => ({
      arrowLeftDay: t('accessibility-arrow_left_day'),
      arrowRightDay: t('accessibility-arrow_right_day'),
      arrowLeftMonth: t('accessibility-arrow_left_month'),
      arrowRightMonth: t('accessibility-arrow_right_month'),
      arrowLeftYear: t('accessibility-arrow_left_year'),
      arrowRightYear: t('accessibility-arrow_right_year'),
      closeButton: t('accessibility-calendar_close-button'),
    }),
    [t]
  );

  const value = useMemo(
    () =>
      search
        ? dayjs(search).format(dateFormat)
        : pValue && pValue !== '-'
        ? dayjs(pValue, config.dateFormat).format(dateFormat)
        : undefined,
    [dateFormat, pValue, search]
  );

  const closeDropdown = useCallback(() => {
    dropdown?.trigger.focus();
    dropdown?.setOpen(false);
  }, [dropdown]);

  const handleOnChange = useCallback(
    (date?: CalendarValue) => {
      if (!date) return;

      onSetDate?.(date as Dayjs);
      onChange?.(date as Dayjs);
    },
    [onSetDate, onChange]
  );

  return (
    <Styled.Container className={classNames('calendar-container', className)}>
      <Dropdown
        ref={dropdownRef}
        hasCloudArrow={false}
        openPosition={openPosition}
        renderAsPortal={renderAsPortal}
        portalStyle={Styled.PortalContainer}
        disabled={disabled}
        onClose={() => {
          const activeNode = document.activeElement?.nodeName as string;
          if (NON_FOCUSABLE_NODES.includes(activeNode)) return;
          dropdown.trigger?.focus();
        }}
        onOpen={() => {
          setTimeout(() => {
            const closeBtn = document.querySelector("[class*='close-button']");
            if (closeBtn) (closeBtn as HTMLElement).focus();
          });
        }}
        trigger={
          <Input
            value={value}
            readOnly={true}
            error={hasError}
            errorText={String(error)}
            placeholder={placeholder}
            required={required}
            {...(required && {
              requiredLabel: t('global-input_field_required_label'),
            })}
            prefixIcon={
              <Icon
                name="calendar-days"
                onClick={(e) => {
                  e.preventDefault();
                }}
              />
            }
            disabled={disabled}
            onBlur={(e) => {
              if (!dropdown?.open) {
                onBlur?.(e);
              }
            }}
            {...rest}
          />
        }
        body={
          <Calendar
            keepFocus={dropdown?.open}
            labels={CALENDAR_LABELS}
            dateFormat={dateFormat}
            disablePast={disablePast}
            disableFuture={disableFuture}
            leftLimitDate={leftLimitDate}
            disabledDates={disabledDates}
            rightLimitDate={rightLimitDate}
            calendarAriaLabels={calendarLabels}
            {...(value && { value: dayjs(value, dateFormat) })}
            onClose={closeDropdown}
            onChange={handleOnChange}
            onEnter={closeDropdown}
          />
        }
      />
    </Styled.Container>
  );
};

export default CalendarField;
