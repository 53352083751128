import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { uniqBy, debounce } from 'lodash';
import {
  SortDirections,
  SortOption,
  useEffectSkipFirst,
  useLatestRef,
  useQueryParams,
  useStateQueries,
  useUtilities,
} from '@faxi/web-component-library';
import { isNonEmptyString } from 'utils';

type SortQueryParams = {
  sortBy: string;
  sortDirection: SortDirections;
};

type InfiniteProps<
  /**
   * T is API data array type, data which is to be processed
   */
  T extends Record<string, any>,
  /**
   * S is the type of data we will use in the end, returned by `mappingFunction` if provided, else infer initial given type T
   */
  S extends Record<string, any> = T
> = {
  deps?: any[];
  resetDeps?: any[];
  perPage: number;
  condition?: boolean;
  skipFirst?: boolean;
  defaultItems?: S[];
  spinnerParent?: string;
  initialSearch?: string;
  includeQueryParam?: boolean;
  initialSortBy?: string;
  initialSortDirection?: SortDirections;
  apiRequest: (
    offset: string,
    searchString: string,
    sortBy?: keyof T,
    sortDirection?: SortDirections
  ) => Promise<{ data: T[]; total: number }>;
  onItemsLoad?: (currentPage: number, items: S[]) => void;
  mappingFunction?: (items: T[]) => Promise<S[]>;
};

const useInfinitePagination = <
  T extends Record<string, any>,
  S extends Record<string, any> = T
>({
  deps = [],
  resetDeps = [],
  perPage,
  condition = true,
  skipFirst = false,
  defaultItems = [],
  spinnerParent,
  initialSearch,
  initialSortBy = 'id',
  initialSortDirection = 'asc',
  includeQueryParam = true,
  apiRequest,
  onItemsLoad,
  mappingFunction,
}: InfiniteProps<T, S>) => {
  const { showOverlay, hideOverlay } = useUtilities();

  const [items, setItems] = useState<S[]>(defaultItems);

  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    params: { search },
    setQuery,
  } = useStateQueries<{ search: string }>(
    [
      {
        query: 'search',
        defaultValue: initialSearch || null,
      },
    ],
    includeQueryParam
  );

  const {
    params: { sortBy = initialSortBy, sortDirection = initialSortDirection },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams<SortQueryParams>();

  const [activeSort, setActiveSort] = useState<SortOption<T>>({
    sortBy,
    sortDirection,
  });

  const [loading, setLoading] = useState(true);

  const currentOffset = useRef(0);
  const resetDataFlag = useRef(false);
  const skipFirstRef = useRef(skipFirst);
  const lastSearchString = useRef(search);
  const searchStringChanged = useRef(false);
  const loadRef = useRef(false);
  const lastOffset = useRef<number>();

  const mappingFunctionRef = useLatestRef(mappingFunction);
  const apiRequestRef = useLatestRef(apiRequest);

  const canContinuePagination = useMemo(
    () => currentPage < totalPages,
    [currentPage, totalPages]
  );

  const concatNewItemsUnique = useCallback(
    (newItems: any[], at: 'start' | 'end' = 'end') =>
      setItems((old: S[]) =>
        uniqBy(
          (at === 'start' ? newItems : [])
            .concat(old)
            .concat(at === 'end' ? newItems : []),
          'id'
        )
      ),
    []
  );

  const addItemRemoveOld = useCallback(
    (newItem: S, at: 'start' | 'end' = 'end') => {
      setItems((old) =>
        (at === 'start' ? [newItem] : [])
          .concat(old.filter((i) => i.id !== newItem.id))
          .concat(at === 'end' ? [newItem] : [])
      );
    },
    []
  );

  const applyQueryParam = useCallback(
    (key: keyof SortQueryParams, value: any) => {
      if (value) setQueryParam(key, value, true);
      else removeQueryParam(key, true);
    },
    [removeQueryParam, setQueryParam]
  );

  const updateItem = useCallback(
    (newItem: S) => {
      const index = items.findIndex((i) => i.id === newItem.id);

      if (index > -1) {
        const newItems = [...items];
        const item = { ...items[index], ...newItem };

        newItems[index] = item;

        setItems(newItems);
      }
    },
    [items]
  );

  const getFiles = useCallback(
    async (search = '') => {
      try {
        if (lastOffset.current === currentOffset.current) return;

        setLoading(true);
        spinnerParent && showOverlay(spinnerParent);

        const { data, total } = await apiRequestRef.current(
          `${currentOffset.current}`,
          search,
          activeSort.sortBy,
          activeSort.sortDirection
        );

        let finalItems = data as any;

        if (mappingFunctionRef.current) {
          finalItems = await mappingFunctionRef.current(finalItems);
        }

        if (resetDataFlag.current) {
          setItems(finalItems);
          resetDataFlag.current = false;
        } else if (lastSearchString.current !== search) {
          lastSearchString.current = search;
          setItems(finalItems);
        } else {
          concatNewItemsUnique(finalItems);
        }

        setTotalItems(total);
        setTotalPages(Math.ceil(total / perPage));
        setCurrentPage(Math.floor(currentOffset.current / perPage) + 1);

        loadRef.current = false;
        lastOffset.current = currentOffset.current;

        onItemsLoad?.(currentOffset.current, finalItems);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        setTimeout(() => {
          spinnerParent && hideOverlay(spinnerParent);
        }, 0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [spinnerParent, perPage, activeSort, concatNewItemsUnique]
  );

  const debounceGetFiles = useMemo(() => debounce(getFiles, 250), [getFiles]);

  const onSearchChange = useCallback(
    (search: string) => {
      currentOffset.current = 0;
      lastOffset.current = undefined;

      setTotalItems(1);
      setQuery('search', search);

      //Skip debouncing when search is empty string
      if (isNonEmptyString(search)) {
        debounceGetFiles(search);
        return;
      }
      if (!loadRef.current) getFiles(search);
    },
    [debounceGetFiles, getFiles, setQuery]
  );

  const reset = useCallback(() => {
    resetDataFlag.current = true;
    currentOffset.current = 0;
    lastOffset.current = undefined;

    getFiles();
  }, [getFiles]);

  const handleContainerScroll = useCallback(() => {
    if (!canContinuePagination) return;

    currentOffset.current += perPage;
    setCurrentPage((oldPage) => oldPage + 1);
  }, [canContinuePagination, perPage]);

  const usePaginatedEffect = skipFirstRef.current
    ? useEffectSkipFirst
    : useEffect;

  usePaginatedEffect(() => {
    if (loadRef.current) return;
    if (resetDataFlag.current && currentPage > 1) return;
    if (searchStringChanged.current) loadRef.current = true;

    if (!condition) return;

    getFiles(search ? search : '');
  }, [condition, activeSort, currentPage, ...deps]);

  useEffectSkipFirst(() => {
    reset();
  }, [...resetDeps]);

  return {
    items,
    loading,
    search,
    totalItems,
    canContinuePagination,
    currentPage,
    activeSort,
    reset,
    updateItem,
    setItems,
    setLoading,
    onSearchChange,
    addItemRemoveOld,
    concatNewItemsUnique,
    handleContainerScroll,
    setActiveSort: (sort: keyof T, direction: SortDirections) => {
      resetDataFlag.current = true;
      currentOffset.current = 0;
      lastOffset.current = undefined;

      setCurrentPage(1);

      applyQueryParam('sortBy', sort);
      applyQueryParam('sortDirection', direction);
      setActiveSort({ sortBy: sort, sortDirection: direction });
    },
  };
};

export default useInfinitePagination;
