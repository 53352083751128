import { useCallback, useEffect, useRef, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useUtilities } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { useCallbackAsync } from 'hooks';

export type DropzoneFileType = string | File;

//Image limit is 5MB
const MAX_FILE_SIZE = 5 * 2 ** 20;

export type DropzoneFieldProps = {
  value: DropzoneFileType;
  deleteCondition?: boolean;
  onFileDelete?: (name: string) => void;
  deleteRequest?: (fileName: string) => Promise<any>;
  uploadRequest?: (file: File, fileName: string) => Promise<any>;
} & Partial<DropzoneOptions>;

export default function useDropzoneField(props: DropzoneFieldProps) {
  const {
    value,
    accept,
    deleteCondition,
    uploadRequest,
    deleteRequest,
    onFileDelete,
    ...rest
  } = props;

  const { showSnackBar } = useUtilities();

  const { t } = useTranslation();

  const lastValidImage = useRef<DropzoneFileType>(null);

  const [uploadedFile, setUploadedFile] = useState<DropzoneFileType | null>(
    value
  );

  const handleOnDrop = useCallback(<T extends File>(files: T[]) => {
    //here should be upload request called
    setUploadedFile(files[0]);
  }, []);

  const [deleteFile] = useCallbackAsync({
    spinnerParent: '.kinto-image-preview__wrapper',
    callback: async (file: DropzoneFileType, fileName: string) => {
      const isFile = file instanceof File;

      if (deleteRequest && !isFile) {
        if (deleteCondition || deleteCondition === undefined) {
          await deleteRequest(fileName);
          onFileDelete?.(fileName);
        } else onFileDelete?.(fileName);
      }

      setUploadedFile(null);
    },
  });

  const maxSize = useCallback(
    (file: File) => {
      if (file.size > MAX_FILE_SIZE) {
        const validationMessage = t('file_larger_than', { size: '5MB' });

        showSnackBar({
          actionButtonText: t('dismiss'),
          text: validationMessage,
          variant: 'error',
        });

        return {
          code: 'max-dimensions',
          message: validationMessage,
        };
      }

      return null;
    },
    [showSnackBar, t]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    noKeyboard: true,
    maxSize: MAX_FILE_SIZE,
    accept,
    validator: maxSize,
    onDrop: handleOnDrop,
    ...rest,
  });

  //When value is async then load it in uploaded
  //files when they are ready
  useEffect(() => {
    setUploadedFile(value as string);
  }, [value]);

  return {
    uploadedFile,
    lastValidImage,
    deleteFile,
    setUploadedFile,
    getRootProps,
    getInputProps,
  };
}
