import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLatestRef } from '@faxi/web-component-library';
import { AxiosResponse } from 'axios';

import { CallbackAsyncArgs } from './useCallbackAsync';
import { useAbortController, useCallbackAsync } from 'hooks';

async function defaultQueryFn<Response = any>(
  requestFunc: (signal: () => AbortSignal) => Promise<AxiosResponse<Response>>,
  signal: () => AbortSignal
): Promise<Response> {
  const response = await requestFunc(signal);
  return response.data;
}

type QueryParams<Response = any, MappedResponse = Response> = {
  initialValue?: any;
  queryFn: (signal: () => AbortSignal) => Promise<AxiosResponse<any>>;
  onSuccess?: (data: Response | MappedResponse) => void;
  mappingFunction?: (data: Response) => Promise<MappedResponse>;
} & Omit<CallbackAsyncArgs<Response>, 'callback'>;

export default function useQuery<Response = any, MappedResponse = Response>(
  props: QueryParams<Response, MappedResponse>
) {
  const {
    initialValue,
    condition = true,
    showSpinner = false,
    onSuccess,
    onError,
    queryFn,
    mappingFunction,
    deps = [],
    ...rest
  } = props;

  const queryFnRef = useLatestRef(queryFn);
  const mappingFunctionRef = useLatestRef(mappingFunction);

  const { abortSignal, cancelPreviousRequest } = useAbortController();

  const [data, setData] = useState<MappedResponse>(initialValue);
  const [placeholderActive, setPlaceholderActive] = useState<boolean>(false);

  const [queryData, loading] = useCallbackAsync({
    condition,
    showSpinner,
    onError,
    callback: async () => {
      cancelPreviousRequest();

      let finalData: any = await defaultQueryFn<Response>(
        queryFnRef.current,
        abortSignal
      );

      if (mappingFunctionRef.current) {
        finalData = (await mappingFunctionRef.current(
          finalData
        )) as Awaited<MappedResponse>;
      }

      setData(finalData as MappedResponse);
      onSuccess?.(finalData);

      setPlaceholderActive(() => {
        if ((Array.isArray(finalData) && !finalData.length) || !finalData) {
          return true;
        } else return false;
      });
    },
    deps,
    ...rest,
  });

  const retry = useCallback(queryData, [queryData]);

  const placeholder = useMemo(
    () => placeholderActive && !loading,
    [placeholderActive, loading]
  );

  // TODO: rethink this
  useEffect(() => {
    queryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    data,
    loading,
    placeholder,
    retry,
    setData,
    onSuccess,
    onError,
  };
}
