import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const ShiftElement = styled.div`
  border-radius: ${theme.sizes.SIZE_8};
  padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_10};
  ${flex('row')};
  white-space: nowrap;
  width: fit-content;
  background-color: var(--SHADE_1_7);
  color: var(--SHADE_1_1);
  ${fontSize(theme.fontSizes.FONT_14)};

  @media (min-width: 768px) and (max-width: 936px) {
    width: 100%;
  }

  &.kinto-shift-element {
    &--morning {
      border: ${theme.sizes.SIZE_1} solid var(--SECONDARY_3_1);
    }

    &--afternoon {
      border: ${theme.sizes.SIZE_1} solid var(--SECONDARY_2_1);
    }

    &--night {
      border: ${theme.sizes.SIZE_1} solid var(--SECONDARY_4_1);
    }
  }
`;
