import { focusStyles, theme } from '@faxi/web-component-library';
import {
  flex,
  fontSize,
  phablet,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const PointsForm = styled.div`
  &.kinto-points-form--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &[data-disabled='true'] {
    opacity: 0.4;
    pointer-events: none;
  }

  .overlay {
    border-radius: ${theme.sizes.SIZE_8};
  }

  .points-value {
    ${flex('row', 'flex-start', 'center')};
    ${size('auto', theme.sizes.SIZE_48)}
    ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};
    margin: unset;
  }

  .kinto-points-form {
    &__fields {
      fieldset:first-of-type {
        border: unset;
        padding: unset;
        margin-top: ${theme.sizes.SIZE_24};

        legend {
          color: var(--SHADE_1_2);
          margin-bottom: ${theme.sizes.SIZE_12};
        }
      }
    }

    &__points-switch {
      margin-top: ${theme.sizes.SIZE_20};
    }

    &__warning {
      animation: unset;
      margin: ${theme.sizes.SIZE_16};
    }

    &__input-fields {
      ${flex('row')};

      &--disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      &__bonus {
        color: var(--PRIMARY_1_1);
        width: ${pxToRem(theme.sizes.SIZE_24)};
        margin: ${theme.sizes.SIZE_32} 0 0 ${theme.sizes.SIZE_16};
        font-weight: 600;

        > div:first-of-type {
          ${size(theme.sizes.SIZE_374, theme.sizes.SIZE_48)}
        }
      }
    }

    &__field {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: minmax(${theme.sizes.SIZE_208}, auto) 1fr;
      gap: ${theme.sizes.SIZE_12};
      padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16}
        ${theme.sizes.SIZE_12};
      border-bottom: 1px solid var(--SHADE_1_7);
      margin: 0 -${theme.sizes.SIZE_16};

      .input {
        max-width: ${pxToRem(theme.sizes.SIZE_200)};
      }

      ${phablet(css`
        ${flex('column')};
      `)}
    }

    &__bonus-points {
      max-width: ${theme.sizes.SIZE_240};
      margin: ${theme.sizes.SIZE_24} 0 ${theme.sizes.SIZE_24} 220px;

      ${phablet(css`
        margin: 0;
        margin-top: ${theme.sizes.SIZE_32};
      `)}
    }

    &__filters {
      margin-top: ${theme.sizes.SIZE_48};

      &__header {
        ${flex('column')};
        gap: ${theme.sizes.SIZE_8};

        &__title {
          ${fontSize(theme.fontSizes.FONT_26, theme.sizes.SIZE_32)};
          color: var(--SHADE_1_1);
          font-weight: 600;
          margin: 0;
          ${focusStyles};
        }

        &__description {
          ${fontSize(theme.fontSizes.FONT_12, theme.sizes.SIZE_16)};
          ${focusStyles};
          color: var(--SHADE_1_2);
        }
      }

      &__container {
        display: grid;
        gap: ${theme.sizes.SIZE_8};
        grid-template-columns: repeat(
          auto-fill,
          minmax(${theme.sizes.SIZE_256}, 1fr)
        );
      }

      &__create-btn {
        margin: ${theme.sizes.SIZE_16} 0;
      }
    }

    &__actions {
      margin-top: ${theme.sizes.SIZE_48};
    }
  }
`;
