import styled, { css } from 'styled-components';
import {
  marginChildren,
  flex,
  fontSize,
  laptop,
  maxWidth,
  mobile,
  phablet,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';
import { elevate_l, theme } from '@faxi/web-component-library';

export const Container = styled.div`
  height: 100%;
  padding: ${theme.sizes.SIZE_32};
  background-color: var(--SHADE_1_9);

  > * {
    max-width: ${pxToRem(theme.sizes.SIZE_1064)};
  }

  .profile {
    &__breadcrumbs {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__main-data {
      background-color: var(--BACKGROUND_1_1);
      display: flex;
      align-items: center;
      padding: ${theme.sizes.SIZE_56};
      margin-bottom: ${theme.sizes.SIZE_32};
      border-radius: 8px;

      ${laptop(css`
        padding: ${`${theme.sizes.SIZE_40}
          ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_56}`};
      `)}

      ${phablet(css`
        flex-direction: column;
        align-items: flex-start;
        ${marginChildren(`0 0  ${theme.sizes.SIZE_40} 0`)};
      `)};

      &__avatar-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 20%;
        ${marginChildren(`0 ${theme.sizes.SIZE_48} 0 0`)};

        ${laptop(css`
          margin-right: 10%;
          ${marginChildren(`0 ${theme.sizes.SIZE_32} 0 0`)};
        `)};

        ${maxWidth(
          '900px',
          css`
            margin-right: 5%;
          `
        )};

        ${phablet(css`
          align-items: flex-start;
          flex-direction: column;
          ${marginChildren(`0 0 ${theme.sizes.SIZE_24} 0`)};
        `)};

        &__avatar {
          ${size(theme.sizes.SIZE_72)};
          ${elevate_l};
        }
      }

      &__data-points-container {
        display: flex;
        flex-direction: row;
        ${marginChildren(`0 ${theme.sizes.SIZE_48} 0 0`)};

        ${maxWidth(
          '900px',
          css`
            ${marginChildren(`0 ${theme.sizes.SIZE_32} 0 0`)};
          `
        )};

        ${phablet(css`
          align-items: flex-start;
          flex-direction: column;
          ${marginChildren(`0 0 ${theme.sizes.SIZE_24} 0`)};
        `)};
      }
    }

    &__secondary-data {
      display: flex;
      ${marginChildren(`0 ${theme.sizes.SIZE_32} 0 0`)};
      margin-bottom: ${theme.sizes.SIZE_32};

      ${laptop(css`
        ${marginChildren(`0 0 ${theme.sizes.SIZE_32} 0`)};
        flex-direction: column;
      `)};

      > * {
        background-color: var(--BACKGROUND_1_1);
        padding: ${`${theme.sizes.SIZE_32} ${theme.sizes.SIZE_56} ${theme.sizes.SIZE_72}`};
        width: 100%;
        ${marginChildren(`0 0 ${theme.sizes.SIZE_56} 0`)};
        border-radius: 8px;

        ${phablet(css`
          padding: ${`${theme.sizes.SIZE_56} ${theme.sizes.SIZE_32}
            ${theme.sizes.SIZE_72}`};
        `)};
      }

      &__details,
      &__fields {
        flex: 0 1 60%;

        &__title {
          color: var(--SHADE_1_2);
        }

        ${phablet(css`
          flex: unset;
        `)};
      }

      &__permissions,
      &__journey {
        flex: 0 1 40%;

        ${phablet(css`
          flex: unset;
        `)};

        &__title {
          color: var(--SHADE_1_2);
        }
      }

      &__data-points-container {
        ${marginChildren(`0 0 ${theme.sizes.SIZE_16} 0`)};

        &__empty {
          ${fontSize(theme.fontSizes.FONT_16)};
          color: var(--SHADE_1_3);
        }
      }

      &__journey {
        &__content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: ${theme.sizes.SIZE_10};
          align-items: flex-start;

          @media (min-width: 768px) and (max-width: 1279px) {
            grid-template-columns: repeat(4, 1fr);
          }

          ${mobile(css`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: ${theme.sizes.SIZE_10};
          `)};

          &__box {
            ${flex('column', 'center', 'center')};

            &--label {
              ${fontSize(theme.fontSizes.FONT_14)};

              font-weight: 500;
              width: min-content;
              text-align: center;
              color: var(--SHADE_1_2);
            }

            &__icon {
              margin: ${theme.sizes.SIZE_10} 0;
            }

            &__value {
              font-weight: 600;
              ${fontSize(theme.fontSizes.FONT_24)};
              color: var(--SECONDARY_1_1);
            }
          }
        }
      }
      &__shift-settings {
        flex: 0 1 60%;
        &__dummy {
          flex: 0 1 40%;
          background-color: #f5f5f6;
        }
        &__empty-state-text {
          color: var(--SHADE_1_2);
          margin-bottom: ${theme.sizes.SIZE_40};
        }
        &__add-shift-btn {
          margin-left: ${theme.sizes.SIZE_20};
          margin-top: ${theme.sizes.SIZE_10};
        }
      }
    }
  }
`;
