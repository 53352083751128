import { Modal, theme } from '@faxi/web-component-library';
import { mobile } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const AddressModal = styled(Modal)`
  .form-actions {
    ${mobile(css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: ${theme.sizes.SIZE_8};

      > button {
        width: 100%;
        margin: auto 0;

        &:first-of-type {
          grid-column-end: span 2;
        }
      }

      .delete-btn {
        order: -1;
      }

      .button--ghost {
        order: -2;
      }

      &--non-edit {
        button:first-of-type {
          grid-column-end: span 1;
        }
      }
    `)};
  }
`;
