import { theme } from '@faxi/web-component-library';
import { fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const CommunityElement = styled.p`
  ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_24)};

  color: var(--PRIMARY_1_1);
  font-weight: 400;
  margin: 0;
  word-break: break-word;
  display: flex;
  gap: ${theme.sizes.SIZE_4};

  img {
    ${size(theme.sizes.SIZE_24)};
    border-radius: ${theme.sizes.SIZE_8};
  }
`;
